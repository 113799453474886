const columns = [
  {
    id: 'Id',
    label: 'Id',
    width: '30px'
  },
  {
    id: 'Fecha',
    label: 'Fecha',
    width: '30px'
  },
  {
    id: 'IdPedido',
    label: 'Pedido',
    width: '30px'
  },
  {
    id: 'DescuentoNeto',
    label: 'Dto. €',
    width: '30px'
  },
  {
    id: 'DescuentoPorcentaje',
    label: 'Dto. %',
    width: '30px'
  },
  {
    id: 'Total',
    label: 'Total',
    width: '30px'
  },
  {
    id: 'Abonado',
    label: 'Abonado',
    width: '30px'
  },
  {
    id: 'Metalico',
    label: 'Metálico',
    width: '30px'
  }
];

export default columns;

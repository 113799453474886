const formFields = {
  type: 'Tipo',
  name: 'Cliente',
  description: 'Descripcion',
  status: 'Estado',
  clientId: 'IdCliente',
  contactId: 'IdContacto',
  notes: 'Observaciones',
  deliveryMethod: 'IdMetodoEnvio',
  deliveryName: 'Nombre',
  deliveryLastName: 'Apellidos',
  deliveryAddress: 'Direccion',
  deliveryPostalCode: 'CP',
  deliveryCity: 'Poblacion',
  deliveryRegion: 'Region',
  deliveryProvince: 'RegionCOD',
  deliveryCountry: 'PaisCOD',
  deliveryEmail: 'Email',
  deliveryPhone: 'Telefono',
  deliveryCompany: 'Empresa',
  deliveryNotes: 'ObservacionesEnvio'
};

const formDefaultValues = {
  [formFields.name]: null,
  [formFields.description]: null,
  [formFields.status]: 0,
  [formFields.clientId]: null,
  [formFields.contactId]: null,
  [formFields.notes]: null,
  [formFields.deliveryMethod]: null,
  [formFields.deliveryName]: null,
  [formFields.deliveryLastName]: null,
  [formFields.deliveryAddress]: null,
  [formFields.deliveryPostalCode]: null,
  [formFields.deliveryCity]: null,
  [formFields.deliveryRegion]: null,
  [formFields.deliveryProvince]: '09',
  [formFields.deliveryCountry]: 'ES',
  [formFields.deliveryEmail]: null,
  [formFields.deliveryPhone]: null,
  [formFields.deliveryCompany]: null,
  [formFields.deliveryNotes]: null
};

export { formDefaultValues, formFields };

const columns = [
  {
    id: 'Id',
    label: 'Id',
    width: '15px'
  },
  {
    id: 'Fecha',
    label: 'Fecha',
    width: '30px'
  },
  {
    id: 'SubTotal',
    label: 'Subtotal',
    width: '40px'
  },
  {
    id: 'IVA',
    label: 'IVA',
    width: '40px'
  },
  {
    id: 'TotalDescuentos',
    label: 'Descuentos',
    width: '40px'
  },
  {
    id: 'Total',
    label: 'total',
    width: '40px'
  }
];

export default columns;

import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { Helmet } from 'react-helmet';

import StyledInvoices from './Invoices.style';
import { useAuth, useDispatchAuth } from '../../providers/AuthProvider';
import listTypes from '../../utils/listTypes';
import InvoicesService from '../../services/Invoices';
import useQueryString from '../../hooks/useQueryString';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';
import { formatQueryDate, handleDownload } from '../../utils/functions';
import invoiceDelivery from '../../utils/invoiceDelivery';
import invoiceSeries from '../../utils/invoiceSeries';
import invoiceStatus from '../../utils/invoiceStatus';
import InvoicesFilter from './InvoicesFilter/InvoicesFilter';
import RowItems from './RowItems/RowItems';
import {
  FilterItems,
  Loader,
  ItemsTable,
  DeleteDialog
} from '../../components';
import { DeleteErrorDialog, SendInvoicesAssistant } from '../../molecules';
import columns from './columns';
import InvoiceModal from '../../modals/InvoiceModal/InvoiceModal';

export default function Invoices() {
  const dispatch = useDispatchAuth();
  const invoicesService = new InvoicesService();
  const [invoices, setInvoices] = useState([]);
  const [reload, setReload] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { query, updateQuery } = useQueryString();
  const [selected, setSelected] = useState([]);
  const [filterActive, setFilterActive] = useState(null);
  const [responseErrors, setResponseErrors] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 30,
    page: 1,
    prev: false,
    next: false
  });
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showSendDialog, setShowSendDialog] = useState(false);
  const dispatchSnackbar = useDispatchSnackbar();
  const { lists } = useAuth();
  const usersList = lists.filter((item) => item.type === listTypes.usuario);
  const paymentsList = lists.filter(
    (listItem) => listItem.type === listTypes.formaPago
  );
  const { invoice } = query || {};
  const filterOptions = {
    invoiceDelivery,
    invoiceSeries,
    invoiceStatus
  };

  const handleRouterUpdate = (value) => {
    if (!isLoading) {
      updateQuery(value);
    }
  };

  const handleFilterActive = async (filterQuery) => {
    const { FechaIni, FechaFin, Serie, Estado, TipoEnvio } = filterQuery;
    let hasFilter = false;
    const filters = [];
    if (FechaIni && FechaFin) {
      hasFilter = true;
      filters.push(
        `${formatQueryDate(FechaIni)} a ${formatQueryDate(FechaFin)}`
      );
    }
    if (Estado) {
      const statusName = invoiceStatus.find(
        (item) => item.value.toString() === Estado
      );
      hasFilter = true;
      filters.push(`Estado: ${statusName?.label}`);
    }
    if (Serie) {
      const serieName = invoiceSeries.find(
        (item) => item.value.toString() === Serie
      );
      hasFilter = true;
      filters.push(`Serie: ${serieName?.label}`);
    }
    if (TipoEnvio) {
      const deliveryName = invoiceDelivery.find(
        (item) => item.value.toString() === TipoEnvio
      );
      hasFilter = true;
      filters.push(`Tipo de envío: ${deliveryName?.label}`);
    }
    if (hasFilter) {
      setFilterActive(filters.join(' | '));
    }
  };

  const getInvoices = async () => {
    setIsLoading(true);
    setSelected([]);
    try {
      const response = await invoicesService.getInvoices(
        qs.stringify({ ...query }, { addQueryPrefix: true })
      );
      const {
        Total: total,
        PaginaActual: page,
        Paginacion: limit,
        TieneAnterior: prev,
        TieneSiguiente: next,
        Datos: list
      } = response;
      setPagination({ total, page, limit, prev, next });
      setInvoices(
        list.$values.map((item) => ({
          Id: item.Id,
          Codigo: item.Codigo,
          Fecha: item.Fecha,
          Cliente: item.Cliente,
          Serie: item.Serie,
          Estado: item.Estado,
          FechaEnviada: item.FechaEnviada,
          FechaEnviadaEmail: item.FechaEnviadaEmail,
          payment: paymentsList.find(
            (payment) => payment.value === item.IdFormaPago
          )?.label,
          Total: item.Total,
          user:
            usersList.find((user) => user.id === item?.IdUsuarioCreacion)
              ?.name || 'Importado'
        }))
      );
      handleFilterActive(query);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: 'SET_PAGE',
      payload: 'facturas'
    });
  }, []);

  useEffect(async () => {
    if (!invoice && reload && query instanceof Object) {
      await getInvoices();
    } else if (!reload) {
      setReload(true);
    }
    return function cleanup() {
      setInvoices([]);
    };
  }, [query]);

  const handleClickDelete = () => setConfirmDelete(true);

  const handleCloseDeleteDialog = () => setConfirmDelete(false);

  const handleDeleteInvoices = async () => {
    setConfirmDelete(false);
    setIsLoading(true);
    try {
      const response = await invoicesService.deleteInvoices(selected);
      const ErrorsList = response.$values.filter((item) => !!item.Error);
      if (ErrorsList.length) {
        setResponseErrors(ErrorsList);
      } else {
        dispatchSnackbar({
          type: 'SET',
          payload: 'Facturas eliminadas'
        });
      }
      getInvoices();
      setIsLoading(false);
      setSelected([]);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  const handleEditInvoice = (id) => {
    handleRouterUpdate({ invoice: id });
  };

  const handlePrintInvoices = async () => {
    setIsLoading(true);
    try {
      const response = await invoicesService.printInvoices(selected);
      handleDownload(response);
      setSelected([]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleSendInvoices = () => {
    setShowSendDialog(true);
  };

  const handleCloseSendDialog = () => setShowSendDialog(false);

  const handleLoading = (newstate) => setIsLoading(newstate);

  const handleSelected = (newstate) => setSelected(newstate);

  const extraButtons = [
    {
      label: 'Imprimir',
      onClick: handlePrintInvoices
    },
    {
      label: 'Enviar facturas',
      onClick: handleSendInvoices
    }
  ];

  return (
    <StyledInvoices>
      <Helmet>
        <title>Facturas | Pascual Vinuesa</title>
      </Helmet>
      <Loader show={isLoading} />
      <div className='top-section'>
        <FilterItems isLoading={isLoading} />
      </div>
      <ItemsTable
        handleClickDelete={handleClickDelete}
        FilterForm={InvoicesFilter}
        filterLabel='Filtrar facturas'
        filterActive={filterActive}
        filterOptions={filterOptions}
        setFilterActive={setFilterActive}
        title='Facturas'
        columns={columns}
        items={invoices}
        pagination={pagination}
        isLoading={isLoading}
        selected={selected}
        handleClickRow={handleEditInvoice}
        setSelected={setSelected}
        RowItems={RowItems}
        extraButtons={extraButtons}
      />
      <DeleteDialog
        open={confirmDelete}
        close={handleCloseDeleteDialog}
        handleAccept={handleDeleteInvoices}
        singular='factura'
        plural='facturas'
        quantity={selected.length}
      />
      <DeleteErrorDialog
        errors={responseErrors}
        handleClose={() => setResponseErrors([])}
      />
      {invoice && <InvoiceModal setReload={setReload} />}
      <SendInvoicesAssistant
        showSendDialog={showSendDialog}
        handleCloseSendDialog={handleCloseSendDialog}
        selected={selected}
        handleSelected={handleSelected}
        handleLoading={handleLoading}
        invoices={invoices}
        getInvoices={getInvoices}
        handleEditInvoice={handleEditInvoice}
      />
    </StyledInvoices>
  );
}

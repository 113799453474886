import styled from 'styled-components';

const StyledLineNote = styled.div`
  cursor: pointer;
  svg {
    fill: ${({ theme, empty }) =>
      empty ? theme.colors.gray : theme.colors.blue};
  }
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.orange};
    }
  }
`;

export const StyledNoteDialog = styled.div`
  width: 31rem;
  textarea {
    width: 100%;
    height: 16rem;
    resize: none;
    padding: 0.5rem 1rem;
    margin-bottom: 3.75rem;
  }
  button {
    display: block;
    margin: auto;
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;

export default StyledLineNote;

import { rgba } from 'polished';
import styled, { css } from 'styled-components';

const horizontalStyle = css`
  display: flex;
  align-items: center;
  input {
    margin-left: 1rem;
    margin-top: 0;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  label {
    font-weight: 400;
    ${({ horizontal }) => horizontal && horizontalStyle};
  }
  input {
    font-weight: 300;
    width: 100%;
    margin-top: ${({ label }) => (label ? '0.5rem' : '0')};
    border: 1px solid
      ${({ theme, error }) =>
        error ? rgba(theme.colors.red, 0.5) : theme.colors.gray};
    border-radius: 5px;
    padding: 0.5625rem 1rem;
  }
  .error {
    color: ${({ theme }) => theme.colors.red};
    display: inline-block;
    margin-top: 0.5rem;
  }
`;

export default InputWrapper;

import styled from 'styled-components';

const StyledCompaniesTypeAhead = styled.div`
  .add-client {
    text-align: right;
    cursor: pointer;
  }
`;

export const StyledUserForm = styled.form`
  .radio-buttons {
    display: flex;
    margin-bottom: 1rem;
  }
`;

export default StyledCompaniesTypeAhead;

const columns = [
  {
    id: 'SKU',
    label: 'Referencia',
    width: '30px'
  },
  {
    id: 'Nombre',
    label: 'Nombre',
    width: '100px'
  },
  {
    id: 'BeneficioPorcentaje',
    label: 'Beneficio',
    width: '30px'
  },
  {
    id: 'Cantidad',
    label: 'Cantidad',
    width: '40px'
  },
  {
    id: 'TipoPrecio',
    label: 'Unidad',
    width: '20px'
  },
  {
    id: 'PrecioOriginal',
    label: 'PVP',
    width: '30px'
  },
  {
    id: 'Precio',
    label: 'PVP final',
    width: '30px'
  },
  {
    id: 'DescuentoPorcentaje',
    label: 'Descuento %',
    width: '20px'
  },
  {
    id: 'Total',
    label: 'Total',
    width: '30px'
  },
  {
    id: 'history',
    label: '',
    width: '10px'
  },
  {
    id: 'comments',
    label: '',
    width: '10px'
  }
];

export default columns;

import styled from 'styled-components';

const StyledClock = styled.div`
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.colors.gray};
  }
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.orange};
    }
  }
`;

export default StyledClock;

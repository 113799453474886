import styled from 'styled-components';

const StyledAddressItem = styled.div`
  p {
    margin-bottom: 0.25rem;
  }
  .predeterminada {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    svg.predeterminada-icon {
      margin-right: 0.5rem;
    }
  }
`;

export default StyledAddressItem;

import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import Text from '../Text/Text';
import Button from '../Button/Button';
import StyledDrawer, { StyledDrawerContent } from './Drawer.style';

export default function Drawer({
  open,
  onClose,
  title,
  children,
  onAccept,
  buttonLabel
}) {
  return (
    <StyledDrawer anchor='right' open={open}>
      <StyledDrawerContent>
        <div className='drawer-header'>
          <Text color='white' size='m' bold>
            {title}
          </Text>
          <CloseIcon onClick={onClose} className='close' />
        </div>
        <div className='drawer-content'>{children}</div>
        <div className='drawer-buttons'>
          <Button onClick={onAccept}>{buttonLabel || 'Crear'}</Button>
          <Button outlined onClick={onClose}>
            Cancelar
          </Button>
        </div>
      </StyledDrawerContent>
    </StyledDrawer>
  );
}

import { errorHandler } from './utils';
import instance from './Base';

class Files {
  post = async (files) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    let response;
    try {
      response = await instance.post(
        `/Umbraco/Api/Archivos/Post`,
        formData,
        config
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  deleteFiles = async (files) => {
    let response;
    try {
      response = await instance.post(`/Umbraco/Api/Archivos/Borrar`, files);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };
}

export default Files;

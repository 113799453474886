import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';

import StyledArticleModal from './ArticleModal.style';
import {
  FullDialog,
  Button,
  Input,
  ContentBox,
  Loader,
  Select,
  CompaniesTypeAhead,
  Text,
  CurrencyInput
} from '../../components';
import useQueryString from '../../hooks/useQueryString';
import { ReactComponent as ArticlesIco } from '../../resources/svg/ico-pedidos.svg';
import { tabs, defaultValues } from './constants';
import ArticlesService from '../../services/Article';
import CompaniesService from '../../services/Company';
import relationType from '../../utils/relationType';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';
import { useAuth } from '../../providers/AuthProvider';
import listTypes from '../../utils/listTypes';

export default function ArticleModal({ setReload, modalState, setModalState }) {
  const { query, updateQuery } = useQueryString(modalState);
  const [articleData, setArticleData] = useState({});
  const [isNew, setIsNew] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [manufacturers, setManufacturers] = useState([]);
  const [brands, setBrands] = useState([]);
  const dispatchSnackbar = useDispatchSnackbar();
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [userName, setUserName] = useState(null);
  const { lists } = useAuth();
  const usersList = lists.filter((item) => item.type === listTypes.usuario);
  const companiesService = new CompaniesService();
  const articlesService = new ArticlesService();

  const schema = yup.object().shape({
    IdProveedor: yup.number().nullable(),
    IdMarca: yup.number().nullable(),
    IdFabricante: yup.number().nullable(),
    SKU: yup
      .string()
      .nullable()
      .test('sku-exists', 'Esa referencia ya existe', async (value) => {
        const response = await articlesService.getBySku(value);
        const responseSKUS = response.$values.map((item) => item.SKU);
        const editSKU = articleData.SKU;
        return !(
          responseSKUS.length === 1 &&
          responseSKUS[0] === value &&
          editSKU !== value
        );
      })
      .required('Escribe una referencia'),
    CodigoBarras: yup.string().nullable(),
    Inventario: yup
      .number()
      .nullable()
      .transform((currentValue, originalValue) => {
        return originalValue === '' ? null : currentValue;
      }),
    TopeInventario: yup
      .number()
      .transform((currentValue, originalValue) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable(),
    NombreCompleto: yup.string().nullable().required('Escribe el nombre'),
    NombreCambiado: yup.boolean().nullable(),
    Precio: yup
      .number()
      .transform((currentValue, originalValue) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable()
      .required('Escribe el precio'),
    TipoPrecio: yup.string().nullable(),
    DescuentoProveedor: yup.number().nullable()
  });

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    control,
    getValues,
    formState,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    defaultValues
  });

  const { article, articleTab } = query || {};
  const handleDialog = () => {
    if (modalState) {
      setModalState(null);
    } else {
      updateQuery({ article: undefined, articleTab: undefined });
    }
  };

  const handleTabChange = (index) => {
    updateQuery({ articleTab: tabs[index].slug || tabs[index].label });
  };

  const tabIndex = () => {
    const idxTab = tabs.findIndex(
      (tabItem) => (tabItem.slug || tabItem.label) === articleTab
    );

    return idxTab === -1 ? 0 : idxTab;
  };

  const handleClose = () => {
    if (setReload) {
      setReload(false);
    }
    handleDialog();
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    const data = {
      ...articleData,
      ...values
    };
    if (!isNew && articleData.NombreCompleto !== values.NombreCompleto) {
      data.NombreCambiado = true;
    }
    console.log(data);
    try {
      const response = await articlesService.saveArticle(data);
      handleDialog();
      dispatchSnackbar({
        type: 'SET',
        payload: isNew
          ? `Artículo creado con referencia: ${response.SKU}`
          : 'Artículo Editado'
      });
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
    }
    setIsLoading(false);
  };

  const getOptions = (response) =>
    response?.$values.map((item) => ({
      value: item.Id,
      label: item.Nombre
    }));

  useEffect(async () => {
    if (selectedSupplier) {
      setValue('IdProveedor', selectedSupplier.Id, { shouldDirty: true });
    } else {
      setValue('IdProveedor', null, { shouldDirty: true });
    }
  }, [selectedSupplier]);

  useEffect(async () => {
    if (article) {
      const isNewArticle = article === 'new';
      setIsNew(isNewArticle);
      try {
        const manufacturersResponse = await articlesService.getManufacturers();
        const brandsResponse = await articlesService.getBrands();
        const manufacturerOptions = getOptions(manufacturersResponse);
        const brandOptions = getOptions(brandsResponse);
        setManufacturers(manufacturerOptions);
        setBrands(brandOptions);
        if (!isNewArticle) {
          const response = await articlesService.getArticle(article);
          const { $type, ...rest } = response;
          setArticleData({ ...rest });
          setUserName(
            usersList.find((user) => user.id === response.IdUsuarioCreacion)
              ?.name || 'Desconocido'
          );
          if (response.IdProveedor) {
            const supplierData = await companiesService.getCompany(
              response.IdProveedor
            );
            setSelectedSupplier(supplierData);
          }
          reset({
            IdProveedor: response.IdProveedor,
            IdMarca: response.IdMarca,
            IdFabricante: response.IdFabricante,
            SKU: response.SKU,
            CodigoBarras: response.CodigoBarras,
            Inventario: response.Inventario,
            TopeInventario: response.TopeInventario,
            NombreCompleto: response.NombreCompleto,
            Precio: response.Precio,
            TipoPrecio: response.TipoPrecio,
            DescuentoProveedor: response.DescuentoProveedor
          });
        }
      } catch (error) {
        console.log(error);
        dispatchSnackbar({
          type: 'ERROR',
          payload: 'Ha ocurrido un error'
        });
        handleDialog();
      }
      setIsLoading(false);
    }
  }, [article]);

  return (
    <FullDialog
      open={!!article}
      name={articleData?.NombreCompleto}
      type='Artículo'
      id={articleData?.SKU}
      isNew={isNew}
      icon={<ArticlesIco />}
      handleClose={handleClose}
    >
      <StyledArticleModal>
        {(articleData?.SKU || isNew) && (
          <Helmet>
            <title>
              {isNew
                ? 'Crear artículo | Pascual Vinuesa'
                : `Artículo ${articleData?.SKU} - ${articleData?.NombreCompleto}`}
            </title>
          </Helmet>
        )}
        <Loader show={isLoading} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='top-section'>
            <div className='user-date'>
              {userName ? (
                <Text color='gray'>
                  {userName === 'Desconocido'
                    ? 'Importado'
                    : `Creado por ${userName}`}
                </Text>
              ) : (
                <span />
              )}
            </div>
            <Button secondary type='submit' disabled={!formState.isDirty}>
              Guardar y cerrar
            </Button>
          </div>
          <Tabs
            onSelect={handleTabChange}
            disabledTabClassName='disabled-tab'
            selectedTabClassName='selected-tab'
            className='tabs'
            defaultIndex={tabIndex()}
          >
            <TabList className='tab-list'>
              {tabs.map((tabItem) => (
                <Tab
                  key={tabItem.label}
                  className='tab'
                  disabled={tabItem.disabled}
                >
                  {tabItem.label}
                </Tab>
              ))}
            </TabList>
            <div className='tabs-content'>
              <TabPanel>
                <div className='tab-columns'>
                  <div>
                    <CompaniesTypeAhead
                      selectedClient={selectedSupplier}
                      setSelectedClient={setSelectedSupplier}
                      error={errors?.IdProveedor?.message}
                      type={relationType.supplier}
                    />
                    <ContentBox title='Información del producto'>
                      <Input
                        label='Nombre del artículo'
                        name='NombreCompleto'
                        inputRef={register}
                        placeholder='escribe el nombre del artículo'
                        error={errors?.NombreCompleto?.message}
                      />
                      <Select
                        label='Marca'
                        name='IdMarca'
                        control={control}
                        options={brands}
                        placeholder='marca...'
                        defaultValue={getValues('IdMarca')}
                      />
                      <Select
                        label='Fabricante'
                        name='IdFabricante'
                        control={control}
                        options={manufacturers}
                        placeholder='fabricante...'
                        defaultValue={getValues('IdFabricante')}
                      />
                      <Input
                        label='Referencia'
                        name='SKU'
                        inputRef={register}
                        placeholder='referencia'
                        error={errors?.SKU?.message}
                      />
                      <Input
                        label='Código de barras'
                        name='CodigoBarras'
                        inputRef={register}
                        placeholder='codigo de barras'
                        error={errors?.CodigoBarras?.message}
                      />
                    </ContentBox>
                  </div>
                  <div>
                    <ContentBox title='Stock'>
                      <div className='stock-box'>
                        <Input
                          label='Inventario'
                          name='Inventario'
                          inputRef={register}
                          placeholder='inventario'
                          error={errors?.Inventario?.message}
                        />
                        <Input
                          label='Tope'
                          name='TopeInventario'
                          inputRef={register}
                          placeholder='tope'
                          error={errors?.TopeInventario?.message}
                        />
                      </div>
                    </ContentBox>
                    <ContentBox title='Precios'>
                      <CurrencyInput
                        control={control}
                        label='Precio'
                        name='Precio'
                        placeholder='precio'
                        error={errors?.Precio?.message}
                      />
                      <Input
                        label='Tipo unidad'
                        name='TipoPrecio'
                        inputRef={register}
                        placeholder='tipo unidad'
                        error={errors?.TipoPrecio?.message}
                      />
                      <CurrencyInput
                        label='Descuento proveedor %'
                        control={control}
                        name='DescuentoProveedor'
                        placeholder='descuento proveedor'
                        percentaje
                        error={errors?.DescuentoProveedor?.message}
                      />
                    </ContentBox>
                  </div>
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </form>
      </StyledArticleModal>
    </FullDialog>
  );
}

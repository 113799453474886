import React, { useReducer, useContext, createContext, useEffect } from 'react';

import AuthService from '../services/Auth';

import { clearToken, getAccessToken } from '../utils/localStorage';
import { getLists } from '../utils/functions';

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        name: action.payload.user,
        email: action.payload.email,
        lists: action.payload.lists,
        loading: false
      };
    case 'LOGOUT':
      return {
        name: undefined,
        email: undefined,
        lists: undefined,
        loading: false
      };
    case 'LOADED':
      return {
        ...state,
        loading: false
      };
    case 'ADD_ITEM':
      return {
        ...state,
        lists: [...state.lists, { ...action.payload }]
      };
    case 'SET_PAGE':
      return {
        ...state,
        currentPage: action.payload
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export default function AuthProvider({ children }) {
  const authService = new AuthService();

  const getToken = async () => {
    const token = getAccessToken();
    let userData;
    if (token) {
      try {
        userData = await authService.me();
      } catch (error) {
        // throw new Error({ message: 'something wrong happened' });
        console.log(error);
      }
      return userData;
    }
    return undefined;
  };

  const [state, dispatch] = useReducer(reducer, {
    name: undefined,
    email: undefined,
    lists: undefined,
    loading: true,
    currentPage: ''
  });
  useEffect(async () => {
    const user = await getToken();
    if (user) {
      const lists = await getLists();
      dispatch({
        type: 'LOGIN',
        payload: {
          user: user.Nombre,
          email: user.Email,
          lists
        }
      });
    } else {
      clearToken();
      dispatch({
        type: 'LOADED'
      });
    }
  }, []);
  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
}

export const useAuth = () => useContext(AuthStateContext);
export const useDispatchAuth = () => useContext(AuthDispatchContext);

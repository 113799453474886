import React, { useState } from 'react';

import StockModal from '../../modals/StockModal/StockModal';
import { ReactComponent as RelojIco } from '../../resources/svg/ico-reloj.svg';

import StyledClock from './Clock.style';

export default function Clock({ Nombre, SKU, TipoPrecio }) {
  const [openStockModal, setOpenStockModal] = useState(false);

  const handleOpenStockModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenStockModal(true);
  };

  const handleCloseStockModal = (e) => {
    setOpenStockModal(false);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <StyledClock className='clock' onClick={handleOpenStockModal}>
        <RelojIco />
      </StyledClock>
      {openStockModal && (
        <StockModal
          open={openStockModal}
          handleClose={(e) => handleCloseStockModal(e)}
          Nombre={Nombre}
          TipoPrecio={TipoPrecio}
          SKU={SKU}
        />
      )}
    </>
  );
}

import styled from 'styled-components';

const StyledDocumentation = styled.div`
  position: relative;
  .file-upload {
    display: flex;
    justify-content: flex-end;
  }
  .order-files {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > div {
      width: 32%;
    }
  }
`;

export default StyledDocumentation;

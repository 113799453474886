import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { rgba } from 'polished';

import OswaldRegular from '../resources/fonts/Oswald-Regular.ttf';
import OswalLight from '../resources/fonts/Oswald-Light.ttf';

const GlobalStyle = createGlobalStyle`
 ${normalize}
 @font-face {
    font-family: "Oswald";
    src: url('${OswaldRegular}');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Oswald";
    src: url('${OswalLight}');
    font-weight: 300;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  html {
    color: ${({ theme }) => theme.colors.text};
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: ${({ theme }) => theme.fonts.s}
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
    }
  }

  h1, h2, h3, h4, h5, h6{
    margin: 0;
  }

  p {
    margin: 0;
  }

  a{
    color: inherit;
    text-decoration: inherit;
  }

  button{
    all: unset
  }

  .cell-body {
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 1rem;
  }

  .MuiTableCell-root {
    border-bottom: none;
    border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.gray, 0.3)};
  }

  .MuiTableCell-sizeSmall {
    padding: 6px 16px 6px 12px;
    @media (max-width: 1024px) {
      padding: 6px 2px 6px 1px;
    }
  }
`;

export default GlobalStyle;

import styled from 'styled-components';

const FormRow = styled.div`
  display: flex;
  &.layout-4-6 {
    & > div:first-child,
    .first {
      flex: 4;
      margin-right: 1rem;
    }
    & > div:last-child,
    .second {
      flex: 6;
    }
  }
  &.layout-6-4 {
    & > div:first-child,
    .first {
      flex: 6;
      margin-right: 1rem;
    }
    & > div:last-child,
    .second {
      flex: 4;
    }
  }
  &.layout-3-7 {
    & > div:first-child,
    .first {
      flex: 3;
      margin-right: 1rem;
    }
    & > div:last-child,
    .second {
      flex: 7;
    }
  }
  &.layout-7-3 {
    & > div:first-child,
    .first {
      flex: 7;
      margin-right: 1rem;
    }
    & > div:last-child,
    .second {
      flex: 3;
    }
  }
  &.layout-1-1 {
    & > div:first-child,
    .first {
      flex: 1;
      margin-right: 1rem;
    }
    & > div:last-child,
    .second {
      flex: 1;
    }
  }
`;

export default FormRow;

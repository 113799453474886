import styled from 'styled-components';
import { rgba } from 'polished';

export const StyledSendModal = styled.div`
  width: 32rem;
  p {
    margin-bottom: 3rem;
    color: ${({ theme }) => theme.colors.itemsOptionsText};
    .selected-invoices {
      text-decoration: underline;
      font-weight: 400;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 2rem;
    button {
      width: 11.25rem;
      text-align: center;
      svg {
        fill: ${({ theme }) => theme.colors.blue};
        margin-right: 0.5rem;
      }
    }
  }
`;

export const StyledSendEmailResults = styled.div`
  width: 32rem;
  .total-sent {
    padding: 1rem;
    background-color: ${({ theme }) => rgba(theme.colors.lightGray, 0.6)};
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    margin-bottom: 3rem;
    font-weight: 400;
  }
  .email-errors {
    padding: 1rem;
    padding-left: 4rem;
    background-color: ${({ theme }) => rgba(theme.colors.lightGray, 0.6)};
    .error-code {
      color: ${({ theme }) => theme.colors.orange};
      text-decoration: underline;
      cursor: pointer;
    }
    .error-message {
      color: ${({ theme }) => theme.colors.text};
    }
  }
`;

export const StyledSendPostalDialog = styled.div`
  width: 32rem;
  p {
    margin-bottom: 3rem;
    color: ${({ theme }) => theme.colors.itemsOptionsText};
    .selected-invoices {
      text-decoration: underline;
      font-weight: 400;
    }
  }
  form {
    .date-input {
      padding: 1rem;
      background-color: ${({ theme }) => rgba(theme.colors.lightGray, 0.6)};
      margin: auto;
      margin-bottom: 1rem;
      justify-content: center;
      display: flex;
      label {
        color: ${({ theme }) => theme.colors.text};
      }
    }
    button {
      display: block;
      margin: auto;
    }
  }
`;

export const StyledSendPostalResults = styled.div`
  width: 32rem;
  p {
    padding: 1rem;
    background-color: ${({ theme }) => rgba(theme.colors.lightGray, 0.6)};
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    margin-bottom: 3rem;
    font-weight: 400;
  }
`;

import styled from 'styled-components';

const StyledDeliveryModal = styled.div`
  position: relative;
  .loader {
    z-index: 9999;
  }
  .top-section {
    margin: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .modal-fields {
    margin: 2rem;
    display: flex;
    align-items: center;
    .delivery-input {
      width: 12rem;
      margin-bottom: 0;
      &.codigo {
        input {
          width: 7rem;
        }
      }
      &.fecha {
        padding-right: 1rem;
      }
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    .total {
      margin-left: 1rem;
      span {
        font-weight: 400;
      }
    }
  }
  .articles-list {
    margin: 2rem;
  }
`;

export default StyledDeliveryModal;

import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import {
  CurrencyDisplay,
  DateDisplay,
  ClickableRowItem
} from '../../../../components';

export default function RowItems({ item, rowHandlers }) {
  const {
    Id,
    Fecha,
    IdPedido,
    DescuentoNeto,
    DescuentoPorcentaje,
    Total,
    Abonado,
    Metalico
  } = item;

  const { handleOpenOrder } = rowHandlers;

  const handleClickOrder = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleOpenOrder(IdPedido);
  };

  return (
    <>
      <TableCell className='cell-body'>{Id}</TableCell>
      <TableCell className='cell-body'>
        <DateDisplay date={Fecha} />
      </TableCell>
      <TableCell className='cell-body'>
        <ClickableRowItem onClick={(e) => handleClickOrder(e)}>
          {IdPedido}
        </ClickableRowItem>
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={DescuentoNeto} />
      </TableCell>
      <TableCell className='cell-body'>{DescuentoPorcentaje} %</TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={Total} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={Abonado} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={Metalico} />
      </TableCell>
    </>
  );
}

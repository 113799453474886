import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import { CurrencyDisplay, DateDisplay } from '../../../../components';

export default function RowItems({ item }) {
  const { Id, Fecha, SubTotal, IVA, Total, TotalDescuentos } = item;

  return (
    <>
      <TableCell className='cell-body'>{Id}</TableCell>
      <TableCell className='cell-body'>
        <DateDisplay date={Fecha} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={SubTotal} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={IVA} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={TotalDescuentos} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={Total} />
      </TableCell>
    </>
  );
}

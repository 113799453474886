import React, { useState, useEffect } from 'react';
import qs from 'qs';

import StyledInvoicesList from './InvoicesList.style';
import columns from './columns';
import RowItems from './RowItems/RowItems';
import InvoiceModal from '../InvoiceModal/InvoiceModal';
import { Loader, ItemsTable, DeleteDialog } from '../../../components';
import { DeleteErrorDialog, DatesFilter } from '../../../molecules';
import { useDispatchSnackbar } from '../../../providers/SnackbarProvider';
import { useAuth } from '../../../providers/AuthProvider';
import listTypes from '../../../utils/listTypes';
import InvoicesService from '../../../services/SupplierInvoice';
import { ReactComponent as DeliveryIcon } from '../../../resources/svg/ico-albaranes.svg';
import {
  formatQueryDate,
  getMonthYear,
  getMonthLabel
} from '../../../utils/functions';

export default function InvoicesList({
  supplierId,
  supplierName,
  supplierPayment
}) {
  const [invoices, setInvoices] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [invoiceToEdit, setInvoiceToEdit] = useState(null);
  const [responseErrors, setResponseErrors] = useState([]);
  const invoicesServices = new InvoicesService();
  const dispatchSnackbar = useDispatchSnackbar();
  const { lists } = useAuth();
  const [filterActive, setFilterActive] = useState(null);
  const [filterQuery, setFilterQuery] = useState(null);
  const [getQuery, setGetQuery] = useState({});
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 30,
    page: 1,
    prev: false,
    next: false
  });

  const usersList = lists.filter((item) => item.type === listTypes.usuario);

  const handleClickDelete = () => {
    setConfirmDelete(true);
  };
  const handleCloseDialog = () => setConfirmDelete(false);

  const handleEditInvoice = (id) => {
    setOpenModal(true);
    setInvoiceToEdit(invoices.find((item) => item.Id === id));
  };

  const getInvoices = async (pag) => {
    setIsLoading(true);
    try {
      let newPagination;
      if (pag) {
        newPagination = {
          Total: pag.total,
          PaginaActual: pag.page,
          Paginacion: pag.limit,
          TieneAnterior: pag.prev,
          TieneSiguiente: pag.next
        };
      } else {
        newPagination = {
          Total: pagination.total,
          PaginaActual: pagination.page,
          Paginacion: pagination.limit,
          TieneAnterior: pagination.prev,
          TieneSiguiente: pagination.next
        };
      }
      const response = await invoicesServices.getQuery(
        qs.stringify(
          { ...newPagination, ...getQuery, IdEmpresa: supplierId },
          { addQueryPrefix: true }
        )
      );
      const {
        Total: total,
        PaginaActual: page,
        Paginacion: limit,
        TieneAnterior: prev,
        TieneSiguiente: next,
        Datos
      } = response;
      setPagination({ total, page, limit, prev, next });
      setInvoices(
        Datos.$values.map((item) => {
          return {
            Id: item.Id,
            Codigo: item.Codigo,
            Total: item.Total,
            SubTotal: item.SubTotal,
            TotalBase: item.TotalBase,
            TotalIVA: item.TotalIVA,
            TotalDescuentos: item.TotalDescuentos,
            IdFormaCobro: item.IdFormaCobro,
            Fecha: item.Fecha,
            Adjuntos: item.Adjuntos.$values,
            Albaranes: item.Albaranes.$values.map(
              (delivery) => delivery.Codigo
            ),
            user:
              usersList.find((user) => user.id === item?.IdUsuarioCreacion)
                ?.name || 'Desconocido'
          };
        })
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const changeRowsPerPage = (newLimit) => {
    const newPagination = {
      ...pagination,
      limit: newLimit,
      page: 1
    };
    getInvoices(newPagination);
    setPagination(newPagination);
  };

  const changePage = (newPage) => {
    const newPagination = {
      ...pagination,
      page: newPage
    };
    getInvoices(newPagination);
    setPagination(newPagination);
  };

  const handleClickAdd = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setInvoiceToEdit(null);
  };

  const handleFilter = (filter) => {
    setGetQuery({
      ...getQuery,
      ...filter
    });
    setFilterQuery(filter);
    const { FechaIni, FechaFin, TipoFiltro } = filter;
    if (FechaIni && FechaFin) {
      if (TipoFiltro === 'range') {
        setFilterActive(
          `${formatQueryDate(FechaIni)} a ${formatQueryDate(FechaFin)}`
        );
      }
      if (TipoFiltro === 'month') {
        const { month, year } = getMonthYear(FechaIni);
        setFilterActive(`${getMonthLabel(month)} ${year}`);
      }
    }
  };

  const handleClickSubmit = async (values) => {
    handleCloseModal();
    setIsLoading(true);
    try {
      await invoicesServices.saveInvoice({
        ...values,
        IdEmpresa: supplierId
      });
      dispatchSnackbar({
        type: 'SET',
        payload: invoiceToEdit ? 'Factura editada' : 'Factura creada'
      });
      getInvoices();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleDeleteInvoices = async () => {
    setIsLoading(true);
    setConfirmDelete(false);
    try {
      const response = await invoicesServices.deleteInvoices(selected);
      const ErrorsList = response.$values.filter((item) => !!item.Error);
      if (ErrorsList.length) {
        setResponseErrors(ErrorsList);
      } else {
        dispatchSnackbar({
          type: 'SET',
          payload: 'Facturas eliminados'
        });
      }
      setSelected([]);
      getInvoices();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    if (supplierId) {
      getInvoices();
    }
  }, [supplierId]);

  useEffect(async () => {
    let isEmpty = true;
    Object.values(getQuery).forEach((value) => {
      if (value) {
        isEmpty = false;
      }
    });
    if (!isEmpty) {
      setIsLoading(true);
      getInvoices();
    } else {
      setInvoices([]);
    }
  }, [getQuery]);

  return (
    <StyledInvoicesList>
      <Loader show={isLoading} />
      <ItemsTable
        handleClickDelete={handleClickDelete}
        title='Facturas'
        columns={columns}
        items={invoices}
        isLoading={isLoading}
        handleClickRow={handleEditInvoice}
        RowItems={RowItems}
        selected={selected}
        setSelected={setSelected}
        addLabel='Crear Factura'
        AddIcon={DeliveryIcon}
        handleClickAdd={handleClickAdd}
        pagination={pagination}
        changePage={changePage}
        changeRowsPerPage={changeRowsPerPage}
        FilterForm={DatesFilter}
        filterLabel='Filtrar facturas'
        filterActive={filterActive}
        setFilterActive={setFilterActive}
        handleFilter={handleFilter}
        filterData={filterQuery}
      />
      <DeleteDialog
        open={confirmDelete}
        close={handleCloseDialog}
        handleAccept={handleDeleteInvoices}
        singular='factura'
        plural='facturas'
        quantity={selected.length}
      />
      <DeleteErrorDialog
        errors={responseErrors}
        handleClose={() => setResponseErrors([])}
      />
      {openModal && (
        <InvoiceModal
          supplierName={supplierName}
          supplierPayment={supplierPayment}
          open={openModal}
          handleClose={handleCloseModal}
          invoiceToEdit={invoiceToEdit}
          handleClickSubmit={handleClickSubmit}
        />
      )}
    </StyledInvoicesList>
  );
}

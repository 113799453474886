import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import StyledItemsList from './ItemsList.style';
import ContentBox from '../ContentBox/ContentBox';
import Text from '../Text/Text';
import Drawer from '../Drawer/Drawer';
import Loader from '../Loader';
import { ReactComponent as DeleteIco } from '../../resources/svg/ico-borrar-item.svg';

export default function ItemsList({
  title,
  addLabel,
  addTitle,
  editItemButtonLabel,
  editTitle,
  AddItem,
  addItemButtonLabel,
  itemsList,
  setItemsList,
  isNew,
  Item,
  service,
  company,
  loadingParent
}) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    watch,
    control,
    setValue,
    formState
  } = useForm();
  const handleOpenDrawer = () => {
    reset({});
    setShowDrawer(true);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
    setItemToEdit(null);
    reset({});
  };
  const handleRemoveItem = async (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isNew) {
      setIsLoadingForm(true);
      setIsLoading(true);
      try {
        await service.removeItems([id]);
      } catch (error) {
        console.log(error);
      }
      setIsLoadingForm(false);
      setIsLoading(false);
    }
    setItemsList(itemsList.filter((item) => item.Id !== id));
  };
  const handleEditItem = (item) => {
    handleOpenDrawer();
    setItemToEdit(item.Id);
    reset(item);
  };

  const onSubmit = async (values) => {
    let response = null;
    let filteredItemsList;
    if (values.Predeterminada) {
      filteredItemsList = itemsList.map((item) => ({
        ...item,
        Predeterminada: false
      }));
    } else {
      filteredItemsList = [...itemsList];
    }
    if (!isNew) {
      setIsLoading(true);
      setIsLoadingForm(true);
      try {
        response = await service.addItem({
          ...values,
          IdEmpresa: company,
          Id: itemToEdit
        });
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
      setIsLoadingForm(false);
    }
    if (itemToEdit) {
      setItemsList([
        ...filteredItemsList.filter((item) => item.Id !== itemToEdit),
        { ...values, Id: itemToEdit }
      ]);
      setItemToEdit(null);
    } else {
      setItemsList([
        ...filteredItemsList,
        { ...values, Id: response?.Id || Math.random() }
      ]);
    }
    handleCloseDrawer();
  };
  useEffect(async () => {
    if (company && company !== 'new') {
      setIsLoading(true);
      try {
        const itemsResponse = await service.getByCompany(company);
        setItemsList(itemsResponse);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
  }, [company]);

  return (
    <StyledItemsList>
      <Loader show={!loadingParent && isLoading} />
      <ContentBox title={title}>
        <div className='items-list'>
          {itemsList.map((item) => (
            <div
              className='single-item'
              key={item.Id}
              onClick={() => handleEditItem(item)}
              onKeyDown={() => handleEditItem(item)}
              role='button'
              tabIndex='-1'
            >
              <DeleteIco onClick={(e) => handleRemoveItem(e, item.Id)} />
              <Item item={item} />
            </div>
          ))}
        </div>
        <div className='add-item'>
          <Text color='blue' bold onClick={handleOpenDrawer}>
            + {addLabel}
          </Text>
        </div>
      </ContentBox>
      <Drawer
        open={showDrawer}
        title={itemToEdit ? editTitle : addTitle}
        onClose={handleCloseDrawer}
        onAccept={handleSubmit(onSubmit)}
        buttonLabel={itemToEdit ? editItemButtonLabel : addItemButtonLabel}
      >
        <Loader show={isLoadingForm} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <AddItem
            register={register}
            errors={errors}
            watch={watch}
            getValues={getValues}
            control={control}
            setIsLoading={setIsLoadingForm}
            setValue={setValue}
            formState={formState}
          />
        </form>
      </Drawer>
    </StyledItemsList>
  );
}

import { rgba } from 'polished';
import styled from 'styled-components';

const TextareaWrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  label {
    font-weight: 400;
  }
  textarea {
    font-weight: 300;
    width: 100%;
    margin-top: 0.5rem;
    border: 1px solid
      ${({ theme, error }) =>
        error ? rgba(theme.colors.red, 0.5) : theme.colors.gray};
    border-radius: 5px;
    padding: 0.5rem 1rem;
    height: ${({ height }) => height || 'auto'};
    resize: none;
  }
  .error {
    color: ${({ theme }) => theme.colors.red};
    display: inline-block;
    margin-top: 0.5rem;
  }
`;

export default TextareaWrapper;

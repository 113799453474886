import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  .page-content {
    flex: 1;
    display: flex;
    & > div:nth-child(2) {
      flex: 1;
      padding: 1.5rem 1rem 0 1rem;
    }
  }
`;

export default Layout;

import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import { rgba } from 'polished';

const StyledFilterList = styled.div`
  .filter-button {
    cursor: pointer;
    padding: 0.5rem 4rem 0.5rem 1.5rem;
    border-radius: 3rem;
    background-color: ${({ theme, filterActive }) =>
      filterActive ? rgba(theme.colors.orange, 0.2) : 'transparent'};
    svg {
      margin-right: 0.5rem;
    }
  }
`;

export const StyledFilterPopover = styled(Popover)`
  margin-top: 0.5rem;
`;

export default StyledFilterList;

import styled from 'styled-components';

const StyledText = styled.p`
  font-size: ${({ theme, size }) => theme.fonts[size] || theme.fonts.s};
  color: ${({ theme, color }) => theme.colors[color] || theme.colors.black};
  font-weight: ${({ bold }) => (bold ? 400 : 300)};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;

export default StyledText;

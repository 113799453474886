import React from 'react';

import InputWrapper from './Input.style';

export default function Input({
  label,
  placeholder,
  disabled = false,
  error,
  inputRef,
  name,
  hidden,
  horizontal,
  type = 'text',
  className
}) {
  return (
    <InputWrapper
      error={error}
      hidden={hidden}
      horizontal={horizontal}
      label={label}
      className={className}
    >
      <label>
        {label && label}
        <input
          placeholder={placeholder}
          disabled={disabled}
          ref={inputRef}
          name={name}
          type={type}
        />
      </label>
      {!disabled && error && <span className='error'>{error}</span>}
    </InputWrapper>
  );
}

import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { Helmet } from 'react-helmet';

import ArticlesFilter from './ArticlesFilter/ArticlesFilter';
import StyledArticles, {
  StyledImportDialog,
  StyledSImportResults
} from './Articles.style';
import RowItems from './RowItems/RowItems';
import columns from './columns';
import {
  Button,
  FilterItems,
  ItemsTable,
  Loader,
  DeleteDialog,
  Dialog,
  CompaniesTypeAhead,
  Text
} from '../../components';
import { useDispatchAuth } from '../../providers/AuthProvider';
import { DeleteErrorDialog } from '../../molecules';
import useQueryString from '../../hooks/useQueryString';
import ArticlesService from '../../services/Article';
import CompaniesService from '../../services/Company';
import ArticleModal from '../../modals/ArticleModal/ArticleModal';
import { ReactComponent as AddIcon } from '../../resources/svg/ico-mas-blanco.svg';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';
import { handleDownload, formatSize } from '../../utils/functions';
import relationType from '../../utils/relationType';

export default function Articles() {
  const dispatch = useDispatchAuth();
  const articlesService = new ArticlesService();
  const companiesService = new CompaniesService();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showImportResults, setShowImportResults] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [articles, setArticles] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [responseErrors, setResponseErrors] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [brands, setBrands] = useState([]);
  const [selected, setSelected] = useState([]);
  const [reload, setReload] = useState(false);
  const [filterActive, setFilterActive] = useState(null);
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 30,
    page: 1,
    prev: false,
    next: false
  });

  const { handleSubmit, setValue, errors, reset, getValues } = useForm({
    shouldUnregister: false,
    defaultValues: {
      file: null,
      IdProveedor: null
    }
  });

  const { query, updateQuery } = useQueryString();
  const dispatchSnackbar = useDispatchSnackbar();

  const handleRouterUpdate = (value) => {
    if (!isLoading) {
      updateQuery(value);
    }
  };

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length) {
        setValue('file', acceptedFiles[0]);
        setFileName(
          `${acceptedFiles[0].name} (${formatSize(acceptedFiles[0].size)})`
        );
      } else if (rejectedFiles.length > 0) {
        console.log(rejectedFiles);
        let message = 'Algo ha fallado al cargar el archivo';
        if (rejectedFiles[0]?.errors[0]?.code === 'file-invalid-type') {
          message = 'El archivo debe de ser de Excel o un csv';
        }
        dispatchSnackbar({
          type: 'ERROR',
          payload: message
        });
      }
    },
    accept: '.csv, .xls, xlsx'
  });

  const clearDialog = () => {
    setFileName(null);
    setSelectedSupplier(null);
    reset();
  };

  const handleCloseImportModal = () => {
    clearDialog();
    setShowImportModal(false);
  };

  const handleCloseImportResults = () => {
    setShowImportResults(false);
    clearDialog();
  };

  const { article } = query || {};

  useEffect(async () => {
    if (selectedSupplier) {
      setValue('IdProveedor', selectedSupplier.Id, { shouldDirty: true });
    } else {
      setValue('IdProveedor', null, { shouldDirty: true });
    }
  }, [selectedSupplier]);

  const handleFilterActive = async (
    brandOptions,
    manufacturerOptions,
    categoryOptions
  ) => {
    let hasFilter = false;
    const filters = [];
    if (query.IdCategoria) {
      const categoryName = (categoryOptions || categories).find(
        (a) => a.value.toString() === query.IdCategoria
      );
      hasFilter = true;
      filters.push(`Categoría: ${categoryName?.label}`);
    }
    if (query.IdMarca) {
      const brandName = (brandOptions || brands).find(
        (c) => c.value.toString() === query.IdMarca
      );
      hasFilter = true;
      filters.push(`Marca: ${brandName?.label}`);
    }
    if (query.IdFabricante) {
      const manufacturerName = (manufacturerOptions || manufacturers).find(
        (c) => c.value.toString() === query.IdFabricante
      );
      hasFilter = true;
      filters.push(`Fabricante: ${manufacturerName?.label}`);
    }
    if (query.IdProveedor) {
      try {
        const supplierResponse = await companiesService.getCompany(
          query.IdProveedor
        );
        hasFilter = true;
        filters.push(`Proveedor: ${supplierResponse?.Nombre}`);
      } catch (error) {
        console.log(error);
      }
    }
    if (query.IdCliente) {
      try {
        const clienteResponse = await companiesService.getCompany(
          query.IdCliente
        );
        hasFilter = true;
        filters.push(`Cliente: ${clienteResponse?.Nombre}`);
      } catch (error) {
        console.log(error);
      }
    }
    if (hasFilter) {
      setFilterActive(filters.join(' | '));
    }
  };

  const getOptions = (response) =>
    response?.$values.map((item) => ({
      value: item.Id,
      label: item.Nombre
    }));

  const handleNewArticleButton = () => {
    handleRouterUpdate({ article: 'new' });
  };

  const getArticles = async (
    brandOptions,
    manufacturerOptions,
    categoryOptions
  ) => {
    setIsLoading(true);
    try {
      const articlesData = await articlesService.getArticles(
        qs.stringify({ ...query }, { addQueryPrefix: true })
      );
      const {
        Total: total,
        PaginaActual: page,
        Paginacion: limit,
        TieneAnterior: prev,
        TieneSiguiente: next,
        Datos: list
      } = articlesData;
      const { $values: articlesList } = list;
      setPagination({ total, page, limit, prev, next });
      setArticles(
        articlesList.map((item) => ({
          ...item,
          Id: item.IdReferencia,
          Marca: (brandOptions || brands).find(
            (brand) => brand.value === item.IdMarca
          )?.label,
          Nombre: item.NombreCompleto
        }))
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    handleFilterActive(brandOptions, manufacturerOptions, categoryOptions);
  };

  const onSubmit = async (values) => {
    handleCloseImportModal();
    setIsLoading(true);
    const { IdProveedor, file } = values;
    try {
      const response = await articlesService.post(file, IdProveedor);
      setShowImportResults(response);
      await getArticles();
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      await getArticles();
    }
  };

  const handleExportArticles = async () => {
    setIsLoading(true);
    try {
      const response = await articlesService.csv(query);
      handleDownload(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleImportArticles = () => {
    setShowImportModal(true);
  };

  const handleClickDelete = () => {
    setConfirmDelete(true);
  };

  const handleCloseDialog = () => setConfirmDelete(false);

  const handleDeleteArticles = async () => {
    setIsLoading(true);
    setConfirmDelete(false);
    try {
      const response = await articlesService.deleteArticles(selected);
      const ErrorsList = response.$values.filter((item) => !!item.Error);
      if (ErrorsList.length) {
        setResponseErrors(ErrorsList);
      } else {
        dispatchSnackbar({
          type: 'SET',
          payload: 'Artículos eliminados'
        });
      }
      setSelected([]);
      handleRouterUpdate();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  const handleEditArticle = (id) => {
    handleRouterUpdate({ article: id });
  };

  useEffect(async () => {
    if (!article && reload && query instanceof Object) {
      let manufacturerOptions = null;
      let brandOptions = null;
      let categoriesTree = null;
      if (isFirstLoad) {
        dispatch({
          type: 'SET_PAGE',
          payload: 'artículos'
        });
        try {
          const manufacturersResponse = await articlesService.getManufacturers();
          const categoriesResponse = await articlesService.getCategories();
          const brandsResponse = await articlesService.getBrands();
          categoriesTree = [];
          const getCategories = (category, parent) => {
            const categoryLabel = parent
              ? `${parent} > ${category.Nombre}`
              : category.Nombre;
            categoriesTree.push({
              value: category.Id,
              label: categoryLabel
            });
            if (category.Subcategorias?.$values?.length) {
              category.Subcategorias?.$values?.forEach((item) =>
                // eslint-disable-next-line no-unused-vars
                getCategories(item, categoryLabel)
              );
            }
          };
          categoriesResponse?.$values.forEach((item) => getCategories(item));
          manufacturerOptions = getOptions(manufacturersResponse);
          brandOptions = getOptions(brandsResponse);
          setManufacturers(manufacturerOptions);
          setBrands(brandOptions);
          setCategories(categoriesTree);
          setIsFirstLoad(false);
        } catch (error) {
          console.log(error);
          dispatchSnackbar({
            type: 'ERROR',
            payload: 'Ha ocurrido un error'
          });
          return;
        }
      }
      await getArticles(brandOptions, manufacturerOptions, categoriesTree);
    } else if (!reload) {
      setReload(true);
    }
  }, [query]);

  return (
    <StyledArticles>
      <Helmet>
        <title>Artículos | Pascual Vinuesa</title>
      </Helmet>
      <Loader show={isLoading} />
      <div className='top-section'>
        <FilterItems
          isLoading={isLoading}
          placeholder='Escribe para buscar artículos'
        />
        <Button outlined onClick={handleExportArticles}>
          Exportar artículos
        </Button>
        <Button outlined onClick={handleImportArticles}>
          Importar artículos
        </Button>
      </div>
      <ItemsTable
        handleClickDelete={handleClickDelete}
        FilterForm={ArticlesFilter}
        filterLabel='Filtrar artículos'
        filterActive={filterActive}
        filterOptions={{ manufacturers, brands, categories }}
        setFilterActive={setFilterActive}
        title='Artículos'
        columns={columns}
        items={articles}
        isLoading={isLoading}
        handleClickRow={handleEditArticle}
        RowItems={RowItems}
        pagination={pagination}
        selected={selected}
        setSelected={setSelected}
        addLabel='Añadir artículo'
        AddIcon={AddIcon}
        handleClickAdd={handleNewArticleButton}
      />
      <DeleteDialog
        open={confirmDelete}
        close={handleCloseDialog}
        handleAccept={handleDeleteArticles}
        singular='artículo'
        plural='artículos'
        quantity={selected.length}
      />
      <DeleteErrorDialog
        errors={responseErrors}
        handleClose={() => setResponseErrors([])}
      />
      {article && <ArticleModal setReload={setReload} />}
      {showImportModal && (
        <Dialog
          open={showImportModal}
          title='Importar artículos'
          blue
          handleClose={handleCloseImportModal}
          hideCloseButton
          disableClickOutside
        >
          <StyledImportDialog>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='typeahead-container'>
                <Text color='text' bold>
                  Proveedor:
                </Text>
                <CompaniesTypeAhead
                  selectedClient={selectedSupplier}
                  setSelectedClient={setSelectedSupplier}
                  error={errors?.IdProveedor?.message}
                  type={relationType.supplier}
                  hideAdd
                />
              </div>
              <div
                className='file-upload'
                isDragAccept={isDragAccept}
                {...getRootProps({ onClick: (e) => e.preventDefault() })}
              >
                <button type='button'>Subir archivo a importar</button>
                <input type='file' name='file' {...getInputProps()} />
                <Text as='span' color='text' className='filename'>
                  {fileName}
                </Text>
              </div>
              <Button
                secondary
                outlined
                type='submit'
                disabled={!getValues('file')}
              >
                Comenzar importación
              </Button>
            </form>
          </StyledImportDialog>
        </Dialog>
      )}
      {showImportResults && (
        <Dialog
          blue
          title='Enviar facturas'
          open={!!showImportResults}
          disableClickOutside
          handleClose={handleCloseImportResults}
          closeLabel='Cerrar'
        >
          <StyledSImportResults>
            <Text color='blue'>Resumen del proceso de importación</Text>
            <div className='import-box'>
              <ul className='results-info'>
                <Text bold as='li' color='text'>
                  Se han procesado {showImportResults.Procesados} artículos.
                </Text>
                <Text bold as='li' color='text'>
                  Se han creado {showImportResults.Creados} artículos.
                </Text>
                <Text bold as='li' color='text'>
                  Se han modificado {showImportResults.Actualizados} artículos.
                </Text>
                <Text bold as='li' color='text'>
                  Se han borrado {showImportResults.Borrados} artículos.
                </Text>
              </ul>
            </div>
            <Text color='blue'>Errores en la importación</Text>
            <div className='import-box'>
              {showImportResults.Errores ? (
                <ul>
                  {showImportResults.Errores.$values.map((error) => (
                    <Text as='li' color='text'>
                      {error}
                    </Text>
                  ))}
                </ul>
              ) : (
                <Text color='text'>No hay errores</Text>
              )}
            </div>
          </StyledSImportResults>
        </Dialog>
      )}
    </StyledArticles>
  );
}

import React from 'react';
import { format, parseISO } from 'date-fns';

export default function DateDisplay({ date }) {
  const formatDate = (dateItem) => {
    if (dateItem) {
      return format(parseISO(dateItem), 'dd/MM/yy');
    }
    return '';
  };

  return <span>{formatDate(date)}</span>;
}

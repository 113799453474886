import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { Helmet } from 'react-helmet';

import StyledDeliveries, { StyledPrintModal } from './Deliveries.style';
import { useAuth, useDispatchAuth } from '../../providers/AuthProvider';
import DeliveriesService from '../../services/Deliveries';
import CompaniesService from '../../services/Company';
import useQueryString from '../../hooks/useQueryString';
import listTypes from '../../utils/listTypes';
import deliveryStatus from '../../utils/deliveryStatus';
import DeliveriesFilter from './DeliveriesFilter/DeliveriesFilter';
import { formatQueryDate, handleDownload } from '../../utils/functions';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';
import {
  FilterItems,
  Loader,
  ItemsTable,
  DeleteDialog,
  Dialog,
  Button,
  Text
} from '../../components';
import { DeleteErrorDialog } from '../../molecules';
import columns from './columns';
import RowItems from './RowItems/RowItems';
import DeliveryModal from '../../modals/DeliveryModal/DeliveryModal';
import OrderModal from '../../modals/OrderModal/OrderModal';
import InvoiceModal from '../../modals/InvoiceModal/InvoiceModal';
import { ReactComponent as FacturasIco } from '../../resources/svg/ico-facturas.svg';
import GenerateInvoicesModal from '../../modals/GenerateInvoicesModal/GenerateInvoicesModal';

export default function Deliveries() {
  const dispatch = useDispatchAuth();
  const deliveriesService = new DeliveriesService();
  const companiesService = new CompaniesService();
  const [deliveries, setDeliveries] = useState([]);
  const [reload, setReload] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { query, updateQuery } = useQueryString();
  const [selected, setSelected] = useState([]);
  const [filterActive, setFilterActive] = useState(null);
  const [responseErrors, setResponseErrors] = useState([]);
  const [showPrintDialog, setShowPrintDialog] = useState(false);
  const [openInvoicesAssistant, setOpenInvoicesAssistant] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 30,
    page: 1,
    prev: false,
    next: false
  });
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dispatchSnackbar = useDispatchSnackbar();
  const { lists } = useAuth();
  const usersList = lists.filter((item) => item.type === listTypes.usuario);

  const { delivery, order, invoice } = query || {};

  const handleRouterUpdate = (value) => {
    if (!isLoading) {
      updateQuery(value);
    }
  };

  const handleEditDelivery = (id) => {
    handleRouterUpdate({ delivery: id });
  };

  const handleFilterActive = async (filterQuery) => {
    const { FechaIni, FechaFin, IdCliente, Estado } = filterQuery;
    let hasFilter = false;
    const filters = [];
    if (FechaIni && FechaFin) {
      hasFilter = true;
      filters.push(
        `${formatQueryDate(FechaIni)} a ${formatQueryDate(FechaFin)}`
      );
    }
    if (Estado) {
      const statusName = deliveryStatus.find(
        (status) => status.value.toString() === Estado
      );
      hasFilter = true;
      filters.push(`Estado: ${statusName?.label}`);
    }
    if (IdCliente) {
      try {
        const clientResponse = await companiesService.getCompany(IdCliente);
        hasFilter = true;
        filters.push(`Cliente: ${clientResponse?.Nombre}`);
      } catch (error) {
        console.log(error);
      }
    }
    if (hasFilter) {
      setFilterActive(filters.join(' | '));
    }
  };

  const handleClickDelete = () => setConfirmDelete(true);

  const handleCloseDialog = () => setConfirmDelete(false);

  const checkHasInvoice = () => {
    const selectedDeliveries = selected.map((item) =>
      deliveries.find((element) => element.Id === item)
    );
    return selectedDeliveries.every((item) => !item.FacturaId);
  };

  const checkHasPaymentMethod = () => {
    const selectedDeliveries = selected.map((item) =>
      deliveries.find((element) => element.Id === item)
    );
    return selectedDeliveries.every((item) => !!item.IdFormaPago);
  };

  const handleClickGenerateInvoices = () => {
    if (!checkHasInvoice(selected)) {
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Hay albaranes que ya están facturados'
      });
      setIsLoading(false);
      return;
    }
    if (!checkHasPaymentMethod(selected)) {
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Hay albaranes que no tienen una forma de pago definida'
      });
      setIsLoading(false);
      return;
    }
    setOpenInvoicesAssistant(true);
  };

  const handleCloseModal = () => {
    setOpenInvoicesAssistant(false);
  };

  const handlePrintDeliveries = () => setShowPrintDialog(true);

  const handleClosePrintDialog = () => setShowPrintDialog(false);

  const printDelivery = async (rated) => {
    setIsLoading(true);
    setShowPrintDialog(false);
    try {
      const response = await deliveriesService.printDeliveries(selected, rated);
      handleDownload(response);
      setSelected([]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  const extraButtons = [
    {
      label: 'Generar facturas',
      icon: <FacturasIco />,
      onClick: handleClickGenerateInvoices,
      className: 'generate-invoices-button'
    },
    {
      label: 'Imprimir',
      onClick: handlePrintDeliveries
    }
  ];

  const getDeliveries = async () => {
    setIsLoading(true);
    setSelected([]);
    try {
      const deliveriesData = await deliveriesService.getDeliveries(
        qs.stringify({ ...query }, { addQueryPrefix: true })
      );
      const {
        Total: total,
        PaginaActual: page,
        Paginacion: limit,
        TieneAnterior: prev,
        TieneSiguiente: next,
        Datos: list
      } = deliveriesData;
      setPagination({ total, page, limit, prev, next });
      setDeliveries(
        list?.$values.map((item) => ({
          Id: item.Id,
          Fecha: item.Fecha,
          Cliente: item.Cliente,
          IdPedido: item.IdPedido,
          Estado: item.Estado,
          FacturaId: item.FacturaId,
          FacturaCOD: item.FacturaCOD,
          Abonado: item.Abonado,
          Pendiente: item.Pendiente,
          IdFormaPago: item.IdFormaPago,
          Total: item.Total,
          user:
            usersList.find((user) => user.id === item?.IdUsuarioCreacion)
              ?.name || 'Importado'
        }))
      );
      handleFilterActive(query);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
    }
  };

  const handleDeleteDeliveries = async () => {
    setConfirmDelete(false);
    setIsLoading(true);
    try {
      const response = await deliveriesService.deleteDeliveries(selected);
      const ErrorsList = response.$values.filter((item) => !!item.Error);
      if (ErrorsList.length) {
        setResponseErrors(ErrorsList);
      } else {
        dispatchSnackbar({
          type: 'SET',
          payload: 'Albaranes eliminados'
        });
      }
      getDeliveries();
      setIsLoading(false);
      setSelected([]);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch({
      type: 'SET_PAGE',
      payload: 'albaranes'
    });
  }, []);

  useEffect(async () => {
    if (!delivery && reload && query instanceof Object) {
      await getDeliveries();
    } else if (!reload) {
      setReload(true);
    }
    return function cleanup() {
      setDeliveries([]);
    };
  }, [query]);

  const rowHandlers = {
    handleOpenOrder: (id) => handleRouterUpdate({ order: id }),
    handleOpenInvoice: (id) => handleRouterUpdate({ invoice: id })
  };

  return (
    <StyledDeliveries>
      <Helmet>
        <title>Albaranes | Pascual Vinuesa</title>
      </Helmet>
      <Loader show={isLoading} />
      <div className='top-section'>
        <FilterItems isLoading={isLoading} />
      </div>
      <ItemsTable
        handleClickDelete={handleClickDelete}
        FilterForm={DeliveriesFilter}
        filterLabel='Filtrar albaranes'
        filterActive={filterActive}
        filterOptions={{ deliveryStatus }}
        setFilterActive={setFilterActive}
        title='Albaranes'
        columns={columns}
        items={deliveries}
        pagination={pagination}
        isLoading={isLoading}
        selected={selected}
        handleClickRow={handleEditDelivery}
        setSelected={setSelected}
        RowItems={RowItems}
        extraButtons={extraButtons}
        rowHandlers={rowHandlers}
      />
      <DeleteDialog
        open={confirmDelete}
        close={handleCloseDialog}
        handleAccept={handleDeleteDeliveries}
        singular='albaran'
        plural='albaranes'
        quantity={selected.length}
      />
      <DeleteErrorDialog
        errors={responseErrors}
        handleClose={() => setResponseErrors([])}
      />
      {delivery && <DeliveryModal setReload={setReload} />}
      {order && <OrderModal setReload={setReload} />}
      {invoice && <InvoiceModal setReload={setReload} />}
      {openInvoicesAssistant && (
        <GenerateInvoicesModal
          open={openInvoicesAssistant}
          handleClose={handleCloseModal}
          selectedDeliveries={selected}
          refresh={() => getDeliveries()}
        />
      )}
      {showPrintDialog && (
        <Dialog
          open={showPrintDialog}
          title='Imprimir albarán'
          blue
          handleClose={handleClosePrintDialog}
          hideCloseButton
        >
          <StyledPrintModal>
            <Text>Selecciona el tipo de albarán que quieres imprimir</Text>
            <div className='buttons'>
              <Button secondary outlined onClick={() => printDelivery(false)}>
                Albarán sin valorar
              </Button>
              <Button secondary outlined onClick={() => printDelivery(true)}>
                Albarán valorado
              </Button>
            </div>
          </StyledPrintModal>
        </Dialog>
      )}
    </StyledDeliveries>
  );
}

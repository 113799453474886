import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import StyledTableHeader from './TableHeader.style';
import Checkbox from '../Checkbox/Checkbox';

export default function TableHeader({
  selected,
  setSelected,
  items,
  columns,
  sortField,
  ascending,
  handleSort,
  selectable
}) {
  const handleSelectAll = () => {
    if (selected?.length === items?.length) {
      setSelected([]);
    } else {
      setSelected(items?.map((client) => client.Id));
    }
  };
  return (
    <StyledTableHeader>
      <TableRow>
        {selectable && (
          <TableCell style={{ width: '30px', minWidth: '30px' }}>
            <Checkbox
              indeterminate={
                selected?.length > 0 && selected?.length < items.length
              }
              checked={items?.length > 0 && selected?.length === items?.length}
              onClick={handleSelectAll}
            />
          </TableCell>
        )}
        {columns?.map((column) => (
          <TableCell
            key={column.id}
            style={{ width: column.width, minWidth: column.width }}
            className='cell-head'
          >
            {column.sortable ? (
              <TableSortLabel
                active={column.id === sortField}
                direction={ascending ? 'asc' : 'desc'}
                onClick={() => handleSort(column.id)}
              >
                {column.label}
              </TableSortLabel>
            ) : (
              column.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHeader>
  );
}

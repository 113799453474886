import React from 'react';
import { Controller } from 'react-hook-form';

import StyledFormCheckbox, { Checkbox } from './FormCheckbox.style';
import Text from '../Text/Text';

export default function FormCheckbox({
  label,
  name,
  control,
  disabled,
  className
}) {
  return (
    <StyledFormCheckbox disabled={disabled} className={className}>
      <Controller
        name={name}
        control={control}
        render={(props) => (
          <Checkbox
            {...props}
            checked={props.value}
            onChange={(e) => props.onChange(e.target.checked)}
            id={name}
            disabled={disabled}
            defaultValue={false}
          />
        )}
      />
      <label htmlFor={name}>
        <Text as='span' bold>
          {label}
        </Text>
      </label>
    </StyledFormCheckbox>
  );
}

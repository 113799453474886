import styled from 'styled-components';

const StyledArticlesSelector = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  .top-section {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .add-button {
      display: flex;
      align-items: center;
      svg {
        fill: ${({ theme }) => theme.colors.white};
        margin-right: 0.5rem;
      }
    }
  }
  .main-section {
    margin: 1rem;
    display: flex;
    flex: 1;
    .table-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      h4 {
        margin-bottom: 0.5rem;
      }
      .articles-table {
        flex: 1;
        border: 1px solid ${({ theme }) => theme.colors.border};
        .table-row {
          padding: 0.75rem;
          display: flex;
          span {
            width: 16%;
            &:nth-child(2) {
              width: 52%;
            }
          }
        }
        .table-header {
          border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
        }
        .table-item {
          cursor: pointer;
          transition: background-color 0.3s;
          &:hover {
            background-color: ${({ theme }) => theme.colors.lighterGray};
          }
          &:focus {
            outline: none;
          }
          &.selected {
            background-color: ${({ theme }) => theme.colors.blue};
            color: ${({ theme }) => theme.colors.white};
            &:hover {
              background-color: ${({ theme }) => theme.colors.blue};
            }
          }
        }
      }
    }
    .buttons {
      margin: 0 1rem;
      padding-top: 8.5rem;
      .add-article {
        width: 2.5rem;
        height: 2.5rem;
        position: relative;
        display: block;
        background-color: ${({ theme }) => theme.colors.gray};
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        transition: background-color 0.3s;
        &.active {
          background-color: ${({ theme }) => theme.colors.blue};
          cursor: pointer;
        }
        &:first-child {
          margin-bottom: 1rem;
        }
      }
    }
  }
`;

export default StyledArticlesSelector;

import React, { useState, useEffect } from 'react';

import StyledAddressItem from './AddressItem.style';
import { Text } from '../../components';
import listTypes from '../../utils/listTypes';
import AddressesService from '../../services/Address';
import { useAuth } from '../../providers/AuthProvider';
import { ReactComponent as PredeterminadoIco } from '../../resources/svg/ico-predeterminado.svg';

export default function AddressItem({ item }) {
  const {
    Nombre,
    Telefono,
    Email,
    Descripcion,
    DireccionPostal,
    CP,
    Poblacion,
    Provincia,
    Region,
    PaisCOD,
    Predeterminada
  } = item;
  const { lists } = useAuth();
  const addressesService = new AddressesService();
  const [countryLabel, setCountryLabel] = useState(null);
  const [provinceLabel, setProvinceLabel] = useState(null);

  useEffect(async () => {
    if (PaisCOD) {
      const countriesList = lists.filter(
        (ListItem) => ListItem.type === listTypes.pais
      );
      const selectedCountry = countriesList.find(
        (listItem) => listItem.value === PaisCOD
      );
      setCountryLabel(selectedCountry?.label || null);
    }
    if (Provincia && PaisCOD) {
      let provinceList;
      try {
        provinceList = await addressesService.getRegions(PaisCOD);
      } catch (error) {
        console.log(error);
      }
      const provinceName = provinceList.find(
        (listItem) => listItem.value === Provincia
      );
      setProvinceLabel(provinceName?.label || null);
    }
  }, [PaisCOD, Provincia]);

  return (
    <StyledAddressItem>
      <Text>
        {CP && `${CP} `}
        {Poblacion && `${Poblacion} `}
        {provinceLabel && `${provinceLabel} `}
        {Region && `${Region} `}
        {countryLabel && `${countryLabel}`}
      </Text>
      {DireccionPostal && (
        <Text color='orange' bold>
          {DireccionPostal}
        </Text>
      )}
      {Nombre && <Text uppercase>{Nombre}</Text>}
      {Telefono && <Text>NÚMERO DE TELÉFONO: {Telefono}</Text>}
      {Email && <Text>EMAIL: {Email}</Text>}
      {Descripcion && (
        <Text>
          <Text as='span' bold>
            Descripción:{' '}
          </Text>
          {Descripcion}
        </Text>
      )}
      {Predeterminada && (
        <div className='predeterminada'>
          <PredeterminadoIco className='predeterminada-icon' />
          <Text color='red'>Predeterminada</Text>
        </div>
      )}
    </StyledAddressItem>
  );
}

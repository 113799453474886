import React, { useState, useEffect } from 'react';

import Select from '../Select/Select';
import Input from '../Input/Input';
import { useAuth } from '../../providers/AuthProvider';
import listTypes from '../../utils/listTypes';
import AddressesService from '../../services/Address';

export default function ProvinceSelector({
  provinceName,
  regionName,
  control,
  provinceDefaultValue,
  inputRef,
  className,
  provinceLabel,
  provincePlaceholder,
  regionLabel,
  regionPlaceholder,
  provinceError,
  regionError,
  country,
  noLabel,
  disabled
}) {
  const { lists } = useAuth();
  const regions = lists.filter((item) => item.type === listTypes.provincia);
  const [regionsList, setRegionsList] = useState(
    lists.filter((item) => item.type === listTypes.provincia)
  );
  const [isLoading, setIsLoading] = useState(false);
  const addressesService = new AddressesService();

  useEffect(async () => {
    if (country) {
      setIsLoading(true);
      let regionsData;
      if (country === 'ES') {
        regionsData = regions;
      } else {
        regionsData = await addressesService.getRegions(country);
      }
      setRegionsList(regionsData);
      setIsLoading(false);
    }
    return () => {
      setRegionsList([]);
    };
  }, [country]);

  return (
    <>
      <Select
        label={!noLabel && (provinceLabel || 'Provincia')}
        name={provinceName}
        control={control}
        options={regionsList}
        defaultValue={provinceDefaultValue}
        searchable
        placeholder={provincePlaceholder || 'selecciona la provincia'}
        hidden={regionsList.length === 0}
        className={className}
        error={provinceError}
        disabled={disabled || isLoading}
      />
      <Input
        label={!noLabel && (regionLabel || 'Región')}
        name={regionName}
        inputRef={inputRef}
        hidden={regionsList.length > 0}
        className='second'
        disabled={disabled || !country || isLoading}
        placeholder={
          country
            ? regionPlaceholder || 'escribe la región'
            : 'selecciona un pais primero'
        }
        error={regionError}
      />
    </>
  );
}

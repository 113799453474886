import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useForm } from 'react-hook-form';
import qs from 'qs';
import { format, parseISO } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';

import StyledOrderModal from './OrderModal.style';
import {
  FullDialog,
  Button,
  Radio,
  Input,
  FormRow,
  Textarea,
  Dialog,
  ContentBox,
  Loader,
  Select,
  ProvinceSelector,
  SelectWithAdd,
  Text,
  CompaniesTypeAhead
} from '../../components';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';
import useQueryString from '../../hooks/useQueryString';
import { useAuth } from '../../providers/AuthProvider';
import { ReactComponent as OrdersIco } from '../../resources/svg/ico-pedidos.svg';
import listTypes from '../../utils/listTypes';
import OrdersService from '../../services/Order';
import CompaniesService from '../../services/Company';
import ContactsService from '../../services/Contacts';
import DeliveriesService from '../../services/Deliveries';
import InvoicesService from '../../services/Invoices';
import NewContactForm from '../../forms/NewContactForm/NewContactForm';
import ArticlesList from './ArticlesList/ArticlesList';
import DeliveriesList from './DeliveriesList/DeliveriesList';
import InvoicesList from './InvoicesList/InvoicesList';
import Documentation from './Documentation/Documentation';
import { formFields, formDefaultValues } from './constants';
import { orderStatusOptions } from '../../utils/functions';
import companyType from '../../utils/companyType';
import DeliveryModal from '../DeliveryModal/DeliveryModal';
import InvoiceModal from '../InvoiceModal/InvoiceModal';

const schema = yup.object().shape({
  [formFields.name]: yup
    .string()
    .required('Debes seleccionar un cliente')
    .typeError('Debes seleccionar un cliente'),
  [formFields.clientId]: yup
    .string()
    .required('Debes seleccionar un cliente')
    .typeError('Debes seleccionar un cliente')
});

export default function OrderModal({
  setReload,
  modalState,
  setModalState,
  clientInfo,
  addressesList
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { query, updateQuery } = useQueryString(modalState);
  const { order, tab } = query || {};
  const { lists } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedClient, setSelectedClient] = useState(null);
  const [contactsOptions, setContactsOptions] = useState([]);
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [articles, setArticles] = useState([]);
  const [files, setFiles] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [isCompany, setIsCompany] = useState(false);
  const countriesList = lists.filter((item) => item.type === listTypes.pais);
  const usersList = lists.filter((item) => item.type === listTypes.usuario);
  const paymentsList = lists.filter(
    (listItem) => listItem.type === listTypes.formaPago
  );
  const [client, setClient] = useState(null);
  const ordersService = new OrdersService();
  const companiesService = new CompaniesService();
  const contactsService = new ContactsService();
  const deliveriesService = new DeliveriesService();
  const invoicesService = new InvoicesService();
  const [orderInfo, setOrderInfo] = useState({});
  const [orderName, setOrderName] = useState(null);
  const [creationDate, setCreationDate] = useState(null);
  const [deliveryModalState, setDeliveryModalState] = useState(null);
  const [invoiceModalState, setInvoiceModalState] = useState(null);
  const [isNew, setIsNew] = useState(true);
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [userName, setUserName] = useState(null);
  const dispatchSnackbar = useDispatchSnackbar();

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    control,
    getValues,
    formState,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formDefaultValues,
    shouldUnregister: false
  });

  const updateArticles = (updatedList) => {
    setArticles(updatedList);
    setIsFormUpdated(true);
  };

  const updateFiles = (updatedList) => {
    setFiles(updatedList);
    setIsFormUpdated(true);
  };

  const handleDeliveryModalState = (newState) =>
    setDeliveryModalState(newState);

  const handleInvoiceModalState = (newState) => setInvoiceModalState(newState);

  const tabs = [
    { label: 'detalles' },
    {
      label: `artículos${articles.length ? ` (${articles.length})` : ''}`,
      slug: 'articulos'
    },
    {
      label: `documentación${files.length ? ` (${files.length})` : ''}`,
      slug: 'documentacion',
      onlyEdit: true
    },
    {
      label: `albaranes${deliveries.length ? ` (${deliveries.length})` : ''}`,
      slug: 'albaranes',
      onlyEdit: true
    },
    {
      label: `facturas${invoices.length ? ` (${invoices.length})` : ''}`,
      slug: 'facturas',
      onlyEdit: true
    }
  ];

  const tabIndex = () => {
    const idxTab = tabs.findIndex(
      (tabItem) => (tabItem.slug || tabItem.label) === tab
    );

    return idxTab === -1 ? 0 : idxTab;
  };

  const handleDialog = () => {
    if (modalState) {
      setModalState(null);
    } else {
      updateQuery({ order: undefined, tab: undefined });
    }
  };

  const handleClose = () => {
    if (setReload) {
      setReload(true);
    }
    handleDialog();
  };

  const handleTabChange = (index) => {
    updateQuery({ tab: tabs[index].slug || tabs[index].label });
  };

  const handleCloseDialog = () => {
    setShowConfirmation(false);
  };

  const onSubmit = (values) => {
    setShowConfirmation(values);
  };

  const saveArticles = async (orderId, articlesList) => {
    const articlesData = articlesList.map(({ Id, ...article }) => ({
      ...article
    }));
    const response = await ordersService.saveOrderLines(orderId, articlesData);
    return response;
  };

  const getLines = async (orderId) => {
    const linesData = await ordersService.getOrderLines(orderId);
    setArticles(
      linesData.$values.map((line) => ({
        ...line,
        Id: line.UUID || line.IdPedidoLinea,
        isFreeText: !line.IdReferencia
      }))
    );
  };

  const getDeliveries = async (orderId) => {
    const deliveriesData = await deliveriesService.getDeliveries(
      qs.stringify(
        {
          IdPedido: orderId
        },
        { addQueryPrefix: true }
      )
    );
    setDeliveries(
      deliveriesData.Datos.$values.map((item) => ({
        ...item,
        user:
          usersList.find((user) => user.id === item?.IdUsuarioCreacion)?.name ||
          'Importado'
      }))
    );
  };

  const getInvoices = async (orderId) => {
    const invoicesData = await invoicesService.getInvoices(
      qs.stringify(
        {
          IdPedido: orderId
        },
        { addQueryPrefix: true }
      )
    );
    setInvoices(
      invoicesData.Datos.$values.map((item) => ({
        Id: item.Id,
        Codigo: item.Codigo,
        Fecha: item.Fecha,
        Cliente: item.Cliente,
        Serie: item.Serie,
        Estado: item.Estado,
        FechaEnviada: item.FechaEnviada,
        FechaEnviadaEmail: item.FechaEnviadaEmail,
        payment: paymentsList.find(
          (payment) => payment.value === item.IdFormaPago
        )?.label,
        Total: item.Total,
        user:
          usersList.find((user) => user.id === item?.IdUsuarioCreacion)?.name ||
          'Importado'
      }))
    );
  };

  const handleConfirmSave = async () => {
    setIsLoading(true);
    const {
      IdCliente,
      Estado,
      IdContacto,
      IdMetodoEnvio,
      Descripcion,
      Observaciones,
      Nombre,
      Apellidos,
      Direccion,
      CP,
      Poblacion,
      Region,
      RegionCOD,
      PaisCOD,
      Email,
      Telefono,
      Empresa,
      ObservacionesEnvio
    } = showConfirmation;
    const orderData = {
      ...orderInfo,
      Id: orderInfo.Id || 0,
      IdCliente,
      Estado,
      Origen: 1,
      IdContacto,
      IdMetodoEnvio: +IdMetodoEnvio,
      Descripcion,
      Observaciones,
      Documentacion: files
    };
    if (IdMetodoEnvio !== '2') {
      orderData.DireccionEnvio = {
        ...orderInfo?.DireccionEnvio,
        Nombre,
        Apellidos,
        Direccion,
        CP,
        Poblacion,
        Region,
        RegionCOD,
        PaisCOD,
        Email,
        Telefono,
        Empresa,
        Observaciones: ObservacionesEnvio
      };
    }

    try {
      const response = await ordersService.saveOrder(orderData);
      saveArticles(response.Id, articles);
      if (isNew) {
        setShowConfirmation(false);
        updateQuery({ order: response.Id });
      } else {
        handleDialog();
      }
      reset();
      dispatchSnackbar({
        type: 'SET',
        payload: isNew
          ? `Pedido creado con id: ${response.Id}`
          : 'Pedido Editado'
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setShowConfirmation(false);
      setIsLoading(false);
    }
  };

  const setContactLabel = (contact) =>
    `${contact.Nombre ? `${contact.Nombre} ` : ''}${
      contact.Apellidos ? `${contact.Apellidos} ` : ''
    }${contact.Email ? `(${contact.Email})` : ''}${
      contact.Telefono ? ` ${contact.Telefono}` : ''
    }`;

  const watchDeliveryMethod = watch(formFields.deliveryMethod);
  const watchCountry = watch(formFields.deliveryCountry);
  const watchClientId = watch(formFields.clientId);

  useEffect(async () => {
    if (selectedClient) {
      setValue(formFields.name, selectedClient.Nombre, { shouldDirty: true });
      setValue(formFields.clientId, selectedClient.Id, { shouldDirty: true });
      setValue(formFields.contactId, null, { shouldDirty: true });
      if (selectedClient.Tipo?.toString() === companyType.company) {
        setIsCompany(true);
        setIsLoading(true);
        try {
          const contactsData = await contactsService.getByCompany(
            selectedClient.Id
          );
          setContactsOptions(
            contactsData.map((contact) => ({
              value: contact.Id,
              label: setContactLabel(contact)
            }))
          );
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      } else {
        setIsCompany(false);
        setContactsOptions([]);
      }
    } else {
      setValue(formFields.name, null, { shouldDirty: true });
      setValue(formFields.clientId, null, { shouldDirty: true });
      setValue(formFields.contactId, null, { shouldDirty: true });
      setIsCompany(false);
      setContactsOptions([]);
    }
  }, [selectedClient]);

  const handleAddContact = async (values) => {
    try {
      const contactResponse = await contactsService.addItem({
        ...values,
        IdEmpresa: watchClientId
      });
      const newContact = {
        value: contactResponse.Id,
        label: setContactLabel(contactResponse)
      };
      setContactsOptions([...contactsOptions, newContact]);
      return newContact;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  useEffect(async () => {
    if (order) {
      const isNewOrder = order === 'new';
      setIsNew(isNewOrder);
      try {
        const deliveryMethodsData = await ordersService.getDeliveryMethods();
        setDeliveryMethods(
          deliveryMethodsData?.$values.map((method) => ({
            value: method.Id,
            label: method.Nombre
          }))
        );
        if (!isNewOrder) {
          const orderData = await ordersService.getOrder(order);
          setOrderInfo(orderData);
          const {
            DireccionEnvio,
            Documentacion,
            IdCliente,
            Descripcion,
            Observaciones,
            IdMetodoEnvio,
            Estado,
            FechaCreacion,
            IdUsuarioCreacion
          } = orderData;
          const {
            Nombre,
            Apellidos,
            Empresa,
            Direccion,
            CP,
            Poblacion,
            PaisCOD,
            Region,
            RegionCOD,
            Telefono,
            Email,
            Observaciones: deliveryNotes
          } = DireccionEnvio || {};
          setUserName(
            usersList.find((user) => user.id === IdUsuarioCreacion)?.name ||
              'Desconocido'
          );
          setFiles(Documentacion.$values);
          if (FechaCreacion) {
            setCreationDate(format(parseISO(FechaCreacion), 'dd/MM/yy'));
          }
          const clientData = await companiesService.getCompany(
            orderData.IdCliente
          );
          setSelectedClient(clientData);
          setOrderName(`${Descripcion ? ` - ${Descripcion}` : ''}`);
          setClient({
            name: clientData.Nombre,
            id: clientData.Id
          });
          reset({
            [formFields.status]: Estado,
            [formFields.clientId]: IdCliente,
            [formFields.name]: clientData.Nombre,
            [formFields.description]: Descripcion,
            [formFields.notes]: Observaciones,
            [formFields.deliveryMethod]: IdMetodoEnvio?.toString(),
            [formFields.deliveryName]: Nombre,
            [formFields.deliveryLastName]: Apellidos,
            [formFields.deliveryAddress]: Direccion,
            [formFields.deliveryPostalCode]: CP,
            [formFields.deliveryCity]: Poblacion,
            [formFields.deliveryRegion]: Region,
            [formFields.deliveryProvince]: RegionCOD,
            [formFields.deliveryCountry]: PaisCOD,
            [formFields.deliveryEmail]: Email,
            [formFields.deliveryPhone]: Telefono,
            [formFields.deliveryCompany]: Empresa,
            [formFields.deliveryNotes]: deliveryNotes
          });
          if (clientData.Tipo?.toString() === companyType.company) {
            setIsCompany(true);
            const contactsData = await contactsService.getByCompany(IdCliente);
            setContactsOptions(
              contactsData.map((contact) => ({
                value: contact.Id,
                label: setContactLabel(contact)
              }))
            );
          }
          await getLines(order);
          await getDeliveries(order);
          await getInvoices(order);
        } else if (clientInfo) {
          setSelectedClient(clientInfo);
          if (clientInfo.Tipo?.toString() === companyType.company) {
            setIsCompany(true);
            const contactsData = await contactsService.getByCompany(
              clientInfo.Id
            );
            setContactsOptions(
              contactsData.map((contact) => ({
                value: contact.Id,
                label: setContactLabel(contact)
              }))
            );
          }
          if (addressesList) {
            const defaultAddress = addressesList.find(
              (address) => address.Predeterminada
            );
            if (defaultAddress) {
              reset({
                [formFields.deliveryName]: defaultAddress.Nombre,
                [formFields.deliveryAddress]: defaultAddress.DireccionPostal,
                [formFields.deliveryPostalCode]: defaultAddress.CP,
                [formFields.deliveryCity]: defaultAddress.Poblacion,
                [formFields.deliveryRegion]: defaultAddress.Region,
                [formFields.deliveryProvince]: defaultAddress.Provincia,
                [formFields.deliveryCountry]: defaultAddress.PaisCOD,
                [formFields.deliveryEmail]: defaultAddress.Email,
                [formFields.deliveryPhone]: defaultAddress.Telefono,
                [formFields.deliveryNotes]: defaultAddress.Descripcion
              });
            }
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }, [order]);

  return (
    <FullDialog
      open={!!order}
      name={orderName}
      type='Pedido'
      id={order}
      isNew={isNew}
      icon={<OrdersIco />}
      handleClose={handleClose}
      client={client}
      touched={formState.isDirty || isFormUpdated}
    >
      <StyledOrderModal>
        {(order || isNew) && (
          <Helmet>
            <title>
              {isNew
                ? 'Crear pedido | Pascual Vinuesa'
                : `Pedido ${order} | Pascual Vinuesa`}
            </title>
          </Helmet>
        )}
        <Loader show={isLoading} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='top-section'>
            {!!userName && !!creationDate ? (
              <Text color='gray'>
                {userName === 'Desconocido'
                  ? `Importado el ${creationDate}`
                  : `Creado por ${userName} el ${creationDate}`}
              </Text>
            ) : (
              <span />
            )}
            <Select
              horizontal
              label='Estado:'
              name={formFields.status}
              control={control}
              options={orderStatusOptions}
              className='status-selector'
              placeholder='estado...'
              defaultValue={getValues(formFields.status)}
            />
            <Button
              secondary
              type='submit'
              disabled={!formState.isDirty && !isFormUpdated}
            >
              {isNew ? 'Guardar pedido' : 'Guardar y cerrar'}
            </Button>
          </div>
          <Tabs
            onSelect={handleTabChange}
            disabledTabClassName='disabled-tab'
            selectedTabClassName='selected-tab'
            className='tabs'
            defaultIndex={tabIndex()}
          >
            <TabList className='tab-list'>
              {tabs.map((tabItem) => (
                <Tab
                  key={tabItem.label}
                  className='tab'
                  disabled={tabItem.disabled || (tabItem.onlyEdit && isNew)}
                >
                  {tabItem.label}
                </Tab>
              ))}
            </TabList>
            <div className='tabs-content'>
              <TabPanel>
                <div className='order-data tab-columns'>
                  <div className='data-form'>
                    <CompaniesTypeAhead
                      selectedClient={selectedClient}
                      setSelectedClient={setSelectedClient}
                      error={
                        errors[formFields.name]?.message ||
                        errors[formFields.clientId]?.message
                      }
                    />
                    {isCompany && watchClientId && (
                      <ContentBox title='Contacto de la empresa'>
                        <SelectWithAdd
                          name={formFields.contactId}
                          control={control}
                          options={contactsOptions}
                          className='filter-select'
                          placeholder='selecciona un contacto'
                          defaultValue={getValues(formFields.contactId)}
                          handleAddItem={handleAddContact}
                          AddForm={NewContactForm}
                          addTitle='Añadir nuevo contacto'
                        />
                      </ContentBox>
                    )}
                    <Input
                      label='Descripción del pedido'
                      name={formFields.description}
                      inputRef={register}
                      placeholder='escribe la descripción'
                      error={
                        errors[formFields.description] &&
                        errors[formFields.description].message
                      }
                    />
                    <Textarea
                      label='Observaciones'
                      name={formFields.notes}
                      inputRef={register}
                      placeholder='observaciones acerca del pedido'
                      error={
                        errors[formFields.notes] &&
                        errors[formFields.notes].message
                      }
                      height='8.5rem'
                    />
                  </div>
                  <div className='data-delivery'>
                    <ContentBox title='Envío o recogida:'>
                      <div className='methods'>
                        {deliveryMethods.map((method) => (
                          <Radio
                            key={method.value}
                            name={formFields.deliveryMethod}
                            label={method.label}
                            inputRef={register}
                            value={method.value}
                          />
                        ))}
                      </div>
                      {watchDeliveryMethod && watchDeliveryMethod !== '2' && (
                        <div className='delivery-fields'>
                          <FormRow className='layout-1-1'>
                            <Input
                              label='Nombre'
                              name={formFields.deliveryName}
                              inputRef={register}
                              placeholder='escribe el nombre'
                              error={
                                errors[formFields.deliveryName] &&
                                errors[formFields.deliveryName].message
                              }
                            />
                            <Input
                              label='Apellidos'
                              name={formFields.deliveryLastName}
                              inputRef={register}
                              placeholder='escribe los apellidos'
                              error={
                                errors[formFields.deliveryLastName] &&
                                errors[formFields.deliveryLastName].message
                              }
                            />
                          </FormRow>
                          <FormRow>
                            <Input
                              label='Empresa'
                              name={formFields.deliveryCompany}
                              inputRef={register}
                              placeholder='escribe el nombre de la empresa'
                              error={
                                errors[formFields.deliveryCompany] &&
                                errors[formFields.deliveryCompany].message
                              }
                            />
                          </FormRow>
                          <FormRow>
                            <Input
                              label='Dirección'
                              name={formFields.deliveryAddress}
                              inputRef={register}
                              placeholder='escribe la dirección'
                              error={
                                errors[formFields.deliveryAddress] &&
                                errors[formFields.deliveryAddress].message
                              }
                            />
                          </FormRow>
                          <FormRow className='layout-4-6'>
                            <Input
                              label='Código postal'
                              name={formFields.deliveryPostalCode}
                              inputRef={register}
                              placeholder='escribe el código postal'
                              error={
                                errors[formFields.deliveryPostalCode] &&
                                errors[formFields.deliveryPostalCode].message
                              }
                            />
                            <Input
                              label='Población'
                              name={formFields.deliveryCity}
                              inputRef={register}
                              placeholder='escribe la población'
                              error={
                                errors[formFields.deliveryCity] &&
                                errors[formFields.deliveryCity].message
                              }
                            />
                          </FormRow>
                          <FormRow className='layout-4-6'>
                            <Select
                              label='Pais'
                              name={formFields.deliveryCountry}
                              control={control}
                              options={countriesList}
                              defaultValue={getValues(
                                formFields.deliveryCountry
                              )}
                              searchable
                              placeholder='selecciona el pais'
                              error={
                                errors[formFields.deliveryCountry] &&
                                errors[formFields.deliveryCountry].message
                              }
                            />
                            <ProvinceSelector
                              provinceName={formFields.deliveryProvince}
                              regionName={formFields.deliveryRegion}
                              control={control}
                              provinceDefaultValue={getValues(
                                formFields.deliveryProvince
                              )}
                              className='second'
                              provinceError={
                                errors[formFields.deliveryProvince] &&
                                errors[formFields.deliveryProvince].message
                              }
                              inputRef={register}
                              regionError={
                                errors[formFields.deliveryRegion] &&
                                errors[formFields.deliveryRegion].message
                              }
                              country={watchCountry}
                            />
                          </FormRow>
                          <FormRow className='layout-6-4'>
                            <Input
                              label='Email'
                              name={formFields.deliveryEmail}
                              inputRef={register}
                              placeholder='escribe el correo electrónico'
                              error={
                                errors[formFields.deliveryEmail] &&
                                errors[formFields.deliveryEmail].message
                              }
                            />
                            <Input
                              label='Teléfono'
                              name={formFields.deliveryPhone}
                              inputRef={register}
                              placeholder='escribe el teléfono'
                              error={
                                errors[formFields.deliveryPhone] &&
                                errors[formFields.deliveryPhone].message
                              }
                            />
                          </FormRow>
                          <FormRow>
                            <Textarea
                              label='Observaciones'
                              name={formFields.deliveryNotes}
                              inputRef={register}
                              placeholder='observaciones acerca del envío'
                              error={
                                errors[formFields.deliveryNotes] &&
                                errors[formFields.deliveryNotes].message
                              }
                              height='5.5rem'
                            />
                          </FormRow>
                        </div>
                      )}
                    </ContentBox>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <ArticlesList
                  articles={articles}
                  setArticles={updateArticles}
                  isNew={isNew}
                  saveArticles={saveArticles}
                  getLines={getLines}
                  getDeliveries={getDeliveries}
                  order={order}
                  handleDeliveryModalState={handleDeliveryModalState}
                />
              </TabPanel>
              <TabPanel>
                <Documentation files={files} setFiles={updateFiles} />
              </TabPanel>
              <TabPanel>
                <DeliveriesList
                  deliveries={deliveries}
                  getDeliveries={getDeliveries}
                  getOrderLines={getLines}
                  order={order}
                  handleDeliveryModalState={handleDeliveryModalState}
                  handleInvoiceModalState={handleInvoiceModalState}
                />
              </TabPanel>
              <TabPanel>
                <InvoicesList
                  invoices={invoices}
                  getInvoices={getInvoices}
                  order={order}
                  handleInvoiceModalState={handleInvoiceModalState}
                />
              </TabPanel>
            </div>
          </Tabs>
        </form>
        <Dialog
          title='Confirmar acción'
          open={!!showConfirmation}
          handleClose={handleCloseDialog}
          handleAccept={handleConfirmSave}
        >
          <p>
            {isNew
              ? '¿Deseas guardar el nuevo pedido?'
              : '¿Deseas editar el pedido?'}
          </p>
        </Dialog>
      </StyledOrderModal>
      {deliveryModalState && (
        <DeliveryModal
          orderInfo={orderInfo}
          articles={articles}
          getOrderLines={getLines}
          getDeliveries={getDeliveries}
          modalState={deliveryModalState}
          setModalState={handleDeliveryModalState}
        />
      )}
      {invoiceModalState && (
        <InvoiceModal
          modalState={invoiceModalState}
          setModalState={handleInvoiceModalState}
        />
      )}
    </FullDialog>
  );
}

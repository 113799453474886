import styled from 'styled-components';
import { rgba } from 'polished';

const StyledSupplierModal = styled.div`
  position: relative;
  .top-section {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tabs {
    .tab-list {
      background-color: ${({ theme }) => rgba(theme.colors.orange, 0.1)};
      .tab {
        display: inline-block;
        text-transform: uppercase;
        padding: 1.5rem 3rem;
        font-weight: 400;
        cursor: pointer;
        &.selected-tab {
          background-color: ${({ theme }) => theme.colors.orange};
          color: ${({ theme }) => theme.colors.white};
          cursor: default;
        }
        &.disabled-tab {
          opacity: 0.3;
          pointer-events: none;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .tabs-content {
      margin: 2.5rem 3rem 1.5rem;
      @media (max-width: 1024px) {
        margin: 2.5rem 1rem 1.5rem;
      }
    }
  }
  .tab-columns {
    display: flex;
    justify-content: space-between;
    & > div {
      width: 48%;
    }
  }
  @media (max-width: 1360px) {
    .tabs {
      .tab-list {
        .tab {
          padding: 1.5rem 1.5rem;
        }
      }
    }
  } ;
`;

export default StyledSupplierModal;

import React, { useState } from 'react';

import { ItemsTable } from '../../../components';
import StyledArticlesList from './ArticlesList.style';
import RowItems from './RowItems/RowItems';
import columns from './columns';
import DeliveryModal from '../../DeliveryModal/DeliveryModal';

export default function ArticlesList({ list }) {
  const [deliveryModalState, setDeliveryModalState] = useState(null);

  const handleDeliveryModalState = (newState) =>
    setDeliveryModalState(newState);

  const rowHandlers = {
    handleOpenDelivery: (id) => {
      setDeliveryModalState({ delivery: id });
    }
  };

  return (
    <StyledArticlesList>
      <ItemsTable
        title='Artículos'
        RowItems={RowItems}
        columns={columns}
        items={list}
        selectable={false}
        rowHandlers={rowHandlers}
      />
      {deliveryModalState && (
        <DeliveryModal
          modalState={deliveryModalState}
          setModalState={handleDeliveryModalState}
        />
      )}
    </StyledArticlesList>
  );
}

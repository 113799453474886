const columns = [
  {
    id: 'SKU',
    label: 'Referencia',
    width: '30px'
  },
  {
    id: 'Nombre',
    label: 'Nombre',
    width: '50px',
    sortable: true
  },
  {
    id: 'Inventario',
    label: 'Stock',
    width: '20px',
    sortable: true
  },
  {
    id: 'Marca',
    label: 'Marca',
    width: '40px'
  },
  {
    id: 'Proveedor',
    label: 'Proveedor',
    width: '40px'
  },
  {
    id: 'TipoPrecio',
    label: 'Unidad',
    width: '30px'
  },
  {
    id: 'UltimaEntradaPrecio',
    label: 'Precio compra',
    width: '30px'
  },
  {
    id: 'DescuentoPorcentaje',
    label: 'Descuento %',
    width: '20px'
  },
  {
    id: 'Precio',
    label: 'Precio',
    width: '30px',
    sortable: true
  },
  {
    id: 'history',
    label: '',
    width: '30px'
  }
];

export default columns;

import React from 'react';

import TextareaWrapper from './Textarea.style';

export default function TextArea({
  label,
  placeholder,
  disabled = false,
  error,
  inputRef,
  name,
  height
}) {
  return (
    <TextareaWrapper error={error} height={height}>
      <label>
        {label}
        <textarea
          placeholder={placeholder}
          disabled={disabled}
          ref={inputRef}
          name={name}
        />
      </label>
      {!disabled && error && <span className='error'>{error}</span>}
    </TextareaWrapper>
  );
}

import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';

const StyledSortPopover = styled.div`
  .sort-items {
    display: flex;
    align-items: center;
    font-weight: 400;
    cursor: pointer;
    svg {
      transition: all 0.2s;
    }
    &.open {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

export const StyledPopover = styled(Popover)`
  .sort-options {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    button {
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
`;

export default StyledSortPopover;

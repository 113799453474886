import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import { CurrencyDisplay, DateDisplay } from '../../../components';

export default function RowItems({ item }) {
  return (
    <>
      <TableCell className='cell-body'>
        <DateDisplay date={item.Fecha} />
      </TableCell>
      <TableCell className='cell-body'>{item.Empresa}</TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={item.Entrada || '-'} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={item.Salida || '-'} />
      </TableCell>

      <TableCell className='cell-body'>
        {`${item.DescuentoPorcentaje}%`}
      </TableCell>
      <TableCell className='cell-body'>{item.Cantidad}</TableCell>
      <TableCell className='cell-body'>{item.TipoPrecio || '-'}</TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={item.Total || '-'} />
      </TableCell>
      <TableCell className='cell-body'>{item.Inventario}</TableCell>
    </>
  );
}

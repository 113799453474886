import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';

import StyledDatesFilter from './DatesFilter.style';
import useQueryString from '../../hooks/useQueryString';
import {
  Text,
  Radio,
  Select,
  Button,
  Datepicker,
  FormCheckbox
} from '../../components';

import {
  parseQueryDate,
  getMonthYear,
  months,
  getMonthLabel,
  orderStatusOptions
} from '../../utils/functions';

const currentYear = new Date().getFullYear();

const years = [
  { value: currentYear, label: currentYear },
  { value: currentYear - 1, label: currentYear - 1 },
  { value: currentYear - 2, label: currentYear - 2 },
  { value: currentYear - 3, label: currentYear - 3 },
  { value: currentYear - 4, label: currentYear - 4 },
  { value: currentYear - 5, label: currentYear - 5 },
  { value: currentYear - 6, label: currentYear - 6 },
  { value: currentYear - 7, label: currentYear - 7 }
];

export default function DatesFilter({
  handleCloseFilter,
  setFilterActive,
  filterTypes = {},
  handleFilter,
  filterData
}) {
  const { register, handleSubmit, control, watch, reset, getValues } = useForm({
    defaultValues: {
      from: '',
      to: '',
      orderStatusCheck: null,
      orderStatus: null,
      type: null
    },
    shouldUnregister: false
  });
  const [filterError, setFilterError] = useState(null);
  const { query, updateQuery } = useQueryString();
  const watchType = watch('type');
  const watchOrderStatus = watch('orderStatusCheck');

  const setFilters = (data) => {
    const { FechaIni, FechaFin, TipoFiltro, Estado } = data;
    const filterValues = {};
    if (FechaIni && FechaFin) {
      if (TipoFiltro === 'range') {
        const parsedStart = parseQueryDate(FechaIni);
        const parsedEnd = parseQueryDate(FechaFin);
        filterValues.type = 'range';
        filterValues.from = parsedStart;
        filterValues.to = parsedEnd;
      }
      if (TipoFiltro === 'month') {
        const { month, year } = getMonthYear(FechaIni);
        filterValues.type = 'month';
        filterValues.month = month;
        filterValues.year = year;
      }
    }
    if (typeof Estado === 'string') {
      filterValues.orderStatusCheck = true;
      filterValues.orderStatus = +Estado;
    }
    reset(filterValues);
  };

  useEffect(() => {
    if (filterData || query) {
      setFilters(filterData || query);
    }
  }, []);

  useEffect(() => {
    if (query) {
      setFilters(query);
    }
  }, [query]);

  const onSubmit = (values) => {
    const { type, from, to, year, month, orderStatus } = values;
    let fromDate;
    let toDate;
    let hasFilter = false;
    const filters = [];
    if (!type && filterTypes.orderStatus && !watchOrderStatus) {
      setFilterError('Selecciona una opción');
      return;
    }
    if (type === 'month') {
      if (!year || !month) {
        setFilterError('Elige un mes y un año');
        return;
      }
      fromDate = `${year}-${month}-01`;
      toDate = format(new Date(year, month, 0), 'yyyy-MM-dd');
      hasFilter = true;
      filters.push(`${getMonthLabel(month)} ${year}`);
    }
    if (type === 'range') {
      if (!from || !to) {
        setFilterError('Selecciona las dos fechas');
        return;
      }
      if (from > to) {
        setFilterError(
          'La fecha de inicio no debe de ser mayor de la de final'
        );
        return;
      }
      fromDate = format(from, 'yyyy-MM-dd');
      toDate = format(to, 'yyyy-MM-dd');
      hasFilter = true;
      filters.push(
        `${format(from, 'dd/MM/yyyy')} a ${format(to, 'dd/MM/yyyy')}`
      );
    }
    if (watchOrderStatus) {
      if (orderStatus === null) {
        setFilterError('Selecciona un estado');
        return;
      }
      hasFilter = true;
      filters.push(`Estado: ${orderStatusOptions[orderStatus].label}`);
    }
    if (handleFilter) {
      handleFilter({
        FechaIni: fromDate,
        FechaFin: toDate,
        TipoFiltro: type,
        Estado: orderStatus
      });
    } else {
      updateQuery({
        FechaIni: fromDate,
        FechaFin: toDate,
        TipoFiltro: type,
        Estado: orderStatus
      });
    }
    if (hasFilter) {
      setFilterActive(filters.join(' | '));
    }
    setFilterError(null);
    handleCloseFilter();
  };

  const handleClearFilters = () => {
    reset();
    if (handleFilter) {
      handleFilter({
        FechaIni: undefined,
        FechaFin: undefined,
        TipoFiltro: undefined,
        Estado: undefined
      });
    } else {
      updateQuery({
        FechaIni: undefined,
        FechaFin: undefined,
        TipoFiltro: undefined,
        Estado: undefined
      });
    }
    handleCloseFilter();
    setFilterActive(null);
  };

  return (
    <StyledDatesFilter>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text className='clear-filters' onClick={handleClearFilters}>
          Borrar filtros
        </Text>
        <div className='filter-item'>
          <Radio
            name='type'
            label='Por mes/año'
            inputRef={register}
            value='month'
            className='filter-radio'
          />
          <Select
            name='month'
            control={control}
            options={months}
            className='filter-select'
            disabled={watchType !== 'month'}
            placeholder='mes...'
            defaultValue={getValues('month')}
          />
          <Select
            name='year'
            control={control}
            options={years}
            className='filter-select'
            disabled={watchType !== 'month'}
            placeholder='año...'
            defaultValue={getValues('year')}
          />
        </div>
        <div className='filter-item'>
          <Radio
            name='type'
            label='Desde/hasta'
            inputRef={register}
            value='range'
            className='filter-radio'
          />
          <Datepicker
            name='from'
            control={control}
            disabled={watchType !== 'range'}
            placeholder='Desde...'
            className='date-picker'
          />
          <Datepicker
            name='to'
            control={control}
            placeholder='Hasta...'
            disabled={watchType !== 'range'}
            className='date-picker'
          />
        </div>
        {filterTypes.orderStatus && (
          <div className='filter-item'>
            <FormCheckbox
              label='Por estado'
              name='orderStatusCheck'
              control={control}
              className='filter-checkbox'
            />
            <Select
              name='orderStatus'
              control={control}
              options={orderStatusOptions}
              className='filter-select'
              disabled={!watchOrderStatus}
              placeholder='estado...'
              defaultValue={getValues('orderStatus')}
            />
          </div>
        )}
        <div className='filters-search'>
          {filterError && <Text color='red'>{filterError}</Text>}
          <Button type='button' onClick={handleSubmit(onSubmit)}>
            Buscar
          </Button>
        </div>
      </form>
    </StyledDatesFilter>
  );
}

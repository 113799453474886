import React from 'react';

import StyledContactItem from './ContactItem.style';
import { Text } from '../../components';

export default function ContactItem({ item }) {
  const { Nombre, Telefono, Email, Apellidos } = item;
  return (
    <StyledContactItem>
      <Text>
        {Nombre} {Apellidos}
      </Text>
      <Text color='orange'>
        <Text as='span' bold>
          {Telefono}
        </Text>
        {Telefono && Email && ' - '}
        {Email}
      </Text>
    </StyledContactItem>
  );
}

import styled from 'styled-components';
import { rgba } from 'polished';

const StyledArticles = styled.div`
  position: relative;
  .top-section {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
    button {
      margin-left: 1rem;
      padding: 1rem 2rem;
      border-radius: 2rem;
    }
  }
`;

export const StyledImportDialog = styled.div`
  width: 35rem;
  .typeahead-container {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    p {
      margin-right: 1rem;
    }
    & > div {
      flex: 1;
      & > div {
        margin-bottom: 0;
      }
    }
  }
  .file-upload {
    border: 1px solid ${({ theme }) => theme.colors.gray};
    background-color: ${({ theme }) => theme.colors.lightGray};
    padding: 5.5rem 0;
    margin-bottom: 4rem;
    position: relative;
    button {
      background-color: ${({ theme }) => theme.colors.gray};
      color: ${({ theme }) => theme.colors.white};
      padding: 0.5rem 2.5rem;
      border-radius: 3rem;
      cursor: pointer;
      font-weight: 400;
    }
    input {
      display: none;
    }
    .filename {
      position: absolute;
      bottom: 3rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  button {
    display: block;
    margin: auto;
  }
`;

export const StyledSImportResults = styled.div`
  width: 35rem;
  .import-box {
    background-color: ${({ theme }) => rgba(theme.colors.lightGray, 0.6)};
    padding: 1rem;
    margin: 0.5rem 0;
    .results-info {
      margin-left: 9rem;
    }
  }
`;

export default StyledArticles;

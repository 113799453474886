import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import qs from 'qs';
import { format, subYears } from 'date-fns';

import StyledStockModal, {
  StyledDialog,
  StyledTitle,
  StyledContent
} from './StockModal.style';
import { Text, ItemsTable } from '../../components';
import { DatesFilter } from '../../molecules';
import { ReactComponent as RelojIco } from '../../resources/svg/ico-reloj.svg';
import ArticlesService from '../../services/Article';
import columns from './columns';
import RowItems from './RowItems/RowItems';
import {
  formatQueryDate,
  getMonthYear,
  getMonthLabel
} from '../../utils/functions';

// eslint-disable-next-line prettier/prettier
export default function StockModal({
  open,
  handleClose,
  Nombre,
  SKU,
  TipoPrecio
}) {
  const [entries, setEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [getQuery, setGetQuery] = useState({});
  const [filterActive, setFilterActive] = useState(null);
  const [filterQuery, setFilterQuery] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const articlesService = new ArticlesService();

  const handleFilter = (filter) => {
    setGetQuery({
      ...getQuery,
      ...filter
    });
    setFilterQuery(filter);
    const { FechaIni, FechaFin, TipoFiltro } = filter;
    if (FechaIni && FechaFin) {
      if (TipoFiltro === 'range') {
        setFilterActive(
          `${formatQueryDate(FechaIni)} a ${formatQueryDate(FechaFin)}`
        );
      }
      if (TipoFiltro === 'month') {
        const { month, year } = getMonthYear(FechaIni);
        setFilterActive(`${getMonthLabel(month)} ${year}`);
      }
    }
  };

  useEffect(() => {
    handleFilter({
      FechaFin: format(new Date(), 'yyyy-MM-dd'),
      FechaIni: format(subYears(new Date(), 1), 'yyyy-MM-dd'),
      TipoFiltro: 'range'
    });
  }, []);

  useEffect(async () => {
    setIsLoading(true);
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      try {
        const entriesData = await articlesService.getStock(
          qs.stringify(
            { ...getQuery, Paginacion: 100, PaginaActual: 1, SKUArticulo: SKU },
            { addQueryPrefix: true }
          )
        );
        setEntries(
          entriesData.Datos.$values.map((entry) => ({
            Id: entry.Id,
            Fecha: entry.Fecha,
            Cantidad: entry.Cantidad,
            TipoPrecio,
            Empresa: entry.Empresa,
            Entrada: entry.Tipo === 0 ? entry.Precio : null,
            Salida: entry.Tipo === 1 ? entry.Precio : null,
            Total: entry.Total,
            DescuentoPorcentaje: entry.DescuentoPorcentaje,
            Inventario: entry.Inventario
          }))
        );
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  }, [getQuery]);

  return (
    <StyledDialog open={open} fullWidth maxWidth='xl'>
      <StyledTitle>
        <Text size='m' color='white'>
          <RelojIco className='search-icon' />
          Histórico de precios
        </Text>
        <CloseIcon onClick={handleClose} fontSize='large' className='close' />
      </StyledTitle>
      <StyledContent>
        <StyledStockModal>
          <ItemsTable
            items={entries}
            title={`${Nombre} - ${SKU}`}
            filterLabel='Filtrar'
            selectable={false}
            columns={columns}
            isLoading={isLoading}
            RowItems={RowItems}
            FilterForm={DatesFilter}
            filterActive={filterActive}
            setFilterActive={setFilterActive}
            handleFilter={handleFilter}
            filterData={filterQuery}
          />
        </StyledStockModal>
      </StyledContent>
    </StyledDialog>
  );
}

import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import { CurrencyDisplay, DateDisplay } from '../../../../components';

export default function RowItems({ item }) {
  return (
    <>
      <TableCell className='cell-body'>{item.Codigo}</TableCell>
      <TableCell className='cell-body'>
        <DateDisplay date={item.Fecha} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={item.Total} />
      </TableCell>
      <TableCell className='cell-body'>
        {item.IdFactura ? item.CodigoFactura || 'Vacio' : '-'}
      </TableCell>
      <TableCell className='cell-body'>{item.user}</TableCell>
    </>
  );
}

import React, { useEffect, useState } from 'react';

import StyledNewPaymentForm from './NewPaymentForm.style';
import { Input, Radio, FormRow, FormCheckbox } from '../../components';

const paymentType = {
  account: '0',
  card: '1'
};

export default function NewPaymentForm({
  register,
  errors,
  watch,
  setValue,
  getValues,
  control
}) {
  const watchType = watch('Tipo');
  const [defaultDisabled, setDefaultDisabled] = useState(false);
  useEffect(() => {
    if (!watchType) {
      setValue('Tipo', paymentType.account);
    }
  }, [watchType]);

  useEffect(() => {
    if (getValues('Predeterminada')) {
      setDefaultDisabled(true);
    }
  }, []);
  return (
    <StyledNewPaymentForm>
      <div className='radio-buttons'>
        <Radio
          name='Tipo'
          label='Domiciliación'
          inputRef={register}
          value={paymentType.account}
          checked
        />
        <Radio
          name='Tipo'
          label='Tarjeta'
          inputRef={register}
          value={paymentType.card}
        />
      </div>
      <Input
        name='Titular'
        label='Titular'
        inputRef={register({ required: 'Este campo es obligatorio' })}
        error={errors.Titular?.message}
      />
      <div hidden={watchType === paymentType.card}>
        <Input name='IBAN' label='IBAN' inputRef={register} />
        <Input name='BIC' label='BIC' inputRef={register} />
      </div>
      <div hidden={watchType === paymentType.account}>
        <Input name='Tarjeta' label='Número de tarjeta' inputRef={register} />
      </div>
      <FormRow
        hidden={watchType === paymentType.account}
        className='layout-1-1'
      >
        <Input
          type='number'
          name='TarjetaCadMes'
          label='Mes caducidad'
          inputRef={register({
            min: {
              value: 1,
              message: 'Debe ser mayor o igual que que 1'
            },
            max: {
              value: 12,
              message: 'Debe ser menor o igual que que 12'
            }
          })}
          error={errors.TarjetaCadMes?.message}
        />
        <Input
          type='number'
          name='TarjetaCadYear'
          label='Año caducidad'
          inputRef={register({
            min: {
              value: 21,
              message: 'Debe ser mayor o igual que que 21'
            },
            max: {
              value: 99,
              message: 'Debe ser menor o igual que que 99'
            }
          })}
          error={errors.TarjetaCadYear?.message}
        />
      </FormRow>
      <FormCheckbox
        label='Predeterminada'
        name='Predeterminada'
        control={control}
        disabled={defaultDisabled}
      />
    </StyledNewPaymentForm>
  );
}

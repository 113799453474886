import styled from 'styled-components';

const StyledDeliveryModal = styled.div`
  position: relative;
  .top-section {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .user-date {
      display: flex;
      align-items: center;
      .date-input {
        width: auto;
        margin-bottom: 0;
        margin-left: 2rem;
      }
      .status-selector {
        width: 15rem;
        margin-bottom: 0;
        margin-left: 3rem;
      }
    }
    .modal-buttons {
      display: flex;
      .print-button {
        display: flex;
        align-items: center;
        margin-right: 2rem;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.itemsOptionsText};
        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }
  .tabs {
    .tab-list {
      background-color: ${({ theme }) => theme.colors.lightGray};
      .tab {
        display: inline-block;
        text-transform: uppercase;
        padding: 1.5rem 3rem;
        font-weight: 400;
        cursor: pointer;
        &.selected-tab {
          background-color: ${({ theme }) => theme.colors.gray};
          color: ${({ theme }) => theme.colors.white};
          cursor: default;
        }
        &.disabled-tab {
          opacity: 0.3;
          pointer-events: none;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .tab-columns {
      display: flex;
      justify-content: space-between;
      & > div {
        width: 48%;
      }
    }
    .tabs-content {
      margin: 2.5rem 3rem 1.5rem;
      @media (max-width: 1024px) {
        margin: 2.5rem 1rem 1.5rem;
      }
    }
    .client-type {
      display: flex;
      margin-bottom: 1rem;
    }
  }
  .totals-container {
    margin: 0 -3rem;
    margin-top: 8rem;
    padding: 3rem;
    background-color: ${({ theme }) => theme.colors.lightGray};
    display: flex;
    justify-content: space-between;
    .inputs {
      display: flex;
      & > div {
        & > p {
          margin-bottom: 0.5rem;
        }
        .price-input {
          width: 4.5rem;
          margin-bottom: 0;
        }
        .pending {
          background-color: #f5f5f5;
          width: 8.5rem;
          display: block;
          border-radius: 5px;
          padding: 0.5625rem 1rem;
        }
        &:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }
    .totals {
      display: flex;
      margin-top: 0.75rem;
      align-items: center;
      .subtotals {
        padding-right: 1.5rem;
        border-right: 1px solid ${({ theme }) => theme.colors.text};
        & > p:first-child {
          margin-bottom: 1rem;
        }
        span {
          font-weight: 400;
          font-size: 18px;
        }
      }
      .total {
        padding-left: 1rem;
        p {
          font-size: 20px;
          span {
            font-weight: 400;
          }
        }
      }
    }
  }
  .credit {
    margin-top: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    width: 10.5rem;
    display: block;
    border-radius: 5px;
    padding: 0.5625rem 1rem;
  }
  .invoice-series {
    width: 12rem;
  }
  .data-delivery {
    .methods {
      display: flex;
      margin-bottom: 2rem;
    }
  }
  .carrier-form {
    width: 48%;
    padding-bottom: 7rem;
    .carrier-title {
      margin-bottom: 0.5rem;
    }
  }
`;

export const StyledPrintModal = styled.div`
  width: 32rem;
  p {
    margin-bottom: 3rem;
  }
  .buttons {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 2rem;
    button {
      width: 11.25rem;
      text-align: center;
    }
  }
`;

export default StyledDeliveryModal;

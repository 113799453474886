import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import { Loader } from '../components';

export default function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  return !('loading' in auth) || auth.loading ? (
    <Loader fullScreen show background='#FFFFFF' />
  ) : (
    <Route
      {...rest}
      render={({ location }) =>
        auth.name ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

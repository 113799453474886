const columns = [
  {
    id: 'ID',
    label: 'Id',
    width: '50px',
    sortable: true
  },
  {
    id: 'Nombre',
    label: 'Nombre',
    width: '200px',
    sortable: true
  },
  {
    id: 'CIF',
    label: 'CIF',
    width: '50px',
    sortable: true
  },
  {
    id: 'lastDelivery',
    label: 'Último albarán',
    width: '150px'
  }
];

export default columns;

import styled from 'styled-components';

const StyledDeliveriesList = styled.div`
  .options-buttons {
    p {
      order: 1;
    }

    button:nth-of-type(1) {
      order: 2;
    }
    button:nth-of-type(2) {
      order: 4;
    }
    button:nth-of-type(3) {
      order: 3;
    }
  }
  .table-options .options-buttons .delete-button.generate-invoices-button {
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export default StyledDeliveriesList;

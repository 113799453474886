import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import { LastCartOrder } from '../../../components';
import StyledEmail from './RowItems.style';

export default function RowItems({ item }) {
  const handleClickEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window) {
      window.location = `mailto:${item.EmailFacturas}`;
    }
  };

  return (
    <>
      <TableCell className='cell-body'>{item.Id}</TableCell>
      <TableCell className='cell-body'>{item.Nombre}</TableCell>
      <TableCell className='cell-body'>{item.CIF || '-'}</TableCell>
      <TableCell className='cell-body'>
        <StyledEmail
          href={`mailto:${item.EmailFacturas}`}
          onClick={(e) => handleClickEmail(e)}
        >
          {item.EmailFacturas}
        </StyledEmail>
      </TableCell>
      <TableCell className='cell-body'>
        <LastCartOrder
          type='order'
          code={item.CodigoPedido}
          date={item.FechaPedido}
          total={item.TotalPedido}
        />
      </TableCell>
    </>
  );
}

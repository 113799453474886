import React from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import StyledTypeahead, { TypeaheadContainer } from './TypeAhead.style';
import { ReactComponent as LupaIco } from '../../resources/svg/ico-lupa.svg';
import Text from '../Text/Text';

export default function TypeAhead({
  id,
  isLoading,
  options,
  selected,
  onSearch,
  onSelect,
  placeholder,
  typeRef,
  disabled,
  className,
  labelKey,
  error
}) {
  return (
    <TypeaheadContainer disabled={disabled} className={className}>
      <StyledTypeahead
        minLength={3}
        searchText='Buscando...'
        promptText='Escribe para buscar...'
        emptyLabel='No se han encontrado resultados'
        id={id}
        labelKey={labelKey}
        isLoading={isLoading}
        options={options}
        selected={selected}
        onSearch={onSearch}
        onChange={onSelect}
        placeholder={placeholder}
        paginate={false}
        useCache={false}
        ref={typeRef}
        disabled={disabled}
        clearButton
        positionFixed
        className={className}
        error={error}
      />
      <LupaIco className='icon' />
      {error && (
        <Text color='red' className='error-message'>
          {error}
        </Text>
      )}
    </TypeaheadContainer>
  );
}

import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 0;
  }
`;

const StyledTitle = styled(DialogTitle)`
  background-color: ${({ theme, blue }) =>
    blue ? theme.colors.blue : theme.colors.orange};
  h2 {
    color: ${({ theme }) => theme.colors.white};
    font-family: 'Oswald', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close {
      cursor: pointer;
      font-size: 1.5rem;
      margin-left: 12rem;
    }
  }
`;

const StyledContentText = styled(DialogContentText)`
  font-family: 'Oswald', sans-serif;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 1.5rem;
`;

const StyledActions = styled(DialogActions)`
  margin-bottom: 2rem;
`;

export { StyledDialog, StyledTitle, StyledContentText, StyledActions };

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Text, Select, Button, FormCheckbox } from '../../../components';
import StyledClientsFilter from './ClientsFilter.style';
import useQueryString from '../../../hooks/useQueryString';
import { useAuth } from '../../../providers/AuthProvider';
import listTypes from '../../../utils/listTypes';

export default function ClientsFilter({ handleCloseFilter, setFilterActive }) {
  const { handleSubmit, control, watch, reset, getValues } = useForm({
    defaultValues: {
      IdGrupoCaucion: null,
      IdRepresentante: null,
      clientGroup: null,
      agent: null
    },
    shouldUnregister: false
  });
  const [filterError, setFilterError] = useState(null);
  const { query, updateQuery } = useQueryString();
  const watchClientGroup = watch('clientGroup');
  const watchAgent = watch('agent');
  const { lists } = useAuth();
  const agentsList = lists.filter(
    (item) => item.type === listTypes.representante
  );
  const clientsGroupList = lists.filter(
    (item) => item.type === listTypes.grupoCaucion
  );

  useEffect(() => {
    if (query) {
      const { IdGrupoCaucion, IdRepresentante } = query;
      const filterValues = {};
      if (IdGrupoCaucion) {
        filterValues.IdGrupoCaucion = +IdGrupoCaucion;
        filterValues.clientGroup = true;
      }
      if (IdRepresentante) {
        filterValues.IdRepresentante = +IdRepresentante;
        filterValues.agent = true;
      }
      reset(filterValues);
    }
  }, [query]);

  const onSubmit = (values) => {
    const { IdGrupoCaucion, IdRepresentante } = values;
    console.log(values);
    const filterQuery = {
      IdGrupoCaucion: undefined,
      IdRepresentante: undefined
    };
    if (!watchAgent && !watchClientGroup) {
      setFilterError('Selecciona una opción');
      return;
    }
    if (watchAgent) {
      if (!IdRepresentante) {
        setFilterError('Selecciona un representante');
        return;
      }
      filterQuery.IdRepresentante = IdRepresentante;
    }
    if (watchClientGroup) {
      if (!IdGrupoCaucion) {
        setFilterError('Selecciona un grupo de cliente');
        return;
      }
      filterQuery.IdGrupoCaucion = IdGrupoCaucion;
    }
    updateQuery(filterQuery);
    setFilterError(null);
    handleCloseFilter();
  };

  const handleClearFilters = () => {
    reset();
    updateQuery({
      IdGrupoCaucion: undefined,
      IdRepresentante: undefined
    });
    handleCloseFilter();
    setFilterActive(null);
  };

  return (
    <StyledClientsFilter>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text className='clear-filters' onClick={handleClearFilters}>
          Borrar filtros
        </Text>
        <div className='filter-item'>
          <FormCheckbox
            label='Por representante'
            name='agent'
            control={control}
            className='filter-checkbox'
          />
          <Select
            name='IdRepresentante'
            control={control}
            options={agentsList}
            className='filter-select'
            disabled={!watchAgent}
            placeholder='selecciona representante'
            defaultValue={getValues('IdRepresentante')}
          />
        </div>
        <div className='filter-item'>
          <FormCheckbox
            label='Por grupo de Cliente'
            name='clientGroup'
            control={control}
            className='filter-checkbox'
          />
          <Select
            name='IdGrupoCaucion'
            control={control}
            options={clientsGroupList}
            className='filter-select'
            disabled={!watchClientGroup}
            placeholder='selecciona grupo de cliente'
            defaultValue={getValues('IdGrupoCaucion')}
          />
        </div>
        <div className='filters-search'>
          {filterError && <Text color='red'>{filterError}</Text>}
          <Button type='submit'>Buscar</Button>
        </div>
      </form>
    </StyledClientsFilter>
  );
}

import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '../Checkbox/Checkbox';

import StyledRow from './SelectableRow.style';

export default function index({
  children,
  id,
  selected,
  setSelected,
  handleClickRow,
  selectable
}) {
  const isSelected = () => selected?.indexOf(id) !== -1;

  const handleSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let newSelected = [];
    const selectedIndex = selected?.indexOf(id);

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter((item) => item !== id);
    }

    setSelected(newSelected);
  };

  return (
    <StyledRow
      hover
      selected={selectable ? isSelected(id) : false}
      onClick={() => handleClickRow && handleClickRow(id)}
      selectable={selectable}
    >
      {selectable && (
        <TableCell>
          <Checkbox checked={isSelected(id)} onClick={(e) => handleSelect(e)} />
        </TableCell>
      )}
      {children}
    </StyledRow>
  );
}

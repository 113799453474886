import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';

import StyledArticlesModal, {
  StyledDialog,
  StyledTitle,
  StyledContent
} from './ArticlesModal.style';
import { ReactComponent as LupaIco } from '../../resources/svg/ico-lupa.svg';
import { Text, FilterItems, Loader, ItemsTable } from '../../components';
import ArticlesService from '../../services/Article';
import CompaniesService from '../../services/Company';
import ArticlesFilter from '../../pages/Articles/ArticlesFilter/ArticlesFilter';
import RowItems from '../../pages/Articles/RowItems/RowItems';
import columns from '../../pages/Articles/columns';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';

export default function ArticlesModal({
  open,
  handleClose,
  handleAddArticles
}) {
  const articlesService = new ArticlesService();
  const companiesService = new CompaniesService();
  const dispatchSnackbar = useDispatchSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [selected, setSelected] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [filterActive, setFilterActive] = useState(null);
  const [filterQuery, setFilterQuery] = useState(null);
  const [getQuery, setGetQuery] = useState({});

  const handleSearch = (value) => {
    setGetQuery({
      ...getQuery,
      Busqueda: value
    });
  };

  const handleClickAdd = () => {
    const articlesToAdd = selected
      .map((item) => articles.find((article) => item === article.Id))
      .map((article) => {
        const UUID = uuidv4();
        return {
          IdReferencia: article.IdReferencia,
          IdPedidoLinea: article.IdPedidoLinea,
          IdProducto: article.IdProducto,
          Nombre: article.Nombre,
          Descripcion: article.Descripcion,
          Inventario: article.Inventario,
          SKU: article.SKU,
          DescuentoPorcentaje: article.DescuentoPorcentaje,
          PrecioOriginal: article.Precio,
          Precio: article.PrecioConDescuento,
          IVA: article.IVA,
          IvaIncluido: article.IvaIncluido,
          Tipo: 0,
          Cantidad: 1,
          TipoPrecio: article.TipoPrecio,
          StockAjustado: article.StockAjustado,
          Id: UUID,
          UUID
        };
      });
    const isPlural = selected.length > 1;
    handleAddArticles(articlesToAdd);
    setSelected([]);
    dispatchSnackbar({
      type: 'SET',
      payload: `Artículo${isPlural ? 's' : ''} añadido${isPlural ? 's' : ''}`
    });
  };

  const handleFilter = async (filter) => {
    setGetQuery({
      ...getQuery,
      ...filter
    });
    setFilterQuery(filter);
    let hasFilter = false;
    const filters = [];
    if (filter.IdCategoria) {
      const categoryName = categories.find(
        (a) => a.value === filter.IdCategoria
      );
      hasFilter = true;
      filters.push(`Categoría: ${categoryName?.label}`);
    }
    if (filter.IdMarca) {
      const brandName = brands.find((c) => c.value === filter.IdMarca);
      hasFilter = true;
      filters.push(`Marca: ${brandName?.label}`);
    }
    if (filter.IdFabricante) {
      const manufacturerName = manufacturers.find(
        (c) => c.value === filter.IdFabricante
      );
      hasFilter = true;
      filters.push(`Fabricante: ${manufacturerName?.label}`);
    }
    if (filter.IdProveedor) {
      try {
        const supplierResponse = await companiesService.getCompany(
          filter.IdProveedor
        );
        hasFilter = true;
        filters.push(`Proveedor: ${supplierResponse?.Nombre}`);
      } catch (error) {
        console.log(error);
      }
    }
    if (hasFilter) {
      setFilterActive(filters.join(' | '));
    }
  };

  const handleSort = (sortQuery) => {
    setGetQuery({
      ...getQuery,
      ...sortQuery
    });
  };

  const getOptions = (response) =>
    response?.$values.map((item) => ({
      value: item.Id,
      label: item.Nombre
    }));

  useEffect(async () => {
    try {
      const manufacturersResponse = await articlesService.getManufacturers();
      const categoriesResponse = await articlesService.getCategories();
      const brandsResponse = await articlesService.getBrands();
      const categoriesTree = [];
      const getCategories = (category, parent) => {
        const categoryLabel = parent
          ? `${parent} > ${category.Nombre}`
          : category.Nombre;
        categoriesTree.push({
          value: category.Id,
          label: categoryLabel
        });
        if (category.Subcategorias?.$values?.length) {
          category.Subcategorias?.$values?.forEach((item) =>
            // eslint-disable-next-line no-unused-vars
            getCategories(item, categoryLabel)
          );
        }
      };
      categoriesResponse?.$values.forEach((item) => getCategories(item));
      const manufacturerOptions = getOptions(manufacturersResponse);
      const brandOptions = getOptions(brandsResponse);
      setManufacturers(manufacturerOptions);
      setBrands(brandOptions);
      setCategories(categoriesTree);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, []);

  useEffect(async () => {
    let isEmpty = true;
    Object.values(getQuery).forEach((value) => {
      if (value) {
        isEmpty = false;
      }
    });
    if (!isEmpty) {
      setIsLoading(true);
      try {
        const articlesData = await articlesService.getArticles(
          qs.stringify(
            { ...getQuery, Paginacion: 50, PaginaActual: 1 },
            { addQueryPrefix: true }
          )
        );
        const { $values: articlesList } = articlesData.Datos;
        setArticles(
          articlesList.map((item) => ({
            ...item,
            Id: item.SKU,
            Marca: brands.find((brand) => brand.value === item.IdMarca)?.label,
            Nombre: item.NombreCompleto
          }))
        );
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else {
      setArticles([]);
    }
  }, [getQuery]);

  return (
    <StyledDialog open={open} fullWidth maxWidth='xl'>
      <StyledTitle>
        <Text size='m' color='white'>
          <LupaIco className='search-icon' />
          Buscador de artículos
        </Text>
        <CloseIcon onClick={handleClose} fontSize='large' className='close' />
      </StyledTitle>
      <StyledContent>
        <StyledArticlesModal>
          <Loader show={isLoading} />
          <div className='top-section'>
            <FilterItems
              isLoading={isLoading}
              placeholder='Escribe para buscar artículos'
              handleSearch={handleSearch}
              minLength={3}
            />
          </div>
          <ItemsTable
            handleClickDelete={handleClickAdd}
            deleteLabel='Añadir al pedido'
            FilterForm={ArticlesFilter}
            handleFilter={handleFilter}
            filterData={filterQuery}
            filterLabel='Filtrar artículos'
            filterActive={filterActive}
            filterOptions={{ manufacturers, brands, categories }}
            setFilterActive={setFilterActive}
            title='Resultados de búsqueda'
            columns={columns}
            items={articles}
            isLoading={isLoading}
            RowItems={RowItems}
            sortItems={handleSort}
            selected={selected}
            setSelected={setSelected}
            getQuery={getQuery}
          />
        </StyledArticlesModal>
      </StyledContent>
    </StyledDialog>
  );
}

import styled from 'styled-components';

const StyledInvoiceDelivery = styled.div`
  display: flex;
  flex-direction: column;
  svg {
    width: 1rem;
    margin-right: 0.5rem;
  }
`;

export default StyledInvoiceDelivery;

import styled from 'styled-components';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { rgba } from 'polished';

const StyledFreeTextForm = styled.form`
  position: relative;
  padding: 1rem 0.75rem 1.5rem 0.75rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: flex-start;
  background-color: ${({ theme }) => rgba(theme.colors.lightGray, 0.3)};
  .inputs {
    display: flex;
    flex: 1;
    input {
      border-radius: 0;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
    }
    & > div {
      margin-bottom: 0;
      flex: 1;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    .name-input {
      flex: 4;
    }
  }
  .add-line {
    display: flex;
    align-items: center;
    margin-left: 3rem;
    width: 6rem;
  }
`;

export const StyledTypeahead = styled(AsyncTypeahead)`
  .form-control {
    display: block;
    width: 100%;
    height: 2.375rem;
    padding: 0.375rem 0.75rem;
    font-weight: 300;
    line-height: 1.5;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    &:focus {
      outline: none;
    }
  }

  .rbt-input-hint {
    font-weight: 300;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    margin-top: 0.5rem;
    padding: 0.5rem 0;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid ${({ theme }) => theme.colors.gray};
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem;
    clear: both;
    font-weight: 300;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGray};
    }
  }

  .rbt-highlight-text {
    background-color: inherit;
    color: inherit;
    font-weight: 400;
    padding: 0;
  }

  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
  }

  .close {
    float: right;
    font-size: 3rem;
    font-weight: 300;
    line-height: 1;
    color: ${({ theme }) => theme.colors.blue};
    text-shadow: 0 1px 0 #fff;
    opacity: 0.6;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0;
  }

  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    -webkit-clip-path: none;
    clip-path: none;
  }

  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner-border {
    display: inline-block;
    color: ${({ theme }) => theme.colors.gray};
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
  }

  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }
`;

export default StyledFreeTextForm;

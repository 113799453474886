import relationType from '../../utils/relationType';
import companyType from '../../utils/companyType';

const formFields = {
  city: 'Poblacion',
  postalCode: 'CP',
  address: 'Direccion',
  cif: 'CIF',
  email: 'email',
  notes: 'Observaciones',
  country: 'PaisCOD',
  name: 'Nombre',
  businessName: 'Razon',
  province: 'Provincia',
  region: 'Region',
  agent: 'IdRepresentante',
  phone: 'Telefono',
  type: 'Tipo',
  clientGroup: 'IdGrupoCaucion',
  web: 'Dominio',
  relation: 'TipoRelacion',
  paymentMethod: 'IdFormaPago',
  pendingInvoices: 'AcumFacturasPdtesContab',
  invoiceTermDays: 'DiasPlazoFra',
  balance: 'Saldo',
  debtTotal: 'ImporteDeuda',
  creditLimit: 'LimiteCredito',
  invoicesEmail: 'EmailFacturas',
  sendPostalInvoice: 'EnviarFacturaImpresa',
  sendPostalInvoiceOther: 'EnviarFacturaImpresaOtra',
  invoicesPostalCode: 'CPPostal',
  invoicesName: 'NombrePostal',
  invoicesAddress: 'DireccionPostal',
  invoicesCountry: 'PaisCODPostal',
  invoicesCity: 'PoblacionPostal',
  invoicesProvince: 'ProvinciaPostal',
  invoicesRegion: 'RegionPostal'
};

const formDefaultValues = {
  [formFields.city]: null,
  [formFields.postalCode]: null,
  [formFields.address]: null,
  [formFields.cif]: null,
  [formFields.email]: null,
  [formFields.notes]: null,
  [formFields.country]: 'ES',
  [formFields.province]: '09',
  [formFields.region]: null,
  [formFields.private]: null,
  [formFields.name]: null,
  [formFields.businessName]: null,
  [formFields.agent]: null,
  [formFields.phone]: null,
  [formFields.type]: companyType.company,
  [formFields.clientGroup]: null,
  [formFields.web]: null,
  [formFields.relation]: relationType.client,
  [formFields.paymentMethod]: null,
  [formFields.pendingInvoices]: null,
  [formFields.invoiceTermDays]: null,
  [formFields.balance]: null,
  [formFields.debtTotal]: null,
  [formFields.creditLimit]: null,
  [formFields.invoicesEmail]: null,
  [formFields.sendPostalInvoice]: null,
  [formFields.sendPostalInvoiceOther]: null,
  [formFields.invoicesPostalCode]: null,
  [formFields.invoicesAddress]: null,
  [formFields.invoicesName]: null,
  [formFields.invoicesCountry]: 'ES',
  [formFields.invoicesCity]: null,
  [formFields.invoicesProvince]: '09',
  [formFields.invoicesRegion]: null
};

export { formFields, relationType, companyType, formDefaultValues };

import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import StyledDocumentation from './Documentation.style';
import FilesService from '../../../services/File';
import { FilePreview, Button, Loader } from '../../../components';
import { useDispatchSnackbar } from '../../../providers/SnackbarProvider';
import { formatSize } from '../../../utils/functions';

const maxSize = process.env.REACT_APP_FILE_SIZE || 4000000;

export default function Documentation({ files, setFiles }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const dispatchSnackbar = useDispatchSnackbar();

  const filesService = new FilesService();

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setIsLoading(true);
      setIsUploading(true);
      try {
        const response = await filesService.post(acceptedFiles);
        setFiles([
          ...files,
          ...response.$values.map((item) => ({
            Id: item.Id,
            Url: item.Url,
            Nombre: item.Nombre,
            PesoBytes: item.PesoBytes
          }))
        ]);
        setIsLoading(false);
        setIsUploading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setIsUploading(false);
      }
    } else if (rejectedFiles.length > 0) {
      console.log(rejectedFiles);
      dispatchSnackbar({
        type: 'ERROR',
        payload: `Algunos archivos exceden el tamaño máximo de ${formatSize(
          maxSize
        )}`
      });
    }
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize
  });

  const handleRemove = async (id) => {
    setIsLoading(true);
    try {
      await filesService.deleteFiles([id]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    setFiles(files.filter((item) => item.Id !== id));
  };

  return (
    <StyledDocumentation>
      <Loader show={isLoading} />
      <div className='file-upload'>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Button secondary={isDragActive || isUploading} type='button'>
            {isUploading ? 'Subiendo...' : 'Nuevo documento'}
          </Button>
        </div>
      </div>
      <div className='order-files'>
        {files.map((file) => (
          <FilePreview key={file.Id} file={file} handleRemove={handleRemove} />
        ))}
      </div>
    </StyledDocumentation>
  );
}

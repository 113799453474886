const columns = [
  {
    id: 'SKU',
    label: 'Referencia',
    width: '50px'
  },
  {
    id: 'Nombre',
    label: 'Nombre',
    width: '200px'
  },
  {
    id: 'Cantidad',
    label: 'Cantidad',
    width: '40px'
  },
  {
    id: 'TipoPrecio',
    label: 'Unidad',
    width: '20px'
  },
  {
    id: 'Precio',
    label: 'PVP',
    width: '30px'
  },
  {
    id: 'DescuentoPorcentaje',
    label: 'Descuento %',
    width: '20px'
  },
  {
    id: 'Total',
    label: 'Total',
    width: '30px'
  },
  {
    id: 'history',
    label: '',
    width: '10px'
  },
  {
    id: 'comments',
    label: '',
    width: '10px'
  }
];

export default columns;

import React, { useState, useEffect } from 'react';

import StyledArticlesSelector from './ArticlesSelector.style';
import { FullDialog, Button, Text, CurrencyDisplay } from '../../../components';
import ArticlesModal from '../../ArticlesModal/ArticlesModal';
import { ReactComponent as DeliveryIco } from '../../../resources/svg/ico-albaranes.svg';
import { ReactComponent as AddIcon } from '../../../resources/svg/ico-mas-blanco.svg';
import { ReactComponent as LeftChevron } from '../../../resources/svg/ico-chevron-izquierda.svg';
import { ReactComponent as RightChevron } from '../../../resources/svg/ico-chevron-derecha.svg';

export default function ArticlesSelector({
  open,
  handleClose,
  handleAddArticles,
  id,
  deliveryName,
  orderLines
}) {
  const [orderItem, setOrderItem] = useState(null);
  const [deliveryItem, setDeliveryItem] = useState(null);
  const [orderArticles, setOrderArticles] = useState([]);
  const [deliveryArticles, setDeliveryArticles] = useState([]);
  const [showArticlesModal, setShowArticlesModal] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState(null);

  useEffect(() => {
    setOrderArticles(orderLines.filter((line) => !line.IdAlbaran));
  }, []);

  const handleAddArticleButton = () => {
    setShowArticlesModal(true);
  };

  const handleSelectOrderArticle = (article, idx) => {
    setOrderItem(article);
    setSelectedIdx(idx);
    setDeliveryItem(null);
  };

  const handleAddOrderArticle = () => {
    if (orderItem) {
      setDeliveryArticles([...deliveryArticles, { ...orderItem }]);
      const filteredArticles = orderArticles.filter(
        (article) => article.Id !== orderItem.Id
      );
      setOrderArticles(filteredArticles);
      if (filteredArticles.length) {
        const nextIdx =
          selectedIdx >= filteredArticles.length ? 0 : selectedIdx;
        const nextItem = filteredArticles[nextIdx];
        handleSelectOrderArticle(nextItem, nextIdx);
      } else {
        setOrderItem(null);
        setSelectedIdx(null);
      }
    }
  };

  const handleSelectDeliveryArticle = (article, idx) => {
    setDeliveryItem(article);
    setSelectedIdx(idx);
    setOrderItem(null);
  };

  const handleRemoveOrderArticle = () => {
    if (deliveryItem) {
      setOrderArticles([...orderArticles, { ...deliveryItem }]);
      const filteredArticles = deliveryArticles.filter(
        (article) => article.Id !== deliveryItem.Id
      );
      setDeliveryArticles(filteredArticles);
      if (filteredArticles.length) {
        const nextIdx =
          selectedIdx >= filteredArticles.length ? 0 : selectedIdx;
        const nextItem = filteredArticles[nextIdx];
        handleSelectDeliveryArticle(nextItem, nextIdx);
      } else {
        setDeliveryItem(null);
        setSelectedIdx(null);
      }
    }
  };

  const handleCloseArticlesModal = () => {
    setShowArticlesModal(false);
  };

  const handleAddArticlesFromModal = (items) => {
    setDeliveryArticles([...orderArticles, ...items]);
  };

  const handleAddArticlesToDelivery = () => {
    handleAddArticles(deliveryArticles);
    handleClose();
  };

  return (
    <>
      <FullDialog
        open={open}
        handleClose={handleClose}
        name={deliveryName}
        type='Albarán'
        id={id}
        icon={<DeliveryIco />}
      >
        <StyledArticlesSelector>
          <div className='top-section'>
            <Button className='add-button' onClick={handleAddArticleButton}>
              <AddIcon /> Añadir artículos al pedido
            </Button>
            <Button
              secondary
              disabled={deliveryArticles.length === 0}
              onClick={handleAddArticlesToDelivery}
            >
              Aceptar
            </Button>
          </div>
          <div className='main-section'>
            <div className='order-articles table-container'>
              <Text as='h4' size='m' bold color='gray'>
                Contenido del pedido
              </Text>
              <div className='articles-table'>
                <div className='table-header table-row'>
                  <span>REF.</span>
                  <span>Nombre</span>
                  <span>Unidad</span>
                  <span>Precio</span>
                </div>
                {orderArticles.map((article, idx) => (
                  <div
                    className={`table-row table-item ${
                      article.Id === orderItem?.Id ? 'selected' : ''
                    }`}
                    key={article.Id}
                    onClick={() => handleSelectOrderArticle(article, idx)}
                    onKeyDown={() => handleSelectOrderArticle(article, idx)}
                    role='button'
                    tabIndex='-1'
                  >
                    <span>{article.SKU || ''}</span>
                    <span>{article.Nombre}</span>
                    <span>{article.TipoPrecio}</span>
                    <span>
                      <CurrencyDisplay number={article.Precio} />
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className='buttons'>
              <button
                className={`add-article ${orderItem ? 'active' : ''}`}
                type='button'
                onClick={handleAddOrderArticle}
              >
                <RightChevron />
              </button>
              <button
                className={`add-article ${deliveryItem ? 'active' : ''}`}
                type='button'
                onClick={handleRemoveOrderArticle}
              >
                <LeftChevron />
              </button>
            </div>
            <div className='delivery-articles table-container'>
              <Text as='h4' size='m' bold color='gray'>
                Albarán
              </Text>
              <div className='articles-table'>
                <div className='table-header table-row'>
                  <span>REF.</span>
                  <span>Nombre</span>
                  <span>Unidad</span>
                  <span>Precio</span>
                </div>
                {deliveryArticles.map((article, idx) => (
                  <div
                    className={`table-row table-item ${
                      article.Id === deliveryItem?.Id ? 'selected' : ''
                    }`}
                    key={article.Id}
                    onClick={() => handleSelectDeliveryArticle(article, idx)}
                    onKeyDown={() => handleSelectDeliveryArticle(article, idx)}
                    role='button'
                    tabIndex='-1'
                  >
                    <span>{article.SKU || ''}</span>
                    <span>{article.Nombre}</span>
                    <span>{article.TipoPrecio}</span>
                    <span>
                      <CurrencyDisplay number={article.Precio} />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </StyledArticlesSelector>
      </FullDialog>
      {showArticlesModal && (
        <ArticlesModal
          open={showArticlesModal}
          handleClose={handleCloseArticlesModal}
          handleAddArticles={handleAddArticlesFromModal}
        />
      )}
    </>
  );
}

import { errorHandler } from './utils';
import instance from './Base';

class Deliveries {
  getDeliveries = async (query) => {
    let response;
    try {
      response = await instance.get(`/Umbraco/Api/Albaranes/GetQuery${query}`);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  getDelivery = async (id) => {
    let response;
    try {
      response = await instance.get(`/Umbraco/Api/Albaranes/Get/?Id=${id}`);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  saveDelivery = async (data) => {
    let response;
    try {
      response = await instance.post('/Umbraco/Api/Albaranes/Guardar', data);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  createDelivery = async (order, data) => {
    let response;
    try {
      response = await instance.post(
        `/Umbraco/Api/Albaranes/Crear/?idPedido=${order}`,
        data
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  deleteDeliveries = async (deliveries) => {
    let response;
    try {
      response = await instance.post(
        `/Umbraco/Api/Albaranes/Borrar`,
        deliveries
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  getDeliveryLines = async (id) => {
    let response;
    try {
      response = await instance.get(
        `/Umbraco/Api/Albaranes/GetLineas/?Id=${id}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  saveDeliveryLines = async (id, lines) => {
    let response;
    try {
      response = await instance.post(
        `/Umbraco/Api/Albaranes/GuardarLineas/?IdAlbaran=${id}`,
        lines
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  printDeliveries = async (deliveries, rated) => {
    let response;
    try {
      response = await instance.post(
        `/Umbraco/Api/Albaranes/Imprimir?valorar=${rated ? 'true' : 'false'}`,
        deliveries
      );
    } catch (error) {
      errorHandler(error);
    }

    return response.data;
  };
}

export default Deliveries;

import React, { useState } from 'react';

import StyledTopNav, { StyledPopover } from './StyledTopNav';
import { ReactComponent as TopLogo } from '../../resources/svg/top-logo.svg';
import { ReactComponent as OrderIco } from '../../resources/svg/ico-volver.svg';
import { useAuth, useDispatchAuth } from '../../providers/AuthProvider';
import { clearToken } from '../../utils/localStorage';
import Button from '../Button/Button';
import { ReactComponent as HamburguesaIco } from '../../resources/svg/ico-hamburguesa.svg';

export default function TopNav({ handleCollapsed }) {
  const auth = useAuth();
  const dispatch = useDispatchAuth();
  const [popover, setPopover] = useState(null);
  const handlePopover = (event) => setPopover(event.currentTarget);
  const handleClosePopover = () => setPopover(null);
  const handleLogout = () => {
    clearToken();
    dispatch({ type: 'LOGOUT' });
  };
  return (
    <StyledTopNav>
      <div
        className='hamburguer'
        onClick={handleCollapsed}
        onKeyDown={handleCollapsed}
        role='button'
        tabIndex='-1'
      >
        <HamburguesaIco />
      </div>
      <div className='top-logo'>
        <TopLogo />
      </div>
      <div className='content'>
        <p className='breadcrumbs'>Inicio / {auth.currentPage}</p>
        <div className='right-section'>
          <button type='button'>
            <OrderIco /> Volver a la web
          </button>
          <div
            className='user-name'
            onClick={handlePopover}
            onKeyPress={handlePopover}
            role='button'
            tabIndex={0}
          >
            <span>{auth.name && auth.name[0]}</span>
            {auth.name}
          </div>
        </div>
      </div>
      <StyledPopover
        open={!!popover}
        onClose={handleClosePopover}
        anchorEl={popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className='popover-content'>
          <div className='popover-user'>
            <span className='letter'>{auth.name && auth.name[0]}</span>
            <div className='user-data'>
              <p className='user-name'>{auth.name}</p>
              <p>{auth.email}</p>
            </div>
          </div>
          <Button outlined className='logout-button' onClick={handleLogout}>
            Cerrar sesión
          </Button>
        </div>
      </StyledPopover>
    </StyledTopNav>
  );
}

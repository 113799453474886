import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';

export default function useQueryString(state) {
  const [query, setQuery] = useState(state);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (!state) {
      setQuery(
        qs.parse(location.search, {
          ignoreQueryPrefix: true
        })
      );
    }
  }, [location]);

  const updateQuery = (value) => {
    if (state) {
      setQuery({
        ...query,
        ...value
      });
    } else {
      history.push({
        path: history.location.pathname,
        search: qs.stringify(
          {
            ...query,
            ...value
          },
          { addQueryPrefix: true }
        )
      });
    }
  };

  return { query, updateQuery };
}

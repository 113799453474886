import React from 'react';

import StyledButton from './Button.style';

export default function Button({
  children,
  onClick,
  disabled,
  secondary,
  outlined,
  className,
  type
}) {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      secondary={secondary}
      outlined={outlined}
      className={className}
      type={type}
    >
      {children}
    </StyledButton>
  );
}

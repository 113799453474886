import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CloseIcon from '@material-ui/icons/Close';

import StyledGenerateInvoicesModal, {
  StyledDialog,
  StyledTitle,
  StyledContent
} from './GenerateInvoicesModal.style';
import { ReactComponent as FacturasIco } from '../../resources/svg/ico-facturas.svg';
import {
  Text,
  ItemsTable,
  Loader,
  Datepicker,
  Button,
  DeleteDialog,
  Dialog
} from '../../components';
import InvoicesService from '../../services/Invoices';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';
import { useAuth } from '../../providers/AuthProvider';
import listTypes from '../../utils/listTypes';
import columns from './columns';
import RowItems from './RowItems/RowItems';
import DeliveriesModal from './DeliveriesModal/DeliveriesModal';
import ResultsModal from './ResultsModal/ResultsModal';

const schema = yup.object().shape({
  Fecha: yup.string().nullable().required('Selecciona una fecha')
});

export default function GenerateInvoicesModal({
  open,
  handleClose,
  selectedDeliveries,
  refresh
}) {
  const invoicesService = new InvoicesService();
  const [isLoading, setIsloading] = useState(true);
  const [preInvoices, setPreinvoices] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selected, setSelected] = useState([]);
  const [idx, setIdx] = useState(null);
  const dispatchSnackbar = useDispatchSnackbar();
  const [showDialog, setShowDialog] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState(undefined);
  const { lists } = useAuth();
  const paymentsList = lists.filter(
    (listItem) => listItem.type === listTypes.formaPago
  );

  const { handleSubmit, control, reset, errors, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      Fecha: ''
    },
    shouldUnregister: false
  });

  const onSubmit = () => {
    setShowDialog(true);
  };

  const handleGenerateInvoices = async () => {
    const Fecha = getValues('Fecha');
    const PreFacturas = preInvoices.map(({ Id, FormaPagoLabel, ...rest }) => ({
      ...rest
    }));
    setIsloading(true);
    setShowDialog(false);
    try {
      const response = await invoicesService.savePreInvoices({
        Fecha,
        PreFacturas
      });
      setResults(
        response?.$values.map((item) => ({
          ...item,
          TotalBruto: item.Total - item.TotalIVA,
          FormaPagoLabel: paymentsList.find(
            (payment) => payment.value === item.IdFormaPago
          )?.label
        }))
      );
      setIsloading(false);
      setShowResults(true);
      refresh();
    } catch (error) {
      console.log(error);
      setIsloading(false);
      setShowDialog(false);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
    }
  };

  const handleClickDelete = () => setConfirmDelete(true);

  const handleCloseDialog = () => setConfirmDelete(false);

  const handleCloseResultsDialog = () => {
    setShowResults(false);
    handleClose();
  };

  const handleDeletePreinvoices = () => {
    setConfirmDelete(false);
    setSelected([]);
    const filteredPreinvoices = preInvoices.filter(
      (item) => !selected.includes(item.Id)
    );
    dispatchSnackbar({
      type: 'SET',
      payload: 'Prefacturas eliminadas'
    });
    setPreinvoices(filteredPreinvoices);
    if (filteredPreinvoices.length === 0) {
      handleClose();
    }
  };

  const deleteDeliveries = (deliveriesIds) => {
    if (deliveriesIds.length === preInvoices[idx].Albaranes.$values.length) {
      const filteredPreinvoices = preInvoices.filter((item, i) => i !== idx);
      setPreinvoices(filteredPreinvoices);
      if (filteredPreinvoices.length === 0) {
        handleClose();
      }
    } else {
      setPreinvoices(
        preInvoices.map((item, i) => {
          if (i === idx) {
            return {
              ...item,
              Albaranes: {
                ...item.Albaranes,
                $values: item.Albaranes.$values.filter((delivery) => {
                  return !deliveriesIds.includes(delivery.Id);
                })
              }
            };
          }
          return { ...item };
        })
      );
    }
    dispatchSnackbar({
      type: 'SET',
      payload: 'Albaranes eliminados'
    });
  };

  const rowHandlers = {
    openDeliveries: (i) => {
      setIdx(i);
    }
  };

  const handleCloseDeliveriesModal = () => {
    setIdx(null);
  };

  const getClientsNumber = () => {
    const clientsNames = preInvoices.map((item) => item.Cliente);
    return [...new Set(clientsNames)].length;
  };

  useEffect(async () => {
    try {
      const response = await invoicesService.createPreInvoices(
        selectedDeliveries
      );
      reset({
        Fecha: response.Fecha
      });
      setPreinvoices(
        response?.PreFacturas?.$values.map((item) => ({
          ...item,
          FormaPagoLabel: paymentsList.find(
            (payment) => payment.value === item.IdFormaPago
          )?.label,
          Id: uuidv4()
        }))
      );
      setIsloading(false);
    } catch (error) {
      console.log(error);
      setIsloading(false);
      handleClose();
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
    }
  }, []);

  return (
    <StyledDialog open={open} fullWidth maxWidth='xl'>
      <StyledTitle>
        <Text size='m' color='white'>
          <FacturasIco className='invoice-icon' />
          Asistente de facturación
        </Text>
        <CloseIcon onClick={handleClose} fontSize='large' className='close' />
      </StyledTitle>
      <StyledContent>
        <StyledGenerateInvoicesModal>
          <Loader show={isLoading} />
          <form onSubmit={handleSubmit(onSubmit)} className='top-section'>
            <Datepicker
              name='Fecha'
              control={control}
              placeholder='Fecha de facturación'
              label='Fecha de facturación'
              horizontal
              error={errors.Fecha && errors.Fecha.message}
            />
            <div>
              <Button secondary type='button' onClick={() => onSubmit()}>
                Generar facturas
              </Button>
            </div>
          </form>
          <div className='preinvoices'>
            <ItemsTable
              handleClickDelete={handleClickDelete}
              title={`Prefacturas (${preInvoices.length})`}
              columns={columns}
              items={preInvoices}
              isLoading={isLoading}
              selected={selected}
              setSelected={setSelected}
              RowItems={RowItems}
              rowHandlers={rowHandlers}
            />
          </div>
          <DeliveriesModal
            open={idx !== null}
            handleClose={handleCloseDeliveriesModal}
            deliveries={preInvoices[idx]?.Albaranes?.$values || []}
            client={preInvoices[idx]?.Cliente}
            deleteDeliveries={deleteDeliveries}
          />
          <ResultsModal
            open={showResults}
            handleClose={handleCloseResultsDialog}
            results={results}
          />
          <DeleteDialog
            open={confirmDelete}
            close={handleCloseDialog}
            handleAccept={handleDeletePreinvoices}
            quantity={selected.length}
            singular='factura'
            plural='facturas'
          />
          {showDialog && (
            <Dialog
              open={showDialog}
              handleClose={() => setShowDialog(false)}
              title='Confirmación para generar facturas'
              handleAccept={handleGenerateInvoices}
              blue
            >
              <Text>
                Esta acción generará {preInvoices.length} facturas de{' '}
                {getClientsNumber()} clientes, ¿seguro que quieres continuar?
              </Text>
            </Dialog>
          )}
        </StyledGenerateInvoicesModal>
      </StyledContent>
    </StyledDialog>
  );
}

import { errorHandler } from './utils';
import instance from './Base';
import listTypes from '../utils/listTypes';

class Agents {
  getAllAgents = async () => {
    let agentsData;
    try {
      agentsData = await instance.get('/Umbraco/Api/Representantes/GetAll');
    } catch (error) {
      errorHandler(error);
    }
    return agentsData.data.$values.map((value) => ({
      type: listTypes.representante,
      label: value.Nombre,
      value: value.Id,
      email: value.Email
    }));
  };

  getAgent = async (agentId) => {
    let agentData;
    try {
      agentData = await instance.get(
        `/Umbraco/Api/Representantes/Get/${agentId}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return agentData.data;
  };

  addAgent = async (agent) => {
    let response;
    try {
      response = await instance.post(
        '/Umbraco/Api/Representantes/Guardar',
        agent
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };
}

export default Agents;

import React, { useState, useEffect, useRef } from 'react';
import TableCell from '@material-ui/core/TableCell';

import { CurrencyDisplay } from '../../../../components';
import Clock from '../../../../components/Clock/Clock';
import { LineNote } from '../../../../molecules';
import {
  StyledQuantity,
  StyledStock,
  StyledPercentajeInput,
  StyledDelivery,
  StyledPriceInput
} from './RowItems.style';
import { showPrice } from '../../../../utils/functions';

export default function RowItems({ item, handleRowChange, idx, rowHandlers }) {
  const {
    Nombre,
    Inventario,
    IdAlbaran,
    Descripcion,
    TipoPrecio,
    PrecioOriginal,
    Precio,
    IVA,
    IvaIncluido,
    Cantidad,
    DescuentoPorcentaje,
    isFreeText,
    SKU,
    Id
  } = item;

  const [total, setTotal] = useState(0);
  const { handleOpenDelivery, newLineId, handleClearNewLineId } = rowHandlers;

  const qtyRef = useRef();

  const calculatePrice = (newPrice) =>
    showPrice(newPrice || Precio, IVA, IvaIncluido);

  const calculateFreeTextTotal = () =>
    calculatePrice() * Cantidad * (1 - DescuentoPorcentaje / 100);

  const handleFocus = (event) => {
    if (event.target.value === '0%') {
      event.target.select();
    }
  };

  const selectOnFocus = (event) => {
    event.target.select();
  };

  const updateItem = (key, value, updatedTotal) => {
    const updatedItem = { ...item };
    updatedItem[key] = value;
    setTotal(updatedTotal);
    handleRowChange(updatedItem, idx);
  };

  const handleQuantityChange = (event) => {
    const { value } = event.target;
    const updatedTotal =
      calculatePrice() * value * (1 - DescuentoPorcentaje / 100);
    updateItem('Cantidad', value, updatedTotal);
  };

  const handleNoteChange = (text) => {
    handleRowChange(
      {
        ...item,
        Descripcion: text
      },
      idx
    );
  };

  const handlePriceChange = (value) => {
    const updatedTotal =
      calculatePrice(value.floatValue) *
      Cantidad *
      (1 - DescuentoPorcentaje / 100);
    updateItem('Precio', value.floatValue, updatedTotal);
  };

  const handleDiscountChange = (value) => {
    const updatedTotal =
      calculatePrice() * Cantidad * (1 - value.floatValue / 100);
    updateItem('DescuentoPorcentaje', value.floatValue, updatedTotal);
  };

  useEffect(() => {
    if (isFreeText) {
      setTotal(Precio);
    } else {
      setTotal(calculatePrice() * Cantidad * (1 - DescuentoPorcentaje / 100));
    }
  }, []);

  useEffect(() => {
    if (newLineId === Id) {
      qtyRef.current.focus();
      handleClearNewLineId();
    }
  }, [newLineId]);

  if (isFreeText) {
    return (
      <>
        <TableCell className='cell-body'>{SKU}</TableCell>
        <TableCell className='cell-body' colSpan={2}>
          {Nombre}
        </TableCell>
        <TableCell className='cell-body'>
          <StyledDelivery onClick={() => handleOpenDelivery(IdAlbaran)}>
            {IdAlbaran}
          </StyledDelivery>
        </TableCell>
        <TableCell className='cell-body'>
          {IdAlbaran ? (
            Cantidad
          ) : (
            <StyledQuantity
              value={Cantidad}
              onChange={handleQuantityChange}
              type='number'
              min={0}
              ref={qtyRef}
              onFocus={selectOnFocus}
            />
          )}
        </TableCell>
        <TableCell className='cell-body' colSpan={2}>
          {TipoPrecio || '-'}
        </TableCell>
        <TableCell className='cell-body'>
          <StyledPriceInput
            value={Precio}
            suffix='€'
            thousandSeparator='.'
            decimalSeparator=','
            decimalScale={2}
            onValueChange={handlePriceChange}
            onFocus={selectOnFocus}
          />
        </TableCell>
        <TableCell className='cell-body'>
          {IdAlbaran ? (
            `${DescuentoPorcentaje}%`
          ) : (
            <StyledPercentajeInput
              value={DescuentoPorcentaje}
              suffix='%'
              onValueChange={handleDiscountChange}
              onFocus={handleFocus}
            />
          )}
        </TableCell>
        <TableCell className='cell-body' colSpan={2}>
          <CurrencyDisplay number={calculateFreeTextTotal()} />
        </TableCell>
        <TableCell className='cell-body'>
          <LineNote note={Descripcion} handleChange={handleNoteChange} />
        </TableCell>
      </>
    );
  }

  return (
    <>
      <TableCell className='cell-body'>{SKU}</TableCell>
      <TableCell className='cell-body'>{Nombre}</TableCell>
      <TableCell className='cell-body'>
        <StyledStock exceed={Cantidad > Inventario}>
          {Inventario || '0'}
        </StyledStock>
      </TableCell>
      <TableCell className='cell-body'>
        <StyledDelivery onClick={() => handleOpenDelivery(IdAlbaran)}>
          {IdAlbaran}
        </StyledDelivery>
      </TableCell>
      <TableCell className='cell-body'>
        {IdAlbaran ? (
          Cantidad
        ) : (
          <StyledQuantity
            error={Cantidad < 0 || Cantidad > Inventario}
            value={Cantidad}
            onChange={handleQuantityChange}
            type='number'
            min={0}
            onFocus={selectOnFocus}
          />
        )}
      </TableCell>
      <TableCell className='cell-body'>{TipoPrecio || '-'}</TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={PrecioOriginal} />
      </TableCell>
      <TableCell className='cell-body'>
        <StyledPriceInput
          value={Precio}
          suffix='€'
          thousandSeparator='.'
          decimalSeparator=','
          decimalScale={2}
          onValueChange={handlePriceChange}
          onFocus={selectOnFocus}
        />
      </TableCell>
      <TableCell className='cell-body'>
        {IdAlbaran ? (
          `${DescuentoPorcentaje}%`
        ) : (
          <StyledPercentajeInput
            value={DescuentoPorcentaje}
            suffix='%'
            onValueChange={handleDiscountChange}
            onFocus={handleFocus}
          />
        )}
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={total} />
      </TableCell>
      <TableCell className='cell-body'>
        <Clock Nombre={Nombre} SKU={SKU} TipoPrecio={TipoPrecio} />
      </TableCell>
      <TableCell className='cell-body'>
        <LineNote note={Descripcion} handleChange={handleNoteChange} />
      </TableCell>
    </>
  );
}

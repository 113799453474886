import { errorHandler } from './utils';
import instance from './Base';

class Companies {
  getCompanies = async (query) => {
    let companiesData;
    try {
      companiesData = await instance.get(
        `/Umbraco/Api/Empresas/GetQuery${query}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return companiesData?.data;
  };

  getCompany = async (id) => {
    let companyData;
    try {
      companyData = await instance.get(`/Umbraco/Api/Empresas/Get/?Id=${id}`);
    } catch (error) {
      errorHandler(error);
    }
    return companyData.data;
  };

  deleteCompanies = async (clients) => {
    let response;
    try {
      response = await instance.post(`/Umbraco/Api/Empresas/Borrar`, clients);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  addCompany = async (company) => {
    let response;
    try {
      response = await instance.post('/Umbraco/Api/Empresas/Guardar', company);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  csv = async (query) => {
    let response;
    try {
      response = await instance.post('/Umbraco/Api/Empresas/CSV', query);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };
}

export default Companies;

import { errorHandler } from './utils';
import instance from './Base';

class Lists {
  getAllLists = async () => {
    let listsData;
    try {
      listsData = await instance.get('/Umbraco/Api/Listas/GetAll');
    } catch (error) {
      errorHandler(error);
    }
    return listsData.data.$values.map((value) => ({
      type: value.Tipo,
      label: value.Nombre,
      value: value.Id
    }));
  };

  getList = async (listId) => {
    let listData;
    try {
      listData = await instance.get(
        `/Umbraco/Api/Listas/GetByTipo?tipo=${listId}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return listData.data;
  };

  addListItem = async (item) => {
    let response;
    try {
      response = await instance.post('/Umbraco/Api/Listas/Guardar', item);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };
}

export default Lists;

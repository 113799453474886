import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';

const StyledDeliveriesCell = styled(TableCell)`
  color: ${({ theme }) => theme.colors.orange};
  font-weight: 400;
  cursor: pointer;
`;

export default StyledDeliveriesCell;

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import StyledDeliveryModal from './DeliveryModal.style';
import {
  FullDialog,
  Input,
  Text,
  Button,
  Loader,
  Datepicker,
  ItemsTable,
  DeleteDialog,
  CurrencyDisplay
} from '../../../components';
import { FreeTextForm } from '../../../molecules';
import { ReactComponent as DeliveryIcon } from '../../../resources/svg/ico-albaranes.svg';
import { ReactComponent as AddIcon } from '../../../resources/svg/ico-mas-blanco.svg';
import DeliveriesService from '../../../services/SupplierDelivery';
import columns from './columns';
import RowItems from './RowItems/RowItems';
import ArticlesModal from '../../ArticlesModal/ArticlesModal';
import { useDispatchSnackbar } from '../../../providers/SnackbarProvider';

export default function DeliveryModal({
  supplierName,
  open,
  handleClose,
  deliveryToEdit,
  supplierId,
  handleNewDelivery
}) {
  const { Codigo, user, Id } = deliveryToEdit || {};
  const {
    register,
    handleSubmit,
    errors,
    formState,
    reset,
    control
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [hasEmptyQty, setHasEmptyQty] = useState(false);
  const [articles, setArticles] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selected, setSelected] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [total, setTotal] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showArticlesModal, setShowArticlesModal] = useState(false);
  const [newLineId, setNewLineId] = useState(null);

  const dispatchSnackbar = useDispatchSnackbar();
  const deliveriesService = new DeliveriesService();

  const handleClearNewLineId = () => {
    setNewLineId(null);
  };

  const rowHandlers = {
    newLineId,
    handleClearNewLineId
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    const filteredArticles = articles.map(
      ({ Id: articleId, isFreeText, ...line }) => ({ ...line })
    );
    try {
      const response = await deliveriesService.addDelivery({
        ...deliveryToEdit,
        ...values,
        Id,
        IdEmpresa: supplierId
      });
      await deliveriesService.saveLines(response.Id, filteredArticles);
      dispatchSnackbar({
        type: 'SET',
        payload: deliveryToEdit ? 'Albaran Editado' : 'Albaran creado'
      });
      handleNewDelivery();
      setIsLoading(false);
      handleClose({ reload: true });
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  const handleClickDelete = () => {
    setConfirmDelete(true);
  };

  const handleRowChange = (updated, idx) => {
    const updatedArticles = [...articles];
    updatedArticles[idx] = updated;
    setArticles(updatedArticles);
    setIsUpdated(true);
  };

  const handleAddArticleButton = () => {
    setShowArticlesModal(true);
    setIsUpdated(true);
  };

  const handleAddFreeText = (newLine) => {
    setNewLineId(newLine.Id);
    setArticles([...articles, { ...newLine }]);
    setIsUpdated(true);
  };

  const handleCloseArticlesModal = () => {
    setShowArticlesModal(false);
  };

  const handleAddArticles = (items) => {
    setArticles([
      ...articles,
      ...items.map(({ IdPedidoLinea, ...rest }) => ({
        ...rest,
        Cantidad: ''
      }))
    ]);
    setIsUpdated(true);
  };

  const handleAddSku = (article) => {
    setArticles([...articles, { ...article, Cantidad: '' }]);
  };

  const handleCloseDialog = () => setConfirmDelete(false);

  const handleDeleteArticles = () => {
    setSelected([]);
    setConfirmDelete(false);
    setArticles(articles.filter((article) => !selected.includes(article.Id)));
    setIsUpdated(true);
  };

  useEffect(async () => {
    if (Id) {
      setIsLoading(true);
      try {
        const response = await deliveriesService.getLines(Id);
        console.log(response.$values);
        setArticles(
          response.$values.map((value) => ({
            ...value,
            Id: value.UUID,
            isFreeText: !value.IdReferencia
          }))
        );
        reset({ ...deliveryToEdit });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  }, [Id]);

  const getSubTotal = () => {
    return articles.reduce((acc, article) => {
      const {
        Precio,
        Cantidad = 0,
        DescuentoPorcentaje = 0,
        IvaIncluido,
        IVA
      } = article;
      if (IvaIncluido) {
        return (
          acc +
          (Precio / (1 + IVA)) * Cantidad * (1 - DescuentoPorcentaje / 100)
        );
      }
      return acc + Precio * Cantidad * (1 - DescuentoPorcentaje / 100);
    }, 0);
  };

  const getTaxes = () => {
    return articles.reduce((acc, article) => {
      const {
        Precio,
        Cantidad = 0,
        DescuentoPorcentaje = 0,
        IVA,
        IvaIncluido
      } = article;
      if (IvaIncluido) {
        return (
          acc +
          (Precio - Precio / (1 + IVA)) *
            Cantidad *
            (1 - DescuentoPorcentaje / 100)
        );
      }
      return acc + Precio * IVA * Cantidad * (1 - DescuentoPorcentaje / 100);
    }, 0);
  };

  const getTotal = () => {
    return articles.reduce((acc, article) => {
      const {
        Precio,
        Cantidad = 0,
        DescuentoPorcentaje = 0,
        IVA,
        IvaIncluido
      } = article;
      if (IvaIncluido) {
        return acc + Precio * Cantidad * (1 - DescuentoPorcentaje / 100);
      }
      return (
        acc + Precio * (1 + IVA) * Cantidad * (1 - DescuentoPorcentaje / 100)
      );
    }, 0);
  };

  useEffect(() => {
    setSubTotal(getSubTotal());
    setTaxes(getTaxes());
    setTotal(getTotal());
    if (articles.some((article) => article.Cantidad === '')) {
      setHasEmptyQty(true);
    } else {
      setHasEmptyQty(false);
    }
  }, [articles]);

  return (
    <FullDialog
      open={open}
      handleClose={handleClose}
      type='Albaran'
      isNew={!deliveryToEdit}
      icon={<DeliveryIcon />}
      name={supplierName && `- ${supplierName}`}
      id={Codigo}
    >
      <StyledDeliveryModal>
        <Loader show={isLoading} />
        <form>
          <div className='top-section'>
            {user && <Text color='gray'>Creado por {user}</Text>}
            {!deliveryToEdit && <Text color='gray'>{supplierName}</Text>}
            <Button
              secondary
              type='button'
              disabled={(!formState.isDirty && !isUpdated) || hasEmptyQty}
              onClick={handleSubmit(onSubmit)}
            >
              Guardar y cerrar
            </Button>
          </div>
          <div className='modal-fields'>
            <Datepicker
              className='delivery-input fecha'
              name='Fecha'
              control={control}
              label='Fecha'
              horizontal
              placeholder='Fecha'
            />
            <Input
              label='Nº Albarán'
              name='Codigo'
              inputRef={register({
                required: 'Este campo es obligatorio'
              })}
              placeholder='Nº Albarán'
              error={errors.Codigo && errors.Codigo.message}
              horizontal
              className='delivery-input codigo'
            />
            <Text uppercase className='total'>
              subtotal: <CurrencyDisplay number={subTotal} />
            </Text>
            <Text uppercase className='total'>
              iva: <CurrencyDisplay number={taxes} />
            </Text>
            <Text uppercase className='total'>
              total: <CurrencyDisplay number={total} />
            </Text>
          </div>
          <div className='articles-list'>
            <ItemsTable
              handleClickDelete={handleClickDelete}
              title='Artículos'
              columns={columns}
              items={articles}
              handleRowChange={handleRowChange}
              isLoading={isLoading}
              RowItems={RowItems}
              selected={selected}
              setSelected={setSelected}
              AddIcon={AddIcon}
              addLabel='Añadir artículo'
              handleClickAdd={handleAddArticleButton}
              rowHandlers={rowHandlers}
            />
            <FreeTextForm
              handleAddFreeText={handleAddFreeText}
              handleAddSku={handleAddSku}
            />
          </div>
          {showArticlesModal && (
            <ArticlesModal
              open={showArticlesModal}
              handleClose={handleCloseArticlesModal}
              handleAddArticles={handleAddArticles}
            />
          )}
          <DeleteDialog
            open={confirmDelete}
            close={handleCloseDialog}
            handleAccept={handleDeleteArticles}
            quantity={selected.length}
            singular='artículo'
            plural='artículos'
          />
        </form>
      </StyledDeliveryModal>
    </FullDialog>
  );
}

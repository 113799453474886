/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';
import theme from '../../styles/theme';

const horizontalStyle = css`
  display: flex;
  align-items: center;
  span {
    margin-right: 1rem;
    margin-bottom: 0;
  }
  & > div {
    flex: 1;
  }
`;

const StyledSelect = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  span {
    display: block;
    margin-bottom: 0.5rem;
  }
  ${({ horizontal }) => horizontal && horizontalStyle};
  .error {
    color: ${({ theme }) => theme.colors.red};
    display: inline-block;
    margin-top: 0.5rem;
  }
`;

export const selectStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (base) => ({
    ...base,
    border: `1px solid ${theme.colors.gray}`,
    borderRadius: '5px'
  })
};

export default StyledSelect;

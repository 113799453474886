import { rgba } from 'polished';
import styled from 'styled-components';

const StyledContentBox = styled.div`
  margin-bottom: 1rem;
  h4 {
    margin-bottom: 1rem;
  }
  .box {
    background-color: ${({ theme }) => rgba(theme.colors.lightGray, 0.6)};
    padding: ${({ thin }) => (thin ? '1rem' : '1.5rem')};
  }
`;

export default StyledContentBox;

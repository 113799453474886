import styled from 'styled-components';

const StyledFilePreview = styled.div`
  margin-bottom: ${({ horizontal }) => (horizontal ? '1.5rem' : '0')};
  width: ${({ horizontal }) => (horizontal ? '100%' : '48%')};
  display: ${({ horizontal }) => (horizontal ? 'flex' : 'block')};
  .preview-container {
    padding-bottom: ${({ horizontal }) => (horizontal ? '21%' : '60%')};
    border: 1px solid ${({ theme }) => theme.colors.gray};
    &.file-image {
      width: ${({ horizontal }) => (horizontal ? '30%' : '100%')};
      background-image: url(${({ background, horizontal }) =>
        `${background}&width=1000&height=${
          horizontal ? '700' : '600'
        }&bgcolor=E9ECEF&mode=boxpad`});
      background-color: ${({ theme }) => theme.colors.lightGray};
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    &.not-image {
      position: relative;
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${({ theme }) => theme.colors.orange};
        padding: 1.5rem 2.5rem;
        border-radius: 0.5rem;
      }
    }
  }
  .file-info {
    margin-top: 1rem;
    margin-bottom: 3rem;
    margin: ${({ horizontal }) =>
      horizontal ? '1rem 3rem 0 1.5rem' : '1rem 0 3rem 0'};
    .file-name {
      span {
        font-weight: 400;
      }
    }
    .file-actions {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        cursor: pointer;
        text-decoration: underline;
        &.download {
          color: ${({ theme }) => theme.colors.orange};
        }
        &.delete {
          color: ${({ theme }) => theme.colors.gray};
        }
      }
    }
  }
`;

export default StyledFilePreview;

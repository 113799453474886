import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`;

export const StyledDrawerContent = styled.div`
  width: 33rem;

  .drawer-header {
    background-color: ${({ theme }) => theme.colors.orange};
    padding: 1rem 1.5rem 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close {
      fill: ${({ theme }) => theme.colors.white};
      cursor: pointer;
    }
  }
  .drawer-content {
    padding: 2.5rem 2rem 3.5rem 2rem;
  }
  .drawer-buttons {
    padding: 0 2rem 2rem 2rem;
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 0.5rem;
      width: 8.5rem;
      text-align: center;
    }
  }
`;

export default StyledDrawer;

import { errorHandler } from './utils';
import instance from './Base';
import listTypes from '../utils/listTypes';

class PaymentWays {
  getAllPayments = async () => {
    let response;
    try {
      response = await instance.get(
        '/Umbraco/Api/FormasPago/GetAll?borradas=false'
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data.$values.map((value) => ({
      type: listTypes.formaPago,
      value: value.Id,
      label: value.Nombre,
      days: value.Dias,
      paymentsNumber: value.NumPagos,
      daysBetween: value.PlazoEntre,
      paymentType: value.Tipo
    }));
  };

  addPaymentWay = async (item) => {
    let response;
    try {
      response = await instance.post('/Umbraco/Api/FormasPago/Guardar', item);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };
}

export default PaymentWays;

import relationType from '../../utils/relationType';

const formFields = {
  city: 'Poblacion',
  postalCode: 'CP',
  address: 'Direccion',
  cif: 'CIF',
  email: 'EmailFacturas',
  notes: 'Observaciones',
  country: 'PaisCOD',
  name: 'Nombre',
  province: 'Provincia',
  region: 'Region',
  web: 'Dominio',
  relation: 'TipoRelacion',
  paymentMethod: 'IdFormaCobro'
};

const formDefaultValues = {
  [formFields.city]: null,
  [formFields.postalCode]: null,
  [formFields.address]: null,
  [formFields.cif]: null,
  [formFields.email]: null,
  [formFields.notes]: null,
  [formFields.country]: 'ES',
  [formFields.province]: '09',
  [formFields.region]: null,
  [formFields.name]: null,
  [formFields.web]: null,
  [formFields.relation]: relationType.supplier
};
const tabs = [
  { label: 'datos' },
  { label: 'albaranes' },
  { label: 'facturas' }
];

export { formFields, relationType, tabs, formDefaultValues };

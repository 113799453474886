import styled from 'styled-components';

const StyledOrigin = styled.span`
  color: ${({ theme }) => theme.colors.orange};
  text-transform: uppercase;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  display: inline-block;
`;

export default StyledOrigin;

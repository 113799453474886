import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useForm } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { Helmet } from 'react-helmet';

import StyledSupplierModal from './SupplierModal.styled';
import {
  FullDialog,
  Button,
  Input,
  FormRow,
  Textarea,
  Dialog,
  ItemsList,
  Loader,
  Select,
  ProvinceSelector,
  Text,
  ContentBox,
  SelectFromList
} from '../../components';
import useQueryString from '../../hooks/useQueryString';
import { ReactComponent as SuppliersIco } from '../../resources/svg/ico-proveedores.svg';
import { useAuth } from '../../providers/AuthProvider';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';
import listTypes from '../../utils/listTypes';
import CompanyService from '../../services/Company';
import ContactsService from '../../services/Contacts';

import { formFields, tabs, formDefaultValues } from './constants';
import NewContactForm from '../../forms/NewContactForm/NewContactForm';
import ContactItem from '../../items/ContactItem/ContactItem';
import DeliveriesList from './DeliveriesList/DeliveriesList';
import InvoicesList from './InvoicesList/InvoicesList';

export default function SupplierModal({
  setReload,
  modalState,
  setModalState
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [contactsList, setcontactsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [supplierName, setSupplierName] = useState(null);
  const [supplierId, setSupplierId] = useState(null);
  const [supplierPayment, setSupplierPayment] = useState(null);
  const [userName, setUserName] = useState(null);
  const [creationDate, setCreationDate] = useState(null);
  const { query, updateQuery } = useQueryString(modalState);
  const [isNewDelivery, setIsNewDelivery] = useState(false);
  const { supplier, tab } = query || {};
  const { lists } = useAuth();
  const countriesList = lists.filter((item) => item.type === listTypes.pais);
  const usersList = lists.filter((item) => item.type === listTypes.usuario);

  const companyService = new CompanyService();
  const contactsService = new ContactsService();

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    control,
    getValues,
    formState,
    reset
  } = useForm({
    defaultValues: formDefaultValues,
    shouldUnregister: false
  });

  const dispatchSnackbar = useDispatchSnackbar();
  const isNew = supplier === 'new';

  const tabIndex = () => {
    const idxTab = tabs.findIndex(
      (tabItem) => (tabItem.slug || tabItem.label) === tab
    );

    return idxTab === -1 ? 0 : idxTab;
  };

  const handleDialog = () => {
    if (modalState) {
      setModalState(null);
    } else {
      updateQuery({ supplier: undefined, tab: undefined });
    }
  };

  const handleClose = () => {
    if (isNewDelivery) {
      if (setReload) {
        setReload(true);
      }
      setIsNewDelivery(false);
    } else {
      setReload(false);
    }
    handleDialog();
  };

  const handleNewDelivery = () => {
    setIsNewDelivery(true);
  };

  const handleTabChange = (index) => {
    updateQuery({ tab: tabs[index].slug || tabs[index].label });
  };

  const handleCloseDialog = () => {
    setShowConfirmation(false);
  };

  const handleConfirmSave = async () => {
    setShowConfirmation(false);
    setIsLoading(true);
    try {
      const response = await companyService.addCompany(showConfirmation);
      if (contactsList.length > 0 && isNew) {
        contactsList.forEach(async (contact) => {
          await contactsService.addItem({
            IdEmpresa: response.Id,
            Nombre: contact.Nombre,
            Email: contact.Email
          });
        });
      }
      handleDialog();
      reset();
      dispatchSnackbar({
        type: 'SET',
        payload: isNew
          ? `Proveedor creado con id: ${response.Id}`
          : 'Proveedor Editado'
      });
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'SET',
        payload: 'Ha ocurrido un error'
      });
    }
    console.log('confirm save');
    setIsLoading(false);
  };

  const onSubmit = (values) => {
    setShowConfirmation(values);
  };

  const watchCountry = watch(formFields.country);

  useEffect(async () => {
    let companyData;
    if (supplier) {
      if (!isNew) {
        companyData = await companyService.getCompany(supplier);
        console.log(companyData);
        setSupplierName(companyData.Nombre);
        setSupplierId(companyData.Id);
        setSupplierPayment(companyData.IdFormaCobro);
        setUserName(
          usersList.find((user) => user.id === companyData?.IdUsuarioCreacion)
            ?.name || 'Desconocido'
        );
        if (companyData.FechaCreacion) {
          setCreationDate(
            format(parseISO(companyData.FechaCreacion), 'dd/MM/yy')
          );
        }
        reset({
          ...companyData
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  }, [supplier]);

  useEffect(async () => {
    if (formState.isDirty && watchCountry) {
      setValue(formFields.province, null);
      setValue(formFields.region, null);
    }
  }, [watchCountry]);

  return (
    <StyledSupplierModal>
      <FullDialog
        open={!!supplier}
        name={supplierName}
        type='Proveedor'
        id={supplierId}
        isNew={isNew}
        icon={<SuppliersIco />}
        handleClose={handleClose}
      >
        <StyledSupplierModal>
          {(supplierName || isNew) && (
            <Helmet>
              <title>
                {isNew ? 'Crear proveedor | Pascual Vinuesa' : supplierName}
              </title>
            </Helmet>
          )}
          <Loader show={isLoading} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='top-section'>
              {!!userName && !!creationDate ? (
                <Text color='gray'>
                  {userName === 'Desconocido'
                    ? `Importado el ${creationDate}`
                    : `Creado por ${userName} el ${creationDate}`}
                </Text>
              ) : (
                <span />
              )}
              <Button secondary type='submit' disabled={!formState.isDirty}>
                Guardar y cerrar
              </Button>
            </div>
            <Tabs
              onSelect={handleTabChange}
              disabledTabClassName='disabled-tab'
              selectedTabClassName='selected-tab'
              className='tabs'
              defaultIndex={tabIndex()}
            >
              <TabList className='tab-list'>
                {tabs.map((tabItem, idx) => (
                  <Tab
                    key={tabItem.label}
                    className='tab'
                    disabled={tabItem.disabled || (isNew && idx === 1)}
                  >
                    {tabItem.label}
                  </Tab>
                ))}
              </TabList>
              <div className='tabs-content'>
                <TabPanel>
                  <div className='tab-columns'>
                    <div className='data-form'>
                      <div className='data-inputs'>
                        <FormRow>
                          <Input
                            label='Nombre Empresa'
                            name={formFields.name}
                            inputRef={register({
                              required: 'Este campo es obligatorio'
                            })}
                            placeholder='escribe el nombre de la empresa'
                            error={
                              errors[formFields.name] &&
                              errors[formFields.name].message
                            }
                          />
                        </FormRow>
                        <FormRow className='layout-4-6'>
                          <Input
                            label='CIF'
                            name={formFields.cif}
                            inputRef={register}
                            placeholder='escribe el CIF'
                            error={
                              errors[formFields.cif] &&
                              errors[formFields.cif].message
                            }
                          />
                          <Input
                            label='Dirección'
                            name={formFields.address}
                            inputRef={register}
                            placeholder='escribe la direccion'
                            error={
                              errors[formFields.address] &&
                              errors[formFields.address].message
                            }
                          />
                        </FormRow>
                        <FormRow className='layout-6-4'>
                          <Input
                            label='Ciudad'
                            name={formFields.city}
                            inputRef={register}
                            placeholder='escribe la ciudad'
                            error={
                              errors[formFields.city] &&
                              errors[formFields.city].message
                            }
                          />
                          <ProvinceSelector
                            provinceName={formFields.province}
                            regionName={formFields.region}
                            control={control}
                            provinceDefaultValue={getValues(
                              formFields.province
                            )}
                            className='second'
                            provinceError={
                              errors[formFields.province] &&
                              errors[formFields.province].message
                            }
                            inputRef={register}
                            regionError={
                              errors[formFields.region] &&
                              errors[formFields.region].message
                            }
                            country={watchCountry}
                          />
                        </FormRow>
                        <FormRow className='layout-4-6'>
                          <Input
                            label='Código postal'
                            name={formFields.postalCode}
                            inputRef={register}
                            placeholder='escribe el código postal'
                            error={
                              errors[formFields.postalCode] &&
                              errors[formFields.postalCode].message
                            }
                          />
                          <Select
                            label='Pais'
                            name={formFields.country}
                            control={control}
                            options={countriesList}
                            defaultValue={getValues(formFields.country)}
                            searchable
                            placeholder='selecciona el pais'
                            error={
                              errors[formFields.country] &&
                              errors[formFields.country].message
                            }
                          />
                        </FormRow>
                        <FormRow className='layout-4-6'>
                          <Input
                            label='Email'
                            name={formFields.email}
                            inputRef={register}
                            placeholder='escribe el email'
                            error={
                              errors[formFields.email] &&
                              errors[formFields.email].message
                            }
                          />
                          <Input
                            label='Web'
                            name={formFields.web}
                            inputRef={register}
                            placeholder='escribe la web'
                            error={
                              errors[formFields.web] &&
                              errors[formFields.web].message
                            }
                          />
                        </FormRow>
                        <FormRow>
                          <Textarea
                            label='Observaciones'
                            name={formFields.notes}
                            inputRef={register}
                            placeholder='observaciones acerca del cliente'
                            error={
                              errors[formFields.notes] &&
                              errors[formFields.notes].message
                            }
                            height='8.5rem'
                          />
                        </FormRow>
                      </div>
                    </div>
                    <div className='data-contacts'>
                      <ItemsList
                        title='Contactos'
                        addLabel='Agregar contacto'
                        addTitle='Agregar contacto a esta empresa'
                        editTitle='Editar contacto'
                        AddItem={NewContactForm}
                        addItemButtonLabel='Crear contacto'
                        editItemButtonLabel='Editar contacto'
                        isNew={isNew}
                        itemsList={contactsList}
                        setItemsList={setcontactsList}
                        Item={ContactItem}
                        service={contactsService}
                        company={supplier}
                        loadingParent={isLoading}
                      />
                      <ContentBox title='Forma de pago'>
                        <SelectFromList
                          type={listTypes.formaPago}
                          control={control}
                          name={formFields.paymentMethod}
                          addTitle='Añadir nueva forma de pago'
                          setValue={setValue}
                          defaultValue={getValues(formFields.paymentMethod)}
                        />
                      </ContentBox>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <DeliveriesList
                    supplierId={supplierId}
                    supplierName={supplierName}
                    handleNewDelivery={handleNewDelivery}
                  />
                </TabPanel>
                <TabPanel>
                  <InvoicesList
                    supplierId={supplierId}
                    supplierName={supplierName}
                    supplierPayment={supplierPayment}
                  />
                </TabPanel>
              </div>
            </Tabs>
          </form>
          <Dialog
            title='Confirmar acción'
            open={!!showConfirmation}
            handleClose={handleCloseDialog}
            handleAccept={handleConfirmSave}
          >
            <p>
              {isNew
                ? '¿Deseas guardar el nuevo proveedor?'
                : '¿Deseas editar el proveedor?'}
            </p>
          </Dialog>
        </StyledSupplierModal>
      </FullDialog>
    </StyledSupplierModal>
  );
}

import React from 'react';

import StyledText from './Text.style';

export default function Text({
  children,
  as = 'p',
  size = 's',
  color = 'black',
  bold = false,
  uppercase = false,
  className,
  onClick
}) {
  return (
    <StyledText
      as={as}
      size={size}
      color={color}
      bold={bold}
      uppercase={uppercase}
      className={className}
      onClick={onClick}
    >
      {children}
    </StyledText>
  );
}

const columns = [
  {
    id: 'Fecha',
    label: 'Fecha',
    width: '30px'
  },
  {
    id: 'Serie',
    label: 'Serie',
    width: '30px'
  },
  {
    id: 'Codigo',
    label: 'Numero',
    width: '30px'
  },
  {
    id: 'Cliente',
    label: 'Cliente',
    width: '30px'
  },
  {
    id: 'Cliente',
    label: 'ClienteCIF',
    width: '30px'
  },
  {
    id: 'TotalBruto',
    label: 'TotalBruto',
    width: '30px'
  },
  {
    id: 'TotalIVA',
    label: 'IVA',
    width: '30px'
  },
  {
    id: 'Total',
    label: 'Total',
    width: '30px'
  },
  {
    id: 'FormaPagoLabel',
    label: 'Forma Pago',
    width: '30px'
  }
];

export default columns;

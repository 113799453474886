import React, { useState } from 'react';

import { ItemsTable, DeleteDialog } from '../../../components';
import { FreeTextForm } from '../../../molecules';
import StyledArticlesList from './ArticlesList.style';
import { ReactComponent as AddIcon } from '../../../resources/svg/ico-mas-blanco.svg';
import columns from './columns';
import RowItems from './RowItems/RowItems';
import ArticlesSelector from '../ArticlesSelector/ArticlesSelector';
import ArticleModal from '../../ArticleModal/ArticleModal';

export default function ArticlesList({
  articles,
  setArticles,
  deliveryName,
  id,
  orderLines,
  setOrderLines,
  isReadonly
}) {
  const [selected, setSelected] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showArticlesSelector, setShowArticlesSelector] = useState(false);
  const [articleModalState, setArticleModalState] = useState(null);
  const [newLineId, setNewLineId] = useState(null);

  const handleClearNewLineId = () => {
    setNewLineId(null);
  };

  const handleClickDelete = () => {
    setConfirmDelete(true);
  };

  const handleRowChange = (updated, idx) => {
    const updatedArticles = [...articles];
    updatedArticles[idx] = updated;
    setArticles(updatedArticles);
  };

  const handleAddArticleButton = () => {
    setShowArticlesSelector(true);
  };

  const handleCloseDialog = () => setConfirmDelete(false);

  const handleDeleteArticles = () => {
    setSelected([]);
    setConfirmDelete(false);
    const articlesFromOrder = articles.filter(
      (article) => selected.includes(article.Id) && article.fromOrder
    );
    setOrderLines([...orderLines, ...articlesFromOrder]);
    setArticles(articles.filter((article) => !selected.includes(article.Id)));
  };

  const handleCloseArticlesSelector = () => {
    setShowArticlesSelector(false);
  };

  const handleAddArticles = (items) => {
    setArticles([
      ...articles,
      ...items.map((item) => ({
        ...item,
        fromOrder: true
      }))
    ]);
    const itemsIds = items.map((item) => item.Id);
    setOrderLines(orderLines.filter((item) => !itemsIds.includes(item.Id)));
  };

  const handleAddSku = (article) => {
    setArticles([...articles, article]);
  };

  const handleAddFreeText = (newLine) => {
    setNewLineId(newLine.Id);
    setArticles([...articles, { ...newLine }]);
  };

  const handleArticleModalState = (newState) => setArticleModalState(newState);

  const rowHandlers = {
    handleClickArticle: (articleId) =>
      setArticleModalState({ article: articleId }),
    isReadonly,
    newLineId,
    handleClearNewLineId
  };

  return (
    <StyledArticlesList>
      <ItemsTable
        handleClickDelete={handleClickDelete}
        title='Artículos'
        columns={columns}
        items={articles}
        handleRowChange={handleRowChange}
        RowItems={RowItems}
        selected={selected}
        setSelected={setSelected}
        AddIcon={AddIcon}
        addLabel='Añadir artículo'
        handleClickAdd={!isReadonly && handleAddArticleButton}
        rowHandlers={rowHandlers}
        selectable={!isReadonly}
      />
      {!isReadonly && (
        <FreeTextForm
          handleAddFreeText={handleAddFreeText}
          handleAddSku={handleAddSku}
        />
      )}
      {showArticlesSelector && (
        <ArticlesSelector
          open={showArticlesSelector}
          handleClose={handleCloseArticlesSelector}
          handleAddArticles={handleAddArticles}
          id={id}
          deliveryName={deliveryName}
          orderLines={orderLines}
        />
      )}
      <DeleteDialog
        open={confirmDelete}
        close={handleCloseDialog}
        handleAccept={handleDeleteArticles}
        quantity={selected.length}
        singular='artículo'
        plural='artículos'
      />
      {articleModalState && (
        <ArticleModal
          modalState={articleModalState}
          setModalState={handleArticleModalState}
        />
      )}
    </StyledArticlesList>
  );
}

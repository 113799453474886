import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export const StyledPercentajeInput = styled(NumberFormat)`
  width: 4rem;
  height: 1.5rem;
  border-radius: 0;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.text};
  text-align: right;
  &:focus {
    outline: none;
  }
`;

export const StyledQuantity = styled.input`
  width: 4rem;
  height: 1.5rem;
  border-radius: 0;
  border: 1px solid
    ${({ theme, error }) => (error ? theme.colors.red : theme.colors.gray)};
  font-weight: 300;
  color: ${({ theme, error }) =>
    error ? theme.colors.red : theme.colors.text};
  text-align: right;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
`;

export const StyledStock = styled.span`
  color: ${({ theme, exceed }) =>
    exceed ? theme.colors.red : theme.colors.text};
  font-weight: ${({ exceed }) => (exceed ? '500' : '300')};
  text-decoration: ${({ exceed }) => (exceed ? 'underline' : 'none')};
  text-align: right;
  width: 100%;
  display: block;
`;

export const StyledDelivery = styled.span`
  color: ${({ theme }) => theme.colors.orange};
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledPriceInput = styled(NumberFormat)`
  width: 4rem;
  height: 1.5rem;
  border-radius: 0;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.text};
  text-align: right;
  &:focus {
    outline: none;
  }
`;

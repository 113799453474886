import React, { useReducer, useContext, createContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const SnackbarDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET':
      return {
        ...state,
        message: action.payload,
        type: 'success',
        open: true
      };
    case 'ERROR':
      return {
        ...state,
        message: action.payload,
        type: 'error',
        open: true
      };
    case 'CLEAR':
      return {
        ...state,
        message: '',
        open: false
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export default function SnackbarProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    message: '',
    open: false,
    type: 'success'
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: 'CLEAR'
    });
  };

  return (
    <SnackbarDispatchContext.Provider value={dispatch}>
      <Snackbar open={state.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={state.type}>
          {state.message}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarDispatchContext.Provider>
  );
}

export const useDispatchSnackbar = () => useContext(SnackbarDispatchContext);

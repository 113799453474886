import React from 'react';

import StyledFilePreview from './FilePreview.styled';
import Text from '../Text/Text';
import {
  formatSize,
  isImage,
  getExtension,
  handleDownload
} from '../../utils/functions';

export default function FilePreview({ file, handleRemove, horizontal }) {
  const fileName = (name, size) => {
    return (
      <Text className='file-name'>
        <span>{name}</span> ({formatSize(size)})
      </Text>
    );
  };
  return (
    <StyledFilePreview background={file.Url} horizontal={horizontal}>
      {isImage(file.Nombre) ? (
        <div className='preview-container file-image' />
      ) : (
        <div className='preview-container not-image'>
          <Text as='span' uppercase bold size='xl' color='white'>
            {getExtension(file.Nombre)}
          </Text>
        </div>
      )}
      <div className='file-info'>
        <Text>{fileName(file.Nombre, file.PesoBytes)}</Text>
        <div className='file-actions'>
          <button
            type='button'
            className='download'
            onClick={() => handleDownload(file.Url, true)}
          >
            Descargar
          </button>
          <button
            type='button'
            className='remove'
            onClick={() => handleRemove(file.Id)}
          >
            Eliminar
          </button>
        </div>
      </div>
    </StyledFilePreview>
  );
}

const columns = [
  {
    id: 'Id',
    label: 'Id',
    width: '50px',
    sortable: true
  },
  {
    id: 'FechaCreacion',
    label: 'Fecha',
    width: '50px',
    sortable: true
  },
  {
    id: 'Descripcion',
    label: 'Descripción',
    width: '200px',
    sortable: true
  },
  {
    id: 'Estado',
    label: 'Estado',
    width: '50px',
    sortable: true
  },
  {
    id: 'Total',
    label: 'Total',
    width: '50px',
    sortable: true
  },
  {
    id: 'user',
    label: 'Creado por',
    width: '50px',
    sortable: true
  }
];

export default columns;

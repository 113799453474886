import React from 'react';
import { DebounceInput } from 'react-debounce-input';

import StyledFilterItems from './FilterItems.style';
import useQueryString from '../../hooks/useQueryString';

export default function FilterItems({
  isLoading,
  placeholder,
  handleSearch,
  minLength = 0
}) {
  const { query, updateQuery } = useQueryString();
  const { Busqueda } = query || {};

  const handleSearchInput = (event) => {
    const searchTerm = event.target.value || undefined;
    if (!isLoading) {
      if (handleSearch) {
        handleSearch(searchTerm);
      } else {
        updateQuery({
          PaginaActual: 1,
          Busqueda: searchTerm
        });
      }
    }
  };

  return (
    <StyledFilterItems>
      <DebounceInput
        minLength={minLength}
        debounceTimeout={400}
        onChange={handleSearchInput}
        type='text'
        placeholder={placeholder || 'Escribe para filtrar'}
        className='filter-items'
        value={Busqueda}
      />
    </StyledFilterItems>
  );
}

import React from 'react';

import StyledRadio from './Radio.style';

export default function Radio({
  name,
  label,
  inputRef,
  value,
  checked,
  className,
  disabled
}) {
  return (
    <StyledRadio className={className} disabled={disabled}>
      <input
        type='radio'
        name={name}
        ref={inputRef}
        value={value}
        defaultChecked={checked}
        disabled={disabled}
      />
      <div className='check' />
      {label}
    </StyledRadio>
  );
}

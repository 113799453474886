import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useForm } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import qs from 'qs';
import { Helmet } from 'react-helmet';

import StyledClientModal from './ClientModal.styled';
import {
  FullDialog,
  Button,
  Radio,
  Input,
  FormRow,
  Textarea,
  Dialog,
  SelectFromList,
  ContentBox,
  ItemsList,
  Loader,
  Select,
  FormCheckbox,
  ProvinceSelector,
  Text
} from '../../components';
import { formFields, companyType, formDefaultValues } from './constants';
import useQueryString from '../../hooks/useQueryString';
import { ReactComponent as ClientesIco } from '../../resources/svg/ico-clientes.svg';
import { useAuth } from '../../providers/AuthProvider';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';
import listTypes from '../../utils/listTypes';
import NewContactForm from '../../forms/NewContactForm/NewContactForm';
import ContactItem from '../../items/ContactItem/ContactItem';
import NewPaymentForm from '../../forms/NewPaymentForm/NewPaymentForm';
import PaymentItem from '../../items/PaymentItem/PaymentItem';
import NewAddressForm from '../../forms/NewAddressForm/NewAddressForm';
import AddressItem from '../../items/AddressItem/AddressItem';
import OrdersService from '../../services/Order';
import DeliveriesService from '../../services/Deliveries';
import InvoicesService from '../../services/Invoices';
import CompanyService from '../../services/Company';
import AddressesService from '../../services/Address';
import ContactsService from '../../services/Contacts';
import PaymentMethodsService from '../../services/PaymentMethods';
import OrdersList from './OrdersList/OrdersList';
import DeliveriesList from './DeliveriesList/DeliveriesList';
import InvoicesList from './InvoicesList/InvoicesList';

export default function ClientModal({ setReload, modalState, setModalState }) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [contactsList, setcontactsList] = useState([]);
  const [paymentMethodsList, setPaymentMethodsList] = useState([]);
  const [addressesList, setAddressesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientName, setClientName] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [creationDate, setCreationDate] = useState(null);
  const [orders, setOrders] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [clientInfo, setClientInfo] = useState(null);
  const { query, updateQuery } = useQueryString(modalState);
  const { client, tab } = query || {};
  const { lists } = useAuth();
  const countriesList = lists.filter((item) => item.type === listTypes.pais);
  const usersList = lists.filter((item) => item.type === listTypes.usuario);
  const paymentsList = lists.filter(
    (listItem) => listItem.type === listTypes.formaPago
  );

  const tabs = [
    { label: 'datos' },
    {
      label: `pedidos${orders.length ? ` (${orders.length})` : ''}`,
      slug: 'pedidos',
      onlyEdit: true
    },
    {
      label: `albaranes${deliveries.length ? ` (${deliveries.length})` : ''}`,
      slug: 'albaranes',
      onlyEdit: true
    },
    {
      label: `facturas${invoices.length ? ` (${invoices.length})` : ''}`,
      slug: 'facturas',
      onlyEdit: true
    },
    { label: 'financiación y pagos', slug: 'financiacion' },
    { label: 'envíos', slug: 'envios' }
  ];

  const companyService = new CompanyService();
  const ordersService = new OrdersService();
  const deliveriesService = new DeliveriesService();
  const invoicesService = new InvoicesService();
  const addressesService = new AddressesService();
  const contactsService = new ContactsService();
  const paymentMethodsService = new PaymentMethodsService();
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    control,
    getValues,
    formState,
    reset
  } = useForm({
    defaultValues: formDefaultValues,
    shouldUnregister: false
  });
  const dispatchSnackbar = useDispatchSnackbar();
  const isNew = client === 'new';
  const handleLoading = (newState) => setIsLoading(newState);

  const tabIndex = () => {
    const idxTab = tabs.findIndex(
      (tabItem) => (tabItem.slug || tabItem.label) === tab
    );

    return idxTab === -1 ? 0 : idxTab;
  };

  const handleDialog = () => {
    if (modalState) {
      setModalState(null);
    } else {
      updateQuery({ client: undefined, tab: undefined });
    }
  };

  const handleClose = () => {
    if (setReload) {
      setReload(true);
    }
    handleDialog();
  };

  const handleTabChange = (index) => {
    updateQuery({ tab: tabs[index].slug || tabs[index].label });
  };

  const handleCloseDialog = () => {
    setShowConfirmation(false);
  };

  const handleConfirmSave = async () => {
    setShowConfirmation(false);
    setIsLoading(true);
    try {
      const response = await companyService.addCompany(showConfirmation);
      if (contactsList.length > 0 && isNew) {
        contactsList.forEach(async (contact) => {
          await contactsService.addItem({
            IdEmpresa: response.Id,
            Nombre: contact.Nombre,
            Email: contact.Email
          });
        });
      }
      if (paymentMethodsList.length > 0 && isNew) {
        paymentMethodsList.forEach(async (payment) => {
          await paymentMethodsService.addItem({
            IdEmpresa: response.Id,
            Tipo: payment.Tipo,
            Titular: payment.Titular,
            IBAN: payment.IBAN
          });
        });
      }
      if (addressesList.length > 0 && isNew) {
        addressesList.forEach(async (address) => {
          await addressesService.addItem({
            IdEmpresa: response.Id,
            Nombre: address.Nombre,
            Descripcion: address.Descripcion,
            Email: address.Email,
            Telefono: address.Telefono,
            DireccionPostal: address.DireccionPostal,
            CP: address.CP,
            Poblacion: address.Poblacion,
            Provincia: address.Provincia,
            PaisCOD: address.PaisCOD
          });
        });
      }
      handleDialog();
      reset();
      dispatchSnackbar({
        type: 'SET',
        payload: isNew
          ? `Cliente creado con id: ${response.Id}`
          : 'Cliente Editado'
      });
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'SET',
        payload: 'Ha ocurrido un error'
      });
    }
    console.log('confirm save');
    setIsLoading(false);
  };

  const onSubmit = (values) => {
    setShowConfirmation(values);
  };

  const watchType = watch(formFields.type);
  const watchCountry = watch(formFields.country);
  const watchInvoiceCountry = watch(formFields.invoicesCountry);
  const isCompany = watchType === companyType.company;
  const watchSendPostalInvoice = watch(formFields.sendPostalInvoiceOther);

  const getOrders = async (queryState = {}) => {
    setIsLoading(true);
    try {
      const ordersData = await ordersService.getOrders(
        qs.stringify(
          { ...queryState, IdCliente: client },
          { addQueryPrefix: true }
        )
      );
      const {
        Total: total,
        PaginaActual: page,
        Paginacion: limit,
        TieneAnterior: prev,
        TieneSiguiente: next,
        Datos: list
      } = ordersData;
      const { $values: ordersList } = list;
      setOrders(
        ordersList.map((orderItem) => ({
          Id: orderItem.Id,
          Fecha: orderItem.FechaCreacion,
          Descripcion: orderItem.Descripcion,
          Total: orderItem.Total,
          Estado: orderItem.Estado,
          user:
            usersList.find((user) => user.id === orderItem?.IdUsuarioCreacion)
              ?.name || 'Importado'
        }))
      );
      setIsLoading(false);
      return { total, page, limit, prev, next };
    } catch (error) {
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      console.log(error);
      setIsLoading(false);
      return null;
    }
  };

  const getDeliveries = async (queryState = {}) => {
    setIsLoading(true);
    try {
      const deliveriesData = await deliveriesService.getDeliveries(
        qs.stringify(
          { ...queryState, IdCliente: client },
          { addQueryPrefix: true }
        )
      );
      const {
        Total: total,
        PaginaActual: page,
        Paginacion: limit,
        TieneAnterior: prev,
        TieneSiguiente: next,
        Datos: list
      } = deliveriesData;
      const { $values: deliveriesList } = list;
      setDeliveries(
        deliveriesList?.map((item) => ({
          Id: item.Id,
          Fecha: item.Fecha,
          IdPedido: item.IdPedido,
          Estado: item.Estado,
          FacturaId: item.FacturaId,
          FacturaCOD: item.FacturaCOD,
          Abonado: item.Abonado,
          Pendiente: item.Pendiente,
          IdFormaPago: item.IdFormaPago,
          Total: item.Total,
          user:
            usersList.find((user) => user.id === item?.IdUsuarioCreacion)
              ?.name || 'Importado'
        }))
      );
      setIsLoading(false);
      return { total, page, limit, prev, next };
    } catch (error) {
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      console.log(error);
      setIsLoading(false);
      return null;
    }
  };

  const getInvoices = async (queryState = {}) => {
    setIsLoading(true);
    try {
      const invoicesData = await invoicesService.getInvoices(
        qs.stringify(
          { ...queryState, IdCliente: client },
          { addQueryPrefix: true }
        )
      );
      const {
        Total: total,
        PaginaActual: page,
        Paginacion: limit,
        TieneAnterior: prev,
        TieneSiguiente: next,
        Datos: list
      } = invoicesData;
      const { $values: invoicesList } = list;
      setInvoices(
        invoicesList?.map((item) => ({
          Id: item.Id,
          Codigo: item.Codigo,
          Fecha: item.Fecha,
          Serie: item.Serie,
          Estado: item.Estado,
          FechaEnviada: item.FechaEnviada,
          FechaEnviadaEmail: item.FechaEnviadaEmail,
          payment: paymentsList.find(
            (payment) => payment.value === item.IdFormaPago
          )?.label,
          Total: item.Total,
          user:
            usersList.find((user) => user.id === item?.IdUsuarioCreacion)
              ?.name || 'Importado'
        }))
      );
      setIsLoading(false);
      return { total, page, limit, prev, next };
    } catch (error) {
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      console.log(error);
      setIsLoading(false);
      return null;
    }
  };

  useEffect(async () => {
    if (client) {
      if (!isNew) {
        const companyData = await companyService.getCompany(client);
        await getOrders();
        await getDeliveries();
        await getInvoices();
        setClientName(companyData.Nombre);
        setClientId(companyData.Id);
        setClientInfo(companyData);
        setUserName(
          usersList.find((user) => user.id === companyData?.IdUsuarioCreacion)
            ?.name || 'Desconocido'
        );
        if (companyData.FechaCreacion) {
          setCreationDate(
            format(parseISO(companyData.FechaCreacion), 'dd/MM/yy')
          );
        }
        reset({
          ...companyData,
          [formFields.type]: companyData[formFields.type].toString()
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  }, [client]);

  useEffect(() => {
    if (formState.isDirty && watchType === companyType.company) {
      setValue(formFields.name, null);
      setValue(formFields.cif, null);
      setValue(formFields.address, null);
      setValue(formFields.email, null);
      setValue(formFields.phone, null);
    } else if (formState.isDirty && watchType === companyType.private) {
      setValue(formFields.name, null);
      setValue(formFields.cif, null);
      setValue(formFields.address, null);
      setValue(formFields.web, null);
      setValue(formFields.businessName, null);
    }
  }, [watchType]);

  useEffect(async () => {
    if (formState.isDirty && watchCountry) {
      setValue(formFields.province, null);
      setValue(formFields.region, null);
    }
  }, [watchCountry]);

  useEffect(async () => {
    if (formState.isDirty && watchInvoiceCountry) {
      setValue(formFields.invoicesProvince, null);
      setValue(formFields.invoicesRegion, null);
    }
  }, [watchInvoiceCountry]);
  return (
    <FullDialog
      open={!!client}
      name={clientName}
      type='Cliente'
      id={clientId}
      isNew={isNew}
      icon={<ClientesIco />}
      handleClose={handleClose}
    >
      <StyledClientModal>
        {(clientName || isNew) && (
          <Helmet>
            <title>
              {isNew ? 'Crear cliente | Pascual Vinuesa' : clientName}
            </title>
          </Helmet>
        )}
        <Loader show={isLoading} />
        <form>
          <div className='top-section'>
            {!!userName && !!creationDate ? (
              <Text color='gray'>
                {userName === 'Desconocido'
                  ? `Importado el ${creationDate}`
                  : `Creado por ${userName} el ${creationDate}`}
              </Text>
            ) : (
              <span />
            )}
            <Button
              secondary
              disabled={!formState.isDirty}
              onClick={handleSubmit(onSubmit)}
            >
              Guardar y cerrar
            </Button>
          </div>
          <Tabs
            onSelect={handleTabChange}
            disabledTabClassName='disabled-tab'
            selectedTabClassName='selected-tab'
            className='tabs'
            defaultIndex={tabIndex()}
          >
            <TabList className='tab-list'>
              {tabs.map((tabItem) => (
                <Tab
                  key={tabItem.label}
                  className='tab'
                  disabled={tabItem.disabled || (tabItem.onlyEdit && isNew)}
                >
                  {tabItem.label}
                </Tab>
              ))}
            </TabList>
            <div className='tabs-content'>
              <TabPanel>
                <div className='client-data tab-columns'>
                  <div className='data-form'>
                    <div className='client-type'>
                      <Radio
                        name={formFields.type}
                        label='Empresa'
                        inputRef={register}
                        value={companyType.company}
                      />
                      <Radio
                        name={formFields.type}
                        label='Particular'
                        inputRef={register}
                        value={companyType.private}
                      />
                    </div>
                    <div className='data-inputs'>
                      <FormRow>
                        <Input
                          label={
                            isCompany ? 'Nombre Empresa' : 'Nombre y Apellidos'
                          }
                          name={formFields.name}
                          inputRef={register({
                            required: 'Este campo es obligatorio'
                          })}
                          placeholder={
                            isCompany
                              ? 'escribe el nombre de la empresa'
                              : 'escribe el nombre y los apellidos'
                          }
                          error={
                            errors[formFields.name] &&
                            errors[formFields.name].message
                          }
                        />
                      </FormRow>
                      <FormRow hidden={!isCompany}>
                        <Input
                          label='Razon social'
                          name={formFields.businessName}
                          inputRef={register}
                          placeholder='escribe la razón social'
                          error={
                            errors[formFields.businessName] &&
                            errors[formFields.businessName].message
                          }
                        />
                      </FormRow>
                      <FormRow className='layout-4-6'>
                        <Input
                          label={isCompany ? 'CIF' : 'DNI'}
                          name={formFields.cif}
                          inputRef={register}
                          placeholder={
                            isCompany ? 'escribe el CIF' : 'escribe el DNI'
                          }
                          error={
                            errors[formFields.cif] &&
                            errors[formFields.cif].message
                          }
                        />
                        <Input
                          label='Dirección'
                          name={formFields.address}
                          inputRef={register}
                          placeholder='escribe la direccion'
                          error={
                            errors[formFields.address] &&
                            errors[formFields.address].message
                          }
                        />
                      </FormRow>
                      <FormRow className='layout-6-4'>
                        <Input
                          label='Ciudad'
                          name={formFields.city}
                          inputRef={register}
                          placeholder='escribe la ciudad'
                          error={
                            errors[formFields.city] &&
                            errors[formFields.city].message
                          }
                        />
                        <ProvinceSelector
                          provinceName={formFields.province}
                          regionName={formFields.region}
                          control={control}
                          provinceDefaultValue={getValues(formFields.province)}
                          className='second'
                          provinceError={
                            errors[formFields.province] &&
                            errors[formFields.province].message
                          }
                          inputRef={register}
                          regionError={
                            errors[formFields.region] &&
                            errors[formFields.region].message
                          }
                          country={watchCountry}
                        />
                      </FormRow>
                      <FormRow className='layout-4-6'>
                        <Input
                          label='Código postal'
                          name={formFields.postalCode}
                          inputRef={register}
                          placeholder='escribe el código postal'
                          error={
                            errors[formFields.postalCode] &&
                            errors[formFields.postalCode].message
                          }
                        />
                        <Select
                          label='Pais'
                          name={formFields.country}
                          control={control}
                          options={countriesList}
                          defaultValue={getValues(formFields.country)}
                          searchable
                          placeholder='selecciona el pais'
                          error={
                            errors[formFields.country] &&
                            errors[formFields.country].message
                          }
                        />
                      </FormRow>
                      <FormRow hidden={!isCompany}>
                        <Input
                          label='Web'
                          name={formFields.web}
                          inputRef={register}
                          placeholder='escribe la web'
                          error={
                            errors[formFields.web] &&
                            errors[formFields.web].message
                          }
                        />
                      </FormRow>
                      <FormRow className='layout-4-6' hidden={isCompany}>
                        <Input
                          label='Email'
                          name={formFields.email}
                          inputRef={register}
                          placeholder='escribe el email'
                          error={
                            errors[formFields.email] &&
                            errors[formFields.email].message
                          }
                        />
                        <Input
                          label='Teléfono'
                          name={formFields.phone}
                          inputRef={register}
                          placeholder='escribe el teléfono'
                          error={
                            errors[formFields.phone] &&
                            errors[formFields.phone].message
                          }
                        />
                      </FormRow>
                      <FormRow>
                        <Textarea
                          label='Observaciones'
                          name={formFields.notes}
                          inputRef={register}
                          placeholder='observaciones acerca del cliente'
                          error={
                            errors[formFields.notes] &&
                            errors[formFields.notes].message
                          }
                          height='8.5rem'
                        />
                      </FormRow>
                    </div>
                  </div>
                  <div className='data-contacts'>
                    <ContentBox title='Comercial'>
                      <SelectFromList
                        type={listTypes.representante}
                        control={control}
                        name={formFields.agent}
                        label='Representante'
                        addTitle='Añadir nuevo representante'
                        setValue={setValue}
                        defaultValue={getValues(formFields.agent)}
                      />
                    </ContentBox>
                    <ItemsList
                      title='Contactos'
                      addLabel='Agregar contacto'
                      addTitle='Agregar contacto a esta empresa'
                      editTitle='Editar contacto'
                      AddItem={NewContactForm}
                      addItemButtonLabel='Crear contacto'
                      editItemButtonLabel='Editar contacto'
                      isNew={isNew}
                      itemsList={contactsList}
                      setItemsList={setcontactsList}
                      Item={ContactItem}
                      service={contactsService}
                      company={client}
                      loadingParent={isLoading}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                {!isNew && (
                  <OrdersList
                    orders={orders}
                    getOrders={getOrders}
                    isLoading={isLoading}
                    setIsLoading={handleLoading}
                    clientInfo={clientInfo}
                    addressesList={addressesList}
                  />
                )}
              </TabPanel>
              <TabPanel>
                {!isNew && (
                  <DeliveriesList
                    deliveries={deliveries}
                    getDeliveries={getDeliveries}
                    isLoading={isLoading}
                    setIsLoading={handleLoading}
                  />
                )}
              </TabPanel>
              <TabPanel>
                {!isNew && (
                  <InvoicesList
                    invoices={invoices}
                    getInvoices={getInvoices}
                    isLoading={isLoading}
                    setIsLoading={handleLoading}
                  />
                )}
              </TabPanel>
              <TabPanel>
                <div className='tab-columns'>
                  <div>
                    <ItemsList
                      title='Métodos de pago'
                      addLabel='Agregar método de pago'
                      addTitle='Agregar método de pago'
                      editTitle='Editar método de pago'
                      AddItem={NewPaymentForm}
                      addItemButtonLabel='Crear método de pago'
                      editItemButtonLabel='Editar método de pago'
                      isNew={isNew}
                      itemsList={paymentMethodsList}
                      setItemsList={setPaymentMethodsList}
                      Item={PaymentItem}
                      service={paymentMethodsService}
                      company={client}
                      loadingParent={isLoading}
                    />
                    <ContentBox title='Envío de facturas'>
                      <Input
                        label='Email'
                        name={formFields.invoicesEmail}
                        inputRef={register}
                        placeholder='escribe el email para enviar facturas'
                        error={
                          errors[formFields.invoicesEmail] &&
                          errors[formFields.invoicesEmail].message
                        }
                        horizontal
                      />
                      <FormCheckbox
                        label='Enviar a dirección de facturación'
                        name={formFields.sendPostalInvoice}
                        control={control}
                      />
                      <FormCheckbox
                        label='Enviar a otra dirección'
                        name={formFields.sendPostalInvoiceOther}
                        control={control}
                      />
                      <Input
                        name={formFields.invoicesName}
                        inputRef={register}
                        placeholder='nombre'
                        error={
                          errors[formFields.invoicesName] &&
                          errors[formFields.invoicesName].message
                        }
                        disabled={!watchSendPostalInvoice}
                      />
                      <Input
                        name={formFields.invoicesAddress}
                        inputRef={register}
                        placeholder='dirección'
                        error={
                          errors[formFields.invoicesAddress] &&
                          errors[formFields.invoicesAddress].message
                        }
                        disabled={!watchSendPostalInvoice}
                      />
                      <FormRow className='layout-4-6'>
                        <Input
                          name={formFields.invoicesCity}
                          inputRef={register}
                          placeholder='localidad'
                          error={
                            errors[formFields.invoicesCity] &&
                            errors[formFields.invoicesCity].message
                          }
                          disabled={!watchSendPostalInvoice}
                        />
                        <Input
                          name={formFields.invoicesPostalCode}
                          inputRef={register}
                          placeholder='codigo postal'
                          error={
                            errors[formFields.invoicesPostalCode] &&
                            errors[formFields.invoicesPostalCode].message
                          }
                          disabled={!watchSendPostalInvoice}
                        />
                      </FormRow>
                      <FormRow className='layout-4-6'>
                        <Select
                          name={formFields.invoicesCountry}
                          control={control}
                          options={countriesList}
                          defaultValue={getValues(formFields.invoicesCountry)}
                          searchable
                          placeholder='selecciona el pais'
                          disabled={!watchSendPostalInvoice}
                          error={
                            errors[formFields.invoicesCountry] &&
                            errors[formFields.invoicesCountry].message
                          }
                        />
                        <ProvinceSelector
                          provinceName={formFields.invoicesProvince}
                          regionName={formFields.invoicesRegion}
                          control={control}
                          provinceDefaultValue={getValues(
                            formFields.invoicesProvince
                          )}
                          className='second'
                          provinceError={
                            errors[formFields.invoicesProvince] &&
                            errors[formFields.invoicesProvince].message
                          }
                          inputRef={register}
                          regionError={
                            errors[formFields.invoicesRegion] &&
                            errors[formFields.reginvoicesRegionion].message
                          }
                          provincePlaceholder='provincia'
                          regionPlaceholder='región'
                          disabled={!watchSendPostalInvoice}
                          country={watchInvoiceCountry}
                          noLabel
                        />
                      </FormRow>
                    </ContentBox>
                  </div>
                  <div>
                    <ContentBox title='Forma de pago'>
                      <SelectFromList
                        type={listTypes.formaPago}
                        control={control}
                        name={formFields.paymentMethod}
                        addTitle='Añadir nueva forma de pago'
                        setValue={setValue}
                        defaultValue={getValues(formFields.paymentMethod)}
                      />
                    </ContentBox>
                    <ContentBox title='Financiación'>
                      <SelectFromList
                        type={listTypes.grupoCaucion}
                        control={control}
                        name={formFields.clientGroup}
                        label='Grupo de cliente'
                        addTitle='Añadir nuevo grupo de cliente'
                        setValue={setValue}
                        defaultValue={getValues(formFields.clientGroup)}
                      />
                      <FormRow className='layout-1-1'>
                        <Input
                          label='Acum. Facturas pendientes contab.'
                          name={formFields.pendingInvoices}
                          inputRef={register}
                          placeholder='escribe...'
                          error={
                            errors[formFields.pendingInvoices] &&
                            errors[formFields.pendingInvoices].message
                          }
                        />
                        <Input
                          label='Nº días de plazo seguro fra'
                          name={formFields.invoiceTermDays}
                          inputRef={register}
                          placeholder='escribe...'
                          error={
                            errors[formFields.invoiceTermDays] &&
                            errors[formFields.invoiceTermDays].message
                          }
                        />
                      </FormRow>
                      <FormRow className='layout-1-1'>
                        <Input
                          label='Saldo actual de la cuenta'
                          name={formFields.balance}
                          inputRef={register}
                          placeholder='escribe...'
                          error={
                            errors[formFields.balance] &&
                            errors[formFields.balance].message
                          }
                        />
                        <Input
                          label='Importe de deuda en efectos'
                          name={formFields.debtTotal}
                          inputRef={register}
                          placeholder='escribe...'
                          error={
                            errors[formFields.debtTotal] &&
                            errors[formFields.debtTotal].message
                          }
                        />
                      </FormRow>
                      <FormRow className='layout-1-1'>
                        <Input
                          label='Límite de crédito y caución'
                          name={formFields.creditLimit}
                          inputRef={register}
                          placeholder='escribe...'
                          error={
                            errors[formFields.creditLimit] &&
                            errors[formFields.creditLimit].message
                          }
                        />
                        <div />
                      </FormRow>
                    </ContentBox>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className='tab-columns'>
                  <div>
                    <ItemsList
                      title='Direcciones de envío'
                      addLabel='Agregar dirección de envío'
                      addTitle='Agregar dirección de envío'
                      editTitle='Editar dirección de envío'
                      AddItem={NewAddressForm}
                      addItemButtonLabel='Crear'
                      editItemButtonLabel='Editar'
                      isNew={isNew}
                      itemsList={addressesList}
                      setItemsList={setAddressesList}
                      Item={AddressItem}
                      service={addressesService}
                      company={client}
                      loadingParent={isLoading}
                    />
                  </div>
                  <div />
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </form>
        <Dialog
          title='Confirmar acción'
          open={!!showConfirmation}
          handleClose={handleCloseDialog}
          handleAccept={handleConfirmSave}
        >
          <p>
            {isNew
              ? '¿Deseas guardar el nuevo cliente?'
              : '¿Deseas editar el cliente?'}
          </p>
        </Dialog>
      </StyledClientModal>
    </FullDialog>
  );
}

import styled, { css } from 'styled-components';

const secondaryStyle = css`
  background-color: ${({ theme }) => theme.colors.blue};
`;
const outlinedStyle = css`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.orange};
  color: ${({ theme }) => theme.colors.orange};
`;

const outlinedSecondaryStyle = css`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.blue};
`;

const disabledStyle = css`
  cursor: default;
  opacity: 0.2;
`;

const StyledButton = styled.button`
  cursor: pointer;
  font-weight: 400;
  background-color: ${({ theme }) => theme.colors.orange};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.5rem 1.5rem;
  border-radius: 3rem;
  ${({ secondary }) => secondary && secondaryStyle}
  ${({ outlined, secondary }) =>
    outlined && (secondary ? outlinedSecondaryStyle : outlinedStyle)}
  ${({ disabled }) => disabled && disabledStyle}
`;

export default StyledButton;

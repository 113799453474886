import React, { useEffect, useState } from 'react';

import StyledInvoicesList from './InvoicesList.style';
import columns from './columns';
import RowItems from './RowItems/RowItems';
import {
  Loader,
  FilterItems,
  ItemsTable,
  DeleteDialog
} from '../../../components';
import { formatQueryDate, handleDownload } from '../../../utils/functions';
import InvoicesFilter from '../../../pages/Invoices/InvoicesFilter/InvoicesFilter';
import { DeleteErrorDialog, SendInvoicesAssistant } from '../../../molecules';
import InvoicesService from '../../../services/Invoices';
import { useDispatchSnackbar } from '../../../providers/SnackbarProvider';
import InvoiceModal from '../../InvoiceModal/InvoiceModal';
import invoiceDelivery from '../../../utils/invoiceDelivery';
import invoiceSeries from '../../../utils/invoiceSeries';
import invoiceStatus from '../../../utils/invoiceStatus';

export default function InvoicesList({
  invoices,
  getInvoices,
  isLoading,
  setIsLoading
}) {
  const invoicesService = new InvoicesService();
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 30,
    page: 1,
    prev: false,
    next: false
  });
  const [selected, setSelected] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [filterActive, setFilterActive] = useState(null);
  const [reload, setReload] = useState(true);
  const [responseErrors, setResponseErrors] = useState([]);
  const [showSendDialog, setShowSendDialog] = useState(false);

  const [query, setQuery] = useState({});
  const updateQuery = (value) => {
    setQuery({
      ...query,
      ...value
    });
  };
  const dispatchSnackbar = useDispatchSnackbar();
  const { invoice } = query || {};

  const handleRouterUpdate = (value) => {
    if (!isLoading) {
      updateQuery(value);
    }
  };

  const handleEditInvoice = (id) => {
    handleRouterUpdate({ invoice: id });
  };

  const handleClickDelete = () => setConfirmDelete(true);

  const handleFilterActive = async (filterQuery) => {
    const { FechaIni, FechaFin, Serie, Estado, TipoEnvio } = filterQuery;
    if (FechaIni && FechaFin) {
      setFilterActive(
        `${formatQueryDate(FechaIni)} a ${formatQueryDate(FechaFin)}`
      );
    }
    if (Estado) {
      const statusName = invoiceStatus.find(
        (item) => item.value.toString() === Estado
      );
      setFilterActive(`Estado: ${statusName?.label}`);
    }
    if (Serie) {
      const serieName = invoiceSeries.find(
        (item) => item.value.toString() === Serie
      );
      setFilterActive(`Serie: ${serieName?.label}`);
    }
    if (TipoEnvio) {
      const deliveryName = invoiceDelivery.find(
        (item) => item.value.toString() === TipoEnvio
      );
      setFilterActive(`Tipo de envío: ${deliveryName?.label}`);
    }
  };

  const handleDeleteInvoices = async () => {
    setIsLoading(true);
    setConfirmDelete(false);
    try {
      const response = await invoicesService.deleteInvoices(selected);
      const ErrorsList = response.$values.filter((item) => !!item.Error);
      if (ErrorsList.length) {
        setResponseErrors(ErrorsList);
      } else {
        dispatchSnackbar({
          type: 'SET',
          payload: 'facturas eliminadas'
        });
      }
      handleRouterUpdate();
      setIsLoading(false);
      setSelected([]);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    if (!invoice && reload && query instanceof Object) {
      setIsLoading(true);
      try {
        const newPagination = await getInvoices(query);
        setPagination({ ...newPagination });
        handleFilterActive(query);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else if (!reload) {
      setReload(true);
    }
  }, [query]);

  const handleSearch = (term) => {
    updateQuery({
      PaginaActual: 1,
      Busqueda: term
    });
  };

  const changeRowsPerPage = (rows) => {
    updateQuery({
      PaginaActual: 1,
      Paginacion: rows
    });
  };

  const changePage = (newPage) => {
    updateQuery({
      PaginaActual: newPage
    });
  };

  const sortItems = (sortQuery) => {
    updateQuery(sortQuery);
  };

  const handleFilter = (filterQuery) => {
    updateQuery(filterQuery);
  };

  const handleCloseInvoiceModal = () => {
    updateQuery({ invoice: null });
  };

  const handleSelected = (newstate) => setSelected(newstate);

  const handlePrintInvoices = async () => {
    setIsLoading(true);
    try {
      const response = await invoicesService.printInvoices(selected);
      handleDownload(response);
      setSelected([]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleSendInvoices = () => {
    setShowSendDialog(true);
  };

  const handleCloseSendDialog = () => setShowSendDialog(false);

  const handleCloseDeleteDialog = () => setConfirmDelete(false);

  const extraButtons = [
    {
      label: 'Imprimir',
      onClick: handlePrintInvoices
    },
    {
      label: 'Enviar facturas',
      onClick: handleSendInvoices
    }
  ];

  const filterOptions = {
    invoiceDelivery,
    invoiceSeries,
    invoiceStatus
  };

  return (
    <StyledInvoicesList>
      <Loader show={isLoading} />
      <div className='top-section'>
        <FilterItems isLoading={isLoading} handleSearch={handleSearch} />
      </div>
      <ItemsTable
        handleClickDelete={handleClickDelete}
        FilterForm={InvoicesFilter}
        filterLabel='Filtrar facturas'
        filterActive={filterActive}
        filterOptions={filterOptions}
        setFilterActive={setFilterActive}
        title='Facturas'
        columns={columns}
        items={invoices}
        pagination={pagination}
        isLoading={isLoading}
        selected={selected}
        handleClickRow={handleEditInvoice}
        setSelected={setSelected}
        RowItems={RowItems}
        handleFilter={handleFilter}
        filterData={query}
        changeRowsPerPage={changeRowsPerPage}
        changePage={changePage}
        sortItems={sortItems}
        extraButtons={extraButtons}
      />
      {invoice && (
        <InvoiceModal
          setReload={setReload}
          modalState={query}
          setModalState={handleCloseInvoiceModal}
        />
      )}
      <DeleteDialog
        open={confirmDelete}
        close={handleCloseDeleteDialog}
        handleAccept={handleDeleteInvoices}
        singular='factura'
        plural='facturas'
        quantity={selected.length}
      />
      <DeleteErrorDialog
        errors={responseErrors}
        handleClose={() => setResponseErrors([])}
      />
      <SendInvoicesAssistant
        showSendDialog={showSendDialog}
        handleCloseSendDialog={handleCloseSendDialog}
        selected={selected}
        handleSelected={handleSelected}
        handleLoading={setIsLoading}
        invoices={invoices}
        getInvoices={getInvoices}
        handleEditInvoice={handleEditInvoice}
      />
    </StyledInvoicesList>
  );
}

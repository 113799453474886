const columns = [
  {
    id: 'Id',
    label: 'Id',
    width: '50px'
  },
  {
    id: 'FechaActualizacion',
    label: 'Fecha',
    width: '50px'
  },
  {
    id: 'EstadoLabel',
    label: 'Estado',
    width: '50px'
  },
  {
    id: 'FacturaCOD',
    label: 'Factura',
    width: '50px'
  },
  {
    id: 'ArticulosNum',
    label: 'Artículos',
    width: '50px'
  },
  {
    id: 'Abonado',
    label: 'Abonado',
    width: '50px'
  },
  {
    id: 'Pendiente',
    label: 'Pendiente',
    width: '50px'
  },
  {
    id: 'Total',
    label: 'Total',
    width: '50px'
  },
  {
    id: 'user',
    label: 'Creado por',
    width: '50px'
  }
];

export default columns;

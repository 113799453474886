import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';

import StyledDeliveryModal, { StyledPrintModal } from './DeliveryModal.style';
import {
  FullDialog,
  Button,
  Radio,
  Input,
  FormRow,
  Textarea,
  SelectFromList,
  ContentBox,
  Loader,
  Select,
  ProvinceSelector,
  Text,
  CurrencyDisplay,
  CurrencyInput,
  Datepicker,
  SelectWithAdd,
  CompaniesTypeAhead,
  Dialog
} from '../../components';
import useQueryString from '../../hooks/useQueryString';
import DeliveriesService from '../../services/Deliveries';
import { ReactComponent as DeliveryIco } from '../../resources/svg/ico-albaranes.svg';
import { ReactComponent as PrintIco } from '../../resources/svg/ico-imprimir.svg';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';
import NewContactForm from '../../forms/NewContactForm/NewContactForm';
import NewCarrierForm from '../../forms/NewCarrierForm/NewCarrierForm';
import { tabs } from './constants';
import listTypes from '../../utils/listTypes';
import { useAuth } from '../../providers/AuthProvider';
import ArticlesList from './ArticlesList/ArticlesList';
import invoiceSeries from '../../utils/invoiceSeries';
import CompaniesService from '../../services/Company';
import ContactsService from '../../services/Contacts';
import OrdersService from '../../services/Order';
import CarriersService from '../../services/Carriers';
import deliveryStatus from '../../utils/deliveryStatus';
import { handleDownload } from '../../utils/functions';

const companyType = {
  company: '0',
  private: '1'
};

export default function DeliveryModal({
  setReload,
  orderInfo,
  articles,
  getOrderLines,
  getDeliveries,
  modalState,
  setModalState
}) {
  const { query, updateQuery } = useQueryString(modalState);
  const { delivery, deliveryTab } = query || {};
  const [deliveryName, setDeliveryName] = useState('');
  const [lines, setLines] = useState([]);
  const [deliveryInfo, setDeliveryInfo] = useState([]);
  const [contactsOptions, setContactsOptions] = useState([]);
  const [carriersOptions, setCarriersOption] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [userName, setUserName] = useState(null);
  const [client, setClient] = useState(null);
  const [showPrintDialog, setShowPrintDialog] = useState(false);
  const [printAndSave, setPrintandSave] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [isCompany, setIsCompany] = useState(false);
  const [isReadonly, setIsReadonly] = useState(false);
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const deliveriesService = new DeliveriesService();
  const companiesService = new CompaniesService();
  const contactsService = new ContactsService();
  const ordersService = new OrdersService();
  const carriersService = new CarriersService();
  const dispatchSnackbar = useDispatchSnackbar();
  const [orderLines, setOrderLines] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [total, setTotal] = useState(0);
  const [pending, setPending] = useState(0);
  const [credit, setCredit] = useState(null);
  const { lists } = useAuth();

  const usersList = lists.filter((item) => item.type === listTypes.usuario);
  const countriesList = lists.filter((item) => item.type === listTypes.pais);

  const handlePrintDelivery = () => setShowPrintDialog(true);
  const handleClosePrintDialog = () => setShowPrintDialog(false);

  const handleTabChange = (index) => {
    updateQuery({ deliveryTab: tabs[index].slug || tabs[index].label });
  };

  const tabIndex = () => {
    const idxTab = tabs.findIndex(
      (tabItem) => (tabItem.slug || tabItem.label) === deliveryTab
    );

    return idxTab === -1 ? 0 : idxTab;
  };

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    control,
    getValues,
    formState,
    reset
  } = useForm({
    shouldUnregister: false,
    defaultValues: {
      Cliente: null,
      IdEmpresa: null,
      Estado: 0,
      Abonado: 0,
      Metalico: 0,
      DescuentoPorcentaje: 0,
      DescuentoNeto: 0,
      DescuentoProntoPago: 0,
      Fecha: null,
      IdFormaPago: null,
      IdRepresentante: null,
      Observaciones: null,
      SerieFactura: null,
      IdContacto: null,
      IdMetodoEnvio: null,
      Nombre: null,
      Apellidos: null,
      Direccion: null,
      CP: null,
      Poblacion: null,
      Region: null,
      RegionCOD: '09',
      PaisCOD: 'ES',
      Email: null,
      Telefono: null,
      Empresa: null,
      ObservacionesEnvio: null,
      TransportistaId: null,
      TransportistaNombre: null,
      TransportistaCIF: null,
      TransportistaVehiculo: null,
      TransportistaRemolque: null
    }
  });
  const watchAbonado = watch('Abonado');
  const watchMetalico = watch('Metalico');
  const watchDescuentoPorcentaje = watch('DescuentoPorcentaje');
  const watchDescuentoNeto = watch('DescuentoNeto');
  const watchDescuentoProntoPago = watch('DescuentoProntoPago');
  const watchClientId = watch('IdEmpresa');
  const watchDeliveryMethod = watch('IdMetodoEnvio');
  const watchCountry = watch('PaisCOD');
  const watchCarrier = watch('TransportistaId');

  const setContactLabel = (contact) =>
    `${contact.Nombre ? `${contact.Nombre} ` : ''}${
      contact.Apellidos ? `${contact.Apellidos} ` : ''
    }${contact.Email ? `(${contact.Email})` : ''}${
      contact.Telefono ? ` ${contact.Telefono}` : ''
    }`;

  const handleDialog = () => {
    if (modalState) {
      setModalState(null);
    } else {
      updateQuery({ delivery: undefined, deliveryTab: undefined });
    }
  };

  const handleClose = () => {
    if (setReload) {
      setReload(false);
    }
    handleDialog();
  };

  const saveDelivery = async (values) => {
    const deliveryData = {
      ...deliveryInfo,
      Id: deliveryInfo.Id || 0,
      IdEmpresa: values.IdEmpresa,
      Estado: values.Estado,
      Origen: 1,
      IdContacto: values.IdContacto,
      IdFormaPago: values.IdFormaPago,
      IdRepresentante: values.IdRepresentante,
      IdMetodoEnvio: +values.IdMetodoEnvio,
      Fecha: values.Fecha,
      Metalico: values.Metalico,
      Abonado: values.Abonado,
      SerieFactura: values.SerieFactura,
      DescuentoNeto: values.DescuentoNeto,
      DescuentoPorcentaje: values.DescuentoPorcentaje,
      DescuentoProntoPago: values.DescuentoProntoPago,
      Observaciones: values.Observaciones,
      TransportistaId: values.TransportistaId,
      TransportistaNombre: values.TransportistaNombre,
      TransportistaCIF: values.TransportistaCIF,
      TransportistaVehiculo: values.TransportistaVehiculo,
      TransportistaRemolque: values.TransportistaRemolque
    };
    if (values.IdMetodoEnvio !== '2') {
      deliveryData.DireccionEnvio = {
        ...deliveryInfo?.DireccionEnvio,
        Nombre: values.Nombre,
        Apellidos: values.Apellidos,
        Direccion: values.Direccion,
        CP: values.CP,
        Poblacion: values.Poblacion,
        Region: values.Region,
        RegionCOD: values.RegionCOD,
        PaisCOD: values.PaisCOD,
        Email: values.Email,
        Telefono: values.Telefono,
        Empresa: values.Empresa,
        Observaciones: values.ObservacionesEnvio
      };
    }
    if (isNew && orderInfo) {
      deliveryData.IdPedido = orderInfo.Id;
    }
    let response;
    try {
      response = await deliveriesService.saveDelivery(deliveryData);
      if (lines.length) {
        await deliveriesService.saveDeliveryLines(
          response.Id,
          lines.map(({ Id, ...line }) => ({ ...line }))
        );
      }
      if (getOrderLines && orderInfo) {
        await getOrderLines(orderInfo.Id);
      }
      if (getDeliveries && orderInfo) {
        await getDeliveries(orderInfo.Id);
      }
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
    }
    return response?.Id;
  };

  const handlePrintButton = () => {
    handlePrintDelivery();
  };

  const handlePrintandSaveButton = () => {
    handlePrintDelivery();
    setPrintandSave(true);
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    await saveDelivery(values);
    handleDialog();
    setIsLoading(false);
  };

  const printDelivery = async (rated) => {
    setIsLoading(true);
    setShowPrintDialog(false);
    let id;
    if (printAndSave) {
      const values = getValues();
      id = await saveDelivery(values);
    }
    try {
      const response = await deliveriesService.printDeliveries(
        [id || deliveryInfo.Id],
        rated
      );
      handleDownload(response);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
    } finally {
      setIsLoading(false);
      if (printAndSave) {
        handleDialog();
      }
    }
  };

  const updateLines = (updatedList) => {
    setLines(updatedList);
    setIsListUpdated(true);
  };

  const setCarrierOption = (carrier) => ({
    value: carrier.Id,
    label: `${carrier.Nombre}${carrier.CIF ? ` - ${carrier.CIF}` : ''}`,
    TransportistaNombre: carrier.Nombre,
    TransportistaRemolque: carrier.Remolque,
    TransportistaVehiculo: carrier.Vehiculo,
    TransportistaCIF: carrier.CIF
  });

  useEffect(async () => {
    if (delivery) {
      const isNewDelivery = delivery === 'new';
      setIsNew(isNewDelivery);
      if (articles) {
        setOrderLines([...articles]);
      }
      try {
        const deliveryMethodsData = await ordersService.getDeliveryMethods();
        setDeliveryMethods(deliveryMethodsData?.$values.map(setCarrierOption));
        const carriersData = await carriersService.getAll();
        setCarriersOption(carriersData?.$values.map(setCarrierOption));
        if (!isNewDelivery) {
          const deliveryResponse = await deliveriesService.getDelivery(
            delivery
          );
          const deliveryLines = await deliveriesService.getDeliveryLines(
            delivery
          );
          if (!orderInfo && deliveryResponse.IdPedido) {
            const orderResponse = await ordersService.getOrder(
              deliveryResponse.IdPedido
            );
            setDeliveryName(
              ` - Pedido ${deliveryResponse.IdPedido} ${
                orderResponse?.Descripcion || ''
              }`
            );
            if (!articles) {
              const linesResponse = await ordersService.getOrderLines(
                deliveryResponse.IdPedido
              );
              setOrderLines(
                linesResponse.$values.map((line) => ({
                  ...line,
                  Id: line.UUID || line.IdPedidoLinea,
                  isFreeText: !line.IdReferencia
                }))
              );
            }
          } else {
            setDeliveryName(
              ` - Pedido ${deliveryResponse?.IdPedido} ${
                orderInfo?.Descripcion || ''
              }`
            );
          }
          if (deliveryResponse.FacturaId) {
            setIsReadonly(true);
          }
          setDeliveryInfo(deliveryResponse);
          const {
            Abonado,
            Metalico,
            DescuentoPorcentaje,
            DescuentoNeto,
            DescuentoProntoPago,
            IdRepresentante,
            Observaciones,
            IdEmpresa,
            IdContacto,
            IdMetodoEnvio,
            IdUsuarioCreacion,
            IdFormaPago,
            Fecha,
            SerieFactura,
            Estado,
            TransportistaId,
            TransportistaNombre,
            TransportistaCIF,
            TransportistaVehiculo,
            TransportistaRemolque
          } = deliveryResponse;
          const {
            Nombre,
            Apellidos,
            Direccion,
            CP,
            Poblacion,
            Region,
            RegionCOD,
            PaisCOD,
            Email,
            Telefono,
            Empresa,
            Observaciones: ObservacionesEnvio
          } = deliveryResponse.DireccionEnvio;
          setUserName(
            usersList.find((user) => user.id === IdUsuarioCreacion)?.name ||
              'Desconocido'
          );
          const clientData = await companiesService.getCompany(IdEmpresa);
          setSelectedClient(clientData);
          setClient({
            name: clientData.Nombre,
            id: clientData.Id
          });
          if (clientData.Tipo?.toString() === companyType.company) {
            setIsCompany(true);
            const contactsData = await contactsService.getByCompany(IdEmpresa);
            setContactsOptions(
              contactsData.map((contact) => ({
                value: contact.Id,
                label: setContactLabel(contact)
              }))
            );
          }
          setLines(
            deliveryLines.$values.map((line) => ({
              ...line,
              Id: line.UUID,
              isFreeText: !line.IdReferencia
            }))
          );
          setCredit(clientData.LimiteCredito);
          reset({
            Cliente: clientData.Nombre,
            IdEmpresa,
            Estado,
            Abonado,
            Metalico,
            DescuentoPorcentaje,
            DescuentoNeto,
            DescuentoProntoPago,
            Fecha,
            IdFormaPago,
            IdRepresentante,
            Observaciones,
            SerieFactura,
            IdContacto,
            IdMetodoEnvio: IdMetodoEnvio?.toString(),
            Nombre,
            Apellidos,
            Direccion,
            CP,
            Poblacion,
            Region,
            RegionCOD,
            PaisCOD,
            Email,
            Telefono,
            Empresa,
            ObservacionesEnvio,
            TransportistaId,
            TransportistaNombre,
            TransportistaCIF,
            TransportistaVehiculo,
            TransportistaRemolque
          });
        } else if (orderInfo) {
          const { IdCliente, DireccionEnvio, IdMetodoEnvio } = orderInfo;
          const clientData = await companiesService.getCompany(IdCliente);
          setSelectedClient(clientData);
          setClient({
            name: clientData.Nombre,
            id: clientData.Id
          });
          if (clientData.Tipo?.toString() === companyType.company) {
            setIsCompany(true);
            const contactsData = await contactsService.getByCompany(IdCliente);
            setContactsOptions(
              contactsData.map((contact) => ({
                value: contact.Id,
                label: setContactLabel(contact)
              }))
            );
          }
          const {
            Nombre,
            Apellidos,
            Direccion,
            CP,
            Poblacion,
            Region,
            RegionCOD,
            PaisCOD,
            Email,
            Telefono,
            Empresa,
            Observaciones: ObservacionesEnvio
          } = DireccionEnvio;
          reset({
            type: clientData.Tipo?.toString(),
            Cliente: clientData.Nombre,
            IdEmpresa: IdCliente,
            IdMetodoEnvio: IdMetodoEnvio?.toString(),
            Nombre,
            Apellidos,
            Direccion,
            CP,
            Poblacion,
            Region,
            RegionCOD,
            PaisCOD,
            Email,
            Telefono,
            Empresa,
            ObservacionesEnvio
          });
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        dispatchSnackbar({
          type: 'ERROR',
          payload: 'Ha ocurrido un error'
        });
        setIsLoading(false);
      }
    }
    return function cleanup() {
      setLines([]);
    };
  }, [delivery]);

  const applyDiscounts = (price) => {
    return (
      (price * (1 - watchDescuentoPorcentaje / 100) - watchDescuentoNeto) *
      (1 - watchDescuentoProntoPago / 100)
    );
  };

  const getSubTotal = () => {
    const accumulated = lines.reduce((acc, article) => {
      const {
        Precio,
        Cantidad = 0,
        DescuentoPorcentaje = 0,
        IvaIncluido,
        IVA
      } = article;
      if (IvaIncluido) {
        return (
          acc +
          (Precio / (1 + IVA)) * Cantidad * (1 - DescuentoPorcentaje / 100)
        );
      }
      return acc + Precio * Cantidad * (1 - DescuentoPorcentaje / 100);
    }, 0);
    return applyDiscounts(accumulated);
  };

  const getTaxes = () => {
    const accumulated = lines.reduce((acc, article) => {
      const {
        Precio,
        Cantidad = 0,
        DescuentoPorcentaje = 0,
        IVA,
        IvaIncluido
      } = article;
      if (IvaIncluido) {
        return (
          acc +
          (Precio - Precio / (1 + IVA)) *
            Cantidad *
            (1 - DescuentoPorcentaje / 100)
        );
      }
      return acc + Precio * IVA * Cantidad * (1 - DescuentoPorcentaje / 100);
    }, 0);
    return applyDiscounts(accumulated);
  };

  const getTotal = () => {
    const accumulated = lines.reduce((acc, article) => {
      const {
        Precio,
        Cantidad = 0,
        DescuentoPorcentaje = 0,
        IVA,
        IvaIncluido
      } = article;
      if (IvaIncluido) {
        return acc + Precio * Cantidad * (1 - DescuentoPorcentaje / 100);
      }
      return (
        acc + Precio * (1 + IVA) * Cantidad * (1 - DescuentoPorcentaje / 100)
      );
    }, 0);
    return applyDiscounts(accumulated);
  };

  const handleAddContact = async (values) => {
    try {
      const contactResponse = await contactsService.addItem({
        ...values,
        IdEmpresa: watchClientId
      });
      const newContact = {
        value: contactResponse.Id,
        label: setContactLabel(contactResponse)
      };
      setContactsOptions([...contactsOptions, newContact]);
      return newContact;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const handleAddCarrier = async (values) => {
    const carrierValues = {
      CIF: values.TransportistaCIF,
      Nombre: values.TransportistaNombre,
      Remolque: values.TransportistaRemolque,
      Vehiculo: values.TransportistaVehiculo
    };
    if (values.Id) {
      carrierValues.Id = values.Id;
    }
    try {
      const carrierResponse = await carriersService.addItem(carrierValues);
      const newCarrier = setCarrierOption(carrierResponse);
      if (!values.Id) {
        setCarriersOption([...carriersOptions, newCarrier]);
      }
      setValue('TransportistaCIF', newCarrier.TransportistaCIF);
      setValue('TransportistaNombre', newCarrier.TransportistaNombre);
      setValue('TransportistaRemolque', newCarrier.TransportistaRemolque);
      setValue('TransportistaVehiculo', newCarrier.TransportistaVehiculo);
      return newCarrier;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  useEffect(() => {
    setSubTotal(getSubTotal());
    setTaxes(getTaxes());
    setTotal(getTotal());
  }, [
    lines,
    watchDescuentoPorcentaje,
    watchDescuentoNeto,
    watchDescuentoProntoPago
  ]);

  useEffect(() => {
    setPending(total - watchAbonado - watchMetalico);
  }, [total, watchAbonado, watchMetalico]);

  useEffect(() => {
    if (formState.isDirty && watchCarrier) {
      const carrierData = carriersOptions.find(
        (carrier) => carrier.value === watchCarrier
      );
      setValue('TransportistaCIF', carrierData.TransportistaCIF);
      setValue('TransportistaNombre', carrierData.TransportistaNombre);
      setValue('TransportistaRemolque', carrierData.TransportistaRemolque);
      setValue('TransportistaVehiculo', carrierData.TransportistaVehiculo);
    }
  }, [watchCarrier]);

  useEffect(async () => {
    if (selectedClient) {
      setValue('Cliente', selectedClient.Nombre, { shouldDirty: true });
      setValue('IdEmpresa', selectedClient.Id, { shouldDirty: true });
      setValue('IdContacto', null, { shouldDirty: true });
      if (selectedClient.Tipo?.toString() === companyType.company) {
        setIsLoading(true);
        setIsCompany(true);
        try {
          const contactsData = await contactsService.getByCompany(
            selectedClient.Id
          );
          setContactsOptions(
            contactsData.map((contact) => ({
              value: contact.Id,
              label: setContactLabel(contact)
            }))
          );
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      } else {
        setIsCompany(false);
        setContactsOptions([]);
      }
    } else {
      setValue('Cliente', null, { shouldDirty: true });
      setValue('IdEmpresa', null, { shouldDirty: true });
      setValue('IdContacto', null, { shouldDirty: true });
      setIsCompany(false);
      setContactsOptions([]);
    }
  }, [selectedClient]);

  return (
    <FullDialog
      open={!!delivery}
      name={deliveryName}
      type='Albarán'
      id={delivery}
      isNew={isNew}
      client={client}
      icon={<DeliveryIco />}
      handleClose={handleClose}
      touched={formState.isDirty || isListUpdated}
    >
      <StyledDeliveryModal>
        <Loader show={isLoading} />
        {(delivery || isNew) && (
          <Helmet>
            <title>
              {isNew
                ? 'Crear albarán | Pascual Vinuesa'
                : `Albarán ${delivery}  | Pascual Vinuesa`}
            </title>
          </Helmet>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='top-section'>
            <div className='user-date'>
              {userName ? (
                <Text color='gray'>
                  {userName === 'Desconocido'
                    ? 'Importado'
                    : `Creado por ${userName}`}
                </Text>
              ) : (
                <span />
              )}
              <Datepicker
                className='date-input'
                name='Fecha'
                control={control}
                label='Fecha'
                horizontal
                placeholder='Fecha'
                disabled={isReadonly}
              />
              <Select
                horizontal
                label='Estado:'
                name='Estado'
                control={control}
                options={deliveryStatus}
                className='status-selector'
                placeholder='estado...'
                defaultValue={getValues('Estado')}
                disabled={isReadonly}
              />
            </div>
            <div className='modal-buttons'>
              {(formState.isDirty || isListUpdated) && (
                <button
                  type='button'
                  className='print-button'
                  onClick={handlePrintandSaveButton}
                >
                  <PrintIco /> Guardar e imprimir
                </button>
              )}
              {!isNew && !formState.isDirty && !isListUpdated && (
                <button
                  type='button'
                  className='print-button'
                  onClick={handlePrintButton}
                >
                  <PrintIco /> Imprimir
                </button>
              )}
              <Button
                secondary
                type='submit'
                disabled={!formState.isDirty && !isListUpdated}
              >
                Guardar y cerrar
              </Button>
            </div>
          </div>
          <Tabs
            onSelect={handleTabChange}
            disabledTabClassName='disabled-tab'
            selectedTabClassName='selected-tab'
            className='tabs'
            defaultIndex={tabIndex()}
          >
            <TabList className='tab-list'>
              {tabs.map((tabItem) => (
                <Tab
                  key={tabItem.label}
                  className='tab'
                  disabled={tabItem.disabled}
                >
                  {tabItem.label}
                </Tab>
              ))}
            </TabList>
            <div className='tabs-content'>
              <TabPanel>
                <ArticlesList
                  articles={lines}
                  setArticles={updateLines}
                  isNew={isNew}
                  id={delivery}
                  deliveryName={deliveryName}
                  orderLines={orderLines}
                  setOrderLines={setOrderLines}
                  isReadonly={isReadonly}
                />
                <div className='totals-container'>
                  <div className='inputs'>
                    <div>
                      <Text bold>Dto. %</Text>
                      <CurrencyInput
                        control={control}
                        name='DescuentoPorcentaje'
                        className='price-input'
                        percentaje
                        disabled={isReadonly}
                      />
                    </div>
                    <div>
                      <Text bold>Dto. €</Text>
                      <CurrencyInput
                        control={control}
                        name='DescuentoNeto'
                        className='price-input'
                        disabled={isReadonly}
                      />
                    </div>
                    <div>
                      <Text bold>Dto. Prontopago %</Text>
                      <CurrencyInput
                        control={control}
                        name='DescuentoProntoPago'
                        className='price-input'
                        percentaje
                        disabled={isReadonly}
                      />
                    </div>
                    <div>
                      <Text bold>Abonado</Text>
                      <CurrencyInput
                        control={control}
                        name='Abonado'
                        className='price-input'
                        disabled={isReadonly}
                      />
                    </div>
                    <div>
                      <Text bold>Metálico</Text>
                      <CurrencyInput
                        control={control}
                        name='Metalico'
                        className='price-input'
                        disabled={isReadonly}
                      />
                    </div>
                    <div>
                      <Text bold>Pendiente de pago</Text>
                      <CurrencyDisplay number={pending} className='pending' />
                    </div>
                  </div>
                  <div className='totals'>
                    <div className='subtotals'>
                      <Text uppercase>
                        subtotal: <CurrencyDisplay number={subTotal} />
                      </Text>
                      <Text uppercase>
                        iva: <CurrencyDisplay number={taxes} />
                      </Text>
                    </div>
                    <div className='total'>
                      <Text uppercase>
                        total: <CurrencyDisplay number={total} />
                      </Text>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className='tab-columns'>
                  <div>
                    <ContentBox title='Forma de pago'>
                      <SelectFromList
                        type={listTypes.formaPago}
                        control={control}
                        name='IdFormaPago'
                        addTitle='Añadir nueva forma de pago'
                        setValue={setValue}
                        defaultValue={getValues('IdFormaPago')}
                        disabled={isReadonly}
                      />
                    </ContentBox>
                    {credit !== null && (
                      <ContentBox title='Financiación'>
                        <Text bold>Límite de crédito</Text>
                        <CurrencyDisplay number={credit} className='credit' />
                      </ContentBox>
                    )}
                    <ContentBox title='Serie Factura'>
                      <Select
                        name='SerieFactura'
                        control={control}
                        options={invoiceSeries}
                        defaultValue={getValues('SerieFactura')}
                        searchable
                        placeholder='Serie de la factura'
                        error={
                          errors.SerieFactura && errors.SerieFactura?.message
                        }
                        className='invoice-series'
                        disabled={isReadonly}
                      />
                    </ContentBox>
                  </div>
                  <div>
                    <ContentBox title='Comercial'>
                      <SelectFromList
                        type={listTypes.representante}
                        control={control}
                        name='IdRepresentante'
                        addTitle='Añadir nuevo representante'
                        setValue={setValue}
                        defaultValue={getValues('IdRepresentante')}
                        disabled={isReadonly}
                      />
                    </ContentBox>
                    <ContentBox title='Observaciones'>
                      <Textarea
                        name='Observaciones'
                        inputRef={register}
                        placeholder='observaciones acerca del albarán'
                        error={
                          errors.Observaciones && errors.Observaciones.message
                        }
                        height='8.5rem'
                        disabled={isReadonly}
                      />
                    </ContentBox>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className='tab-columns'>
                  <div>
                    <CompaniesTypeAhead
                      selectedClient={selectedClient}
                      setSelectedClient={setSelectedClient}
                      error={errors.Cliente?.message || errors.Cliente?.message}
                      disabled={isReadonly}
                    />
                    {isCompany && watchClientId && (
                      <ContentBox title='Contacto de la empresa'>
                        <SelectWithAdd
                          name='IdContacto'
                          control={control}
                          options={contactsOptions}
                          className='filter-select'
                          placeholder='selecciona un contacto'
                          defaultValue={getValues('IdContacto')}
                          handleAddItem={handleAddContact}
                          AddForm={NewContactForm}
                          addTitle='Añadir nuevo contacto'
                          disabled={isReadonly}
                        />
                      </ContentBox>
                    )}
                  </div>
                  <div className='data-delivery'>
                    <ContentBox title='Envío o recogida:'>
                      <div className='methods'>
                        {deliveryMethods.map((method) => (
                          <Radio
                            key={method.value}
                            name='IdMetodoEnvio'
                            label={method.label}
                            inputRef={register}
                            value={method.value}
                            disabled={isReadonly}
                          />
                        ))}
                      </div>
                      {watchDeliveryMethod && watchDeliveryMethod !== '2' && (
                        <div className='delivery-fields'>
                          <FormRow className='layout-1-1'>
                            <Input
                              label='Nombre'
                              name='Nombre'
                              inputRef={register}
                              placeholder='escribe el nombre'
                              error={errors.Nombre && errors.Nombre.message}
                              disabled={isReadonly}
                            />
                            <Input
                              label='Apellidos'
                              name='Apellidos'
                              inputRef={register}
                              placeholder='escribe los apellidos'
                              error={
                                errors.Apellidos && errors.Apellidos.message
                              }
                              disabled={isReadonly}
                            />
                          </FormRow>
                          <FormRow>
                            <Input
                              label='Empresa'
                              name='Empresa'
                              inputRef={register}
                              placeholder='escribe el nombre de la empresa'
                              error={errors.Empresa && errors.Empresa.message}
                              disabled={isReadonly}
                            />
                          </FormRow>
                          <FormRow>
                            <Input
                              label='Dirección'
                              name='Direccion'
                              inputRef={register}
                              placeholder='escribe la dirección'
                              error={
                                errors.Direccion && errors.Direccion.message
                              }
                              disabled={isReadonly}
                            />
                          </FormRow>
                          <FormRow className='layout-4-6'>
                            <Input
                              label='Código postal'
                              name='CP'
                              inputRef={register}
                              placeholder='escribe el código postal'
                              error={errors.CP && errors.CP.message}
                              disabled={isReadonly}
                            />
                            <Input
                              label='Población'
                              name='Poblacion'
                              inputRef={register}
                              placeholder='escribe la población'
                              error={
                                errors.Poblacion && errors.Poblacion.message
                              }
                              disabled={isReadonly}
                            />
                          </FormRow>
                          <FormRow className='layout-4-6'>
                            <Select
                              label='Pais'
                              name='Pais'
                              control={control}
                              options={countriesList}
                              defaultValue={getValues('Pais')}
                              searchable
                              placeholder='selecciona el pais'
                              error={errors.Pais && errors.Pais.message}
                              disabled={isReadonly}
                            />
                            <ProvinceSelector
                              provinceName='RegionCOD'
                              regionName='Region'
                              control={control}
                              provinceDefaultValue={getValues('RegionCOD')}
                              className='second'
                              provinceError={
                                errors.RegionCOD && errors.RegionCOD.message
                              }
                              inputRef={register}
                              regionError={
                                errors.Region && errors.Region.message
                              }
                              country={watchCountry}
                              disabled={isReadonly}
                            />
                          </FormRow>
                          <FormRow className='layout-6-4'>
                            <Input
                              label='Email'
                              name='Email'
                              inputRef={register}
                              placeholder='escribe el correo electrónico'
                              error={errors.Email && errors.Email.message}
                              disabled={isReadonly}
                            />
                            <Input
                              label='Teléfono'
                              name='Telefono'
                              inputRef={register}
                              placeholder='escribe el teléfono'
                              error={errors.Telefono && errors.Telefono.message}
                              disabled={isReadonly}
                            />
                          </FormRow>
                          <FormRow>
                            <Textarea
                              label='Observaciones'
                              name='ObservacionesEnvio'
                              inputRef={register}
                              placeholder='observaciones acerca del envío'
                              error={
                                errors.ObservacionesEnvio &&
                                errors.ObservacionesEnvio.message
                              }
                              height='5.5rem'
                              disabled={isReadonly}
                            />
                          </FormRow>
                        </div>
                      )}
                    </ContentBox>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <ContentBox className='carrier-form'>
                    <Text bold className='carrier-title'>
                      Transportista
                    </Text>
                    <SelectWithAdd
                      name='TransportistaId'
                      control={control}
                      options={carriersOptions}
                      className='filter-select'
                      placeholder='selecciona un transportista'
                      defaultValue={getValues('TransportistaId')}
                      handleAddItem={handleAddCarrier}
                      AddForm={NewCarrierForm}
                      addTitle='Añadir nuevo transportista'
                      editTitle='Editar transportista'
                      editable
                      defaultValues={{
                        TransportistaNombre: null,
                        TransportistaCIF: null,
                        TransportistaVehiculo: null,
                        TransportistaRemolque: null
                      }}
                      disabled={isReadonly}
                    />
                    <Input
                      label='Vehículo'
                      name='TransportistaVehiculo'
                      inputRef={register}
                      placeholder='escribe la matrícula del vehículo'
                      error={
                        errors.TransportistaVehiculo &&
                        errors.TransportistaVehiculo.message
                      }
                      disabled={isReadonly}
                    />
                    <Input
                      label='Remolque'
                      name='TransportistaRemolque'
                      inputRef={register}
                      placeholder='escribe la matrícula del remolque'
                      error={
                        errors.TransportistaRemolque &&
                        errors.TransportistaRemolque.message
                      }
                      disabled={isReadonly}
                    />
                  </ContentBox>
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </form>
      </StyledDeliveryModal>
      {showPrintDialog && (
        <Dialog
          open={showPrintDialog}
          title='Imprimir albarán'
          blue
          handleClose={handleClosePrintDialog}
          hideCloseButton
        >
          <StyledPrintModal>
            <Text>Selecciona el tipo de albarán que quieres imprimir</Text>
            <div className='buttons'>
              <Button secondary outlined onClick={() => printDelivery(false)}>
                Albarán sin valorar
              </Button>
              <Button secondary outlined onClick={() => printDelivery(true)}>
                Albarán valorado
              </Button>
            </div>
          </StyledPrintModal>
        </Dialog>
      )}
    </FullDialog>
  );
}

import React, { useEffect, useState } from 'react';

import StyledNewAddressForm from './NewAddressForm.style';
import {
  Input,
  Select,
  Textarea,
  ProvinceSelector,
  FormCheckbox
} from '../../components';
import { useAuth } from '../../providers/AuthProvider';
import listTypes from '../../utils/listTypes';

export default function NewAddressForm({
  register,
  errors,
  watch,
  getValues,
  control,
  setIsLoading,
  setValue,
  formState
}) {
  const watchCountry = watch('PaisCod');
  const { lists } = useAuth();
  const [provincia, setProvincia] = useState(null);
  const countriesList = lists.filter((item) => item.type === listTypes.pais);
  const [defaultDisabled, setDefaultDisabled] = useState(false);
  useEffect(async () => {
    if (formState.isDirty && watchCountry) {
      setValue('Provincia', null);
      setValue('Region', null);
    }
  }, [watchCountry]);
  useEffect(() => {
    setProvincia(getValues('Provincia'));
    if (getValues('Predeterminada')) {
      setDefaultDisabled(true);
    }
  }, []);
  return (
    <StyledNewAddressForm>
      <Input
        name='Nombre'
        label='Nombre'
        inputRef={register({ required: 'Este campo es obligatorio' })}
        error={errors.Nombre?.message}
      />
      <Input
        name='Email'
        label='Email'
        inputRef={register({
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Formato de correo electrónico incorrecto'
          }
        })}
        error={errors.Email?.message}
      />
      <Input name='Telefono' label='Teléfono' inputRef={register} />
      <Input
        name='DireccionPostal'
        label='Dirección postal'
        inputRef={register}
      />
      <Input name='CP' label='Código Postal' inputRef={register} />
      <Input name='Poblacion' label='Población' inputRef={register} />
      <Select
        label='Pais'
        name='PaisCOD'
        control={control}
        options={countriesList}
        defaultValue={getValues('PaisCOD') || 'ES'}
        searchable
        placeholder='selecciona el pais'
        error={errors.PaisCOD && errors.PaisCOD.message}
      />
      <ProvinceSelector
        provinceName='Provincia'
        regionName='Region'
        control={control}
        provinceDefaultValue={provincia}
        provinceError={errors.Provincia && errors.Provincia.message}
        inputRef={register}
        regionError={errors.Region && errors.Region.message}
        regionPlaceholder='región'
        country={watchCountry}
        setIsLoading={setIsLoading}
      />
      <Textarea
        name='Descripcion'
        label='Instrucciones de entrega'
        inputRef={register}
        height='5.5rem'
      />
      <FormCheckbox
        label='Predeterminada'
        name='Predeterminada'
        control={control}
        disabled={defaultDisabled}
      />
    </StyledNewAddressForm>
  );
}

const columns = [
  {
    id: 'Codigo',
    label: 'Nº Factura',
    width: '50px'
  },
  {
    id: 'Fecha',
    label: 'Fecha',
    width: '50px'
  },
  {
    id: 'Total',
    label: 'Total',
    width: '50px'
  },
  {
    id: 'Albaranes',
    label: 'Albaranes',
    width: '200px'
  },
  {
    id: 'Adjuntos',
    label: 'Fotos',
    width: '200px'
  },
  {
    id: 'user',
    label: 'Creado por',
    width: '50px'
  }
];

export default columns;

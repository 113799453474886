import { parse, format, getMonth, getYear, parseISO } from 'date-fns';

import AuthService from '../services/Auth';
import ListsService from '../services/Lists';
import AgentsService from '../services/Agents';
import AddressesService from '../services/Address';
import PaymentWaysService from '../services/PaymentWays';
import listTypes from './listTypes';
import orderStatusList from './orderStatus';

const authService = new AuthService();
const listService = new ListsService();
const agentsService = new AgentsService();
const addressesService = new AddressesService();
const paymentWaysService = new PaymentWaysService();

const showTaxes = process.env.REACT_APP_SHOW_TAXES;

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getExtension = (filename) => {
  return filename?.split('.').pop();
};

export const isImage = (filename) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  return imageExtensions.includes(getExtension(filename));
};

export const formatSize = (size) => {
  let formatted;
  if (size > 1000000) {
    formatted = `${Math.floor(size / 1000000)}MB`;
  } else if (size > 1000) {
    formatted = `${Math.floor(size / 1000)}KB`;
  } else {
    formatted = `${size}bytes`;
  }
  return formatted;
};

export const handleDownload = (url, isFullPath) =>
  window.open(
    isFullPath
      ? url
      : `${process.env.REACT_APP_API_URL}/umbraco/api/archivos/get?archivo=${url}`
  );

export const getLists = async () => {
  let listsData;
  let agentsData;
  let usersData;
  let countriesData;
  let regionsData;
  let paymentWaysData;
  try {
    listsData = await listService.getAllLists();
    agentsData = await agentsService.getAllAgents();
    usersData = await authService.getUsers();
    countriesData = await addressesService.getCountries();
    regionsData = await addressesService.getRegions('ES');
    paymentWaysData = await paymentWaysService.getAllPayments();
  } catch (error) {
    // throw new Error({ message: 'something wrong happened' });
    console.log(error);
  }
  return [
    ...listsData,
    ...agentsData,
    ...usersData,
    ...countriesData,
    ...paymentWaysData,
    ...regionsData.map((region) => ({ type: listTypes.provincia, ...region }))
  ];
};

export const formatDate = (date) => format(parseISO(date), 'dd/MM/yy');

export const parseQueryDate = (queryDate) =>
  parse(queryDate, 'y-MM-dd', new Date());

export const formatQueryDate = (queryDate) =>
  format(parseQueryDate(queryDate), 'dd/MM/yy');

export const getMonthYear = (queryDate) => {
  const parsedQuery = parseQueryDate(queryDate);
  return {
    month: getMonth(parsedQuery) + 1,
    year: getYear(parsedQuery)
  };
};

export const months = [
  { value: 1, label: 'Enero' },
  { value: 2, label: 'Febrero' },
  { value: 3, label: 'Marzo' },
  { value: 4, label: 'Abril' },
  { value: 5, label: 'Mayo' },
  { value: 6, label: 'Junio' },
  { value: 7, label: 'Julio' },
  { value: 8, label: 'Agosto' },
  { value: 9, label: 'Septiembre' },
  { value: 10, label: 'Octubre' },
  { value: 11, label: 'Noviembre' },
  { value: 12, label: 'Diciembre' }
];

export const getMonthLabel = (month) =>
  months.find((item) => item.value === month)?.label;

export const orderStatusOptions = orderStatusList.map((status, idx) => ({
  value: idx,
  label: status.label,
  color: status.color
}));

export const showPrice = (Precio, IVA, IvaIncluido) => {
  if (showTaxes === '0' && IvaIncluido === true) {
    return Precio / (1 + IVA);
  }
  if (showTaxes === '1' && IvaIncluido === false) {
    return Precio * (1 + IVA);
  }
  return Precio;
};

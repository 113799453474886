import { errorHandler } from './utils';
import instance from './Base';

class Orders {
  getOrders = async (query) => {
    let ordersData;
    try {
      ordersData = await instance.get(`/Umbraco/Api/Pedidos/GetQuery${query}`);
    } catch (error) {
      errorHandler(error);
    }
    return ordersData.data;
  };

  getOrder = async (id) => {
    let orderData;
    try {
      orderData = await instance.get(`/Umbraco/Api/Pedidos/Get/?Id=${id}`);
    } catch (error) {
      errorHandler(error);
    }
    return orderData.data;
  };

  saveOrder = async (data) => {
    let response;
    try {
      response = await instance.post('/Umbraco/Api/Pedidos/Guardar', data);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  deleteOrders = async (orders) => {
    let response;
    try {
      response = await instance.post(`/Umbraco/Api/Pedidos/Borrar`, orders);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  getDeliveryMethods = async () => {
    let methodsData;
    try {
      methodsData = await instance.get('/Umbraco/Api/Pedidos/GetMetodosEnvio');
    } catch (error) {
      errorHandler(error);
    }
    return methodsData.data;
  };

  getOrderLines = async (id) => {
    let response;
    try {
      response = await instance.get(`/Umbraco/Api/Pedidos/GetLineas/?Id=${id}`);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  saveOrderLines = async (id, lines) => {
    let response;
    try {
      response = await instance.post(
        `/Umbraco/Api/Pedidos/GuardarLineas/?IdPedido=${id}`,
        lines
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };
}

export default Orders;

import { errorHandler } from './utils';
import instance from './Base';

class SupplierInvoices {
  getQuery = async (query) => {
    let response;
    try {
      response = await instance.get(
        `/Umbraco/Api/FacturasProveedor/GetQuery${query}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return response?.data;
  };

  getInvoices = async (id) => {
    let response;
    try {
      response = await instance.get(
        `/Umbraco/Api/FacturasProveedor/GetByEmpresa?IdEmpresa=${id}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  getInvoice = async (id) => {
    let response;
    try {
      response = await instance.get(
        `/Umbraco/Api/FacturasProveedor/Get/?id=${id}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  deleteInvoices = async (invoices) => {
    let response;
    try {
      response = await instance.post(
        `/Umbraco/Api/FacturasProveedor/Borrar`,
        invoices
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  saveInvoice = async (invoice) => {
    let response;
    try {
      response = await instance.post(
        '/Umbraco/Api/FacturasProveedor/Guardar',
        invoice
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  createInvoice = async (deliveries) => {
    let response;
    try {
      response = await instance.post(
        '/Umbraco/Api/FacturasProveedor/Crear',
        deliveries
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };
}

export default SupplierInvoices;

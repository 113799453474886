import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';

import StyledSelectWithAdd, {
  StyledDrawerAddItem
} from './SelectWithAdd.style';
import Drawer from '../Drawer/Drawer';
import Text from '../Text/Text';
import Loader from '../Loader';

export default function SelectWithAdd({
  name,
  options,
  control,
  className,
  label,
  placeholder,
  addTitle,
  editTitle,
  defaultValue,
  handleAddItem,
  AddForm,
  editable,
  defaultValues,
  disabled
}) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [selected, setSelected] = useState(defaultValue);
  const [isEdit, setIsEdit] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm();
  const handleOnchange = (onChange, option) => {
    onChange(option.value);
    setSelected(option);
  };

  const handleClickAdd = () => {
    setShowDrawer(true);
  };

  const handleClickEdit = () => {
    const { value, label: selectedLabel, ...formValues } = selected;
    setIsEdit(true);
    reset({ ...formValues });
    setShowDrawer(true);
  };

  const handleCloseDrawer = () => {
    setIsEdit(false);
    reset(defaultValues);
    setShowDrawer(false);
  };

  const onSubmit = async (values) => {
    const itemValues = { ...values };
    if (isEdit) {
      itemValues.Id = selected.value;
    }
    setIsloading(true);
    try {
      const newItem = await handleAddItem(itemValues);
      setSelected({ ...newItem });
      setIsloading(false);
      setShowDrawer(false);
    } catch (err) {
      console.log(err);
      setIsloading(false);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setSelected(options.find((option) => option.value === defaultValue));
    }
  }, [defaultValue]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange }) => (
          <StyledSelectWithAdd className={className}>
            <label>
              {label && (
                <Text as='span' bold>
                  {label}
                </Text>
              )}
              <ReactSelect
                isSearchable={false}
                options={options}
                onChange={(option) => handleOnchange(onChange, option)}
                placeholder={placeholder}
                value={selected}
                isDisabled={disabled}
              />
            </label>
            {!disabled && (
              <div className='buttons'>
                {editable && selected ? (
                  <Text
                    color='blue'
                    bold
                    className='add-item'
                    onClick={handleClickEdit}
                  >
                    + Editar
                  </Text>
                ) : (
                  <span />
                )}
                <Text
                  color='blue'
                  bold
                  className='add-item'
                  onClick={handleClickAdd}
                >
                  + Añadir
                </Text>
              </div>
            )}
          </StyledSelectWithAdd>
        )}
      />
      {showDrawer && (
        <Drawer
          open={showDrawer}
          onClose={handleCloseDrawer}
          title={isEdit ? editTitle : addTitle}
          onAccept={handleSubmit(onSubmit)}
          buttonLabel={isEdit && 'Editar'}
        >
          <StyledDrawerAddItem>
            <Loader show={isLoading} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <AddForm register={register} control={control} errors={errors} />
            </form>
          </StyledDrawerAddItem>
        </Drawer>
      )}
    </>
  );
}

import styled from 'styled-components';

const StyledDeliveries = styled.div`
  position: relative;
  .top-section {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
  }

  .options-buttons {
    p {
      order: 1;
    }

    button:nth-of-type(1) {
      order: 3;
    }
    button:nth-of-type(2) {
      order: 2;
    }
    button:nth-of-type(3) {
      order: 4;
    }
  }

  .table-options .options-buttons .delete-button.generate-invoices-button {
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledPrintModal = styled.div`
  width: 32rem;
  p {
    margin-bottom: 3rem;
  }
  .buttons {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 2rem;
    button {
      width: 11.25rem;
      text-align: center;
    }
  }
`;

export default StyledDeliveries;

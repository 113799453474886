import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { Helmet } from 'react-helmet';

import StyledOrders from './Orders.style';
import { useAuth, useDispatchAuth } from '../../providers/AuthProvider';
import OrdersService from '../../services/Order';
import useQueryString from '../../hooks/useQueryString';
import columns from './columns';
import {
  Loader,
  Button,
  FilterItems,
  ItemsTable,
  DeleteDialog
} from '../../components';
import { DeleteErrorDialog, DatesFilter } from '../../molecules';
import {
  // handleDownload,
  formatQueryDate,
  getMonthYear,
  getMonthLabel
} from '../../utils/functions';
import listTypes from '../../utils/listTypes';
import orderStatusList from '../../utils/orderStatus';
import RowItems from './RowItems/RowItems';
import OrderModal from '../../modals/OrderModal/OrderModal';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';

export default function Orders() {
  const ordersService = new OrdersService();
  const dispatch = useDispatchAuth();
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 30,
    page: 1,
    prev: false,
    next: false
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [filterActive, setFilterActive] = useState(null);
  const [reload, setReload] = useState(true);
  const [responseErrors, setResponseErrors] = useState([]);
  const { lists } = useAuth();
  const usersList = lists.filter((item) => item.type === listTypes.usuario);
  const { query, updateQuery } = useQueryString();
  const dispatchSnackbar = useDispatchSnackbar();
  const { order } = query || {};

  const handleRouterUpdate = (value) => {
    if (!isLoading) {
      updateQuery(value);
    }
  };

  const handleEditOrder = (id) => {
    handleRouterUpdate({ order: id, tab: 'articulos' });
  };

  const handleNewOrderButton = () => {
    handleRouterUpdate({ order: 'new' });
  };

  const handleClickDelete = () => setConfirmDelete(true);

  const handleFilterActive = (filterQuery) => {
    const { FechaIni, FechaFin, TipoFiltro, Estado } = filterQuery;
    let hasFilter = false;
    const filters = [];
    if (FechaIni && FechaFin) {
      if (TipoFiltro === 'range') {
        hasFilter = true;
        filters.push(
          `${formatQueryDate(FechaIni)} a ${formatQueryDate(FechaFin)}`
        );
      }
      if (TipoFiltro === 'month') {
        hasFilter = true;
        const { month, year } = getMonthYear(FechaIni);
        filters.push(`${getMonthLabel(month)} ${year}`);
      }
    }
    if (typeof Estado === 'string') {
      hasFilter = true;
      filters.push(`Estado: ${orderStatusList[Estado].label}`);
    }
    if (hasFilter) {
      setFilterActive(filters.join(' | '));
    }
  };

  const handleCloseDialog = () => setConfirmDelete(false);

  const handleDeleteOrders = async () => {
    setIsLoading(true);
    setConfirmDelete(false);
    try {
      const response = await ordersService.deleteOrders(selected);
      const ErrorsList = response.$values.filter((item) => !!item.Error);
      if (ErrorsList.length) {
        setResponseErrors(ErrorsList);
      } else {
        dispatchSnackbar({
          type: 'SET',
          payload: 'pedidos eliminados'
        });
      }
      handleRouterUpdate();
      setIsLoading(false);
      setSelected([]);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  /* const handleExportCSV = async () => {
    setIsLoading(true);
    try {
      const response = await companiesService.csv({
        OrdenCampo,
        OrdenAscendente,
        Busqueda,
        TipoRelacion: 1
      });
      console.log(response);
      handleDownload(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }; */

  useEffect(async () => {
    if (!order && reload && query instanceof Object) {
      setIsLoading(true);
      try {
        const ordersData = await ordersService.getOrders(
          qs.stringify({ ...query }, { addQueryPrefix: true })
        );
        const {
          Total: total,
          PaginaActual: page,
          Paginacion: limit,
          TieneAnterior: prev,
          TieneSiguiente: next,
          Datos: list
        } = ordersData;
        const { $values: ordersList } = list;
        setPagination({ total, page, limit, prev, next });
        setOrders(
          ordersList.map((orderItem) => ({
            Id: orderItem.Id,
            Fecha: orderItem.FechaCreacion,
            Cliente: orderItem.Cliente,
            Descripcion: orderItem.Descripcion,
            Total: orderItem.Total,
            Estado: orderItem.Estado,
            Origen: orderItem.Origen,
            user:
              usersList.find((user) => user.id === orderItem?.IdUsuarioCreacion)
                ?.name || 'Importado'
          }))
        );
        handleFilterActive(query);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else if (!reload) {
      setReload(true);
    }
    return function cleanup() {
      setOrders([]);
    };
  }, [query]);

  useEffect(() => {
    dispatch({
      type: 'SET_PAGE',
      payload: 'pedidos'
    });
  }, []);
  return (
    <StyledOrders filterActive={filterActive}>
      <Helmet>
        <title>Pedidos | Pascual Vinuesa</title>
      </Helmet>
      <Loader show={isLoading} />
      <div className='top-section'>
        <FilterItems isLoading={isLoading} />
        {/* <Button outlined onClick={handleExportCSV}>
          Exportar Excel
        </Button> */}
        <Button onClick={handleNewOrderButton}>Crear pedido</Button>
      </div>
      <ItemsTable
        handleClickDelete={handleClickDelete}
        FilterForm={DatesFilter}
        filterLabel='Filtrar pedidos'
        filterActive={filterActive}
        setFilterActive={setFilterActive}
        filterTypes={{ orderStatus: true }}
        title='Pedidos'
        columns={columns}
        items={orders}
        isLoading={isLoading}
        handleClickRow={handleEditOrder}
        RowItems={RowItems}
        pagination={pagination}
        selected={selected}
        setSelected={setSelected}
      />
      {order && <OrderModal setReload={setReload} />}
      <DeleteDialog
        open={confirmDelete}
        close={handleCloseDialog}
        handleAccept={handleDeleteOrders}
        singular='pedido'
        plural='pedidos'
        quantity={selected.length}
      />
      <DeleteErrorDialog
        errors={responseErrors}
        handleClose={() => setResponseErrors([])}
      />
    </StyledOrders>
  );
}

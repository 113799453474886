import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import { CurrencyDisplay, ClickableRowItem } from '../../../../components';

export default function RowItems({ item, rowHandlers }) {
  const {
    SKU,
    Nombre,
    IdAlbaran,
    Cantidad,
    TipoPrecio,
    Precio,
    DescuentoPorcentaje,
    BeneficioPorcentaje
  } = item;

  const { handleOpenDelivery } = rowHandlers;

  const handleClickDelivery = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleOpenDelivery(IdAlbaran);
  };

  const calculateTotal = () => Precio * Cantidad;

  return (
    <>
      <TableCell className='cell-body'>{SKU}</TableCell>
      <TableCell className='cell-body'>{Nombre}</TableCell>
      <TableCell className='cell-body'>
        <ClickableRowItem onClick={(e) => handleClickDelivery(e)}>
          {IdAlbaran}
        </ClickableRowItem>
      </TableCell>
      <TableCell className='cell-body'>{Cantidad}</TableCell>
      <TableCell className='cell-body'>{TipoPrecio}</TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={Precio} />
      </TableCell>
      <TableCell className='cell-body'>{DescuentoPorcentaje}%</TableCell>
      <TableCell className='cell-body'>{BeneficioPorcentaje}%</TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={calculateTotal()} />
      </TableCell>
    </>
  );
}

import styled from 'styled-components';
import { rgba } from 'polished';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const StyledDialog = styled(Dialog)`
  margin: 0 3.5%;
  .MuiDialog-paperScrollPaper {
    max-height: 100%;
  }
  .MuiDialog-paper {
    height: 100%;
    border-radius: 0;
    width: 100%;
    margin: 0;
  }
  @media (max-width: 1024px) {
    margin: 0 2%;
  }
`;

const StyledTitle = styled(DialogTitle)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme, orange }) =>
    orange ? theme.colors.orange : theme.colors.blue};
  h2 {
    font-family: 'Oswald', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      svg {
        margin-left: 1rem;
        margin-right: 1rem;
        stroke: ${({ theme }) => theme.colors.white};
      }
    }
    .close {
      cursor: pointer;
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const StyledContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 0;
    background-color: ${({ theme, gray }) =>
      gray ? rgba(theme.colors.lightGray, 0.6) : theme.colors.white};
  }
  .cell-body {
    height: 55px;
  }
  .options-buttons p {
    font-weight: 400;
    margin-bottom: 1rem;
  }
`;

export { StyledDialog, StyledTitle, StyledContent };

import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';

import StyledSelect, { selectStyles } from './Select.style';
import Text from '../Text/Text';

export default function Select({
  label,
  name,
  control,
  defaultValue = null,
  options,
  className,
  disabled,
  horizontal,
  placeholder,
  searchable = false,
  hidden
}) {
  const [selected, setSelected] = useState(
    options.find((option) => option.value === defaultValue)
  );

  const handleOnchange = (onChange, option) => {
    onChange(option.value);
    setSelected(option);
  };

  useEffect(() => {
    if (
      options.length > 0 &&
      defaultValue !== undefined &&
      defaultValue !== null
    ) {
      setSelected(options.find((option) => option.value === defaultValue));
    }
  }, [options, defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      options={options}
      disabled={disabled}
      defaultValue={defaultValue}
      render={({ onChange }) => (
        <StyledSelect
          className={className}
          hidden={hidden}
          horizontal={horizontal}
        >
          {label && (
            <Text as='span' bold>
              {label}
            </Text>
          )}
          <ReactSelect
            defaultValue={options.find(
              (option) => option.value === defaultValue
            )}
            isSearchable={searchable}
            options={options}
            isDisabled={disabled}
            onChange={(option) => handleOnchange(onChange, option)}
            placeholder={placeholder}
            value={selected}
            styles={selectStyles}
            menuPortalTarget={document.body}
          />
        </StyledSelect>
      )}
    />
  );
}

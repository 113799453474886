const tabs = [{ label: 'detalle' }];

const defaultValues = {
  IdProveedor: null,
  IdMarca: null,
  IdFabricante: null,
  SKU: null,
  CodigoBarras: null,
  Inventario: null,
  TopeInventario: null,
  NombreCompleto: null,
  NombreCambiado: false,
  Precio: null,
  TipoPrecio: null,
  DescuentoProveedor: null
};

export { tabs, defaultValues };

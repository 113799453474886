import { errorHandler } from './utils';
import instance from './Base';

class Carriers {
  getAll = async () => {
    let response;
    try {
      response = await instance.get(
        '/Umbraco/Api/Transportistas/GetAll?borrados=false'
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  addItem = async (carrier) => {
    let response;
    try {
      response = await instance.post(
        '/Umbraco/Api/Transportistas/Guardar',
        carrier
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };
}

export default Carriers;

import styled from 'styled-components';

const StyledInvoiceModal = styled.div`
  position: relative;
  .top-section {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .user-date {
      display: flex;
      align-items: center;
      .date-input {
        width: auto;
        margin-bottom: 0;
        margin-left: 2rem;
      }
      .status-selector {
        width: 15rem;
        margin-bottom: 0;
        margin-left: 3rem;
      }
    }
    .modal-buttons {
      display: flex;
      .print-button {
        display: flex;
        align-items: center;
        margin-right: 2rem;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.itemsOptionsText};
        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }
  .tabs {
    .tab-list {
      background-color: ${({ theme }) => theme.colors.lightGray};
      .tab {
        display: inline-block;
        text-transform: uppercase;
        padding: 1.5rem 3rem;
        font-weight: 400;
        cursor: pointer;
        &.selected-tab {
          background-color: ${({ theme }) => theme.colors.gray};
          color: ${({ theme }) => theme.colors.white};
          cursor: default;
        }
        &.disabled-tab {
          opacity: 0.3;
          pointer-events: none;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .tab-columns {
      display: flex;
      justify-content: space-between;
      & > div {
        width: 48%;
      }
    }
    .tabs-content {
      margin: 2.5rem 3rem 1.5rem;
      @media (max-width: 1024px) {
        margin: 2.5rem 1rem 1.5rem;
      }
    }
  }
`;

export default StyledInvoiceModal;

import React, { useState, useEffect } from 'react';

import {
  Text,
  ItemsTable,
  CurrencyDisplay,
  DeleteDialog
} from '../../../components';
import { FreeTextForm } from '../../../molecules';
import StyledArticlesList from './ArticlesList.style';
import ArticlesModal from '../../ArticlesModal/ArticlesModal';
import { ReactComponent as AddIcon } from '../../../resources/svg/ico-mas-blanco.svg';
import { ReactComponent as OrderIcon } from '../../../resources/svg/ico-albaranes.svg';
import columns from './columns';
import RowItems from './RowItems/RowItems';
import DeliveriesService from '../../../services/Deliveries';
import { useDispatchSnackbar } from '../../../providers/SnackbarProvider';

export default function ArticlesList({
  articles,
  setArticles,
  isNew,
  saveArticles,
  getLines,
  getDeliveries,
  order,
  handleDeliveryModalState
}) {
  const [showArticlesModal, setShowArticlesModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [total, setTotal] = useState(0);
  const [newLineId, setNewLineId] = useState(null);

  const handleClearNewLineId = () => {
    setNewLineId(null);
  };

  const deliveriesService = new DeliveriesService();
  const dispatchSnackbar = useDispatchSnackbar();

  const handleAddFreeText = (newLine) => {
    setNewLineId(newLine.Id);
    setArticles([...articles, { ...newLine }]);
  };

  const handleAddArticleButton = () => {
    setShowArticlesModal(true);
  };

  const handleCloseArticlesModal = () => {
    setShowArticlesModal(false);
  };

  const handleAddArticles = (items) => {
    setArticles([...articles, ...items]);
  };

  const handleAddSku = (article) => {
    setArticles([...articles, article]);
  };

  const checkHasDelivery = () => {
    let hasDelivery = false;
    articles
      .filter((article) => selected.includes(article.Id))
      .every((article) => {
        if (article.IdAlbaran) {
          hasDelivery = true;
          return false;
        }
        return true;
      });
    return hasDelivery;
  };

  const handleClickDelete = () => {
    if (checkHasDelivery()) {
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'No puedes borrar artículos albaranados'
      });
      return;
    }
    setConfirmDelete(true);
  };

  const handleCloseDialog = () => setConfirmDelete(false);

  const handleDeleteArticles = () => {
    setSelected([]);
    setConfirmDelete(false);
    setArticles(articles.filter((article) => !selected.includes(article.Id)));
  };

  const handleRowChange = (updated, idx) => {
    const updatedArticles = [...articles];
    updatedArticles[idx] = updated;
    setArticles(updatedArticles);
  };

  const rowHandlers = {
    handleOpenDelivery: (id) => handleDeliveryModalState({ delivery: id }),
    newLineId,
    handleClearNewLineId
  };

  const handleAddDelivery = async () => {
    setIsLoading(true);
    if (checkHasDelivery()) {
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Hay artículos que ya pertenecen a un albarán'
      });
      setIsLoading(false);
      return;
    }
    try {
      const response = await saveArticles(order, articles);
      const selectedArticles = response.$values.filter((article) =>
        selected.includes(article.UUID)
      );
      await deliveriesService.createDelivery(order, selectedArticles);
      await getLines(order);
      await getDeliveries(order);
      setSelected([]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  const extraButtons = [
    {
      label: 'Crear albarán',
      icon: <OrderIcon />,
      onClick: handleAddDelivery
    }
  ];

  const getSubTotal = () => {
    return articles.reduce((acc, article) => {
      const {
        Precio,
        Cantidad = 0,
        DescuentoPorcentaje = 0,
        IvaIncluido,
        IVA
      } = article;
      if (IvaIncluido) {
        return (
          acc +
          (Precio / (1 + IVA)) * Cantidad * (1 - DescuentoPorcentaje / 100)
        );
      }
      return acc + Precio * Cantidad * (1 - DescuentoPorcentaje / 100);
    }, 0);
  };

  const getTaxes = () => {
    return articles.reduce((acc, article) => {
      const {
        Precio,
        Cantidad = 0,
        DescuentoPorcentaje = 0,
        IVA,
        IvaIncluido
      } = article;
      if (IvaIncluido) {
        return (
          acc +
          (Precio - Precio / (1 + IVA)) *
            Cantidad *
            (1 - DescuentoPorcentaje / 100)
        );
      }
      return acc + Precio * IVA * Cantidad * (1 - DescuentoPorcentaje / 100);
    }, 0);
  };

  const getTotal = () => {
    return articles.reduce((acc, article) => {
      const {
        Precio,
        Cantidad = 0,
        DescuentoPorcentaje = 0,
        IVA,
        IvaIncluido
      } = article;
      if (IvaIncluido) {
        return acc + Precio * Cantidad * (1 - DescuentoPorcentaje / 100);
      }
      return (
        acc + Precio * (1 + IVA) * Cantidad * (1 - DescuentoPorcentaje / 100)
      );
    }, 0);
  };

  useEffect(() => {
    setSubTotal(getSubTotal());
    setTaxes(getTaxes());
    setTotal(getTotal());
  }, [articles]);

  return (
    <>
      <StyledArticlesList>
        <ItemsTable
          handleClickDelete={handleClickDelete}
          title='Artículos'
          columns={columns}
          items={articles}
          handleRowChange={handleRowChange}
          isLoading={isLoading}
          RowItems={RowItems}
          selected={selected}
          setSelected={setSelected}
          AddIcon={AddIcon}
          addLabel='Añadir artículo'
          handleClickAdd={handleAddArticleButton}
          extraButtons={isNew ? [] : extraButtons}
          rowHandlers={rowHandlers}
        />
        <FreeTextForm
          handleAddFreeText={handleAddFreeText}
          handleAddSku={handleAddSku}
        />
        <div className='totals'>
          <Text uppercase>
            subtotal: <CurrencyDisplay number={subTotal} />
          </Text>
          <Text uppercase>
            iva: <CurrencyDisplay number={taxes} />
          </Text>
          <Text uppercase className='total'>
            total: <CurrencyDisplay number={total} />
          </Text>
        </div>
      </StyledArticlesList>
      <DeleteDialog
        open={confirmDelete}
        close={handleCloseDialog}
        handleAccept={handleDeleteArticles}
        quantity={selected.length}
        singular='artículo'
        plural='artículos'
      />
      {showArticlesModal && (
        <ArticlesModal
          open={showArticlesModal}
          handleClose={handleCloseArticlesModal}
          handleAddArticles={handleAddArticles}
        />
      )}
    </>
  );
}

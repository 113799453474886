import styled, { css } from 'styled-components';
import { rgba } from 'polished';

const notSelectableCell = css`
  .MuiTableCell-sizeSmall {
    padding: 16px 16px 16px 12px;
  }
`;

const StyledItemsTable = styled.div`
  ${({ selectable }) => !selectable && notSelectableCell}
  .table-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 3rem;
    .options-buttons {
      display: flex;
      align-items: center;
      .table-title {
        font-size: 18px;
      }
      .add-button {
        margin-left: 2rem;
        display: flex;
        align-items: center;
        svg {
          fill: ${({ theme }) => theme.colors.white};
          margin-right: 0.5rem;
          height: 21px;
        }
      }
      .delete-button {
        background-color: ${({ theme }) => rgba(theme.colors.orange, 0.2)};
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        border-radius: 2rem;
        cursor: pointer;
        margin-left: 2rem;
        transition: background-color 0.3s;
        &:hover {
          background-color: ${({ theme }) => rgba(theme.colors.orange, 0.5)};
        }
        svg {
          margin-right: 0.5rem;
          height: 14px;
          width: 12px;
        }
      }
    }
    .options-filter-pagination {
      display: flex;
      align-items: center;
      .filter-button {
        cursor: pointer;
        padding: 0.5rem 4rem 0.5rem 1.5rem;
        border-radius: 3rem;
        background-color: ${({ theme, filterActive }) =>
          filterActive ? rgba(theme.colors.orange, 0.2) : 'transparent'};
        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }
  .MuiTableBody-root {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
    tr:last-child {
      .MuiTableCell-root {
        border-bottom: none;
      }
    }
  }
`;

export default StyledItemsTable;

import React from 'react';

import StyledNewContactForm from './NewContactForm.style';
import { Input } from '../../components';

export default function NewContactForm({ register, errors }) {
  return (
    <StyledNewContactForm>
      <Input
        name='Email'
        label='Email'
        inputRef={register({
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Formato de correo electrónico incorrecto'
          }
        })}
        error={errors.Email?.message}
      />
      <Input
        name='Nombre'
        label='Nombre'
        inputRef={register({ required: 'Este campo es obligatorio' })}
        error={errors.Nombre?.message}
      />
      <Input name='Apellidos' label='Apellidos' inputRef={register} />
      <Input name='Telefono' label='Teléfono' inputRef={register} />
    </StyledNewContactForm>
  );
}

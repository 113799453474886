import React, { useState, useRef } from 'react';
import qs from 'qs';
import { useForm } from 'react-hook-form';

import StyledCompaniesTypeAhead, {
  StyledUserForm
} from './CompaniesTypeAhead.style';
import TypeAhead from '../TypeAhead/TypeAhead';
import Text from '../Text/Text';
import CompaniesService from '../../services/Company';
import Drawer from '../Drawer/Drawer';
import Input from '../Input/Input';
import Radio from '../Radio/Radio';
import Select from '../Select/Select';
import FormRow from '../FormRow/FormRow';
import Loader from '../Loader';
import ProvinceSelector from '../ProvinceSelector/ProvinceSelector';
import companyType from '../../utils/companyType';
import relationType from '../../utils/relationType';
import listTypes from '../../utils/listTypes';
import { useAuth } from '../../providers/AuthProvider';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';

const defaultValues = {
  PaisCOD: 'ES',
  Provincia: '09',
  Tipo: companyType.company
};

// eslint-disable-next-line prettier/prettier
export default function CompaniesTypeAhead({
  setSelectedClient,
  error,
  selectedClient,
  type = relationType.client,
  hideAdd,
  disabled
}) {
  const typeAheadRef = useRef(null);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);
  const [showForm, setShowform] = useState(false);
  const { lists } = useAuth();
  const countriesList = lists.filter((item) => item.type === listTypes.pais);

  const {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    watch,
    control
  } = useForm({
    defaultValues,
    shouldUnregister: false
  });

  const watchType = watch('Tipo');
  const watchCountry = watch('PaisCOD');
  const companiesService = new CompaniesService();
  const dispatchSnackbar = useDispatchSnackbar();

  const handleClientsSearch = async (searchQuery) => {
    setIsLoadingClients(true);
    try {
      const clientsData = await companiesService.getCompanies(
        qs.stringify(
          { TipoRelacion: type, Busqueda: searchQuery },
          { addQueryPrefix: true }
        )
      );
      setClientOptions(clientsData.Datos.$values);
      setIsLoadingClients(false);
    } catch (e) {
      console.log(e);
      setIsLoadingClients(false);
    }
  };

  const handleClientSelect = (option) => {
    if (!option.length) {
      setSelectedClient(null);
    } else {
      setSelectedClient(option[0]);
    }
  };

  const handleOpenDrawer = () => {
    setShowform(true);
  };

  const handleCloseDrawer = () => {
    reset(defaultValues);
    setShowform(false);
  };

  const onSubmit = async (values) => {
    const newClient = { ...values, TipoRelacion: type };
    if (values.Tipo === companyType.company) {
      delete newClient.Telefono;
    } else if (values.Tipo === companyType.private) {
      delete newClient.Razon;
    }
    setIsLoading(true);
    try {
      const response = await companiesService.addCompany(newClient);
      setSelectedClient(response);
      dispatchSnackbar({
        type: 'SET',
        payload: 'Se ha creado el cliente'
      });
      setIsLoading(false);
      setShowform(false);
    } catch (e) {
      console.log(e);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
      setShowform(false);
    }
  };
  return (
    <StyledCompaniesTypeAhead>
      <TypeAhead
        typeRef={typeAheadRef}
        id='client-search'
        isLoading={isLoadingClients}
        options={clientOptions}
        onSearch={handleClientsSearch}
        onSelect={handleClientSelect}
        labelKey={({ CIF, Nombre }) =>
          `${CIF || ''}${CIF && Nombre ? ' - ' : ''}${Nombre || ''}`
        }
        placeholder={
          type === relationType.client
            ? 'Buscar empresa o particular'
            : 'Buscar proveedor'
        }
        error={error}
        selected={selectedClient ? [selectedClient] : []}
        disabled={disabled}
      />
      {!hideAdd && !disabled && (
        <Text
          color='blue'
          bold
          className='add-client'
          onClick={handleOpenDrawer}
        >
          {type === relationType.client
            ? '+ Nuevo cliente'
            : '+ Nuevo proveedor '}
        </Text>
      )}
      <Drawer
        open={showForm}
        onClose={handleCloseDrawer}
        title={
          type === relationType.client ? 'Nuevo cliente' : 'Nuevo proveedor '
        }
        onAccept={handleSubmit(onSubmit)}
      >
        <StyledUserForm onSubmit={handleSubmit(onSubmit)}>
          <Loader show={isLoading} />
          {type === relationType.client && (
            <div className='radio-buttons'>
              <Radio
                name='Tipo'
                label='Empresa'
                inputRef={register}
                value={companyType.company}
                checked
              />
              <Radio
                name='Tipo'
                label='Particular'
                inputRef={register}
                value={companyType.private}
              />
            </div>
          )}
          <div>
            <Input
              name='Nombre'
              label={
                watchType === companyType.company
                  ? 'Nombre de la empresa'
                  : 'Nombre'
              }
              inputRef={register({
                required: 'Este campo es obligatorio'
              })}
              error={errors.Nombre && errors.Nombre.message}
            />
          </div>
          <FormRow className='layout-4-6'>
            <Input
              name='CIF'
              label={watchType === companyType.company ? 'CIF' : 'DNI'}
              inputRef={register}
            />
            <div hidden={watchType === companyType.private} className='second'>
              <Input label='Razon social' name='Razon' inputRef={register} />
            </div>

            <div hidden={watchType === companyType.company} className='second'>
              <Input label='Teléfono' name='Telefono' inputRef={register} />
            </div>
          </FormRow>
          <div>
            <Input label='Direccion' name='Direccion' inputRef={register} />
          </div>
          <FormRow className='layout-7-3'>
            <Input label='Localidad' name='Poblacion' inputRef={register} />
            <Input label='Código Postal' name='CP' inputRef={register} />
          </FormRow>
          <FormRow className='layout-1-1'>
            <ProvinceSelector
              provinceName='Provincia'
              regionName='Region'
              control={control}
              provinceDefaultValue={getValues('Provincia')}
              provinceError={errors.Provincia && errors.Provincia.message}
              inputRef={register}
              regionError={errors.Region && errors.Region.message}
              country={watchCountry}
            />
            <Select
              label='Pais'
              name='PaisCOD'
              control={control}
              options={countriesList}
              defaultValue={getValues('PaisCOD')}
              searchable
              placeholder='selecciona el pais'
              error={errors.PaisCOD && errors.PaisCOD.message}
            />
          </FormRow>
        </StyledUserForm>
      </Drawer>
    </StyledCompaniesTypeAhead>
  );
}

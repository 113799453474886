import React, { useState } from 'react';

import StyledFilterList, { StyledFilterPopover } from './FilterList.style';
import Text from '../Text/Text';
import { ReactComponent as FilterIco } from '../../resources/svg/ico-filtro.svg';

export default function FilterList({
  FilterForm,
  handleFilter,
  filterData,
  filterTypes,
  filterOptions,
  label,
  filterActive,
  setFilterActive
}) {
  const [filterPopover, setFilterPopover] = useState(null);
  const handleFilterPopover = (event) => setFilterPopover(event.currentTarget);
  const handleCloseFilter = () => {
    setFilterPopover(null);
  };
  return (
    <StyledFilterList>
      <button
        className='filter-button'
        type='button'
        onClick={handleFilterPopover}
      >
        <FilterIco /> {label}{' '}
        <Text as='span' bold>
          {filterActive}
        </Text>
      </button>
      <StyledFilterPopover
        open={!!filterPopover}
        onClose={handleCloseFilter}
        anchorEl={filterPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <FilterForm
          handleCloseFilter={handleCloseFilter}
          setFilterActive={setFilterActive}
          filterTypes={filterTypes}
          filterOptions={filterOptions}
          handleFilter={handleFilter}
          filterData={filterData}
        />
      </StyledFilterPopover>
    </StyledFilterList>
  );
}

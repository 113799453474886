import styled from 'styled-components';
import TablePagination from '@material-ui/core/TablePagination';

const StyledPagination = styled(TablePagination)`
  .MuiTablePagination-toolbar p:first-of-type {
    display: ${({ hideSelect }) => (hideSelect ? 'none' : 'flex')};
  }
  .MuiTablePagination-caption {
    font-family: 'Oswald';
    font-size: ${({ theme }) => theme.fonts.s};
    color: ${({ theme }) => theme.colors.gray};
  }
  .MuiTablePagination-selectRoot {
    display: ${({ hideSelect }) => (hideSelect ? 'none' : 'flex')};
    margin-right: 6.5rem;
    font-family: 'Oswald';
    font-size: ${({ theme }) => theme.fonts.s};
  }
`;

export default StyledPagination;

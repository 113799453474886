import React, { useState } from 'react';

import StyledResultsModal from './ResultsModal.style';
import { FullModal, ItemsTable } from '../../../components';
import { ReactComponent as FacturasIco } from '../../../resources/svg/ico-facturas.svg';
import columns from './columns';
import RowItems from './RowItems/RowItems';
import InvoicesService from '../../../services/Invoices';
import { handleDownload } from '../../../utils/functions';
import InvoiceModal from '../../InvoiceModal/InvoiceModal';

export default function ResultsModal({ open, handleClose, results }) {
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceModalState, setInvoiceModalState] = useState(null);

  const invoicesService = new InvoicesService();

  const handleInvoiceModalState = (newState) => setInvoiceModalState(newState);

  const handlePrintInvoices = async () => {
    setIsLoading(true);
    try {
      const response = await invoicesService.printInvoices(selected);
      handleDownload(response);
      setSelected([]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  /* const handleSendInvoices = () => {
    console.log(selected);
  }; */

  const extraButtons = [
    {
      label: 'Imprimir',
      onClick: handlePrintInvoices
    }
    /* ,
    {
      label: 'Enviar por Email',
      onClick: handleSendInvoices
    } */
  ];

  const rowHandlers = {
    handleOpenInvoice: (id) => {
      handleInvoiceModalState({ invoice: id });
    }
  };

  return (
    <FullModal
      open={open}
      handleClose={handleClose}
      title='Asistente de facturación'
      Icon={<FacturasIco />}
    >
      <StyledResultsModal>
        <ItemsTable
          title='Facturas Generadas'
          columns={columns}
          items={results}
          isLoading={isLoading}
          selected={selected}
          setSelected={setSelected}
          RowItems={RowItems}
          extraButtons={extraButtons}
          rowHandlers={rowHandlers}
        />
      </StyledResultsModal>
      {invoiceModalState && (
        <InvoiceModal
          modalState={invoiceModalState}
          setModalState={handleInvoiceModalState}
        />
      )}
    </FullModal>
  );
}

const columns = [
  {
    id: 'Id',
    label: 'Id',
    width: '15px'
  },
  {
    id: 'Fecha',
    label: 'Fecha',
    width: '30px'
  },
  {
    id: 'Cliente',
    label: 'Cliente',
    width: '100px'
  },
  {
    id: 'IdPedido',
    label: 'Pedido',
    width: '15px'
  },
  {
    id: 'Estado',
    label: 'Estado',
    width: '30px'
  },
  {
    id: 'FacturaCOD',
    label: 'Factura',
    width: '30px'
  },
  {
    id: 'Abonado',
    label: 'Abonado',
    width: '30px'
  },
  {
    id: 'Pendiente',
    label: 'Pendiente',
    width: '30px'
  },
  {
    id: 'Total',
    label: 'Total',
    width: '30px'
  },
  {
    id: 'user',
    label: 'Creado por',
    width: '30px'
  }
];

export default columns;

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import styled from 'styled-components';

export const TypeaheadContainer = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
    pointer-events: none;
    display: ${({ disabled }) => (disabled ? 'none' : 'block')};
  }
  .error-message {
    position: absolute;
    bottom: -1.1rem;
  }
`;

const StyledTypeahead = styled(AsyncTypeahead)`
  .form-control {
    display: block;
    width: 100%;
    height: 2.375rem;
    padding: 0.375rem 0.75rem;
    ${({ disabled }) => !disabled && 'padding-left: 3rem;'}
    font-weight: 300;
    color: ${({ theme }) => theme.colors.blue};
    line-height: 1.5;
    border: 2px solid
      ${({ theme, disabled, error }) =>
        // eslint-disable-next-line no-nested-ternary
        disabled
          ? theme.colors.gray
          : error
          ? theme.colors.red
          : theme.colors.blue};
    border-radius: 5px;
    &:focus {
      outline: none;
    }
    &[aria-owns] {
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }

  .rbt-input-hint {
    font-weight: 300;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 1rem 0;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid ${({ theme }) => theme.colors.blue};
    border-top: none;
    border-radius: 0 0 0.5rem 0.5rem;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 2rem;
    clear: both;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.blue};
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGray};
    }
  }

  .rbt-highlight-text {
    background-color: inherit;
    color: inherit;
    font-weight: 400;
    padding: 0;
  }

  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
  }

  .close {
    float: right;
    font-size: 3rem;
    font-weight: 300;
    line-height: 1;
    color: ${({ theme }) => theme.colors.blue};
    text-shadow: 0 1px 0 #fff;
    opacity: 0.6;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0;
  }

  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    -webkit-clip-path: none;
    clip-path: none;
  }

  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner-border {
    display: inline-block;
    color: ${({ theme }) => theme.colors.blue};
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
  }

  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }
`;

export default StyledTypeahead;

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

import { ReactComponent as AlbaranesIco } from '../../resources/svg/ico-albaranes.svg';
import { ReactComponent as ClientesIco } from '../../resources/svg/ico-clientes.svg';
import { ReactComponent as FacturasIco } from '../../resources/svg/ico-facturas.svg';
// import { ReactComponent as InicioIco } from '../../resources/svg/ico-inicio.svg';
import { ReactComponent as PedidosIco } from '../../resources/svg/ico-pedidos.svg';
// import { ReactComponent as PresupuestosIco } from '../../resources/svg/ico-presupuestos.svg';
import { ReactComponent as ProveedoresIco } from '../../resources/svg/ico-proveedores.svg';
import { ReactComponent as ArticulosIco } from '../../resources/svg/ico-articulos.svg';
import AppService from '../../services/App';
import StyledNav from './StyledNav';
import { Text } from '../../components';

const routes = [
  /* {
    path: '/',
    label: 'Inicio',
    icon: <InicioIco />
  }, */
  {
    path: '/pedidos',
    label: 'Pedidos',
    icon: <PedidosIco />
  },
  /* {
    path: '/presupestos',
    label: 'Presupuestos',
    icon: <PresupuestosIco />,
    disabled: true
  }, */
  {
    path: '/albaranes',
    label: 'Albaranes',
    icon: <AlbaranesIco />
  },
  {
    path: '/facturas',
    label: 'Facturas',
    icon: <FacturasIco />
  },
  {
    path: '/clientes',
    label: 'Clientes',
    icon: <ClientesIco />
  },
  {
    path: '/proveedores',
    label: 'Proveedores',
    icon: <ProveedoresIco />
  },
  {
    path: '/articulos',
    label: 'Artículos',
    icon: <ArticulosIco />
  }
];

export default function Nav({ collapsed }) {
  const appService = new AppService();
  const [appInfo, setAppInfo] = useState(null);
  const today = format(new Date(), "EEEE d 'de' MMMM", { locale: es });

  useEffect(async () => {
    try {
      const appData = await appService.get();
      setAppInfo({
        Version: appData.Version,
        Fecha: format(parseISO(appData.Fecha), "d 'de' MMMM", { locale: es })
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <StyledNav collapsed={collapsed}>
      <div className='date'>
        {!collapsed && <p>{today.charAt(0).toUpperCase() + today.slice(1)}</p>}
      </div>
      <ul>
        {routes.map((route) => (
          <NavLink
            to={route.path}
            activeClassName='selected'
            className={`nav-item ${route.disabled && 'disabled'}`}
            key={route.label}
            onClick={(e) => route.disabled && e.preventDefault()}
            exact
          >
            <li>
              {route.icon}
              {!collapsed && route.label}
            </li>
          </NavLink>
        ))}
      </ul>
      {!collapsed && appInfo && (
        <div className='app-info'>
          <Text uppercase color='white' bold>
            V {appInfo.Version}
          </Text>
          <Text color='white'>Actualizado el {appInfo.Fecha}</Text>
        </div>
      )}
    </StyledNav>
  );
}

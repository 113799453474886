import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const StyledArticlesModal = styled.div`
  margin: 2rem 2.5rem;
  position: relative;
  .top-section {
    margin-bottom: 1.5rem;
  }
  @media (max-width: 1024px) {
    margin: 2rem 1rem;
  }
`;

const StyledDialog = styled(Dialog)`
  margin: 0 3.5%;
  .MuiDialog-paperScrollPaper {
    max-height: 100%;
  }
  .MuiDialog-paper {
    height: 100%;
    border-radius: 0;
    width: 100%;
    margin: 0;
  }
  @media (max-width: 1024px) {
    margin: 0 2%;
  }
`;

const StyledTitle = styled(DialogTitle)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.orange};
  h2 {
    font-family: 'Oswald', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-icon {
      margin-left: 1rem;
      margin-right: 1rem;
      stroke: ${({ theme }) => theme.colors.white};
    }
    .close {
      cursor: pointer;
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const StyledContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 0;
  }
`;

export { StyledDialog, StyledTitle, StyledContent };

export default StyledArticlesModal;

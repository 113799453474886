import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { Helmet } from 'react-helmet';

import CompaniesService from '../../services/Company';
import RowItems from './RowItems/RowItems';
import StyledSuppliers from './Suppliers.style';
import { useDispatchAuth } from '../../providers/AuthProvider';
import {
  Loader,
  DeleteDialog,
  Button,
  FilterItems,
  ItemsTable
} from '../../components';
import { DeleteErrorDialog, DatesFilter } from '../../molecules';
import SupplierModal from '../../modals/SupplierModal/SupplierModal';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';
import useQueryString from '../../hooks/useQueryString';
import columns from './columns';
import {
  handleDownload,
  formatQueryDate,
  getMonthYear,
  getMonthLabel
} from '../../utils/functions';

export default function Suppliers() {
  const companiesService = new CompaniesService();
  const [suppliers, setSuppliers] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 30,
    page: 1,
    prev: false,
    next: false
  });
  const [reload, setReload] = useState(true);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterActive, setFilterActive] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [responseErrors, setResponseErrors] = useState([]);
  const dispatch = useDispatchAuth();
  const dispatchSnackbar = useDispatchSnackbar();

  const { query, updateQuery } = useQueryString();
  const {
    OrdenCampo = 'FechaCreacion',
    OrdenAscendente = false,
    Busqueda = '',
    supplier
  } = query || {};

  const handleClickDelete = () => setConfirmDelete(true);
  const handleCloseDialog = () => setConfirmDelete(false);

  const handleExportCSV = async () => {
    setIsLoading(true);
    try {
      const response = await companiesService.csv({
        OrdenCampo,
        OrdenAscendente,
        Busqueda,
        TipoRelacion: 1
      });
      console.log(response);
      handleDownload(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleRouterUpdate = (value) => {
    if (!isLoading) {
      updateQuery(value);
    }
  };

  const handleEditSupplier = (id) => {
    handleRouterUpdate({ supplier: id });
  };

  const handleFilterActive = (filterQuery) => {
    const { FechaIni, FechaFin, TipoFiltro } = filterQuery;
    if (FechaIni && FechaFin) {
      if (TipoFiltro === 'range') {
        setFilterActive(
          `${formatQueryDate(FechaIni)} a ${formatQueryDate(FechaFin)}`
        );
      }
      if (TipoFiltro === 'month') {
        const { month, year } = getMonthYear(FechaIni);
        setFilterActive(`${getMonthLabel(month)} ${year}`);
      }
    }
  };

  const handleDeleteSuppliers = async () => {
    setConfirmDelete(false);
    setIsLoading(true);
    try {
      const response = await companiesService.deleteCompanies(selected);
      const ErrorsList = response.$values.filter((item) => !!item.Error);
      if (ErrorsList.length) {
        setResponseErrors(ErrorsList);
      } else {
        dispatchSnackbar({
          type: 'SET',
          payload: 'Proveedores eliminados'
        });
      }
      setSelected([]);
      handleRouterUpdate();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  const handleNewSupplierButton = () => {
    handleRouterUpdate({ supplier: 'new' });
  };

  useEffect(async () => {
    if (!supplier && reload && query instanceof Object) {
      setIsLoading(true);
      const suppliersData = await companiesService.getCompanies(
        qs.stringify({ ...query, TipoRelacion: 1 }, { addQueryPrefix: true })
      );
      console.log(suppliersData);
      const {
        Total: total,
        PaginaActual: page,
        Paginacion: limit,
        TieneAnterior: prev,
        TieneSiguiente: next,
        Datos: list
      } = suppliersData;
      const { $values: suppliersList } = list;
      setPagination({ total, page, limit, prev, next });
      setSuppliers(
        suppliersList.map((supplierItem) => ({
          Id: supplierItem.Id,
          Nombre: supplierItem.Nombre,
          CIF: supplierItem.CIF,
          CodigoPedido: supplierItem.CodigoPedido,
          FechaPedido: supplierItem.FechaPedido,
          TotalPedido: supplierItem.TotalPedido
        }))
      );
      handleFilterActive(query);
      setIsLoading(false);
    } else if (!reload) {
      setReload(true);
    }
    return function cleanup() {
      setSuppliers([]);
    };
  }, [query]);

  useEffect(() => {
    dispatch({
      type: 'SET_PAGE',
      payload: 'proveedores'
    });
  }, []);

  return (
    <StyledSuppliers>
      <Helmet>
        <title>Proveedores | Pascual Vinuesa</title>
      </Helmet>
      <Loader show={isLoading} />
      <div className='top-section'>
        <FilterItems isLoading={isLoading} />
        <Button outlined onClick={handleExportCSV}>
          Exportar Excel
        </Button>
        <Button onClick={handleNewSupplierButton}>Crear proveedor</Button>
      </div>
      <ItemsTable
        handleClickDelete={handleClickDelete}
        FilterForm={DatesFilter}
        filterLabel='Filtrar proveedores'
        filterActive={filterActive}
        setFilterActive={setFilterActive}
        title='Proveedores'
        columns={columns}
        items={suppliers}
        isLoading={isLoading}
        handleClickRow={handleEditSupplier}
        RowItems={RowItems}
        pagination={pagination}
        selected={selected}
        setSelected={setSelected}
      />
      <DeleteDialog
        open={confirmDelete}
        close={handleCloseDialog}
        handleAccept={handleDeleteSuppliers}
        singular='proveedor'
        plural='proveedores'
        quantity={selected.length}
      />
      <DeleteErrorDialog
        errors={responseErrors}
        handleClose={() => setResponseErrors([])}
      />
      {supplier && <SupplierModal setReload={setReload} />}
    </StyledSuppliers>
  );
}

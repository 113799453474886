import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import {
  DateDisplay,
  CurrencyDisplay,
  ClickableRowItem
} from '../../../../components';

export default function RowItems({ item, rowHandlers }) {
  const {
    Fecha,
    Serie,
    Codigo,
    Cliente,
    ClienteCIF,
    TotalBruto,
    TotalIVA,
    Total,
    FormaPagoLabel,
    Id
  } = item;
  const { handleOpenInvoice } = rowHandlers;

  const handleClickInvoice = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleOpenInvoice(Id);
  };

  return (
    <>
      <TableCell className='cell-body'>
        <DateDisplay date={Fecha} />
      </TableCell>
      <TableCell className='cell-body'>{Serie}</TableCell>
      <TableCell className='cell-body'>
        <ClickableRowItem onClick={(e) => handleClickInvoice(e)}>
          {Codigo}
        </ClickableRowItem>
      </TableCell>
      <TableCell className='cell-body'>{Cliente}</TableCell>
      <TableCell className='cell-body'>{ClienteCIF}</TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={TotalBruto} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={TotalIVA} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={Total} />
      </TableCell>
      <TableCell className='cell-body'>{FormaPagoLabel}</TableCell>
    </>
  );
}

import styled from 'styled-components';
import { centered } from '../../styles/utils/mixins';

const StyledRadio = styled.label`
  cursor: pointer;
  ${centered}
  &:not(:last-child) {
    margin-right: 1rem;
  }
  input[type='radio'] {
    display: none;
    &:checked ~ .check:after {
      background-color: ${({ theme, disabled }) =>
        disabled ? theme.colors.gray : theme.colors.orange};
    }
  }
  .check {
    margin-right: 0.5rem;
    display: inline-block;
    height: 0.8125rem;
    width: 0.8125rem;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 50%;
    position: relative;
    ${centered}
    &:after {
      content: '';
      height: 0.5625rem;
      width: 0.5625rem;
      border-radius: 50%;
    }
  }
`;

export default StyledRadio;

import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';

const StyledTopNav = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding-right: 2rem;
  display: flex;
  .hamburguer {
    width: 3.5rem;
    display: grid;
    place-content: center;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: all 0.3s;
    &:hover {
      svg {
        fill: #000000;
      }
    }
  }
  .top-logo {
    padding-bottom: 1rem;
  }
  .content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .breadcrumbs {
      text-transform: uppercase;
      margin-left: 4rem;
      font-weight: 300;
    }
    .right-section {
      display: flex;
      align-items: center;
      button {
        background: transparent;
        display: flex;
        align-items: center;
        border: 1px solid ${({ theme }) => theme.colors.gray};
        border-radius: 0.5rem;
        padding: 0.5rem 1rem;
        color: ${({ theme }) => theme.colors.gray};
        margin-right: 4rem;
        cursor: pointer;
        transition: all 0.3s;
        svg {
          margin-right: 0.5rem;
          transition: all 0.3s;
        }
        &:hover {
          background-color: ${({ theme }) => theme.colors.gray};
          color: ${({ theme }) => theme.colors.white};
          svg {
            fill: ${({ theme }) => theme.colors.white};
          }
        }
      }
      .user-name {
        font-weight: 400;
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 1.125rem;
          display: inline-block;
          color: #ffffff;
          margin-right: 0.5rem;
          background-color: ${({ theme }) => theme.colors.blue};
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`;

export const StyledPopover = styled(Popover)`
  margin-top: 0.5rem;
  .popover-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    .popover-user {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      position: relative;
      &:after {
        content: '';
        border: 1px solid ${({ theme }) => theme.colors.lightGray};
        position: absolute;
        bottom: 0;
        left: -1.5rem;
        right: -1.5rem;
      }
      .letter {
        font-size: 1.125rem;
        display: inline-block;
        color: #ffffff;
        margin-right: 0.5rem;
        background-color: ${({ theme }) => theme.colors.blue};
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .user-data {
        margin-left: 0.5rem;
        .user-name {
          font-weight: 400;
          font-size: 19px;
          margin-bottom: 4px;
        }
      }
    }
    .logout-button {
      text-align: center;
      border-color: ${({ theme }) => theme.colors.gray};
      color: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export default StyledTopNav;

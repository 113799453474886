import React, { useState } from 'react';

import StyledDeliveriesList from './DeliveriesList.style';
import { ItemsTable, DeleteDialog } from '../../../components';
import { DeleteErrorDialog } from '../../../molecules';
import columns from './columns';
import RowItems from './RowItems/RowItems';
import { ReactComponent as AddIcon } from '../../../resources/svg/ico-mas-blanco.svg';
import { ReactComponent as FacturasIco } from '../../../resources/svg/ico-facturas.svg';
import GenerateInvoicesModal from '../../GenerateInvoicesModal/GenerateInvoicesModal';
import DeliveriesService from '../../../services/Deliveries';
import { useDispatchSnackbar } from '../../../providers/SnackbarProvider';

export default function DeliveriesList({
  deliveries,
  getDeliveries,
  order,
  getOrderLines,
  handleDeliveryModalState,
  handleInvoiceModalState
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [responseErrors, setResponseErrors] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openInvoicesAssistant, setOpenInvoicesAssistant] = useState(false);
  const dispatchSnackbar = useDispatchSnackbar();
  const deliveriesService = new DeliveriesService();

  const handleAddDeliveryButton = () => {
    handleDeliveryModalState({ delivery: 'new' });
  };

  const handleOpenDelivery = (id) => {
    handleDeliveryModalState({ delivery: id });
  };

  const handleClickDelete = () => {
    setConfirmDelete(true);
  };

  const handleCloseDialog = () => setConfirmDelete(false);

  const handleDeleteDeliveries = async () => {
    setIsLoading(true);
    setConfirmDelete(false);
    try {
      const response = await deliveriesService.deleteDeliveries(selected);
      const ErrorsList = response.$values.filter((item) => !!item.Error);
      if (ErrorsList.length) {
        setResponseErrors(ErrorsList);
      } else {
        dispatchSnackbar({
          type: 'SET',
          payload: 'albaranes eliminados'
        });
      }
      setSelected([]);
      await getDeliveries(order);
      await getOrderLines(order);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  const checkHasInvoice = () => {
    const selectedDeliveries = selected.map((item) =>
      deliveries.find((element) => element.Id === item)
    );
    return selectedDeliveries.every((item) => !item.FacturaId);
  };

  const checkHasPaymentMethod = () => {
    const selectedDeliveries = selected.map((item) =>
      deliveries.find((element) => element.Id === item)
    );
    return selectedDeliveries.every((item) => !!item.IdFormaPago);
  };

  const handleClickGenerateInvoices = () => {
    if (!checkHasInvoice(selected)) {
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Hay albaranes que ya están facturados'
      });
      setIsLoading(false);
      return;
    }
    if (!checkHasPaymentMethod(selected)) {
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Hay albaranes que no tienen una forma de pago definida'
      });
      setIsLoading(false);
      return;
    }
    setOpenInvoicesAssistant(true);
  };

  const handleCloseModal = () => {
    setOpenInvoicesAssistant(false);
  };

  const extraButtons = [
    {
      label: 'Generar facturas',
      icon: <FacturasIco />,
      onClick: handleClickGenerateInvoices,
      className: 'generate-invoices-button'
    }
  ];
  const rowHandlers = {
    handleOpenInvoice: (id) => handleInvoiceModalState({ invoice: id })
  };

  return (
    <StyledDeliveriesList>
      <ItemsTable
        handleClickDelete={handleClickDelete}
        title='Albaranes'
        columns={columns}
        items={deliveries}
        selected={selected}
        setSelected={setSelected}
        RowItems={RowItems}
        isLoading={isLoading}
        AddIcon={AddIcon}
        addLabel='Añadir albarán'
        handleClickAdd={handleAddDeliveryButton}
        handleClickRow={handleOpenDelivery}
        extraButtons={extraButtons}
        rowHandlers={rowHandlers}
      />
      <DeleteDialog
        open={confirmDelete}
        close={handleCloseDialog}
        handleAccept={handleDeleteDeliveries}
        quantity={selected.length}
        singular='albarán'
        plural='albaranes'
      />
      <DeleteErrorDialog
        errors={responseErrors}
        handleClose={() => setResponseErrors([])}
      />
      {openInvoicesAssistant && (
        <GenerateInvoicesModal
          open={openInvoicesAssistant}
          handleClose={handleCloseModal}
          selectedDeliveries={selected}
          refresh={() => getDeliveries(order)}
        />
      )}
    </StyledDeliveriesList>
  );
}

import styled from 'styled-components';

const StyledEmail = styled.a`
  color: ${({ theme }) => theme.colors.orange};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export default StyledEmail;

import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { StyledDialog, StyledTitle, StyledContent } from './FullModal.style';
import Text from '../Text/Text';

export default function FullModal({
  open,
  handleClose,
  title,
  Icon,
  children,
  orange,
  gray
}) {
  return (
    <StyledDialog open={open} fullWidth maxWidth='xl'>
      <StyledTitle orange={orange}>
        <Text size='m' color='white'>
          {Icon}
          {title}
        </Text>
        <CloseIcon onClick={handleClose} fontSize='large' className='close' />
      </StyledTitle>
      <StyledContent gray={gray}>{children}</StyledContent>
    </StyledDialog>
  );
}

import React, { useState } from 'react';

import { ItemsTable } from '../../../components';
import StyledDeliveriesList from './DeliveriesList.style';
import RowItems from './RowItems/RowItems';
import columns from './columns';
import OrderModal from '../../OrderModal/OrderModal';

export default function DeliveriesList({ list }) {
  const [orderModalState, setOrderModalState] = useState(null);

  const handleOrderModalState = (newState) => setOrderModalState(newState);

  const rowHandlers = {
    handleOpenOrder: (id) => {
      setOrderModalState({ order: id });
    }
  };

  return (
    <StyledDeliveriesList>
      <ItemsTable
        title='Albaranes'
        RowItems={RowItems}
        columns={columns}
        items={list}
        selectable={false}
        rowHandlers={rowHandlers}
      />
      {orderModalState && (
        <OrderModal
          modalState={orderModalState}
          setModalState={handleOrderModalState}
        />
      )}
    </StyledDeliveriesList>
  );
}

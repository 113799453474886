const columns = [
  {
    id: 'SKU',
    label: 'Referencia',
    width: '30px'
  },
  {
    id: 'Nombre',
    label: 'Artículo',
    width: '60px'
  },
  {
    id: 'IdAlbaran',
    label: 'Albarán',
    width: '30px'
  },
  {
    id: 'Cantidad',
    label: 'Cantidad',
    width: '30px'
  },
  {
    id: 'TipoPrecio',
    label: 'Unidad',
    width: '30px'
  },
  {
    id: 'Precio',
    label: 'Precio',
    width: '30px'
  },
  {
    id: 'DescuentoPorcentaje',
    label: 'Dto. %',
    width: '30px'
  },
  {
    id: 'BeneficioPorcentaje',
    label: 'Beneficio %',
    width: '30px'
  },
  {
    id: 'Total',
    label: 'Total',
    width: '30px'
  }
];

export default columns;

import React, { useEffect, useState } from 'react';

import StyledDeliveriesList, { StyledPrintModal } from './DeliveriesList.style';
import columns from './columns';
import RowItems from './RowItems/RowItems';
import {
  Loader,
  FilterItems,
  ItemsTable,
  DeleteDialog,
  Dialog,
  Text,
  Button
} from '../../../components';
import { formatQueryDate, handleDownload } from '../../../utils/functions';
import DeliveriesFilter from '../../../pages/Deliveries/DeliveriesFilter/DeliveriesFilter';
import { DeleteErrorDialog } from '../../../molecules';
import DeliveriesService from '../../../services/Deliveries';
import { useDispatchSnackbar } from '../../../providers/SnackbarProvider';
import deliveryStatus from '../../../utils/deliveryStatus';
import OrderModal from '../../OrderModal/OrderModal';
import DeliveryModal from '../../DeliveryModal/DeliveryModal';
import InvoiceModal from '../../InvoiceModal/InvoiceModal';
import { ReactComponent as FacturasIco } from '../../../resources/svg/ico-facturas.svg';
import GenerateInvoicesModal from '../../GenerateInvoicesModal/GenerateInvoicesModal';

export default function DeliveriesList({
  deliveries,
  getDeliveries,
  isLoading,
  setIsLoading
}) {
  const deliveriesService = new DeliveriesService();
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 30,
    page: 1,
    prev: false,
    next: false
  });
  const [selected, setSelected] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [filterActive, setFilterActive] = useState(null);
  const [reload, setReload] = useState(true);
  const [responseErrors, setResponseErrors] = useState([]);
  const [openInvoicesAssistant, setOpenInvoicesAssistant] = useState(false);
  const [showPrintDialog, setShowPrintDialog] = useState(false);

  const [query, setQuery] = useState({});
  const updateQuery = (value) => {
    setQuery({
      ...query,
      ...value
    });
  };
  const dispatchSnackbar = useDispatchSnackbar();
  const { delivery, order, invoice } = query || {};

  const handleRouterUpdate = (value) => {
    if (!isLoading) {
      updateQuery(value);
    }
  };

  const handleEditDelivery = (id) => {
    handleRouterUpdate({ delivery: id });
  };

  const handleClickDelete = () => setConfirmDelete(true);

  const handleFilterActive = async (filterQuery) => {
    const { FechaIni, FechaFin, Estado } = filterQuery;
    if (FechaIni && FechaFin) {
      setFilterActive(
        `${formatQueryDate(FechaIni)} a ${formatQueryDate(FechaFin)}`
      );
    }
    if (Estado) {
      const statusName = deliveryStatus.find(
        (status) => status.value.toString() === Estado
      );
      setFilterActive(`Estado: ${statusName?.label}`);
    }
  };

  const handleCloseDialog = () => setConfirmDelete(false);

  const handleDeleteDeliveries = async () => {
    setIsLoading(true);
    setConfirmDelete(false);
    try {
      const response = await deliveriesService.deleteDeliveries(selected);
      const ErrorsList = response.$values.filter((item) => !!item.Error);
      if (ErrorsList.length) {
        setResponseErrors(ErrorsList);
      } else {
        dispatchSnackbar({
          type: 'SET',
          payload: 'albaranes eliminados'
        });
      }
      handleRouterUpdate();
      setIsLoading(false);
      setSelected([]);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  const checkHasInvoice = () => {
    const selectedDeliveries = selected.map((item) =>
      deliveries.find((element) => element.Id === item)
    );
    return selectedDeliveries.every((item) => !item.FacturaId);
  };

  const checkHasPaymentMethod = () => {
    const selectedDeliveries = selected.map((item) =>
      deliveries.find((element) => element.Id === item)
    );
    return selectedDeliveries.every((item) => !!item.IdFormaPago);
  };

  const handleCloseModal = () => {
    setOpenInvoicesAssistant(false);
  };

  const handleClickGenerateInvoices = () => {
    if (!checkHasInvoice(selected)) {
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Hay albaranes que ya están facturados'
      });
      setIsLoading(false);
      return;
    }
    if (!checkHasPaymentMethod(selected)) {
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Hay albaranes que no tienen una forma de pago definida'
      });
      setIsLoading(false);
      return;
    }
    setOpenInvoicesAssistant(true);
  };

  const handlePrintDeliveries = () => setShowPrintDialog(true);

  const handleClosePrintDialog = () => setShowPrintDialog(false);

  const printDelivery = async (rated) => {
    setIsLoading(true);
    setShowPrintDialog(false);
    try {
      const response = await deliveriesService.printDeliveries(selected, rated);
      handleDownload(response);
      setSelected([]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  const extraButtons = [
    {
      label: 'Generar facturas',
      icon: <FacturasIco />,
      onClick: handleClickGenerateInvoices,
      className: 'generate-invoices-button'
    },
    {
      label: 'Imprimir',
      onClick: handlePrintDeliveries
    }
  ];

  useEffect(async () => {
    if (!delivery && reload && query instanceof Object) {
      setIsLoading(true);
      try {
        const newPagination = await getDeliveries(query);
        setPagination({ ...newPagination });
        handleFilterActive(query);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else if (!reload) {
      setReload(true);
    }
  }, [query]);

  const handleSearch = (term) => {
    updateQuery({
      PaginaActual: 1,
      Busqueda: term
    });
  };

  const changeRowsPerPage = (rows) => {
    updateQuery({
      PaginaActual: 1,
      Paginacion: rows
    });
  };

  const changePage = (newPage) => {
    updateQuery({
      PaginaActual: newPage
    });
  };

  const sortItems = (sortQuery) => {
    updateQuery(sortQuery);
  };

  const handleFilter = (filterQuery) => {
    updateQuery(filterQuery);
  };

  const handleCloseDeliveryModal = () => {
    updateQuery({ delivery: null });
  };
  const handleCloseOrderModal = () => {
    updateQuery({ order: null });
  };
  const handleCloseInvoiceModal = () => {
    updateQuery({ invoice: null });
  };

  const rowHandlers = {
    handleOpenOrder: (id) => handleRouterUpdate({ order: id }),
    handleOpenInvoice: (id) => handleRouterUpdate({ invoice: id })
  };

  return (
    <StyledDeliveriesList>
      <Loader show={isLoading} />
      <div className='top-section'>
        <FilterItems isLoading={isLoading} handleSearch={handleSearch} />
      </div>
      <ItemsTable
        handleClickDelete={handleClickDelete}
        FilterForm={DeliveriesFilter}
        filterLabel='Filtrar albaranes'
        filterActive={filterActive}
        setFilterActive={setFilterActive}
        filterTypes={{ orderStatus: true }}
        title='Albaranes'
        columns={columns}
        items={deliveries}
        filterOptions={{ deliveryStatus }}
        isLoading={isLoading}
        handleClickRow={handleEditDelivery}
        RowItems={RowItems}
        pagination={pagination}
        selected={selected}
        setSelected={setSelected}
        changeRowsPerPage={changeRowsPerPage}
        changePage={changePage}
        sortItems={sortItems}
        handleFilter={handleFilter}
        filterData={query}
        rowHandlers={rowHandlers}
        extraButtons={extraButtons}
      />
      {delivery && (
        <DeliveryModal
          setReload={setReload}
          modalState={query}
          setModalState={handleCloseDeliveryModal}
        />
      )}
      {order && (
        <OrderModal
          setReload={setReload}
          modalState={query}
          setModalState={handleCloseOrderModal}
        />
      )}
      {invoice && (
        <InvoiceModal
          setReload={setReload}
          modalState={query}
          setModalState={handleCloseInvoiceModal}
        />
      )}
      <DeleteDialog
        open={confirmDelete}
        close={handleCloseDialog}
        handleAccept={handleDeleteDeliveries}
        singular='albaran'
        plural='albaranes'
        quantity={selected.length}
      />
      <DeleteErrorDialog
        errors={responseErrors}
        handleClose={() => setResponseErrors([])}
      />
      {openInvoicesAssistant && (
        <GenerateInvoicesModal
          open={openInvoicesAssistant}
          handleClose={handleCloseModal}
          selectedDeliveries={selected}
          refresh={() => getDeliveries()}
        />
      )}
      {showPrintDialog && (
        <Dialog
          open={showPrintDialog}
          title='Imprimir albarán'
          blue
          handleClose={handleClosePrintDialog}
          hideCloseButton
        >
          <StyledPrintModal>
            <Text>Selecciona el tipo de albarán que quieres imprimir</Text>
            <div className='buttons'>
              <Button secondary outlined onClick={() => printDelivery(false)}>
                Albarán sin valorar
              </Button>
              <Button secondary outlined onClick={() => printDelivery(true)}>
                Albarán valorado
              </Button>
            </div>
          </StyledPrintModal>
        </Dialog>
      )}
    </StyledDeliveriesList>
  );
}

import styled from 'styled-components';

const StyledDownloadItem = styled.button`
  background-color: ${({ theme }) => theme.colors.lightGray};
  margin-left: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
`;

export default StyledDownloadItem;

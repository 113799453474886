const columns = [
  {
    id: 'Serie',
    label: 'Serie',
    width: '15px'
  },
  {
    id: 'Cliente',
    label: 'Cliente',
    width: '60px'
  },
  {
    id: 'FormaPagoLabel',
    label: 'Forma de pago',
    width: '40px'
  },
  {
    id: 'SubTotal',
    label: 'Subtotal',
    width: '40px'
  },
  {
    id: 'IVA',
    label: 'IVA',
    width: '40px'
  },
  {
    id: 'TotalDescuentos',
    label: 'Descuentos',
    width: '40px'
  },
  {
    id: 'Total',
    label: 'total',
    width: '40px'
  },
  {
    id: 'Albaranes',
    label: 'Albaranes',
    width: '40px'
  }
];

export default columns;

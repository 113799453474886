import styled from 'styled-components';

const StyledLoader = styled.div`
  position: ${(props) => (props.fullScreen ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.background || 'rgba(255, 255, 255, 0.6)'};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export default StyledLoader;

import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import StyledFreeTextForm, { StyledTypeahead } from './FreeTextForm.style';
import { Input, Button, CurrencyInput, Loader } from '../../components';
import { ReactComponent as AddIcon } from '../../resources/svg/ico-mas-blanco.svg';
import ArticlesService from '../../services/Article';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';

const schema = yup.object().shape({
  Nombre: yup.string().required('Escribe el texto'),
  Precio: yup.string().required('Escribe el precio')
});

const IVAGeneral = process.env.REACT_APP_IVA_GENERAL;

const defaultValues = {
  Precio: '',
  Nombre: '',
  Cantidad: 1,
  SKU: '',
  DescuentoPorcentaje: 0,
  TipoPrecio: ''
};

export default function FreeTextForm({ handleAddFreeText, handleAddSku }) {
  const articlesService = new ArticlesService();
  const dispatchSnackbar = useDispatchSnackbar();
  const [articles, setArticles] = useState([]);
  const typeAheadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingArticles, setIsLoadingArticles] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    setValue,
    formState: { isSubmitSuccessful }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  const handleSkuSearch = async (searchQuery) => {
    setIsLoadingArticles(true);
    try {
      const response = await articlesService.getBySku(searchQuery);
      const { $values: results } = response;
      setArticles(results);
      setIsLoadingArticles(false);
    } catch (error) {
      console.log(error);
      setIsLoadingArticles(false);
    }
  };

  const handleSkuSelect = async (option) => {
    const UUID = uuidv4();
    const articleToAdd = {
      IdReferencia: option[0].IdReferencia,
      IdPedidoLinea: option[0].IdPedidoLinea,
      IdProducto: option[0].IdProducto,
      Nombre: option[0].Nombre,
      Descripcion: option[0].Descripcion,
      Inventario: option[0].Inventario,
      SKU: option[0].SKU,
      DescuentoPorcentaje: option[0].DescuentoPorcentaje,
      Precio: option[0].PrecioConDescuento,
      PrecioOriginal: option[0].Precio,
      IVA: option[0].IVA,
      IvaIncluido: option[0].IvaIncluido,
      Tipo: 0,
      Cantidad: 1,
      TipoPrecio: option[0].TipoPrecio,
      StockAjustado: option[0].StockAjustado,
      Id: UUID,
      UUID
    };
    handleAddSku(articleToAdd);
    typeAheadRef.current.clear();
  };

  const handleOnChange = (value) => {
    setValue('SKU', value);
  };

  const onSubmit = async (values) => {
    const {
      Precio,
      Nombre,
      Cantidad,
      SKU,
      DescuentoPorcentaje,
      TipoPrecio
    } = values;
    if (SKU) {
      setIsLoading(true);
      try {
        const response = await articlesService.getBySku(SKU);
        const { $values: results } = response;
        if (results.length === 1) {
          dispatchSnackbar({
            type: 'ERROR',
            payload: `La referencia ${SKU} ya existe`
          });
          setIsLoading(false);
          typeAheadRef.current.clear();
          return;
        }
      } catch (error) {
        console.log(error);
        dispatchSnackbar({
          type: 'ERROR',
          payload: 'Ha ocurrido un error'
        });
        setIsLoading(false);
        typeAheadRef.current.clear();
        return;
      }
    }
    const UUID = uuidv4();
    const newLine = {
      Nombre,
      Precio: +Precio,
      IVA: +IVAGeneral,
      IvaIncluido: false,
      Cantidad: Cantidad || 1,
      SKU: SKU || '',
      TipoPrecio: TipoPrecio || '',
      DescuentoPorcentaje: DescuentoPorcentaje || 0,
      Id: UUID,
      isFreeText: true,
      UUID
    };
    handleAddFreeText(newLine);
    setIsLoading(false);
    typeAheadRef.current.clear();
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <StyledFreeTextForm>
      <Loader show={isLoading} />
      <div className='inputs'>
        <input name='SKU' hidden ref={register} />
        <StyledTypeahead
          id='sku-typeahead'
          searchText='Buscando...'
          promptText='Escribe para buscar...'
          emptyLabel='No hay resultados'
          placeholder='Referencia'
          options={articles}
          isLoading={isLoadingArticles}
          ref={typeAheadRef}
          onSearch={handleSkuSearch}
          onChange={handleSkuSelect}
          onInputChange={handleOnChange}
          useCache={false}
          labelKey='SKU'
          filterBy={() => {
            return true;
          }}
        />
        <Input
          inputRef={register}
          name='Nombre'
          placeholder='Texto libre'
          className='name-input'
          error={errors.text && errors.text.message}
        />
        <Input
          inputRef={register}
          name='Cantidad'
          placeholder='Cantidad'
          type='number'
          error={errors.Cantidad && errors.Cantidad.message}
        />
        <Input
          inputRef={register}
          name='TipoPrecio'
          placeholder='Unidad'
          error={errors.TipoPrecio && errors.TipoPrecio.message}
        />
        <CurrencyInput
          placeholder='Precio'
          control={control}
          name='Precio'
          type='number'
          error={errors.Precio && errors.Precio.message}
        />
        <CurrencyInput
          placeholder='Descuento'
          control={control}
          name='DescuentoPorcentaje'
          type='number'
          percentaje
          error={
            errors.DescuentoPorcentaje && errors.DescuentoPorcentaje.message
          }
        />
      </div>
      <Button onClick={handleSubmit(onSubmit)} className='add-line'>
        <AddIcon /> Añadir línea
      </Button>
    </StyledFreeTextForm>
  );
}

import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';

import StyledPagination from './Pagination.style';

export default function Pagination({
  pagination,
  handleChangeRowsPerPage,
  handleChangePage,
  hideSelect
}) {
  const theme = createMuiTheme({}, esES);
  return (
    <ThemeProvider theme={theme}>
      <StyledPagination
        hideSelect={hideSelect}
        component='div'
        rowsPerPageOptions={[5, 10, 30, 100]}
        count={pagination.total}
        rowsPerPage={pagination.limit}
        page={pagination.page - 1}
        labelRowsPerPage='Filas por página:'
        nextIconButtonText='Página anterior'
        backIconButtonText='Página siguiente'
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
      />
    </ThemeProvider>
  );
}

import { errorHandler } from './utils';
import instance from './Base';

class App {
  get = async () => {
    let data;
    try {
      data = await instance.get('/Umbraco/Api/App/Get');
    } catch (error) {
      errorHandler(error);
    }
    return data.data;
  };
}

export default App;

import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import qs from 'qs';

import {
  getAccessToken,
  getRefreshToken,
  setToken,
  clearToken
} from '../utils/localStorage';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: 'application/json',
    'content-type': 'application/json'
  }
});

instance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token != null) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.config &&
      error.config.data &&
      error.config.data.includes('grant_type=refresh_token')
    ) {
      clearToken();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

const refreshAuthLogic = (failedRequest) => {
  console.log('FAIL');
  return instance
    .post(
      '/oauth/token',
      qs.stringify({
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        grant_type: 'refresh_token',
        refresh_token: getRefreshToken()
      }),
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      }
    )
    .then((tokenRefreshResponse) => {
      setToken(tokenRefreshResponse.data);
      // eslint-disable-next-line no-param-reassign
      failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access_token}`;
      return Promise.resolve();
    })
    .catch((error) => {
      console.log(error);
      clearToken();
      window.location.reload();
    });
};
createAuthRefreshInterceptor(instance, refreshAuthLogic);

export default instance;

import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import { CurrencyDisplay } from '../../../components';
import StyledDeliveriesCell from './RowItems.style';

export default function RowItems({ item, rowHandlers, idx }) {
  const {
    Serie,
    Cliente,
    FormaPagoLabel,
    SubTotal,
    IVA,
    Total,
    Albaranes,
    TotalDescuentos
  } = item;
  const { openDeliveries } = rowHandlers;

  return (
    <>
      <TableCell className='cell-body'>{Serie}</TableCell>
      <TableCell className='cell-body'>{Cliente}</TableCell>
      <TableCell className='cell-body'>{FormaPagoLabel}</TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={SubTotal} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={IVA} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={TotalDescuentos} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={Total} />
      </TableCell>
      <StyledDeliveriesCell
        className='cell-body'
        onClick={() => openDeliveries(idx)}
      >{`(${Albaranes?.$values?.length}) Ver albaranes`}</StyledDeliveriesCell>
    </>
  );
}

import styled from 'styled-components';

const StyledArticlesList = styled.div`
  .totals {
    margin-top: 4rem;
    display: flex;
    border-top: 1px solid ${({ theme }) => theme.colors.border};
    margin-left: -3rem;
    margin-right: -3rem;
    padding: 0.5rem 3rem;
    justify-content: flex-end;
    & > p {
      margin-left: 4rem;
    }
    .total span {
      font-weight: 400;
    }
  }
`;

export default StyledArticlesList;

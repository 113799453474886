import { errorHandler } from './utils';
import instance from './Base';
import listTypes from '../utils/listTypes';

class Addresses {
  getCountries = async () => {
    let countriesData;
    try {
      countriesData = await instance.get('/Umbraco/Api/Direcciones/GetPaises');
    } catch (error) {
      errorHandler(error);
    }
    return countriesData.data.$values.map((value) => ({
      type: listTypes.pais,
      label: value.Nombre,
      value: value.COD
    }));
  };

  getRegions = async (countryCode) => {
    let regionsData;
    try {
      regionsData = await instance.get(
        `/Umbraco/Api/Direcciones/GetRegiones?codPais=${countryCode}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return regionsData.data.$values.map((value) => ({
      label: value.Nombre,
      value: value.COD
    }));
  };

  getByCompany = async (companyId) => {
    let contactsData;
    try {
      contactsData = await instance.get(
        `/Umbraco/Api/Direcciones/GetByEmpresa?IdEmpresa=${companyId}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return contactsData.data.$values;
  };

  addItem = async (contact) => {
    let response;
    try {
      response = await instance.post(
        '/Umbraco/Api/Direcciones/Guardar',
        contact
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  removeItems = async (ids) => {
    let response;
    try {
      response = await instance.post('/Umbraco/Api/Direcciones/Borrar', ids);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };
}

export default Addresses;

import React from 'react';

import StyledContentBox from './ContentBox.style';
import Text from '../Text/Text';

export default function ContentBox({ title, children, thin, className }) {
  return (
    <StyledContentBox thin={thin} className={className}>
      <Text bold as='h4'>
        {title}
      </Text>
      <div className='box'>{children}</div>
    </StyledContentBox>
  );
}

import styled from 'styled-components';
import MUICheckbox from '@material-ui/core/Checkbox';

const StyledFormCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-left: -9px;
  label {
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  }
`;

export const Checkbox = styled(MUICheckbox)`
  &.Mui-checked {
    color: ${({ theme }) => theme.colors.orange};
  }
`;

export default StyledFormCheckbox;

import styled from 'styled-components';

const StyledPaymentItem = styled.div`
  .predeterminada {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    svg.predeterminada-icon {
      margin-right: 0.5rem;
    }
  }
`;

export default StyledPaymentItem;

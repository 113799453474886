const columns = [
  {
    id: 'Codigo',
    label: 'Nº',
    width: '30px'
  },
  {
    id: 'Fecha',
    label: 'Fecha',
    width: '30px'
  },
  {
    id: 'Cliente',
    label: 'Cliente',
    width: '100px'
  },
  {
    id: 'Serie',
    label: 'Serie',
    width: '15px'
  },
  {
    id: 'Estado',
    label: 'Estado',
    width: '30px'
  },
  {
    id: 'Envio',
    label: 'Envío',
    width: '30px'
  },
  {
    id: 'payment',
    label: 'Pago',
    width: '100px'
  },
  {
    id: 'Total',
    label: 'Total',
    width: '30px'
  },
  {
    id: 'user',
    label: 'Creador',
    width: '30px'
  }
];

export default columns;

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import { format } from 'date-fns';

import {
  Text,
  Select,
  Button,
  Datepicker,
  FormCheckbox
} from '../../../components';
import StyledInvoicesFilter from './InvoicesFilter.style';
import { parseQueryDate } from '../../../utils/functions';

const defaultValues = {
  Estado: undefined,
  FechaFin: undefined,
  FechaIni: undefined,
  Serie: undefined,
  TipoEnvio: undefined,
  serieCheck: null,
  status: null,
  range: null,
  delivery: null
};

export default function InvoicesFilter({
  handleCloseFilter,
  setFilterActive,
  filterOptions,
  handleFilter,
  filterData
}) {
  const { handleSubmit, control, watch, reset, getValues } = useForm({
    defaultValues,
    shouldUnregister: false
  });
  const [filterError, setFilterError] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const watchSerie = watch('serieCheck');
  const watchStatus = watch('status');
  const watchRange = watch('range');
  const watchDelivery = watch('delivery');
  const { invoiceDelivery, invoiceSeries, invoiceStatus } = filterOptions;

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });

  const updateQuery = (value) => {
    history.push({
      path: history.location.pathname,
      search: qs.stringify(
        {
          ...query,
          ...value
        },
        { addQueryPrefix: true }
      )
    });
  };

  useEffect(async () => {
    if (filterData || query) {
      const { Estado, FechaIni, FechaFin, Serie, TipoEnvio } =
        filterData || query;
      const filterValues = {};
      if (Estado) {
        filterValues.Estado = +Estado;
        filterValues.status = true;
      }
      if (Serie) {
        filterValues.Serie = Serie;
        filterValues.serieCheck = true;
      }
      if (TipoEnvio) {
        filterValues.TipoEnvio = +TipoEnvio;
        filterValues.delivery = true;
      }
      if (FechaFin && FechaFin) {
        const parsedStart = parseQueryDate(FechaIni);
        const parsedEnd = parseQueryDate(FechaFin);
        filterValues.FechaIni = parsedStart;
        filterValues.FechaFin = parsedEnd;
        filterValues.range = true;
      }
      reset(filterValues);
    }
  }, []);

  const onSubmit = (values) => {
    const filterQuery = {
      Estado: undefined,
      FechaFin: undefined,
      FechaIni: undefined,
      Serie: undefined,
      TipoEnvio: undefined
    };
    if (!watchDelivery && !watchRange && !watchSerie && !watchStatus) {
      setFilterError('Selecciona una opción');
      return;
    }
    if (watchStatus) {
      if (values.Estado === undefined || values.Estado === null) {
        setFilterError('Selecciona un estado');
        return;
      }
      filterQuery.Estado = values.Estado;
    }
    if (watchSerie) {
      if (values.Serie === undefined || values.Serie === null) {
        setFilterError('Selecciona una serie');
        return;
      }
      filterQuery.Serie = values.Serie;
    }
    if (watchDelivery) {
      if (values.TipoEnvio === undefined || values.TipoEnvio === null) {
        setFilterError('Selecciona un tipo de envío');
        return;
      }
      filterQuery.TipoEnvio = values.TipoEnvio;
    }
    if (watchRange) {
      if (!values.FechaFin || !values.FechaFin) {
        setFilterError('Selecciona las dos fechas');
        return;
      }
      const fromDate = format(values.FechaIni, 'yyyy-MM-dd');
      const toDate = format(values.FechaFin, 'yyyy-MM-dd');
      filterQuery.FechaFin = toDate;
      filterQuery.FechaIni = fromDate;
    }
    if (handleFilter) {
      handleFilter(filterQuery);
    } else {
      updateQuery(filterQuery);
    }
    setFilterError(null);
    handleCloseFilter();
  };

  const handleClearFilters = () => {
    reset();
    if (handleFilter) {
      handleFilter({
        Estado: undefined,
        FechaFin: undefined,
        FechaIni: undefined,
        Serie: undefined,
        TipoEnvio: undefined
      });
    } else {
      updateQuery({
        Estado: undefined,
        FechaFin: undefined,
        FechaIni: undefined,
        Serie: undefined,
        TipoEnvio: undefined
      });
    }
    handleCloseFilter();
    setFilterActive(null);
  };

  return (
    <StyledInvoicesFilter>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text className='clear-filters' onClick={handleClearFilters}>
          Borrar filtros
        </Text>
        <div className='filter-item'>
          <FormCheckbox
            label='Serie'
            name='serieCheck'
            control={control}
            className='filter-checkbox'
          />
          <Select
            name='Serie'
            control={control}
            options={invoiceSeries}
            className='filter-select'
            disabled={!watchSerie}
            placeholder='Selecciona serie'
            defaultValue={getValues('Serie')}
            searchable
          />
        </div>
        <div className='filter-item'>
          <FormCheckbox
            label='Estado'
            name='status'
            control={control}
            className='filter-checkbox'
          />
          <Select
            name='Estado'
            control={control}
            options={invoiceStatus}
            className='filter-select'
            disabled={!watchStatus}
            placeholder='selecciona estado'
            defaultValue={getValues('Estado')}
            searchable
          />
        </div>
        <div className='filter-item'>
          <FormCheckbox
            label='Desde/hasta'
            name='range'
            control={control}
            className='filter-checkbox'
          />
          <Datepicker
            name='FechaIni'
            control={control}
            disabled={!watchRange}
            placeholder='Desde...'
            className='date-picker'
          />
          <Datepicker
            name='FechaFin'
            control={control}
            placeholder='Hasta...'
            disabled={!watchRange}
            className='date-picker'
          />
        </div>
        <div className='filter-item'>
          <FormCheckbox
            label='Tipo de envío'
            name='delivery'
            control={control}
            className='filter-checkbox'
          />
          <Select
            name='TipoEnvio'
            control={control}
            options={invoiceDelivery}
            className='filter-select'
            disabled={!watchDelivery}
            placeholder='selecciona tio de envío'
            defaultValue={getValues('TipoEnvio')}
            searchable
          />
        </div>
        <div className='filters-search'>
          {filterError && <Text color='red'>{filterError}</Text>}
          <Button type='button' onClick={handleSubmit(onSubmit)}>
            Buscar
          </Button>
        </div>
      </form>
    </StyledInvoicesFilter>
  );
}

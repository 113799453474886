const columns = [
  {
    id: 'Codigo',
    label: 'Nº Albarán',
    width: '50px'
  },
  {
    id: 'Fecha',
    label: 'Fecha',
    width: '50px'
  },
  {
    id: 'Total',
    label: 'Total',
    width: '50px'
  },
  {
    id: 'IdFactura',
    label: 'Factura',
    width: '50px'
  },
  {
    id: 'user',
    label: 'Creado por',
    width: '50px'
  }
];

export default columns;

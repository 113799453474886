import { errorHandler } from './utils';
import instance from './Base';

class Articles {
  getArticles = async (query) => {
    let articlesData;
    try {
      articlesData = await instance.get(
        `/Umbraco/Api/Productos/GetQuery${query}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return articlesData.data;
  };

  getBySku = async (sku) => {
    let response;
    try {
      response = await instance.get(
        `/Umbraco/Api/Productos/GetBySKU/?sku=${sku}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  getArticle = async (id) => {
    let articleData;
    try {
      articleData = await instance.get(`/Umbraco/Api/Productos/Get/?Id=${id}`);
    } catch (error) {
      errorHandler(error);
    }
    return articleData.data;
  };

  getCategories = async () => {
    let categoriesData;
    try {
      categoriesData = await instance.get(
        '/Umbraco/Api/Productos/GetCategorias'
      );
    } catch (error) {
      errorHandler(error);
    }
    return categoriesData.data;
  };

  getManufacturers = async () => {
    let manufacturersData;
    try {
      manufacturersData = await instance.get(
        '/Umbraco/Api/Productos/GetFabricantes'
      );
    } catch (error) {
      errorHandler(error);
    }
    return manufacturersData.data;
  };

  getBrands = async () => {
    let brandsData;
    try {
      brandsData = await instance.get('/Umbraco/Api/Productos/GetMarcas');
    } catch (error) {
      errorHandler(error);
    }
    return brandsData.data;
  };

  getStock = async (query) => {
    let response;
    try {
      response = await instance.get(
        `/Umbraco/Api/Productos/GetEntradasSalidas${query}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  saveArticle = async (data) => {
    let response;
    try {
      response = await instance.post('/Umbraco/Api/Productos/Guardar', data);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  deleteArticles = async (articles) => {
    let response;
    try {
      response = await instance.post(`/Umbraco/Api/Productos/Borrar`, articles);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  csv = async (query) => {
    let response;
    try {
      response = await instance.post('/Umbraco/Api/Productos/CSV', query);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  post = async (file, supplier) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    const formData = new FormData();
    formData.append('files', file);
    let response;
    try {
      response = await instance.post(
        `/Umbraco/Api/Productos/ImportarCSV${
          supplier ? `?idProveedor=${supplier}` : ''
        }`,
        formData,
        config
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };
}

export default Articles;

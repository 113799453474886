import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

import Button from '../Button/Button';
import {
  StyledDialog,
  StyledTitle,
  StyledContentText,
  StyledActions
} from './Dialog.style';

export default function Dialog({
  open,
  children,
  title,
  handleClose,
  closeLabel,
  handleAccept,
  acceptLabel,
  blue,
  disableClickOutside,
  hideCloseButton
}) {
  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      disableBackdropClick={disableClickOutside}
    >
      <StyledTitle blue={blue}>
        {title}
        <CloseIcon onClick={handleClose} className='close' />
      </StyledTitle>
      <DialogContent>
        <StyledContentText>{children}</StyledContentText>
      </DialogContent>
      {(!hideCloseButton || handleAccept) && (
        <StyledActions>
          {!hideCloseButton && (
            <Button outlined secondary={blue} onClick={handleClose}>
              {closeLabel || 'Cancelar'}
            </Button>
          )}
          {handleAccept && (
            <Button onClick={handleAccept} color='primary' secondary={blue}>
              {acceptLabel || 'Confirmar'}
            </Button>
          )}
        </StyledActions>
      )}
    </StyledDialog>
  );
}

import styled from 'styled-components';

const StyledLastCartOrder = styled.span`
  display: flex;
  align-items: center;
  svg {
    width: 1rem;
    fill: ${({ theme }) => theme.colors.text};
  }
  span {
    margin-left: 0.25rem;
  }
  .code {
    font-weight: 400;
  }
`;

export default StyledLastCartOrder;

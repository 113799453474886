const columns = [
  {
    id: 'Fecha',
    label: 'Fecha',
    width: '50px'
  },
  {
    id: 'Empresa',
    label: 'Empresa',
    width: '50px'
  },
  {
    id: 'Entrada',
    label: 'Entrada',
    width: '50px'
  },
  {
    id: 'Salida',
    label: 'Salida',
    width: '50px'
  },
  {
    id: 'DescuentoPorcentaje',
    label: 'Descuento',
    width: '50px'
  },
  {
    id: 'Cantidad',
    label: 'Cantidad',
    width: '50px'
  },
  {
    id: 'TipoPrecio',
    label: 'Unidad',
    width: '50px'
  },
  {
    id: 'Total',
    label: 'Total',
    width: '50px'
  },
  {
    id: 'Inventario',
    label: 'Inventario',
    width: '50px'
  }
];

export default columns;

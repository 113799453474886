import React, { useEffect, useState } from 'react';

import StyledOrdersList from './OrdersList.style';
import OrdersService from '../../../services/Order';
import columns from './columns';
import {
  Loader,
  Button,
  FilterItems,
  ItemsTable,
  DeleteDialog
} from '../../../components';
import { DeleteErrorDialog, DatesFilter } from '../../../molecules';
import {
  handleDownload,
  formatQueryDate,
  getMonthYear,
  getMonthLabel
} from '../../../utils/functions';
import orderStatusList from '../../../utils/orderStatus';
import RowItems from './RowItems/RowItems';
import OrderModal from '../../OrderModal/OrderModal';
import { useDispatchSnackbar } from '../../../providers/SnackbarProvider';

export default function Orders({
  orders,
  getOrders,
  isLoading,
  setIsLoading,
  clientInfo,
  addressesList
}) {
  const ordersService = new OrdersService();
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 30,
    page: 1,
    prev: false,
    next: false
  });
  const [selected, setSelected] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [filterActive, setFilterActive] = useState(null);
  const [reload, setReload] = useState(true);
  const [responseErrors, setResponseErrors] = useState([]);
  const [query, setQuery] = useState({});
  const updateQuery = (value) => {
    setQuery({
      ...query,
      ...value
    });
  };
  const dispatchSnackbar = useDispatchSnackbar();
  const { order } = query || {};

  const handleRouterUpdate = (value) => {
    if (!isLoading) {
      updateQuery(value);
    }
  };

  const handleEditOrder = (id) => {
    handleRouterUpdate({ order: id, tab: 'articulos' });
  };

  const handleNewOrderButton = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleRouterUpdate({ order: 'new' });
  };

  const handleClickDelete = () => setConfirmDelete(true);

  const handleFilterActive = (filterQuery) => {
    const { FechaIni, FechaFin, TipoFiltro, Estado } = filterQuery;
    if (FechaIni && FechaFin) {
      if (TipoFiltro === 'range') {
        setFilterActive(
          `${formatQueryDate(FechaIni)} a ${formatQueryDate(FechaFin)}`
        );
      }
      if (TipoFiltro === 'month') {
        const { month, year } = getMonthYear(FechaIni);
        setFilterActive(`${getMonthLabel(month)} ${year}`);
      }
    }
    if (TipoFiltro === 'orderStatus') {
      setFilterActive(`Estado: ${orderStatusList[Estado].label}`);
    }
  };

  const handleCloseDialog = () => setConfirmDelete(false);

  const handleDeleteOrders = async () => {
    setIsLoading(true);
    setConfirmDelete(false);
    try {
      const response = await ordersService.deleteOrders(selected);
      const ErrorsList = response.$values.filter((item) => !!item.Error);
      if (ErrorsList.length) {
        setResponseErrors(ErrorsList);
      } else {
        dispatchSnackbar({
          type: 'SET',
          payload: 'pedidos eliminados'
        });
      }
      handleRouterUpdate();
      setIsLoading(false);
      setSelected([]);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  const handleExportCSV = async () => {
    /* setIsLoading(true);
    try {
      const response = await companiesService.csv({
        OrdenCampo,
        OrdenAscendente,
        Busqueda,
        TipoRelacion: 1
      });
      console.log(response);
      handleDownload(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } */
  };

  useEffect(async () => {
    if (!order && reload && query instanceof Object) {
      setIsLoading(true);
      try {
        const newPagination = await getOrders(query);
        setPagination({ ...newPagination });
        handleFilterActive(query);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else if (!reload) {
      setReload(true);
    }
  }, [query]);

  const handleSearch = (term) => {
    updateQuery({
      PaginaActual: 1,
      Busqueda: term
    });
  };

  const changeRowsPerPage = (rows) => {
    updateQuery({
      PaginaActual: 1,
      Paginacion: rows
    });
  };

  const changePage = (newPage) => {
    updateQuery({
      PaginaActual: newPage
    });
  };

  const sortItems = (sortQuery) => {
    updateQuery(sortQuery);
  };

  const handleFilter = (filterQuery) => {
    updateQuery(filterQuery);
  };

  const handleCloseOrderModal = () => {
    updateQuery({ order: null });
  };

  return (
    <StyledOrdersList filterActive={filterActive}>
      <Loader show={isLoading} />
      <div className='top-section'>
        <FilterItems isLoading={isLoading} handleSearch={handleSearch} />
        {/* <Button outlined onClick={handleExportCSV}>
          Exportar Excel
        </Button> */}
        <Button onClick={(e) => handleNewOrderButton(e)}>Crear pedido</Button>
      </div>
      <ItemsTable
        handleClickDelete={handleClickDelete}
        FilterForm={DatesFilter}
        filterLabel='Filtrar pedidos'
        filterActive={filterActive}
        setFilterActive={setFilterActive}
        filterTypes={{ orderStatus: true }}
        title='Pedidos'
        columns={columns}
        items={orders}
        isLoading={isLoading}
        handleClickRow={handleEditOrder}
        RowItems={RowItems}
        pagination={pagination}
        selected={selected}
        setSelected={setSelected}
        changeRowsPerPage={changeRowsPerPage}
        changePage={changePage}
        sortItems={sortItems}
        handleFilter={handleFilter}
        filterData={query}
      />
      {order && (
        <OrderModal
          setReload={setReload}
          modalState={query}
          setModalState={handleCloseOrderModal}
          clientInfo={clientInfo}
          addressesList={addressesList}
        />
      )}
      <DeleteDialog
        open={confirmDelete}
        close={handleCloseDialog}
        handleAccept={handleDeleteOrders}
        singular='pedido'
        plural='pedidos'
        quantity={selected.length}
      />
      <DeleteErrorDialog
        errors={responseErrors}
        handleClose={() => setResponseErrors([])}
      />
    </StyledOrdersList>
  );
}

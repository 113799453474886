import { errorHandler } from './utils';
import instance from './Base';

class Invoices {
  getInvoices = async (query) => {
    let response;
    try {
      response = await instance.get(`/Umbraco/Api/Facturas/GetQuery${query}`);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  getInvoice = async (id) => {
    let response;
    try {
      response = await instance.get(`/Umbraco/Api/Facturas/Get/?Id=${id}`);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  getLines = async (id) => {
    let response;
    try {
      response = await instance.get(
        `/Umbraco/Api/Facturas/GetLineas/?IdFactura=${id}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  saveInvoice = async (data) => {
    let response;
    try {
      response = await instance.post('/Umbraco/Api/Facturas/Guardar', data);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  createPreInvoices = async (deliveries) => {
    let response;
    try {
      response = await instance.post(
        '/Umbraco/Api/Facturas/CrearPrefacturas',
        deliveries
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  savePreInvoices = async (preinvoices) => {
    let response;
    try {
      response = await instance.post(
        '/Umbraco/Api/Facturas/GuardarPrefacturas',
        preinvoices
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  printInvoices = async (invoices) => {
    let response;
    try {
      response = await instance.post(
        '/Umbraco/Api/Facturas/Imprimir',
        invoices
      );
    } catch (error) {
      errorHandler(error);
    }

    return response.data;
  };

  deleteInvoices = async (invoices) => {
    let response;
    try {
      response = await instance.post(`/Umbraco/Api/Facturas/Borrar`, invoices);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  sendInvoices = async (invoices, type, date) => {
    let response;
    try {
      response = await instance.post(
        `/Umbraco/Api/Facturas/Enviar?tipo=${type}${
          date ? `&fecha=${date}` : ''
        }`,
        invoices
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };
}

export default Invoices;

import { errorHandler } from './utils';
import instance from './Base';

class PaymentMethods {
  getByCompany = async (companyId) => {
    let contactsData;
    try {
      contactsData = await instance.get(
        `/Umbraco/Api/MetodosPago/GetByEmpresa?IdEmpresa=${companyId}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return contactsData.data.$values;
  };

  addItem = async (item) => {
    let response;
    try {
      response = await instance.post('/Umbraco/Api/MetodosPago/Guardar', item);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  removeItems = async (ids) => {
    let response;
    try {
      response = await instance.post('/Umbraco/Api/MetodosPago/Borrar', ids);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };
}

export default PaymentMethods;

import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import ListService from '../../services/Lists';
import AgentsService from '../../services/Agents';
import PaymentWaysService from '../../services/PaymentWays';

import Drawer from '../Drawer/Drawer';
import Input from '../Input/Input';
import Select from '../Select/Select';
import Text from '../Text/Text';
import Loader from '../Loader';
import StyledSelectFromList, {
  StyledDrawerAddItem
} from './SelectFromList.style';
import { useAuth, useDispatchAuth } from '../../providers/AuthProvider';
import listTypes from '../../utils/listTypes';
import paymentOptions from '../../utils/paymentOptions';

export default function SelectFromList({
  type,
  name,
  defaultValue = null,
  placeholder = 'seleccionar...',
  control,
  label,
  addTitle = 'Añadir nuevo elemento',
  className,
  setValue,
  disabled
}) {
  const { lists } = useAuth();
  const dispatch = useDispatchAuth();
  const [showDrawer, setShowDrawer] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const listService = new ListService();
  const agentService = new AgentsService();
  const paymentWaysService = new PaymentWaysService();
  const isAgent = type === listTypes.representante;
  const isPayment = type === listTypes.formaPago;
  const list = [...lists.filter((item) => item.type === type)];
  const [selected, setSelected] = useState(
    lists.find(
      (option) => option.type === type && option.value === defaultValue
    )
  );
  const handleOnchange = (onChange, option) => {
    onChange(option.value);
    setSelected(option);
  };

  const handleAddItem = () => {
    setShowDrawer(true);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
  };

  useEffect(() => {
    if (defaultValue) {
      setSelected(
        lists.find(
          (option) => option.type === type && option.value === defaultValue
        )
      );
    }
  }, [defaultValue]);

  const onSubmit = async (values) => {
    setIsloading(true);
    try {
      let newItem;
      if (isAgent) {
        const response = await agentService.addAgent({
          Nombre: values.item
          // Email: values.Email
        });
        newItem = {
          type: listTypes.representante,
          value: response.Id,
          label: response.Nombre
        };
      } else if (isPayment) {
        const response = await paymentWaysService.addPaymentWay({
          Nombre: values.item,
          Dias: values.Dias,
          NumPagos: values.NumPagos,
          PlazoEntre: values.PlazoEntre,
          Tipo: values.Tipo
        });
        newItem = {
          type: listTypes.representante,
          value: response.Id,
          label: response.Nombre
        };
      } else {
        const response = await listService.addListItem({
          Nombre: values.item,
          Tipo: type
        });
        newItem = {
          type: listTypes.tipo,
          value: response.Id,
          label: response.Nombre
        };
      }
      dispatch({
        type: 'ADD_ITEM',
        payload: { ...newItem }
      });
      setSelected({ ...newItem });
      setValue(name, newItem.value, { shouldDirty: true });
      setIsloading(false);
      setShowDrawer(false);
    } catch (err) {
      console.log(err);
      setIsloading(false);
    }
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange }) => (
          <StyledSelectFromList className={className}>
            <label>
              {label && (
                <Text as='span' bold>
                  {label}
                </Text>
              )}
              <ReactSelect
                isSearchable={false}
                options={list}
                onChange={(option) => handleOnchange(onChange, option)}
                placeholder={placeholder}
                value={selected}
                isDisabled={disabled}
              />
            </label>
            {!disabled && (
              <Text
                color='blue'
                bold
                className='add-item'
                onClick={handleAddItem}
              >
                + Añadir
              </Text>
            )}
          </StyledSelectFromList>
        )}
      />
      <Drawer
        open={showDrawer}
        onClose={handleCloseDrawer}
        title={addTitle}
        onAccept={handleSubmit(onSubmit)}
      >
        <StyledDrawerAddItem>
          <Loader show={isLoading} />
          <form onSubmit={handleSubmit(onSubmit)}>
            {isPayment && (
              <>
                <Select
                  label='Tipo'
                  options={paymentOptions}
                  name='Tipo'
                  control={control}
                  placeholder='selecciona el tipo'
                />
                <Input
                  label='Número de Pagos'
                  type='number'
                  inputRef={register}
                  name='NumPagos'
                />
                <Input
                  label='Plazo días'
                  type='number'
                  inputRef={register}
                  name='Dias'
                />
                <Input
                  label='Días entre plazos'
                  type='number'
                  inputRef={register}
                  name='PlazoEntre'
                />
              </>
            )}
            <Input
              label='Nombre'
              name='item'
              inputRef={register({ required: 'Este campo es obligatorio' })}
              error={errors.item?.message}
            />

            {/* {isAgent && (
              <Input
                label='Email'
                name='email'
                inputRef={register({
                  required: 'El correo electrónico es obligatorio',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Formato de correo electrónico incorrecto'
                  }
                })}
                error={errors.email?.message}
              />
            )} */}
          </form>
        </StyledDrawerAddItem>
      </Drawer>
    </>
  );
}

import React, { useState } from 'react';

import StyledLineNote, { StyledNoteDialog } from './LineNote.style';
import { Dialog, Button } from '../../components';
import { ReactComponent as NotaIco } from '../../resources/svg/ico-nota.svg';

export default function LineNote({ note, handleChange, isReadonly }) {
  const [showDialog, setShowDialog] = useState(false);
  const [text, setText] = useState('');

  const handleOpenDialog = () => {
    setShowDialog(true);
    setText(note);
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
    setText('');
  };
  const handleTextChange = (e) => {
    const { value } = e.target;
    setText(value);
  };
  const handleSubmit = () => {
    handleChange(text);
    handleCloseDialog();
  };

  return (
    <StyledLineNote empty={!note}>
      <NotaIco onClick={handleOpenDialog} />
      <Dialog
        open={showDialog}
        title={isReadonly ? 'Notas' : 'Añadir notas'}
        blue
        hideCloseButton
        disableClickOutside
        handleClose={handleCloseDialog}
      >
        <StyledNoteDialog>
          <textarea
            placeholder={isReadonly ? '' : 'escribe una nota...'}
            value={text}
            onChange={handleTextChange}
            disabled={isReadonly}
          />
          {!isReadonly && (
            <Button secondary outlined onClick={handleSubmit}>
              Añadir nota
            </Button>
          )}
        </StyledNoteDialog>
      </Dialog>
    </StyledLineNote>
  );
}

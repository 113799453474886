import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import { formatDate } from '../../../utils/functions';
import orderStatus from '../../../utils/orderStatus';
import { CurrencyDisplay } from '../../../components';
import StyledOrigin from './RowItems.style';

export default function RowItems({ item }) {
  const { label, color } = orderStatus[item.Estado];
  return (
    <>
      <TableCell className='cell-body'>{item.Id}</TableCell>
      <TableCell className='cell-body'>{formatDate(item.Fecha)}</TableCell>
      <TableCell className='cell-body'>{item.Cliente || '-'}</TableCell>
      <TableCell className='cell-body'>
        {item.Origen === 0 && <StyledOrigin>web</StyledOrigin>}
        {item.Descripcion || '-'}
      </TableCell>
      <TableCell className='cell-body'>
        <span style={{ color }}>{label}</span>
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={item.Total} />
      </TableCell>
      <TableCell className='cell-body'>{item.user}</TableCell>
    </>
  );
}

import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import StyledItemsTable from './ItemsTable.style';
import { ReactComponent as TrashIco } from '../../resources/svg/ico-basura.svg';
import Text from '../Text/Text';
import FilterList from '../FilterList/FilterList';
import Pagination from '../Pagination/Pagination';
import TableHeader from '../TableHeader/TableHeader';
import SkeletonTable from '../SkeletonTable/SkeletonTable';
import SelectableRow from '../SelectableRow/SelectableRow';
import Button from '../Button/Button';
import useQueryString from '../../hooks/useQueryString';

export default function ItemsTable({
  handleClickDelete,
  deleteLabel,
  FilterForm,
  handleFilter,
  filterData,
  filterActive,
  setFilterActive,
  filterTypes,
  filterOptions,
  title,
  columns,
  items,
  handleRowChange,
  isLoading,
  handleClickRow,
  RowItems,
  pagination,
  changeRowsPerPage,
  changePage,
  filterLabel,
  selected,
  setSelected,
  addLabel,
  AddIcon,
  handleClickAdd,
  extraButtons,
  selectable = true,
  rowHandlers,
  sortItems,
  getQuery
}) {
  const { query, updateQuery } = useQueryString();
  const { OrdenCampo = 'FechaCreacion', OrdenAscendente = false } =
    getQuery || query || {};

  const handleChangeRowsPerPage = (event) => {
    if (changeRowsPerPage) {
      changeRowsPerPage(parseInt(event.target.value, 10));
    } else {
      updateQuery({
        PaginaActual: 1,
        Paginacion: parseInt(event.target.value, 10)
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setSelected([]);
    if (changePage) {
      changePage(newPage + 1);
    } else {
      updateQuery({ PaginaActual: newPage + 1 });
    }
  };

  const handleSort = (id) => {
    const sortQuery = {
      OrdenCampo: id,
      OrdenAscendente:
        OrdenCampo === id &&
        OrdenAscendente !== true &&
        OrdenAscendente !== 'true',
      PaginaActual: 1
    };
    if (sortItems) {
      sortItems(sortQuery);
    } else {
      updateQuery(sortQuery);
    }
  };

  return (
    <StyledItemsTable filterActive={filterActive} selectable={selectable}>
      <div className='table-options'>
        <div className='options-buttons'>
          <Text bold className='table-title'>
            {title}
          </Text>
          {addLabel && handleClickAdd && (
            <Button
              className='add-button'
              type='button'
              onClick={handleClickAdd}
            >
              {AddIcon && <AddIcon />}
              {addLabel}
            </Button>
          )}
          {selected?.length > 0 && handleClickDelete && (
            <button
              className='delete-button'
              type='button'
              onClick={handleClickDelete}
            >
              {deleteLabel || (
                <span>
                  <TrashIco /> Eliminar
                </span>
              )}
            </button>
          )}
          {selected?.length > 0 &&
            extraButtons &&
            extraButtons.map((button) => (
              <button
                className={`delete-button ${
                  button.className ? button.className : ''
                }`}
                type='button'
                onClick={button.onClick}
              >
                <span>
                  {button.icon} {button.label}
                </span>
              </button>
            ))}
        </div>
        <div className='options-filter-pagination'>
          {FilterForm && (
            <FilterList
              FilterForm={FilterForm}
              handleFilter={handleFilter}
              filterData={filterData}
              filterTypes={filterTypes}
              filterOptions={filterOptions}
              label={filterLabel}
              filterActive={filterActive}
              setFilterActive={setFilterActive}
            />
          )}
          {pagination && (
            <Pagination
              pagination={pagination}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              hideSelect
            />
          )}
        </div>
      </div>
      <TableContainer>
        <Table size='small'>
          <TableHeader
            selected={selected}
            setSelected={setSelected}
            items={items}
            columns={columns}
            sortField={OrdenCampo}
            ascending={OrdenAscendente === 'true' || OrdenAscendente === true}
            handleSort={handleSort}
            selectable={selectable}
          />
          <TableBody>
            {isLoading ? (
              <SkeletonTable
                columns={columns?.length}
                rows={pagination?.limit || 5}
              />
            ) : (
              items.map((item, idx) => (
                <SelectableRow
                  key={item.Id}
                  selected={selected}
                  setSelected={setSelected}
                  id={item.Id}
                  handleClickRow={handleClickRow}
                  selectable={selectable}
                >
                  <RowItems
                    item={item}
                    handleRowChange={handleRowChange}
                    idx={idx}
                    rowHandlers={rowHandlers}
                  />
                </SelectableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <Pagination
          pagination={pagination}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      )}
    </StyledItemsTable>
  );
}

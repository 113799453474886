import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';

import { StyledDialog, StyledTitle, StyledContent } from './FullDialog.style';
import Text from '../Text/Text';
import Dialog from '../Dialog/Dialog';

export default function FullDialog({
  open,
  icon,
  name,
  type,
  id,
  isNew,
  handleClose,
  client,
  touched,
  children
}) {
  const [showDialog, setShowDialog] = useState(false);
  const history = useHistory();
  const handleCloseDialog = () => setShowDialog(false);
  const openClient = () => history.push(`/clientes?client=${client.id}`);
  const handleOpenClient = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (touched) {
      setShowDialog(true);
    } else {
      openClient();
    }
  };

  return (
    <StyledDialog open={open} fullWidth maxWidth='xl'>
      <StyledTitle>
        <div>
          {icon}
          {isNew ? (
            <Text size='m'>Crear {type}</Text>
          ) : (
            <Text size='m'>
              {type}: <span className='id'>{id}</span>
              {client && (
                <a
                  href={`/clientes?client=${client.id}`}
                  /* onClick={(e) => handleOpenClient(e)} */
                  target='_blank'
                  rel='noreferrer'
                  className='client-link'
                >
                  {' '}
                  {client.name}
                </a>
              )}{' '}
              {name}
            </Text>
          )}
        </div>
        <CloseIcon onClick={handleClose} fontSize='large' className='close' />
      </StyledTitle>
      <StyledContent>{children}</StyledContent>
      <Dialog
        open={showDialog}
        title='Confirmar acción'
        blue
        handleClose={handleCloseDialog}
        handleAccept={openClient}
      >
        ¿Seguro que quieres abrir este cliente? Se perderán los cambios no
        guardados.
      </Dialog>
    </StyledDialog>
  );
}

import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import { CurrencyDisplay, DateDisplay } from '../../../../components';
import invoiceStatus from '../../../../utils/invoiceStatus';
import StyledInvoiceDelivery from './RowItems.style';
import { ReactComponent as ArrobaIco } from '../../../../resources/svg/ico-arroba.svg';
import { ReactComponent as SobreIco } from '../../../../resources/svg/ico-sobre.svg';

export default function RowItems({ item }) {
  const {
    Codigo,
    Fecha,
    Serie,
    FechaEnviada,
    FechaEnviadaEmail,
    Estado,
    payment,
    Total,
    user
  } = item;

  const getStatus = (status) => {
    return invoiceStatus.find((i) => i.value === status)?.label;
  };

  return (
    <>
      <TableCell className='cell-body'>{Codigo}</TableCell>
      <TableCell className='cell-body'>
        <DateDisplay date={Fecha} />
      </TableCell>
      <TableCell className='cell-body'>{Serie}</TableCell>
      <TableCell className='cell-body'>{getStatus(Estado)}</TableCell>
      <TableCell className='cell-body'>
        <StyledInvoiceDelivery>
          {FechaEnviada && (
            <span>
              <SobreIco />
              <DateDisplay date={FechaEnviada} />
            </span>
          )}
          {FechaEnviadaEmail && (
            <span>
              <ArrobaIco />
              <DateDisplay date={FechaEnviadaEmail} />
            </span>
          )}
        </StyledInvoiceDelivery>
      </TableCell>
      <TableCell className='cell-body'>{payment}</TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={Total} />
      </TableCell>
      <TableCell className='cell-body'>{user}</TableCell>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useForm } from 'react-hook-form';
import qs from 'qs';
import { Helmet } from 'react-helmet';

import StyledInvoiceModal from './InvoiceModal.style';
import {
  FullDialog,
  Button,
  Input,
  FormRow,
  FormCheckbox,
  Textarea,
  SelectFromList,
  ContentBox,
  Loader,
  Select,
  ProvinceSelector,
  Text,
  Datepicker
} from '../../components';
import useQueryString from '../../hooks/useQueryString';
import { useAuth } from '../../providers/AuthProvider';
import listTypes from '../../utils/listTypes';
import { ReactComponent as FacturaIco } from '../../resources/svg/ico-facturas.svg';
import { ReactComponent as PrintIco } from '../../resources/svg/ico-imprimir.svg';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';
import InvoicesService from '../../services/Invoices';
import DeliveriesService from '../../services/Deliveries';
import { tabs, defaultValues } from './constants';
import invoiceStatus from '../../utils/invoiceStatus';
import DeliveriesList from './DeliveriesList/DeliveriesList';
import ArticlesList from './ArticlesList/ArticlesList';
import { handleDownload } from '../../utils/functions';

export default function InvoiceModal({ setReload, modalState, setModalState }) {
  const { query, updateQuery } = useQueryString(modalState);
  const { invoice, invoiceTab } = query || {};
  const [client, setClient] = useState(null);
  const [deliveriesList, setDeliveriesList] = useState([]);
  const [articlesList, setArticlesList] = useState([]);
  const [invoiceCode, setInvoiceCode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userName, setUserName] = useState(null);
  const { lists } = useAuth();
  const dispatchSnackbar = useDispatchSnackbar();
  const invoicesService = new InvoicesService();
  const deliveriesService = new DeliveriesService();

  const usersList = lists.filter((item) => item.type === listTypes.usuario);
  const countriesList = lists.filter((item) => item.type === listTypes.pais);

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    control,
    getValues,
    formState,
    reset
  } = useForm({
    shouldUnregister: false,
    defaultValues
  });

  const watchSendPostalInvoice = watch('EnviarFacturaImpresa');
  const watchCountry = watch('PaisCODPostal');

  const handleTabChange = (index) => {
    updateQuery({ invoiceTab: tabs[index].slug || tabs[index].label });
  };

  const tabIndex = () => {
    const idxTab = tabs.findIndex(
      (tabItem) => (tabItem.slug || tabItem.label) === invoiceTab
    );

    return idxTab === -1 ? 0 : idxTab;
  };

  const handleDialog = () => {
    reset();
    if (modalState) {
      setModalState(null);
    } else {
      updateQuery({ invoice: undefined, invoiceTab: undefined });
    }
  };

  const handleClose = () => {
    if (setReload) {
      setReload(false);
    }
    handleDialog();
  };

  useEffect(async () => {
    if (formState.isDirty && watchCountry) {
      setValue('ProvinciaPostal', null);
      setValue('RegionPostal', null);
    }
  }, [watchCountry]);

  const saveInvoice = async (values) => {
    try {
      const response = await invoicesService.saveInvoice(values);
      dispatchSnackbar({
        type: 'SET',
        payload: `Factura ${response.Codigo} editada`
      });
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
    }
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    await saveInvoice(values);
    handleDialog();
    setIsLoading(false);
  };

  const handlePrintInvoice = async () => {
    setIsLoading(true);
    if (formState.isDirty) {
      const values = getValues();
      await saveInvoice(values);
    }
    try {
      const response = await invoicesService.printInvoices([invoice]);
      handleDownload(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
    } finally {
      setIsLoading(false);
      if (formState.isDirty) {
        handleDialog();
      }
    }
  };

  useEffect(async () => {
    if (invoice) {
      try {
        const response = await invoicesService.getInvoice(invoice);
        const deliveriesResponse = await deliveriesService.getDeliveries(
          qs.stringify(
            { Paginacion: 0, FacturaId: invoice },
            { addQueryPrefix: true }
          )
        );
        const articlesResponse = await invoicesService.getLines(invoice);
        setInvoiceCode(response.Codigo);
        setDeliveriesList(deliveriesResponse.Datos.$values);
        setArticlesList(articlesResponse.$values);
        setClient({
          name: response.Cliente,
          id: response.IdCliente
        });
        setUserName(
          usersList.find((user) => user.id === response.IdUsuarioCreacion)
            ?.name || 'Desconocido'
        );
        setIsLoading(false);
        reset({
          Id: response.Id,
          Fecha: response.Fecha,
          Estado: response.Estado,
          Observaciones: response.Observaciones,
          IdFormaPago: response.IdFormaPago,
          EmailFacturas: response.EmailFacturas,
          EnviarFacturaImpresa: response.EnviarFacturaImpresa,
          NombrePostal: response.NombrePostal,
          DireccionPostal: response.DireccionPostal,
          LocalidadPostal: response.LocalidadPostal,
          CPPostal: response.CPPostal,
          PaisCODPostal: response.PaisCODPostal,
          ProvinciaPostal: response.ProvinciaPostal,
          RegionPostal: response.RegionPostal
        });
      } catch (error) {
        console.log(error);
        dispatchSnackbar({
          type: 'ERROR',
          payload: 'Ha ocurrido un error'
        });
        setIsLoading(false);
        handleDialog();
      }
    }
  }, [invoice]);

  return (
    <FullDialog
      open={!!invoice}
      type='Factura'
      id={invoiceCode}
      isNew={false}
      icon={<FacturaIco />}
      handleClose={handleClose}
      client={client}
      touched={formState.isDirty}
    >
      <StyledInvoiceModal>
        <Loader show={isLoading} />
        {invoiceCode && (
          <Helmet>
            <title>Factura {invoiceCode} | Pascual Vinuesa</title>
          </Helmet>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='top-section'>
            <div className='user-date'>
              {userName ? (
                <Text color='gray'>
                  {userName === 'Desconocido'
                    ? 'Importado'
                    : `Creado por ${userName}`}
                </Text>
              ) : (
                <span />
              )}
              <Datepicker
                className='date-input'
                name='Fecha'
                control={control}
                label='Fecha'
                horizontal
                placeholder='Fecha'
                disabled
              />
              <Select
                horizontal
                label='Estado:'
                name='Estado'
                control={control}
                options={invoiceStatus}
                className='status-selector'
                placeholder='estado...'
                defaultValue={getValues('Estado')}
              />
            </div>
            <div className='modal-buttons'>
              <button
                type='button'
                className='print-button'
                onClick={handlePrintInvoice}
              >
                <PrintIco />{' '}
                {formState.isDirty ? 'Guardar e imprimir' : 'Imprimir'}
              </button>
              <Button secondary type='submit' disabled={!formState.isDirty}>
                Guardar y cerrar
              </Button>
            </div>
          </div>
          <Tabs
            onSelect={handleTabChange}
            disabledTabClassName='disabled-tab'
            selectedTabClassName='selected-tab'
            className='tabs'
            defaultIndex={tabIndex()}
          >
            <TabList className='tab-list'>
              {tabs.map((tabItem) => (
                <Tab
                  key={tabItem.label}
                  className='tab'
                  disabled={tabItem.disabled}
                >
                  {tabItem.label}
                </Tab>
              ))}
            </TabList>
            <div className='tabs-content'>
              <TabPanel>
                <div className='tab-columns'>
                  <div>
                    <ContentBox title='Forma de pago'>
                      <SelectFromList
                        type={listTypes.formaPago}
                        control={control}
                        name='IdFormaPago'
                        addTitle='Añadir nueva forma de pago'
                        setValue={setValue}
                        defaultValue={getValues('IdFormaPago')}
                      />
                    </ContentBox>
                    <ContentBox title='Envío de facturas'>
                      <Input
                        label='Email'
                        name='EmailFacturas'
                        inputRef={register}
                        placeholder='escribe el email para enviar facturas'
                        error={
                          errors.EmailFacturas && errors.EmailFacturas.message
                        }
                        horizontal
                      />
                      <FormCheckbox
                        label='Enviar a esta dirección'
                        name='EnviarFacturaImpresa'
                        control={control}
                      />
                      <Input
                        name='NombrePostal'
                        inputRef={register}
                        placeholder='nombre'
                        error={
                          errors.NombrePostal && errors.NombrePostal.message
                        }
                        disabled={!watchSendPostalInvoice}
                      />
                      <Input
                        name='DireccionPostal'
                        inputRef={register}
                        placeholder='dirección'
                        error={
                          errors.DireccionPostal &&
                          errors.DireccionPostal.message
                        }
                        disabled={!watchSendPostalInvoice}
                      />
                      <FormRow className='layout-4-6'>
                        <Input
                          name='PoblacionPostal'
                          inputRef={register}
                          placeholder='localidad'
                          error={
                            errors.PoblacionPostal &&
                            errors.PoblacionPostal.message
                          }
                          disabled={!watchSendPostalInvoice}
                        />
                        <Input
                          name='CPPostal'
                          inputRef={register}
                          placeholder='codigo postal'
                          error={errors.CPPostal && errors.CPPostal.message}
                          disabled={!watchSendPostalInvoice}
                        />
                      </FormRow>
                      <FormRow className='layout-4-6'>
                        <Select
                          name='PaisCODPostal'
                          control={control}
                          options={countriesList}
                          defaultValue={getValues('PaisCODPostal')}
                          searchable
                          placeholder='selecciona el pais'
                          disabled={!watchSendPostalInvoice}
                          error={
                            errors.PaisCODPostal && errors.PaisCODPostal.message
                          }
                        />
                        <ProvinceSelector
                          provinceName='ProvinciaPostal'
                          regionName='RegionPostal'
                          control={control}
                          provinceDefaultValue={getValues('ProvinciaPostal')}
                          className='second'
                          provinceError={
                            errors.ProvinciaPostal &&
                            errors.ProvinciaPostal.message
                          }
                          inputRef={register}
                          regionError={
                            errors.RegionPostal && errors.RegionPostal.message
                          }
                          provincePlaceholder='provincia'
                          regionPlaceholder='región'
                          disabled={!watchSendPostalInvoice}
                          country={watchCountry}
                          noLabel
                        />
                      </FormRow>
                    </ContentBox>
                  </div>
                  <div>
                    <ContentBox title='Observaciones'>
                      <Textarea
                        name='Observaciones'
                        inputRef={register}
                        placeholder='observaciones acerca de la factura'
                        error={
                          errors.Observaciones && errors.Observaciones.message
                        }
                        height='8.5rem'
                      />
                    </ContentBox>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <ArticlesList list={articlesList} />
              </TabPanel>
              <TabPanel>
                <DeliveriesList list={deliveriesList} />
              </TabPanel>
            </div>
          </Tabs>
        </form>
      </StyledInvoiceModal>
    </FullDialog>
  );
}

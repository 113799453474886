import React, { useState } from 'react';

import StyledInvoicesList from './InvoicesList.style';
import { ItemsTable, DeleteDialog } from '../../../components';
import { DeleteErrorDialog, SendInvoicesAssistant } from '../../../molecules';
import columns from './columns';
import RowItems from './RowItems/RowItems';
import { useDispatchSnackbar } from '../../../providers/SnackbarProvider';
import InvoicesService from '../../../services/Invoices';
import { handleDownload } from '../../../utils/functions';

export default function InvoicesList({
  invoices,
  getInvoices,
  order,
  handleInvoiceModalState
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [responseErrors, setResponseErrors] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openInvoicesAssistant, setOpenInvoicesAssistant] = useState(false);
  const [invoiceModalState, setInvoiceModalState] = useState(null);
  const [showSendDialog, setShowSendDialog] = useState(false);
  const dispatchSnackbar = useDispatchSnackbar();
  const invoicesService = new InvoicesService();

  const handleClickDelete = () => {
    setConfirmDelete(true);
  };

  const handleOpenInvoice = (id) => {
    handleInvoiceModalState({ invoice: id });
  };

  const handleCloseDialog = () => setConfirmDelete(false);

  const handleDeleteInvoices = async () => {
    setIsLoading(true);
    setConfirmDelete(false);
    try {
      const response = await invoicesService.deleteInvoices(selected);
      const ErrorsList = response.$values.filter((item) => !!item.Error);
      if (ErrorsList.length) {
        setResponseErrors(ErrorsList);
      } else {
        dispatchSnackbar({
          type: 'SET',
          payload: 'facturas eliminadas'
        });
      }
      setSelected([]);
      await getInvoices(order);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  const handleCloseSendDialog = () => setShowSendDialog(false);

  const handleLoading = (newstate) => setIsLoading(newstate);

  const handleSelected = (newstate) => setSelected(newstate);

  const handlePrintInvoices = async () => {
    setIsLoading(true);
    try {
      const response = await invoicesService.printInvoices(selected);
      handleDownload(response);
      setSelected([]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleSendInvoices = () => {
    setShowSendDialog(true);
  };

  const extraButtons = [
    {
      label: 'Imprimir',
      onClick: handlePrintInvoices
    },
    {
      label: 'Enviar facturas',
      onClick: handleSendInvoices
    }
  ];

  return (
    <StyledInvoicesList>
      <ItemsTable
        title='Facturas'
        columns={columns}
        items={invoices}
        selected={selected}
        setSelected={setSelected}
        RowItems={RowItems}
        handleClickDelete={handleClickDelete}
        handleClickRow={handleOpenInvoice}
        isLoading={isLoading}
        extraButtons={extraButtons}
      />
      <DeleteDialog
        open={confirmDelete}
        close={handleCloseDialog}
        handleAccept={handleDeleteInvoices}
        quantity={selected.length}
        singular='factura'
        plural='facturas'
      />
      <DeleteErrorDialog
        errors={responseErrors}
        handleClose={() => setResponseErrors([])}
      />
      <SendInvoicesAssistant
        showSendDialog={showSendDialog}
        handleCloseSendDialog={handleCloseSendDialog}
        selected={selected}
        handleSelected={handleSelected}
        handleLoading={handleLoading}
        invoices={invoices}
        getInvoices={getInvoices}
        handleEditInvoice={handleOpenInvoice}
      />
    </StyledInvoicesList>
  );
}

const tabs = [
  { label: 'detalles' },
  { label: 'artículos' },
  { label: 'albaranes' }
];

const defaultValues = {
  Id: 0,
  Fecha: null,
  Estado: null,
  Observaciones: null,
  IdFormaPago: null,
  EmailFacturas: null,
  EnviarFacturaImpresa: null,
  EnviarFacturaImpresaOtra: null,
  NombrePostal: null,
  DireccionPostal: null,
  LocalidadPostal: null,
  CPPostal: null,
  PaisCODPostal: null,
  ProvinciaPostal: null,
  RegionPostal: null
};

export { tabs, defaultValues };

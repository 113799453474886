import styled from 'styled-components';

const StyledSelectWithAdd = styled.div`
  span {
    display: block;
    margin-bottom: 0.5rem;
  }
  .buttons {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    .add-item {
      cursor: pointer;
    }
  }
  margin-bottom: 1rem;
`;

export const StyledDrawerAddItem = styled.div``;

export default StyledSelectWithAdd;

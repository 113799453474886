import { errorHandler } from './utils';
import instance from './Base';

class SupplierDeliveries {
  getQuery = async (query) => {
    let response;
    try {
      response = await instance.get(
        `/Umbraco/Api/AlbaranesProveedor/GetQuery${query}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return response?.data;
  };

  getDeliveries = async (id) => {
    let deliveriesData;
    try {
      deliveriesData = await instance.get(
        `/Umbraco/Api/AlbaranesProveedor/GetByEmpresa?IdEmpresa=${id}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return deliveriesData.data;
  };

  getDelivery = async (id) => {
    let deliveryData;
    try {
      deliveryData = await instance.get(
        `/Umbraco/Api/AlbaranesProveedor/Get/?id=${id}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return deliveryData.data;
  };

  getLines = async (id) => {
    let response;
    try {
      response = await instance.get(
        `/Umbraco/Api/AlbaranesProveedor/GetLineas/?id=${id}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  saveLines = async (id, lines) => {
    let response;
    try {
      response = await instance.post(
        `/Umbraco/Api/AlbaranesProveedor/GuardarLineas?idAlbaran=${id}`,
        lines
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  deleteDeliveries = async (deliveries) => {
    let response;
    try {
      response = await instance.post(
        `/Umbraco/Api/AlbaranesProveedor/Borrar`,
        deliveries
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  addDelivery = async (delivery) => {
    let response;
    try {
      response = await instance.post(
        '/Umbraco/Api/AlbaranesProveedor/Guardar',
        delivery
      );
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };
}

export default SupplierDeliveries;

import React from 'react';

import StyledNewCarrierForm from './NewCarrierForm.style';
import { Input } from '../../components';

export default function NewCarrierForm({ register, errors }) {
  return (
    <StyledNewCarrierForm>
      <Input
        name='TransportistaNombre'
        label='Nombre'
        inputRef={register({ required: 'Este campo es obligatorio' })}
        error={errors.TransportistaNombre?.message}
      />
      <Input name='TransportistaCIF' label='CIF' inputRef={register} />
      <Input
        name='TransportistaVehiculo'
        label='Vehículo'
        inputRef={register}
      />
      <Input
        name='TransportistaRemolque'
        label='Remolque'
        inputRef={register}
      />
    </StyledNewCarrierForm>
  );
}

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { format } from 'date-fns';

import {
  StyledSendModal,
  StyledSendEmailResults,
  StyledSendPostalDialog,
  StyledSendPostalResults
} from './SendInvoicesAssistant.style';
import {
  Dialog,
  Text,
  Button,
  Datepicker,
  DateDisplay
} from '../../components';
import InvoicesService from '../../services/Invoices';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';
import { ReactComponent as ArrobaIco } from '../../resources/svg/ico-arroba.svg';
import { ReactComponent as SobreIco } from '../../resources/svg/ico-sobre.svg';

const sendTypes = {
  email: 0,
  postal: 1
};

const schema = yup.object().shape({
  Fecha: yup.date().nullable().required('Selecciona una fecha')
});

export default function SendInvoicesAssistant({
  showSendDialog,
  handleCloseSendDialog,
  selected,
  handleLoading,
  handleSelected,
  invoices,
  getInvoices,
  handleEditInvoice
}) {
  const invoicesService = new InvoicesService();
  const dispatchSnackbar = useDispatchSnackbar();
  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      Fecha: new Date()
    },
    shouldUnregister: false
  });

  const [sendEmailResults, setSendEmailResults] = useState(null);
  const [sendPostalDialog, setSendPostalDialog] = useState(false);
  const [sendPostalResults, setSendPostalResults] = useState(null);

  const handleSendEmail = async () => {
    handleCloseSendDialog();
    handleLoading(true);
    try {
      const response = await invoicesService.sendInvoices(
        selected,
        sendTypes.email
      );
      console.log(response);
      const errorsList = response.$values
        .filter((item) => !!item.Error)
        .map((error) => ({
          error: error.Error,
          code: invoices.find((i) => i.Id === error.Id)?.Codigo,
          id: error.Id
        }));
      setSendEmailResults({
        errorsList,
        total: selected.length - errorsList.length
      });
      handleSelected([]);
      await getInvoices();
      handleLoading(false);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      await getInvoices();
      handleLoading(false);
    }
  };

  const handleOpenSendPostalDialog = () => {
    handleCloseSendDialog();
    setSendPostalDialog(true);
  };

  const handleCloseSendEmailResults = () => setSendEmailResults(null);

  const handleCloseSendPostalDialog = () => setSendPostalDialog(false);

  const onSubmit = async (values) => {
    setSendPostalDialog(false);
    handleLoading(true);
    const { Fecha } = values;
    const parsedDate = format(Fecha, 'yyyy-MM-dd');
    try {
      await invoicesService.sendInvoices(
        selected,
        sendTypes.postal,
        parsedDate
      );
      setSendPostalResults({ total: selected.length, date: parsedDate });
      handleSelected([]);
      await getInvoices();
      handleLoading(false);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      await getInvoices();
      handleLoading(false);
    }
  };

  const handleCloseSendPostalResults = () => setSendPostalResults(null);

  return (
    <>
      {showSendDialog && (
        <Dialog
          open={showSendDialog}
          title='Enviar facturas'
          blue
          handleClose={handleCloseSendDialog}
          hideCloseButton
          disableClickOutside
        >
          <StyledSendModal>
            <Text>
              Selecciona el tipo de envio para las{' '}
              <span className='selected-invoices'>
                {selected.length} facturas
              </span>{' '}
              seleccionadas
            </Text>
            <div className='buttons'>
              <Button
                secondary
                outlined
                onClick={() => handleSendEmail(sendTypes.email)}
              >
                <ArrobaIco />
                Enviar por email
              </Button>
              <Button secondary outlined onClick={handleOpenSendPostalDialog}>
                <SobreIco />
                Marcar como envio postal
              </Button>
            </div>
          </StyledSendModal>
        </Dialog>
      )}
      {sendEmailResults && (
        <Dialog
          blue
          title='Enviar facturas'
          open={sendEmailResults}
          disableClickOutside
          handleClose={handleCloseSendEmailResults}
          closeLabel='Cerrar'
        >
          <StyledSendEmailResults>
            <Text className='total-sent'>
              Se han enviado {sendEmailResults.total} facturas por email.
            </Text>
            {sendEmailResults.errorsList.length > 0 && (
              <>
                <Text color='blue'>Facturas con errores en el envio:</Text>
                <div className='email-errors'>
                  {sendEmailResults.errorsList.map((item) => (
                    <Text>
                      <span
                        className='error-code'
                        onClick={() => handleEditInvoice(item.id)}
                        onKeyDown={() => handleEditInvoice(item.id)}
                        role='button'
                        tabIndex='-1'
                      >
                        {item.code}
                      </span>{' '}
                      <span className='error-message'>({item.error})</span>
                    </Text>
                  ))}
                </div>
              </>
            )}
          </StyledSendEmailResults>
        </Dialog>
      )}
      {sendPostalDialog && (
        <Dialog
          blue
          title='Enviar facturas'
          open={sendPostalDialog}
          disableClickOutside
          handleClose={handleCloseSendPostalDialog}
          hideCloseButton
        >
          <StyledSendPostalDialog>
            <Text>
              Selecciona la fecha de envio postal para las{' '}
              <span className='selected-invoices'>
                {selected.length} facturas
              </span>{' '}
              seleccionadas
            </Text>
            <form>
              <Datepicker
                name='Fecha'
                control={control}
                placeholder='Fecha de envío'
                label='Fecha de envío'
                horizontal
                error={errors.Fecha && errors.Fecha.message}
                className='date-input'
              />
              <Button secondary outlined onClick={handleSubmit(onSubmit)}>
                Asignar fecha de envio postal
              </Button>
            </form>
          </StyledSendPostalDialog>
        </Dialog>
      )}
      {sendPostalResults && (
        <Dialog
          blue
          title='Enviar facturas'
          open={sendPostalResults}
          disableClickOutside
          handleClose={handleCloseSendPostalResults}
          closeLabel='Cerrar'
        >
          <StyledSendPostalResults>
            <Text>
              Se ha asignado la fecha{' '}
              <DateDisplay date={sendPostalResults.date} /> a las{' '}
              {sendPostalResults.total} facturas seleccionadas.
            </Text>
          </StyledSendPostalResults>
        </Dialog>
      )}
    </>
  );
}

import React from 'react';

import StyledPaymentItem from './PaymentItem.style';
import { Text } from '../../components';
import { ReactComponent as PredeterminadoIco } from '../../resources/svg/ico-predeterminado.svg';

export default function PaymentItem({ item }) {
  const {
    Tipo,
    Titular,
    IBAN,
    BIC,
    Tarjeta,
    TarjetaCadMes,
    TarjetaCadYear,
    Predeterminada
  } = item;
  return (
    <StyledPaymentItem>
      <Text>
        {Tipo === 0 ? 'Domiciliación bancaria' : 'Tarjeta de crédito'}
      </Text>
      <Text color='orange' bold>
        {IBAN || Tarjeta}
      </Text>
      {BIC && <Text>BIC: {BIC}</Text>}
      {TarjetaCadMes && TarjetaCadYear && (
        <Text>
          Caducidad: {TarjetaCadMes}/{TarjetaCadYear}
        </Text>
      )}
      <Text>{Titular}</Text>
      {Predeterminada && (
        <div className='predeterminada'>
          <PredeterminadoIco className='predeterminada-icon' />
          <Text color='red'>Predeterminada</Text>
        </div>
      )}
    </StyledPaymentItem>
  );
}

const columns = [
  {
    id: 'Id',
    label: 'Referencia',
    width: '30px'
  },
  {
    id: 'Nombre',
    label: 'Nombre',
    width: '60px'
  },
  {
    id: 'Inventario',
    label: 'Stock',
    width: '10px'
  },
  {
    id: 'Albaran',
    label: 'Albarán',
    width: '30px'
  },
  {
    id: 'Cantidad',
    label: 'Cantidad',
    width: '20px'
  },
  {
    id: 'TipoPrecio',
    label: 'Unidad',
    width: '30px'
  },
  {
    id: 'PrecioOriginal',
    label: 'PVP',
    width: '30px'
  },
  {
    id: 'Precio',
    label: 'PVP final',
    width: '30px'
  },
  {
    id: 'DescuentoPorcentaje',
    label: 'Descuento %',
    width: '20px'
  },
  {
    id: 'Total',
    label: 'Total',
    width: '30px'
  },
  {
    id: 'History',
    label: '',
    width: '10px'
  },
  {
    id: 'Comment',
    label: '',
    width: '10px'
  }
];

export default columns;

import styled from 'styled-components';

const StyledSelectFromList = styled.div`
  span {
    display: block;
    margin-bottom: 0.5rem;
  }
  .add-item {
    margin-top: 0.5rem;
    text-align: right;
    cursor: pointer;
  }
  margin-bottom: 1rem;
`;

export const StyledDrawerAddItem = styled.div``;

export default StyledSelectFromList;

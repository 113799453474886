const columns = [
  {
    id: 'ID',
    label: 'Id',
    width: '20px',
    sortable: true
  },
  {
    id: 'Nombre',
    label: 'Nombre',
    width: '200px',
    sortable: true
  },
  {
    id: 'CIF',
    label: 'CIF/DNI',
    width: '50px',
    sortable: true
  },
  {
    id: 'EmailFacturas',
    label: 'Email',
    width: '200px',
    sortable: true
  },
  {
    id: 'cart',
    label: 'Último pedido',
    width: '200px'
  }
];

export default columns;

import styled from 'styled-components';

const StyledItemsList = styled.div`
  position: relative;
  .items-list {
    margin-bottom: 0.75rem;
    .single-item {
      display: flex;
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.white};
      margin-bottom: 0.25rem;
      padding: 1rem;
      svg {
        margin-right: 1.5rem;
        transition: all 0.3s;
        &:hover {
          fill: ${({ theme }) => theme.colors.red};
        }
      }
    }
  }
  .add-item {
    text-align: right;
    cursor: pointer;
  }
`;

export default StyledItemsList;

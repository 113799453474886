import React from 'react';
import NumberFormat from 'react-number-format';
import { Controller } from 'react-hook-form';

import StyledCurrencyInput from './CurrencyInput.styled';

export default function CurrencyInput({
  label,
  placeholder,
  disabled = false,
  error,
  control,
  name,
  hidden,
  horizontal,
  className,
  percentaje
}) {
  const handleFocus = (event) => {
    if (percentaje && event.target.value === '0%') {
      event.target.select();
    }
  };
  return (
    <StyledCurrencyInput
      error={error}
      hidden={hidden}
      horizontal={horizontal}
      label={label}
      className={className}
    >
      <label>
        {label && label}
        <Controller
          name={name}
          control={control}
          render={({ onChange, value, onBlur }) => (
            <NumberFormat
              value={value}
              thousandSeparator='.'
              decimalSeparator=','
              suffix={percentaje ? '%' : '€'}
              placeholder={placeholder}
              disabled={disabled}
              decimalScale={2}
              onBlur={onBlur}
              onFocus={handleFocus}
              onValueChange={(target) => {
                onChange(target.floatValue);
              }}
            />
          )}
        />
      </label>
      {!disabled && error && <span className='error'>{error}</span>}
    </StyledCurrencyInput>
  );
}

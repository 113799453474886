import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { centered } from '../../styles/utils/mixins';

const StyledDialog = styled(Dialog)`
  margin: 0 3.5%;
  .MuiDialog-paperScrollPaper {
    max-height: 100%;
  }
  .MuiDialog-paper {
    height: 100%;
    border-radius: 0;
    width: 100%;
    margin: 0;
  }
  @media (max-width: 1024px) {
    margin: 0 2%;
  }
`;

const StyledTitle = styled(DialogTitle)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  h2 {
    font-family: 'Oswald', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close {
      cursor: pointer;
    }
    div {
      ${centered}
      svg {
        margin-right: 0.5rem;
        width: 1.5rem;
      }
      .id {
        font-weight: 400;
      }
      .client-link {
        color: ${({ theme }) => theme.colors.orange};
        cursor: pointer;
      }
    }
  }
`;

const StyledContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
`;

export { StyledDialog, StyledTitle, StyledContent };

import React from 'react';

import Dialog from '../Dialog/Dialog';

export default function DeleteDialog({
  open,
  close,
  handleAccept,
  singular,
  plural,
  quantity
}) {
  return (
    <Dialog
      open={open}
      handleClose={close}
      title={`Confirmar acción para eliminar ${plural}`}
      handleAccept={handleAccept}
    >
      <p>
        Esta acción afectará a {quantity} {quantity === 1 ? singular : plural}.
        ¿Seguro que quieres continuar?
      </p>
    </Dialog>
  );
}

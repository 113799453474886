import styled from 'styled-components';
import { rgba } from 'polished';

const StyledNav = styled.div`
  width: ${({ collapsed }) => (collapsed ? '3.5rem' : '15.5rem')};
  background-color: ${({ theme }) => theme.colors.navBackground};
  position: relative;
  .date {
    font-size: 0.875rem;
    padding: 2rem;
    padding-left: 1rem;
    background-color: ${({ theme }) => theme.colors.lighterGray};
    display: flex;
    align-items: center;
    height: 5rem;
  }
  .nav-item {
    li {
      padding: 1rem;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lighterGray};
      text-transform: uppercase;
      font-weight: 400;
      display: flex;
      align-items: center;
      color: ${({ theme }) => rgba(theme.colors.text, 0.7)};
      svg {
        margin-right: ${({ collapsed }) => (collapsed ? '0' : '1rem')};
        fill: ${({ theme }) => theme.colors.itemsOptionsText};
        width: 1.5rem;
      }
      transition: all 0.3s;
      &:hover {
        background-color: ${({ theme }) => rgba(theme.colors.darkGray, 0.2)};
        svg {
          opacity: 1;
          transition: all 0.3s;
        }
      }
    }
    &.disabled {
      cursor: default;
      li {
        pointer-events: none;
        opacity: 0.4;
      }
    }
    &.selected li {
      background-color: ${({ theme }) => theme.colors.orange};
      color: ${({ theme }) => theme.colors.white};
      svg {
        fill: ${({ theme }) => theme.colors.white};
        opacity: 1;
      }
      &:hover {
        background-color: ${({ theme }) => theme.colors.orange};
      }
    }
  }
  .app-info {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 2rem;
    padding-left: 1rem;
  }
`;

export default StyledNav;

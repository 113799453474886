import styled from 'styled-components';
import { rgba } from 'polished';

const StyledDeliveriesFilter = styled.div`
  padding: 1rem;
  width: 35rem;
  .clear-filters {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.orange};
    cursor: pointer;
    text-decoration: underline;
    text-align: right;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  }
  .filter-item {
    padding: 1rem 0.25rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
    display: flex;
    align-items: center;
    .filter-radio {
      min-width: 9rem;
      justify-content: flex-start;
    }
    .filter-checkbox {
      min-width: calc(9rem + 10px);
      justify-content: flex-start;
      margin-right: 1rem;
      margin-left: -10px;
      label {
        margin-left: -3px;
        span {
          font-weight: 300;
        }
      }
      .MuiSvgIcon-root {
        width: 16px;
        height: 16px;
      }
    }
    .filter-select {
      margin-bottom: 0;
    }
    .date-picker {
      margin-bottom: 0;
      & + .date-picker {
        margin-left: 0.5rem;
      }
    }
    .client-typeahead {
      margin-bottom: 0;
      width: 100%;
      .form-control {
        border-width: 1px;
      }
    }
    .react-datepicker-wrapper + .react-datepicker-wrapper {
      margin-left: 0.5rem;
    }
    .calendar-input {
      font-weight: 300;
      width: 100%;
      border: 1px solid
        ${({ theme, error }) =>
          error ? rgba(theme.colors.red, 0.5) : theme.colors.gray};
      border-radius: 5px;
      padding: 0.5rem 1rem;
    }
  }
  .filters-search {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      display: block;
      margin-left: auto;
    }
  }
`;

export default StyledDeliveriesFilter;

import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import StyledLogin from './StyledLogin';
import { useAuth, useDispatchAuth } from '../../providers/AuthProvider';
import { Loader, Dialog, Text, Input, Button } from '../../components';
import AuthService from '../../services/Auth';

import { ReactComponent as TopLogo } from '../../resources/svg/top-logo.svg';
import { setToken } from '../../utils/localStorage';
import { getLists } from '../../utils/functions';

export default function Login() {
  const { register, handleSubmit, errors, reset } = useForm();
  const [isLoading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const dispatch = useDispatchAuth();
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };
  const authService = new AuthService();

  const { name, loading } = useAuth();

  useEffect(() => {
    if (!loading && name) {
      history.push('/');
    }
  }, [loading]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const loginData = await authService.login(data);
      if (loginData.access_token) {
        setToken(loginData);
        const lists = await getLists();
        const userData = await authService.me();
        dispatch({
          type: 'LOGIN',
          payload: {
            user: userData.Nombre,
            lists
          }
        });
        history.replace(from);
      } else {
        throw new Error({ message: 'something wrong happened' });
      }
    } catch (error) {
      console.log(error);
      setLoginError(true);
    }
    setLoading(false);
  };

  const handleCloseDialog = () => {
    setLoginError(false);
    reset();
  };

  return (
    <StyledLogin>
      <Loader fullScreen show={loading} />
      <div className='login-container'>
        <TopLogo />
        <Text as='h1' bold size='m'>
          Iniciar sesión
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label='Correo electrónico'
            name='username'
            inputRef={register({
              required: 'Este campo es obligatorio'
            })}
            error={errors.username?.message}
          />
          <Input
            label='Contraseña'
            name='password'
            type='password'
            inputRef={register({
              required: 'Este campo es obligatorio'
            })}
            error={errors.password?.message}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={isLoading}
          >
            Acceder
          </Button>
          <Loader show={isLoading} fullScreen />
        </form>
      </div>
      <Dialog
        open={loginError}
        handleClose={handleCloseDialog}
        title='Credenciales incorrectas'
        closeLabel='Cerrar'
      >
        El usuario o la contraseña son incorrectos.
      </Dialog>
    </StyledLogin>
  );
}

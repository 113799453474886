import React from 'react';
import NumberFormat from 'react-number-format';

export default function CurrencyDisplay({ number, className }) {
  return (
    <NumberFormat
      value={number}
      decimalScale={2}
      fixedDecimalScale
      displayType='text'
      thousandSeparator='.'
      decimalSeparator=','
      suffix='€'
      className={className}
    />
  );
}

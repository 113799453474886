import styled from 'styled-components';

const StyledInvoiceModal = styled.div`
  position: relative;
  .loader {
    z-index: 9999;
  }
  .top-section {
    margin: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .modal-fields {
    margin: 2rem;
    display: flex;
    .delivery-input {
      width: 12rem;
      &.codigo {
        input {
          width: 7rem;
        }
      }
      &.fecha {
        padding-right: 1rem;
      }
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
  .columns {
    display: flex;
    justify-content: space-between;
    margin: 2rem;
    & > div {
      width: 48%;
    }
  }
  .invoice-totals {
    .box {
      display: flex;
      flex-wrap: wrap;
      column-gap: 4%;
      .total-input {
        width: 48%;
        min-width: 10rem;
      }
    }
  }
  .delivery-files {
    .file-upload {
      cursor: pointer;
      &:focus {
        outline: none;
      }
      input {
        display: none;
      }
      div {
        background-color: ${({ theme }) => theme.colors.lightGray};
        border: 1px solid
          ${({ theme, isDragActive }) =>
            isDragActive ? theme.colors.orange : theme.colors.gray};
        position: relative;
        padding-bottom: 35%;
        margin-bottom: 1.5rem;
        &:focus {
          outline: none;
        }
        p {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: ${({ theme, isDragActive }) =>
            isDragActive ? theme.colors.orange : theme.colors.gray};
          padding: 0.5rem 1.5rem;
          border-radius: 1rem;
        }
      }
    }
  }
`;

export default StyledInvoiceModal;

import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { es } from 'date-fns/locale';
import { Controller } from 'react-hook-form';

import StyledDatepicker from './DatePicker.styled';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('es', es);

export default function Datepicker({
  label,
  placeholder,
  disabled = false,
  error,
  control,
  name,
  hidden,
  horizontal,
  className
}) {
  return (
    <StyledDatepicker
      error={error}
      hidden={hidden}
      horizontal={horizontal}
      label={label}
      className={className}
    >
      <label>
        {label && label}
        <Controller
          name={name}
          control={control}
          render={({ onChange, value }) => (
            <DatePicker
              locale='es'
              disabled={disabled}
              selected={Date.parse(value)}
              onChange={onChange}
              dateFormat='dd/MM/yyyy'
              placeholderText={placeholder}
              popperProps={{
                positionFixed: true
              }}
            />
          )}
        />
      </label>
      {!disabled && error && <span className='error'>{error}</span>}
    </StyledDatepicker>
  );
}

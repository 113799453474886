import { rgba } from 'polished';
import styled from 'styled-components';

const StyledLogin = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => rgba(theme.colors.darkGray, 0.2)};
  .login-container {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 2rem 6rem 3.5rem 6rem;
    border-radius: 10px;
    box-shadow: 0px 3px 6px ${() => rgba('#000000', 0.2)};
    h1 {
      margin-bottom: 1.5rem;
    }
    svg {
      display: block;
      margin-bottom: 2rem;
    }
    form {
      position: relative;
      button {
        float: right;
        padding-left: 3rem;
        padding-right: 3rem;
        margin-top: 1.5rem;
      }
    }
  }
`;

export default StyledLogin;

import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';

import StyledInvoiceModal from './InvoiceModal.style';
import {
  FullDialog,
  Input,
  Text,
  Button,
  Loader,
  Datepicker,
  CurrencyInput,
  FilePreview,
  ContentBox,
  SelectFromList
} from '../../../components';
import { ReactComponent as DeliveryIcon } from '../../../resources/svg/ico-albaranes.svg';
import { useDispatchSnackbar } from '../../../providers/SnackbarProvider';
import FilesService from '../../../services/File';
import { formatSize } from '../../../utils/functions';
import listTypes from '../../../utils/listTypes';

const maxSize = process.env.REACT_APP_FILE_SIZE || 4000000;

export default function InvoiceModal({
  supplierName,
  supplierPayment,
  open,
  handleClose,
  invoiceToEdit,
  handleClickSubmit
}) {
  const { Codigo, user, Id } = invoiceToEdit || {};
  const {
    register,
    handleSubmit,
    errors,
    formState,
    reset,
    control,
    setValue,
    getValues
  } = useForm({
    shouldUnregister: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesModified, setFilesModified] = useState(false);
  const filesService = new FilesService();
  const dispatchSnackbar = useDispatchSnackbar();

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setIsLoading(true);
      setIsUploading(true);
      try {
        const response = await filesService.post(acceptedFiles);
        setFiles([
          ...files,
          ...response.$values.map((item) => ({
            Id: item.Id,
            Url: item.Url,
            Nombre: item.Nombre,
            PesoBytes: item.PesoBytes
          }))
        ]);
        setFilesModified(true);
        setIsLoading(false);
        setIsUploading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setIsUploading(false);
      }
    } else if (rejectedFiles.length > 0) {
      console.log(rejectedFiles);
      dispatchSnackbar({
        type: 'ERROR',
        payload: `Algunos archivos exceden el tamaño máximo de ${formatSize(
          maxSize
        )}`
      });
    }
  });

  const handleRemove = async (id) => {
    setIsLoading(true);
    try {
      await filesService.deleteFiles([id]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    setFiles(files.filter((item) => item.Id !== id));
    setFilesModified(true);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize
  });
  const onSubmit = (values) => {
    handleClickSubmit({ ...values, Id, Adjuntos: files });
  };

  useEffect(() => {
    if (invoiceToEdit) {
      console.log(invoiceToEdit);
      setFiles([...invoiceToEdit.Adjuntos]);
      reset({ ...invoiceToEdit });
    }
  }, [invoiceToEdit]);

  return (
    <FullDialog
      open={open}
      handleClose={handleClose}
      type='Factura'
      isNew={!invoiceToEdit}
      icon={<DeliveryIcon />}
      name={supplierName && `- ${supplierName}`}
      id={Codigo}
    >
      <StyledInvoiceModal isDragActive={isDragActive}>
        <Loader show={isLoading} />
        <form>
          <div className='top-section'>
            {user && <Text color='gray'>Creado por {user}</Text>}
            {!invoiceToEdit && <Text color='gray'>{supplierName}</Text>}
            <Button
              secondary
              type='button'
              disabled={!formState.isDirty && !filesModified}
              onClick={handleSubmit(onSubmit)}
            >
              Guardar y cerrar
            </Button>
          </div>
          <div className='modal-fields'>
            <Datepicker
              className='delivery-input fecha'
              name='Fecha'
              control={control}
              label='Fecha'
              horizontal
              placeholder='Fecha'
            />
            <Input
              label='Nº Factura'
              name='Codigo'
              inputRef={register({
                required: 'Este campo es obligatorio'
              })}
              placeholder='Nº Factura'
              error={errors.Codigo && errors.Codigo.message}
              horizontal
              className='delivery-input codigo'
            />
          </div>
          <div className='columns'>
            <div className='delivery-files'>
              <div {...getRootProps({ className: 'file-upload' })}>
                <input {...getInputProps()} />
                <div>
                  {isDragActive ? (
                    <Text color='white' bold>
                      Arrastra la foto aquí
                    </Text>
                  ) : (
                    <Text color='white' bold>
                      {isUploading ? 'Subiendo...' : 'Subir foto de factura'}
                    </Text>
                  )}
                </div>
              </div>
              {files.map((file) => (
                <FilePreview
                  key={file.Id}
                  file={file}
                  handleRemove={handleRemove}
                  horizontal
                />
              ))}
            </div>
            <div>
              <ContentBox
                title='Datos de la factura'
                className='invoice-totals'
              >
                <CurrencyInput
                  label='Subtotal'
                  name='SubTotal'
                  control={control}
                  placeholder='Subtotal'
                  error={errors.SubTotal && errors.SubTotal.message}
                  type='number'
                  className='total-input'
                />
                <CurrencyInput
                  label='Descuentos'
                  name='TotalDescuentos'
                  control={control}
                  placeholder='Descuentos'
                  error={
                    errors.TotalDescuentos && errors.TotalDescuentos.message
                  }
                  type='number'
                  className='total-input'
                />
                <CurrencyInput
                  label='Base imponible'
                  name='TotalBase'
                  control={control}
                  placeholder='Base imponible'
                  error={errors.TotalBase && errors.TotalBase.message}
                  type='number'
                  className='total-input'
                />
                <CurrencyInput
                  label='IVA'
                  name='TotalIVA'
                  control={control}
                  placeholder='IVA'
                  error={errors.TotalIVA && errors.TotalIVA.message}
                  type='number'
                  className='total-input'
                />
                <CurrencyInput
                  label='Total'
                  name='Total'
                  control={control}
                  placeholder='Total'
                  error={errors.Total && errors.Total.message}
                  type='number'
                  className='total-input'
                />
              </ContentBox>
              <ContentBox title='Forma de pago'>
                <SelectFromList
                  type={listTypes.formaPago}
                  control={control}
                  name='IdFormaCobro'
                  addTitle='Añadir nueva forma de pago'
                  setValue={setValue}
                  defaultValue={
                    invoiceToEdit ? getValues('IdFormaCobro') : supplierPayment
                  }
                />
              </ContentBox>
            </div>
          </div>
        </form>
      </StyledInvoiceModal>
    </FullDialog>
  );
}

import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import { CurrencyDisplay } from '../../../components';
import Clock from '../../../components/Clock/Clock';
import { showPrice } from '../../../utils/functions';

export default function RowItems({ item }) {
  return (
    <>
      <TableCell className='cell-body'>{item.SKU}</TableCell>
      <TableCell className='cell-body'>{item.Nombre}</TableCell>
      <TableCell className='cell-body'>{item.Inventario || '-'}</TableCell>
      <TableCell className='cell-body'>{item.Marca || '-'}</TableCell>
      <TableCell className='cell-body'>{item.Proveedor || '-'}</TableCell>
      <TableCell className='cell-body'>{item.TipoPrecio || '-'}</TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={item.UltimaEntradaPrecio} />
      </TableCell>
      <TableCell className='cell-body'>
        {item.DescuentoPorcentaje || 0} %
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay
          number={showPrice(
            item.PrecioConDescuento,
            item.IVA,
            item.IvaIncluido
          )}
        />
      </TableCell>
      <TableCell className='cell-body'>
        <Clock
          Nombre={item.Nombre}
          SKU={item.SKU}
          TipoPrecio={item.TipoPrecio}
        />
      </TableCell>
    </>
  );
}

import { errorHandler } from './utils';
import instance from './Base';

class Contacts {
  getByCompany = async (companyId) => {
    let contactsData;
    try {
      contactsData = await instance.get(
        `/Umbraco/Api/Contactos/GetByEmpresa?IdEmpresa=${companyId}`
      );
    } catch (error) {
      errorHandler(error);
    }
    return contactsData.data.$values;
  };

  addItem = async (contact) => {
    let response;
    try {
      response = await instance.post('/Umbraco/Api/Contactos/Guardar', contact);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };

  removeItems = async (ids) => {
    let response;
    try {
      response = await instance.post('/Umbraco/Api/Contactos/Borrar', ids);
    } catch (error) {
      errorHandler(error);
    }
    return response.data;
  };
}

export default Contacts;

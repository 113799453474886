import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import StyledLoader from './StyledLoader';

export default function Loader({ show, fullScreen, background }) {
  return show ? (
    <StyledLoader
      fullScreen={fullScreen}
      background={background}
      className='loader'
    >
      <CircularProgress />
    </StyledLoader>
  ) : null;
}

import qs from 'qs';

import instance from './Base';
import { errorHandler } from './utils';
import listTypes from '../utils/listTypes';

class Auth {
  login = async (user) => {
    let userData;
    try {
      userData = await instance.post(
        '/oauth/token',
        qs.stringify(
          {
            ...user,
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            grant_type: 'password'
          },
          { skipAuthRefresh: true }
        ),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          }
        }
      );
    } catch (error) {
      errorHandler(error);
    }
    return userData.data;
  };

  me = async () => {
    let userData;
    try {
      userData = await instance.get('/Umbraco/Api/Usuarios/Me');
    } catch (error) {
      errorHandler(error);
    }
    return userData.data;
  };

  getUsers = async () => {
    let userData;
    try {
      userData = await instance.get(
        '/Umbraco/Api/Usuarios/GetByTipo?tipo=1044'
      );
    } catch (error) {
      errorHandler(error);
    }
    return userData.data.$values.map((value) => ({
      type: listTypes.usuario,
      email: value.Email,
      id: value.Id,
      name: value.Nombre,
      roles: value.Roles.$values
    }));
  };
}

export default Auth;

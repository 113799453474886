import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import { LastCartOrder } from '../../../components';

export default function RowItems({ item }) {
  return (
    <>
      <TableCell className='cell-body'>{item.Id}</TableCell>
      <TableCell className='cell-body'>{item.Nombre}</TableCell>
      <TableCell className='cell-body'>{item.CIF || '-'}</TableCell>
      <TableCell className='cell-body'>
        <LastCartOrder
          type='order'
          code={item.CodigoPedido}
          date={item.FechaPedido}
          total={item.TotalPedido}
        />
      </TableCell>
    </>
  );
}

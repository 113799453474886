import styled from 'styled-components';

const StyledNewPaymentForm = styled.div`
  .radio-buttons {
    display: flex;
    margin-bottom: 1rem;
  }
`;

export default StyledNewPaymentForm;

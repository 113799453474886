import React from 'react';
import { Dialog, Text } from '../../components';

export default function DeleteErrorDialog({ errors, handleClose }) {
  return (
    <Dialog
      open={errors.length > 0}
      handleClose={handleClose}
      title='Error al eliminar elementos'
      closeLabel='cerrar'
      disableClickOutside
    >
      <Text bold>Han ocurrido los siguientes errores:</Text>
      <ul>
        {errors.map((item) => (
          <Text as='li'>
            Albaran {item.Id}: {item.Error}
          </Text>
        ))}
      </ul>
    </Dialog>
  );
}

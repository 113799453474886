import React from 'react';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

import StyledLastCartOrder from './LastCartOrder.style';
import { ReactComponent as DeliveryIco } from '../../resources/svg/ico-albaranes.svg';
import { ReactComponent as OrderIco } from '../../resources/svg/ico-volver.svg';
import CurrencyDisplay from '../CurrencyDisplay/CurrencyDisplay';

export default function LastCartOrder({ type, code, date, total }) {
  const formatDate = () => {
    return format(parseISO(date, 'dd/MM/yyyyyy', new Date()), "d MMM'.", {
      locale: es
    });
  };

  return (
    <StyledLastCartOrder>
      {type === 'order' && <OrderIco />}
      {type === 'delivery' && <DeliveryIco />}
      {code && (
        <span className='code'>
          {code}
          {(date || total) && ' -'}
        </span>
      )}
      {date && <span>{formatDate()}</span>}
      <span>{code ? <CurrencyDisplay number={total} /> : ''}</span>
      {!code && !date && (!total || total === '0') && <span>-</span>}
    </StyledLastCartOrder>
  );
}

import styled from 'styled-components';

const StyledFilterItems = styled.div`
  flex: 1;
  input {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lighterGray};
    border: none;
    padding: 1rem 2rem;
    border-radius: 2rem;
    :focus {
      outline: none;
    }
  }
`;

export default StyledFilterItems;

import styled from 'styled-components';
import TableHead from '@material-ui/core/TableHead';

const StyledTableHeader = styled(TableHead)`
  border-top: 1px solid ${({ theme }) => theme.colors.gray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  .MuiTableCell-root {
    border-bottom: none;
  }
  .cell-head {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fonts.s};
  }
`;

export default StyledTableHeader;

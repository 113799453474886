import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import { format } from 'date-fns';

import {
  Text,
  Select,
  Button,
  TypeAhead,
  Datepicker,
  FormCheckbox
} from '../../../components';
import CompaniesService from '../../../services/Company';
import StyledDeliveriesFilter from './DeliveriesFilter.style';
import { parseQueryDate } from '../../../utils/functions';

export default function DeliveriesFilter({
  handleCloseFilter,
  setFilterActive,
  filterOptions,
  handleFilter,
  filterData
}) {
  const { handleSubmit, control, watch, reset, getValues, setValue } = useForm({
    defaultValues: {
      Estado: null,
      FechaIni: '',
      FechaFin: '',
      IdCliente: null,
      range: null,
      status: null,
      client: null
    },
    shouldUnregister: false
  });
  const [filterError, setFilterError] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [clientOptions, setClientOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const watchStatus = watch('status');
  const watchRange = watch('range');
  const watchClient = watch('client');
  const { deliveryStatus } = filterOptions;
  const companiesService = new CompaniesService();
  const [selectedClient, setSelectedClient] = useState(null);

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });

  const updateQuery = (value) => {
    history.push({
      path: history.location.pathname,
      search: qs.stringify(
        {
          ...query,
          ...value
        },
        { addQueryPrefix: true }
      )
    });
  };

  useEffect(async () => {
    if (filterData || query) {
      const { Estado, FechaIni, FechaFin, IdCliente } = filterData || query;
      const filterValues = {};
      if (Estado) {
        filterValues.Estado = +Estado;
        filterValues.status = true;
      }
      if (FechaFin && FechaFin) {
        const parsedStart = parseQueryDate(FechaIni);
        const parsedEnd = parseQueryDate(FechaFin);
        filterValues.FechaIni = parsedStart;
        filterValues.FechaFin = parsedEnd;
        filterValues.range = true;
      }

      if (IdCliente) {
        setIsLoading(true);
        filterValues.IdCliente = +IdCliente;
        filterValues.client = true;
        try {
          const clientResponse = await companiesService.getCompany(
            parseInt(IdCliente, 10)
          );
          setSelectedClient({
            value: clientResponse.Id,
            label: clientResponse.Nombre
          });
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }
      reset(filterValues);
    }
  }, []);

  const handleClientSearch = async (searchQuery) => {
    setIsLoading(true);
    try {
      const response = await companiesService.getCompanies(
        qs.stringify(
          { Busqueda: searchQuery, TipoRelacion: 0 },
          { addQueryPrefix: true }
        )
      );
      const clientsList = response.Datos.$values.map((item) => ({
        value: item.Id,
        label: item.Nombre
      }));
      setClientOptions(clientsList);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleClientSelect = (option) => {
    if (!option.length) {
      setValue('client', null);
      setSelectedClient(null);
    } else {
      setValue('client', option[0].value);
      setSelectedClient(option[0]);
    }
  };

  const onSubmit = (values) => {
    const filterQuery = {
      Estado: undefined,
      FechaFin: undefined,
      FechaIni: undefined,
      IdCliente: undefined,
      Paginacion: 0
    };
    if (!watchStatus && !watchRange && !watchClient) {
      setFilterError('Selecciona una opción');
      return;
    }
    if (watchStatus) {
      if (values.Estado === undefined || values.Estado === null) {
        setFilterError('Selecciona un estado');
        return;
      }
      filterQuery.Estado = values.Estado;
    }
    if (watchRange) {
      if (!values.FechaFin || !values.FechaFin) {
        setFilterError('Selecciona las dos fechas');
        return;
      }
      const fromDate = format(values.FechaIni, 'yyyy-MM-dd');
      const toDate = format(values.FechaFin, 'yyyy-MM-dd');
      filterQuery.FechaFin = toDate;
      filterQuery.FechaIni = fromDate;
    }
    if (watchClient) {
      if (!values.client) {
        setFilterError('Selecciona un cliente');
        return;
      }
      filterQuery.IdCliente = values.client;
    }
    if (handleFilter) {
      handleFilter(filterQuery);
    } else {
      updateQuery(filterQuery);
    }
    setFilterError(null);
    handleCloseFilter();
  };

  const handleClearFilters = () => {
    reset();
    if (handleFilter) {
      handleFilter({
        Estado: undefined,
        FechaFin: undefined,
        FechaIni: undefined,
        IdCliente: undefined,
        Paginacion: undefined
      });
    } else {
      updateQuery({
        Estado: undefined,
        FechaFin: undefined,
        FechaIni: undefined,
        IdCliente: undefined,
        Paginacion: undefined
      });
    }
    handleCloseFilter();
    setFilterActive(null);
  };

  return (
    <StyledDeliveriesFilter>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text className='clear-filters' onClick={handleClearFilters}>
          Borrar filtros
        </Text>
        <div className='filter-item'>
          <FormCheckbox
            label='Por Estado'
            name='status'
            control={control}
            className='filter-checkbox'
          />
          <Select
            name='Estado'
            control={control}
            options={deliveryStatus}
            className='filter-select'
            disabled={!watchStatus}
            placeholder='selecciona estado'
            defaultValue={getValues('Estado')}
            searchable
          />
        </div>
        <div className='filter-item'>
          <FormCheckbox
            label='Desde/hasta'
            name='range'
            control={control}
            className='filter-checkbox'
          />
          <Datepicker
            name='FechaIni'
            control={control}
            disabled={!watchRange}
            placeholder='Desde...'
            className='date-picker'
          />
          <Datepicker
            name='FechaFin'
            control={control}
            placeholder='Hasta...'
            disabled={!watchRange}
            className='date-picker'
          />
        </div>
        {!handleFilter && (
          <div className='filter-item'>
            <FormCheckbox
              label='Por cliente'
              name='client'
              control={control}
              className='filter-checkbox'
            />
            <TypeAhead
              id='supplier-search'
              options={clientOptions}
              onSearch={handleClientSearch}
              onSelect={handleClientSelect}
              placeholder='busca cliente'
              isLoading={isLoading}
              disabled={!watchClient}
              className='client-typeahead'
              selected={selectedClient ? [selectedClient] : []}
            />
          </div>
        )}
        <div className='filters-search'>
          {filterError && <Text color='red'>{filterError}</Text>}
          <Button type='button' onClick={handleSubmit(onSubmit)}>
            Buscar
          </Button>
        </div>
      </form>
    </StyledDeliveriesFilter>
  );
}

import styled from 'styled-components';
import MUICheckbox from '@material-ui/core/Checkbox';

const Checkbox = styled(MUICheckbox)`
  &.Mui-checked {
    color: ${({ theme }) => theme.colors.orange};
  }
`;

export default Checkbox;

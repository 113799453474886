import React, { useState } from 'react';

import StyledDeliveriesModal from './DeliveriesModal.style';
import { FullModal, ItemsTable, DeleteDialog } from '../../../components';
import { ReactComponent as FacturasIco } from '../../../resources/svg/ico-facturas.svg';
import columns from './columns';
import RowItems from './RowItems/RowItems';

export default function DeliveriesModal({
  open,
  handleClose,
  client,
  deliveries,
  deleteDeliveries
}) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selected, setSelected] = useState([]);

  const handleClickDelete = () => setConfirmDelete(true);

  const handleCloseDialog = () => setConfirmDelete(false);

  const handleDeleteDeliveries = () => {
    deleteDeliveries(selected);
    setConfirmDelete(false);
    setSelected([]);
    if (selected.length === deliveries.length) {
      handleClose();
    }
  };
  return (
    <FullModal
      open={open}
      handleClose={handleClose}
      title={`Albaranes${client ? ` - ${client}` : ''}`}
      Icon={<FacturasIco />}
    >
      <StyledDeliveriesModal>
        <ItemsTable
          handleClickDelete={handleClickDelete}
          title='Albaranes'
          columns={columns}
          items={deliveries}
          isLoading={false}
          selected={selected}
          setSelected={setSelected}
          RowItems={RowItems}
        />
        <DeleteDialog
          open={confirmDelete}
          close={handleCloseDialog}
          handleAccept={handleDeleteDeliveries}
          quantity={selected.length}
          singular='albarán'
          plural='albaranes'
        />
      </StyledDeliveriesModal>
    </FullModal>
  );
}

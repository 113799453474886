import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import {
  CurrencyDisplay,
  DateDisplay,
  ClickableRowItem
} from '../../../../components';
import deliveryStatus from '../../../../utils/deliveryStatus';

export default function RowItems({ item, rowHandlers }) {
  const {
    Id,
    Fecha,
    IdPedido,
    Estado,
    FacturaCOD,
    FacturaId,
    Abonado,
    Pendiente,
    Total,
    user
  } = item;

  const { handleOpenOrder, handleOpenInvoice } = rowHandlers;

  const getStatus = (status) => {
    return deliveryStatus.find((i) => i.value === status)?.label;
  };

  const handleClickOrder = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleOpenOrder(IdPedido);
  };

  const handleClickInvoice = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleOpenInvoice(FacturaId);
  };

  return (
    <>
      <TableCell className='cell-body'>{Id}</TableCell>
      <TableCell className='cell-body'>
        <DateDisplay date={Fecha} />
      </TableCell>
      <TableCell className='cell-body'>
        <ClickableRowItem onClick={(e) => handleClickOrder(e)}>
          {IdPedido}
        </ClickableRowItem>
      </TableCell>
      <TableCell className='cell-body'>{getStatus(Estado)}</TableCell>
      <TableCell className='cell-body'>
        <ClickableRowItem onClick={(e) => handleClickInvoice(e)}>
          {FacturaCOD}
        </ClickableRowItem>
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={Abonado} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={Pendiente} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={Total} />
      </TableCell>
      <TableCell className='cell-body'>{user}</TableCell>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';

// import Index from './pages/Index/Index';
import Clients from './pages/Clients/Clients';
import Suppliers from './pages/Suppliers/Suppliers';
import Orders from './pages/Orders/Orders';
import Articles from './pages/Articles/Articles';
import Deliveries from './pages/Deliveries/Deliveries';
import Invoices from './pages/Invoices/Invoices';
import Login from './pages/Login/Login';
import GlobalStyle from './styles/GlobalStyle';
import theme from './styles/theme';
import Layout from './styles/Layout';
import AuthProvider from './providers/AuthProvider';
import SnackbarProvider from './providers/SnackbarProvider';
import { Nav } from './molecules';
import TopNav from './components/TopNav/TopNav';
import PrivateRoute from './utils/PrivateRoute';
import useWindowSize from './hooks/UseWindowSize';

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const size = useWindowSize();
  const handleCollapsed = () => setCollapsed(!collapsed);

  useEffect(() => {
    if (size.width <= 1024) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [size]);

  return (
    <AuthProvider>
      <SnackbarProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Router>
            <Helmet>
              <title>Pascual Vinuesa</title>
            </Helmet>
            <Switch>
              <Route exact path='/login'>
                <Login />
              </Route>
              <Route>
                <Layout>
                  <TopNav handleCollapsed={handleCollapsed} />
                  <div className='page-content'>
                    <Nav collapsed={collapsed} />
                    <Switch>
                      <PrivateRoute exact path='/index.html'>
                        <Redirect to='/' />
                      </PrivateRoute>
                      <PrivateRoute exact path='/'>
                        {/* <Index /> */}
                        <Redirect to='/pedidos' />
                      </PrivateRoute>
                      <PrivateRoute path='/pedidos'>
                        <Orders />
                      </PrivateRoute>
                      <PrivateRoute path='/albaranes'>
                        <Deliveries />
                      </PrivateRoute>
                      <PrivateRoute path='/facturas'>
                        <Invoices />
                      </PrivateRoute>
                      <PrivateRoute path='/clientes'>
                        <Clients />
                      </PrivateRoute>
                      <PrivateRoute path='/proveedores'>
                        <Suppliers />
                      </PrivateRoute>
                      <PrivateRoute path='/articulos'>
                        <Articles />
                      </PrivateRoute>
                    </Switch>
                  </div>
                </Layout>
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>
      </SnackbarProvider>
    </AuthProvider>
  );
}

export default App;

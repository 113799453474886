const theme = {
  colors: {
    text: '#333333',
    orange: '#F5992D',
    blue: '#1F389F',
    gray: '#ADB5BD',
    darkGray: '#AAA59F',
    lightGray: '#E9ECEF',
    lighterGray: '#EAEAEA',
    navBackground: '#DDDCDB',
    itemsOptionsText: '#6C757D',
    border: '#707070',
    white: '#FFFFFF',
    red: '#C91414'
  },
  fonts: {
    s: '1rem',
    m: 'calc(1rem * 1.333)',
    l: 'calc((1rem * 1.333)*1.333)',
    xl: 'calc(((1rem * 1.333)*1.333)*1.333)'
  }
};

export default theme;

import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import { CurrencyDisplay, DateDisplay } from '../../../../components';
import { handleDownload } from '../../../../utils/functions';
import StyledDownloadItem from './RowItems.style';

export default function RowItems({ item }) {
  const downloadFile = (e, file) => {
    e.preventDefault();
    e.stopPropagation();
    handleDownload(file, true);
  };

  const listFiles = (files) => {
    return files.map((file) => (
      <StyledDownloadItem
        key={file.Id}
        type='button'
        onClick={(e) => downloadFile(e, file.Url)}
      >
        {file.Nombre}
      </StyledDownloadItem>
    ));
  };
  return (
    <>
      <TableCell className='cell-body'>{item.Codigo}</TableCell>
      <TableCell className='cell-body'>
        <DateDisplay date={item.Fecha} />
      </TableCell>
      <TableCell className='cell-body'>
        <CurrencyDisplay number={item.Total} />
      </TableCell>
      <TableCell className='cell-body'>{item.Albaranes.join(', ')}</TableCell>
      <TableCell className='cell-body'>{listFiles(item.Adjuntos)}</TableCell>
      <TableCell className='cell-body'>{item.user}</TableCell>
    </>
  );
}

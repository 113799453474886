import styled from 'styled-components';
import TableRow from '@material-ui/core/TableRow';
import { rgba } from 'polished';

const StyledRow = styled(TableRow)`
  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'default')};
  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: ${({ theme }) => rgba(theme.colors.orange, 0.2)};
  }
`;

export default StyledRow;

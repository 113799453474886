/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import qs from 'qs';

import ClientsFilter from './ClientsFilter/ClientsFilter';
import RowItems from './RowItems/RowItems';
import StyledClients from './Clients.style';
import CompaniesService from '../../services/Company';
import {
  Loader,
  DeleteDialog,
  Button,
  FilterItems,
  ItemsTable
} from '../../components';
import { DeleteErrorDialog } from '../../molecules';
import ClientModal from '../../modals/ClientModal/ClientModal';
import { useDispatchSnackbar } from '../../providers/SnackbarProvider';
import { useAuth, useDispatchAuth } from '../../providers/AuthProvider';
import listTypes from '../../utils/listTypes';
import useQueryString from '../../hooks/useQueryString';
import columns from './columns';
import { handleDownload } from '../../utils/functions';

export default function Clients() {
  const companiesService = new CompaniesService();
  const [clients, setClients] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 30,
    page: 1,
    prev: false,
    next: false
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [filterActive, setFilterActive] = useState(null);
  const [reload, setReload] = useState(true);
  const [responseErrors, setResponseErrors] = useState([]);
  const { lists } = useAuth();
  const agentsList = lists.filter(
    (item) => item.type === listTypes.representante
  );
  const clientsGroupList = lists.filter(
    (item) => item.type === listTypes.grupoCaucion
  );

  const { query, updateQuery } = useQueryString();
  const { client } = query || {};

  const dispatchSnackbar = useDispatchSnackbar();
  const dispatch = useDispatchAuth();

  const handleRouterUpdate = (value) => {
    if (!isLoading) {
      updateQuery(value);
    }
  };

  const handleFilterActive = (filterQuery) => {
    let hasFilter = false;
    const filters = [];
    if (filterQuery.IdRepresentante) {
      const agentName = agentsList.find(
        (a) => a.value.toString() === filterQuery.IdRepresentante
      );
      hasFilter = true;
      filters.push(`Representante: ${agentName?.label}`);
    }
    if (filterQuery.IdGrupoCaucion) {
      const clientGroupName = clientsGroupList.find(
        (c) => c.value.toString() === filterQuery.IdGrupoCaucion
      );
      hasFilter = true;
      filters.push(`Grupo de cliente: ${clientGroupName?.label}`);
    }
    if (hasFilter) {
      setFilterActive(filters.join(' | '));
    }
  };

  const handleCloseDialog = () => setConfirmDelete(false);

  const handleDeleteUsers = async () => {
    setIsLoading(true);
    setConfirmDelete(false);
    try {
      const response = await companiesService.deleteCompanies(selected);
      const ErrorsList = response.$values.filter((item) => !!item.Error);
      if (ErrorsList.length) {
        setResponseErrors(ErrorsList);
      } else {
        dispatchSnackbar({
          type: 'SET',
          payload: 'Clientes eliminados'
        });
      }
      setSelected([]);
      handleRouterUpdate();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        type: 'ERROR',
        payload: 'Ha ocurrido un error'
      });
      setIsLoading(false);
    }
  };

  const handleClickDelete = () => setConfirmDelete(true);

  const handleNewClientButton = () => {
    handleRouterUpdate({ client: 'new' });
  };

  const handleEditClient = (id) => {
    handleRouterUpdate({ client: id });
  };

  const handleExportCSV = async () => {
    setIsLoading(true);
    try {
      const response = await companiesService.csv({
        ...query,
        TipoRelacion: 0
      });
      handleDownload(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch({
      type: 'SET_PAGE',
      payload: 'clientes'
    });
  }, []);

  useEffect(async () => {
    if (!client && reload && query instanceof Object) {
      setIsLoading(true);
      try {
        const clientsData = await companiesService.getCompanies(
          qs.stringify({ ...query, TipoRelacion: 0 }, { addQueryPrefix: true })
        );
        const {
          Total: total,
          PaginaActual: page,
          Paginacion: limit,
          TieneAnterior: prev,
          TieneSiguiente: next,
          Datos: list
        } = clientsData;
        const { $values: clientsList } = list;
        setPagination({ total, page, limit, prev, next });
        setClients(
          clientsList.map((clientItem) => ({
            Id: clientItem.Id,
            Nombre: clientItem.Nombre,
            CIF: clientItem.CIF,
            EmailFacturas: clientItem.EmailFacturas,
            CodigoPedido: clientItem.CodigoPedido,
            FechaPedido: clientItem.FechaPedido,
            TotalPedido: clientItem.TotalPedido
          }))
        );
        handleFilterActive(query);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else if (!reload) {
      setReload(true);
    }
    return () => {
      setClients([]);
    };
  }, [query]);

  return (
    <StyledClients filterActive={filterActive}>
      <Loader show={isLoading} />
      <Helmet>
        <title>Clientes | Pascual Vinuesa</title>
      </Helmet>
      <div className='top-section'>
        <FilterItems isLoading={isLoading} />
        <Button outlined onClick={handleExportCSV}>
          Exportar Excel
        </Button>
        <Button onClick={handleNewClientButton}>Crear cliente</Button>
      </div>
      <ItemsTable
        handleClickDelete={handleClickDelete}
        FilterForm={ClientsFilter}
        filterLabel='Filtrar clientes'
        filterActive={filterActive}
        setFilterActive={setFilterActive}
        title='Clientes'
        columns={columns}
        items={clients}
        isLoading={isLoading}
        handleClickRow={handleEditClient}
        RowItems={RowItems}
        pagination={pagination}
        selected={selected}
        setSelected={setSelected}
      />
      <DeleteDialog
        open={confirmDelete}
        close={handleCloseDialog}
        handleAccept={handleDeleteUsers}
        singular='cliente'
        plural='clientes'
        quantity={selected.length}
      />
      <DeleteErrorDialog
        errors={responseErrors}
        handleClose={() => setResponseErrors([])}
      />
      {client && <ClientModal setReload={setReload} />}
    </StyledClients>
  );
}

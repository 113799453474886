import styled from 'styled-components';

const StyledClients = styled.div`
  position: relative;
  .top-section {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
    button {
      margin-left: 1rem;
      padding: 1rem 2rem;
      border-radius: 2rem;
    }
  }
`;

export default StyledClients;

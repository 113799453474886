import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';

import {
  Text,
  Select,
  Button,
  TypeAhead,
  FormCheckbox
} from '../../../components';
import StyledArticlesFilter from './ArticlesFilter.style';
import CompaniesService from '../../../services/Company';

export default function ArticlesFilter({
  handleCloseFilter,
  setFilterActive,
  filterOptions,
  handleFilter,
  filterData
}) {
  const { handleSubmit, control, watch, reset, getValues, setValue } = useForm({
    defaultValues: {
      category: null,
      brand: null,
      manufacturer: null,
      supplier: null,
      client: null,
      categoryCheck: null,
      brandCheck: null,
      manufacturerCheck: null,
      supplierCheck: null,
      clientCheck: null
    },
    shouldUnregister: false
  });
  const [filterError, setFilterError] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const watchCategory = watch('categoryCheck');
  const watchBrand = watch('brandCheck');
  const watchManufacturer = watch('manufacturerCheck');
  const watchSupplier = watch('supplierCheck');
  const watchClient = watch('clientCheck');
  const { manufacturers, brands, categories } = filterOptions;
  const companiesService = new CompaniesService();
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });

  const updateQuery = (value) => {
    history.push({
      path: history.location.pathname,
      search: qs.stringify(
        {
          ...query,
          ...value
        },
        { addQueryPrefix: true }
      )
    });
  };

  useEffect(async () => {
    if (filterData || query) {
      const { IdCategoria, IdMarca, IdFabricante, IdProveedor, IdCliente } =
        filterData || query;
      const filterValues = {};
      if (IdCategoria) {
        filterValues.category = +IdCategoria;
        filterValues.categoryCheck = true;
      }
      if (IdMarca) {
        filterValues.brand = +IdMarca;
        filterValues.brandCheck = true;
      }
      if (IdFabricante) {
        filterValues.manufacturer = +IdFabricante;
        filterValues.manufacturerCheck = true;
      }
      if (IdProveedor) {
        setIsLoading(true);
        filterValues.supplier = +IdProveedor;
        filterValues.supplierCheck = true;
        try {
          const supplierResponse = await companiesService.getCompany(
            parseInt(IdProveedor, 10)
          );
          setSelectedSupplier({
            value: supplierResponse.Id,
            label: supplierResponse.Nombre
          });
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }
      if (IdCliente) {
        setIsLoading(true);
        filterValues.client = +IdCliente;
        filterValues.clientCheck = true;
        try {
          const clientResponse = await companiesService.getCompany(
            parseInt(IdCliente, 10)
          );
          setSelectedClient({
            value: clientResponse.Id,
            label: clientResponse.Nombre
          });
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }
      reset(filterValues);
    }
  }, []);

  const handleSupplierSearch = async (searchQuery) => {
    setIsLoading(true);
    try {
      const response = await companiesService.getCompanies(
        qs.stringify(
          { Busqueda: searchQuery, TipoRelacion: 1 },
          { addQueryPrefix: true }
        )
      );
      const suppliersList = response.Datos.$values.map((item) => ({
        value: item.Id,
        label: item.Nombre
      }));
      setSupplierOptions(suppliersList);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleSupplierSelect = (option) => {
    if (!option.length) {
      setValue('supplier', null);
      setSelectedSupplier(null);
    } else {
      setValue('supplier', option[0].value);
      setSelectedSupplier(option[0]);
    }
  };

  const handleClientSearch = async (searchQuery) => {
    setIsLoading(true);
    try {
      const response = await companiesService.getCompanies(
        qs.stringify(
          { Busqueda: searchQuery, TipoRelacion: 0 },
          { addQueryPrefix: true }
        )
      );
      const clientsList = response.Datos.$values.map((item) => ({
        value: item.Id,
        label: item.Nombre
      }));
      setClientOptions(clientsList);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleClientSelect = (option) => {
    if (!option.length) {
      setValue('client', null);
      setSelectedClient(null);
    } else {
      setValue('client', option[0].value);
      setSelectedClient(option[0]);
    }
  };

  const onSubmit = (values) => {
    const filterQuery = {
      IdCategoria: undefined,
      IdMarca: undefined,
      IdFabricante: undefined,
      IdProveedor: undefined,
      IdCliente: undefined
    };
    if (
      !watchBrand &&
      !watchCategory &&
      !watchManufacturer &&
      !watchSupplier &&
      !watchClient
    ) {
      setFilterError('Selecciona una opción');
      return;
    }
    if (watchCategory) {
      if (!values.category) {
        setFilterError('Selecciona una categoría');
        return;
      }
      filterQuery.IdCategoria = values.category;
    }
    if (watchBrand) {
      if (!values.brand) {
        setFilterError('Selecciona una marca');
        return;
      }
      filterQuery.IdMarca = values.brand;
    }
    if (watchManufacturer) {
      if (!values.manufacturer) {
        setFilterError('Selecciona un fabricante');
        return;
      }
      filterQuery.IdFabricante = values.manufacturer;
    }
    if (watchSupplier) {
      if (!values.supplier) {
        setFilterError('Selecciona un proveedor');
        return;
      }
      filterQuery.IdProveedor = values.supplier;
    }
    if (watchClient) {
      if (!values.client) {
        setFilterError('Selecciona un client');
        return;
      }
      filterQuery.IdCliente = values.client;
    }
    if (handleFilter) {
      handleFilter(filterQuery);
    } else {
      updateQuery(filterQuery);
    }
    setFilterError(null);
    handleCloseFilter();
  };

  const handleClearFilters = () => {
    reset();
    if (handleFilter) {
      handleFilter({
        IdCategoria: undefined,
        IdMarca: undefined,
        IdFabricante: undefined,
        IdProveedor: undefined,
        IdCliente: undefined
      });
    } else {
      updateQuery({
        IdCategoria: undefined,
        IdMarca: undefined,
        IdFabricante: undefined,
        IdProveedor: undefined,
        IdCliente: undefined
      });
    }
    handleCloseFilter();
    setFilterActive(null);
  };

  return (
    <StyledArticlesFilter>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text className='clear-filters' onClick={handleClearFilters}>
          Borrar filtros
        </Text>
        <div className='filter-item'>
          <FormCheckbox
            label='Por fabricante'
            name='manufacturerCheck'
            control={control}
            className='filter-checkbox'
          />
          <Select
            name='manufacturer'
            control={control}
            options={manufacturers}
            className='filter-select'
            disabled={!watchManufacturer}
            placeholder='selecciona fabricante'
            defaultValue={getValues('manufacturer')}
            searchable
          />
        </div>
        <div className='filter-item'>
          <FormCheckbox
            label='Por proveedor'
            name='supplierCheck'
            control={control}
            className='filter-checkbox'
          />
          <TypeAhead
            id='supplier-search'
            options={supplierOptions}
            onSearch={handleSupplierSearch}
            onSelect={handleSupplierSelect}
            placeholder='busca proveedor'
            isLoading={isLoading}
            disabled={!watchSupplier}
            className='supplier-typeahead'
            selected={selectedSupplier ? [selectedSupplier] : []}
          />
        </div>
        <div className='filter-item'>
          <FormCheckbox
            label='Por cliente'
            name='clientCheck'
            control={control}
            className='filter-checkbox'
          />
          <TypeAhead
            id='client-search'
            options={clientOptions}
            onSearch={handleClientSearch}
            onSelect={handleClientSelect}
            placeholder='busca cliente'
            isLoading={isLoading}
            disabled={!watchClient}
            className='supplier-typeahead'
            selected={selectedClient ? [selectedClient] : []}
          />
        </div>
        <div className='filter-item'>
          <FormCheckbox
            label='Por marca'
            name='brandCheck'
            control={control}
            className='filter-checkbox'
          />
          <Select
            name='brand'
            control={control}
            options={brands}
            className='filter-select'
            disabled={!watchBrand}
            placeholder='selecciona marca'
            defaultValue={getValues('brand')}
            searchable
          />
        </div>
        <div className='filter-item'>
          <FormCheckbox
            label='Por categoría'
            name='categoryCheck'
            control={control}
            className='filter-checkbox'
          />
          <Select
            name='category'
            control={control}
            options={categories}
            className='filter-select'
            disabled={!watchCategory}
            placeholder='selecciona categoría'
            defaultValue={getValues('category')}
            searchable
          />
        </div>
        <div className='filters-search'>
          {filterError && <Text color='red'>{filterError}</Text>}
          <Button type='button' onClick={handleSubmit(onSubmit)}>
            Buscar
          </Button>
        </div>
      </form>
    </StyledArticlesFilter>
  );
}
